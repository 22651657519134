import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Components
// Components: Global
import Container from '@mui/material/Container';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';


export const BottomCard = ({theme}) => {
    return (
        <Container maxWidth="100%" sx={{m:0, position: 'sticky', bottom: 0, zIndex: 1}}>
            <BottomNavigation elevation={5} sx={{ width: '100%'}}>
                <BottomNavigationAction component={RouterLink} to="/app" sx={{maxWidth: '100%', color: theme.palette.secondary.main, borderTopLeftRadius: 'inherit' }} color="inherit" selected="secondary" aria-label="home" icon={<HomeIcon />} />
                <BottomNavigationAction component={RouterLink} to="/app/new" sx={{maxWidth: '100%', color: theme.palette.secondary.main }} color="inherit" selected="secondary" aria-label="add" icon={<AddIcon />} />
                <BottomNavigationAction component={RouterLink} to="/app/menu" sx={{maxWidth: '100%', color: theme.palette.secondary.main, borderTopRightRadius: 'inherit' }} color="inherit" selected="secondary" aria-label="menu" icon={<MenuIcon />} />
                <BottomNavigationAction sx={{maxWidth: '100%', color: theme.palette.secondary.main, borderTopRightRadius: 'inherit', display: {lg:'none'} }} color="inherit" selected="secondary" aria-label="help"  />
            </BottomNavigation>
        </Container>
    );
}

export default BottomCard;