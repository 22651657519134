import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, Link as RouterLink } from 'react-router-dom';

import * as R from 'ramda';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// Components: Views
import TenantsMenuList from './list';
import TenantsMenuFooter from './footer';
import { GetTenants } from '../../../actions/tenant-api';
import * as TentantLoadings from '../../../constants/loadings/tenant';

// Components: Common
import Loading from '../../../_common/loading';
import ItemCard from '../../_common/itemCard';
import { alphabet } from '../../../constants/utils/alphabet';
import { useInfinitScroll } from '../../../hooks/useInfinitScroll';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { START_QUERY } from '../../../constants/utils/filterQuery';



export const TenantsMenu = ({ theme, userState, companyState, tenantState, assetState, subcontractorState, collaboratorState, showView, setShowView }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { page, start } = useSearchParams();
    const { lastCardRef, cardWrapperRef } = useInfinitScroll(page, tenantState.hasMorePage, location);


    useEffect(() => {
        let filter;
        filter = R.isNil(start) ? filter : { [START_QUERY.firstOrLastName]: start };
        dispatch(GetTenants({ pageNumber: page, filter }));
        // eslint-disable-next-line 
    }, [location.search]);


    return (
        <Box component="aside" className="aside" display="flex" flex="1 1 auto" py={2} gap={2} pt={{ md: 4 }} height="100%" maxWidth="100%" sx={{ flexDirection: 'column' }}>
            {/* borderRight: '2px solid transparent',  */}
            <Container disableGutters maxWidth="100%" sx={{ maxWidth: "100%", height: "100%", display: "inherit", flexDirection: "inherit", overflow: "hidden auto" }}>
                <Typography component="h3" className="sr-only">Menu</Typography>
                <Stack direction="row" flex="1 1 auto" height="100%" maxWidth="100%" spacing={0}>
                    <Stack direction="column" flex="1 1 auto" height="100%" >
                        <Container ref={cardWrapperRef} maxWidth="100%" sx={{ paddingRight: { sm: '12px' }, maxWidth: "100%", height: "100%", overflow: "hidden auto", display: "inherit", flexDirection: "inherit", gap: 1 }}>
                            {
                                tenantState.isLoading.includes(TentantLoadings.FETCHING_TENANTS) ?
                                    <Loading />
                                    :
                                    R.isNil(tenantState.tenants) || R.isEmpty(tenantState.tenants) ?
                                        <ItemCard /> :
                                        <TenantsMenuList setShowView={setShowView} lastCardRef={lastCardRef} showView={showView} theme={theme} tenants={tenantState.tenants} sx={{ marginTop: 2, maxWidth: "100%", height: "inherit" }} />
                            }
                        </Container>
                    </Stack>
                    <Stack direction="column" flex="0" height="100%">
                        <Container maxWidth="100%" sx={{ paddingX: { sm: '12px' }, maxWidth: "100%", height: "100%", overflow: "hidden auto", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            {alphabet.map((letter) =>
                                <Box fontSize="small" color="text.disabled" to={letter === "#" ? `${location.pathname}` : `${location.pathname}?start=${letter}`} component={RouterLink} fontWeight="bold" role="button" key={letter} >
                                    {letter}
                                </Box>
                            )}
                        </Container>
                    </Stack>
                </Stack>
            </Container>
            <TenantsMenuFooter theme={theme} />
        </Box >
    );
};

export default TenantsMenu;
