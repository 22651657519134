import { createSlice } from '@reduxjs/toolkit';
import * as Loadings from '../constants/loadings/user';



const initState = {
    isLoading: [],
    user: undefined,
    isRegistered: undefined,
    isOpen: false,
    error: {}
};

const UserReducer = createSlice({
    name: 'user',
    initialState: { ...initState },
    reducers: {
        //#region USER
        getUserRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_USER)) {
                state.isLoading.push(Loadings.FETCHING_USER);
            }
        },
        getUserSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_USER);
            state.user = payload;
        },
        getUserFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_USER);
        },

        updateUserRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_USER)) {
                state.isLoading.push(Loadings.UPDATING_USER);
            }
        },
        updateUserSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_USER);
            state.user = { ...state.user, ...payload };
        },
        updateUserFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_USER);
        },
        //#region 

        //#region REGISTER
        registerUserRequest(state, { type,payload }) {
            console.log('type',type);
            if (!state.isLoading.includes(Loadings.REGISTERING_USER)) {
                state.isLoading.push(Loadings.REGISTERING_USER);
            }
            state.isRegistered = undefined;
        },
        registerUserSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.REGISTERING_USER);
            state.isRegistered = true;
        },
        registerUserFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.REGISTERING_USER);
            state.isRegistered = false;
            state.error = payload.errors;
        },
        //#region 

        //#region AVAILABLE SERVICES
        getAvailableServicesRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_AVAILABLE_SERVICES)) {
                state.isLoading.push(Loadings.FETCHING_AVAILABLE_SERVICES);
            }
        },
        getAvailableServicesSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_AVAILABLE_SERVICES);
            state.services = payload;
        },
        getAvailableServicesFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_AVAILABLE_SERVICES);
        },
        //#region 

        //#region GOOGLE AUTHENTICATION
        googleAuthenticationRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_GOOGLE_AUTHENTICATION)) {
                state.isLoading.push(Loadings.FETCHING_GOOGLE_AUTHENTICATION);
            }
        },
        googleAuthenticationSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_GOOGLE_AUTHENTICATION);
        },
        googleAuthenticationFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_GOOGLE_AUTHENTICATION);
        },
        //#region

        //#region GOOGLE REVOKE
        googleRevokeRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.REVOKING_GOOGLE_AUTHENTICATION)) {
                state.isLoading.push(Loadings.REVOKING_GOOGLE_AUTHENTICATION);
            }
        },
        googleRevokeSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.REVOKING_GOOGLE_AUTHENTICATION);
            state.services = state.services?.map(s => {
                if (s.type === "GoogleDrive") s.isConnected = false;
                return s;
            });
        },
        googleRevokeFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.REVOKING_GOOGLE_AUTHENTICATION);
        },
        updateUserPermissionSuccess(state, { payload }) {
            state.user.companyAccesses.push(payload);
        },

        setIsOpen(state, { payload }) {
            state.isOpen = payload;
            // if (payload && R.isNil(payload.message)) {
            //     state.permissionMessage = 'Vous devez créer une nouvelle société.';
            // } else {
            //     state.permissionMessage = null;
            // }
        },
        //#region 
    }
});

export const userActions = UserReducer.actions;
export default UserReducer.reducer;