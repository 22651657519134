import React from 'react';

// Components
// Components: Global
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Components: Views
import BanksMenu from './menu';
import BanksView from './view';

export const Banks = ({ theme, userState, companyState, tenantState, assetState, assetGroupState, subcontractorState, collaboratorState, documentState, rentalState, paymentState }) => {

    return (
        <Box component="section" className="layout" flex="1 1 auto" alignItems="center" width="100%" height="calc(100% - 6.5rem)" overflow="hidden auto" mt={{ md: -2 }}>
            <Typography component="h1" className="sr-only">Content</Typography>
            <BanksMenu theme={theme} userState={userState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} />
            <BanksView theme={theme} userState={userState} tenantState={tenantState} documentState={documentState} companyState={companyState} rentalState={rentalState} assetState={assetState} assetGroupState={assetGroupState} paymentState={paymentState} subcontractorState={subcontractorState} />
        </Box>
    );
};

export default Banks;
