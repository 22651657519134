import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import { useParams } from 'react-router';

// Components
// Components: Global

// Components: Common
import * as LoadingsDocument from '../../constants/loadings/document';
import * as LoadingsCompany from '../../constants/loadings/company';
import * as LoadingsTenant from '../../constants/loadings/tenant';
import * as LoadingsAssetGroup from '../../constants/loadings/assetGroup';
import * as LoadingsSubcontractor from '../../constants/loadings/subcontractor';
import * as LoadingsAsset from '../../constants/loadings/asset';
import { initialAssetForm, initialAssetGroupForm, initialCompanyForm, initialDocumentFormData, initialSubcontractorForm, initialTenantForm } from '../../constants/utils/formInitialData';
import { GetCompanies } from '../../actions/company-api';
import { GetAssets } from '../../actions/asset-api';
import { GetTenants } from '../../actions/tenant-api';
import { GetAssetGroups } from '../../actions/assetGroup-api';
import { GetSubcontractors } from '../../actions/subcontractor-api';
import Loading from '../../_common/loading';
import MainDocument from '../_common/forms/layout/document';
import { GetDocument, GetDocuments } from '../../actions/document-api';

// Components: Views

export const EditDocument = ({ theme, companyState, assetState, assetGroupState, tenantState, subcontractorState, documentState }) => {
    const dispatch = useDispatch();
    const { companyId, id } = useParams();

    useEffect(() => {
        dispatch(GetDocument(companyId, id));

        if (R.isNil(documentState.documents)) {
            dispatch(GetDocuments());
        }
        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        if (R.isNil(assetState.assets)) {
            dispatch(GetAssets());
        }
        if (R.isNil(assetGroupState.assetGroups)) {
            dispatch(GetAssetGroups());
        }
        if (R.isNil(tenantState.tenants)) {
            dispatch(GetTenants());
        }
        if (R.isNil(subcontractorState.subcontractors)) {
            dispatch(GetSubcontractors());
        }
        // eslint-disable-next-line 
    }, []);

    if (documentState.isLoading.includes(LoadingsDocument.FETCHING_DOCUMENT) ||
        documentState.isLoading.includes(LoadingsDocument.FETCHING_DOCUMENTS) ||
        tenantState.isLoading.includes(LoadingsTenant.FETCHING_TENANTS) ||
        assetState.isLoading.includes(LoadingsAsset.FETCHING_ASSETS) ||
        subcontractorState.isLoading.includes(LoadingsSubcontractor.FETCHING_SUBCONTRACTORS) ||
        assetGroupState.isLoading.includes(LoadingsAssetGroup.FETCHING_ASSETGROUPS) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)) {
        return <Loading theme={theme} />;
    }
    if (R.isNil(documentState.document) || R.isEmpty(documentState.document) ||
        R.isNil(companyState.companies) ||
        R.isNil(assetGroupState.assetGroups) ||
        R.isNil(subcontractorState.subcontractors) ||
        R.isNil(assetState.assets)) {
        return '';
    }

    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let documents = !R.isNil(documentState.documents) ? documentState.documents : [];
    let assets = !R.isNil(assetState.assets) ? assetState.assets : [];
    let assetGroups = !R.isNil(assetGroupState.assetGroups) ? assetGroupState.assetGroups.filter(ag => !ag.isDefault) : [];
    let tenants = !R.isNil(tenantState.tenants) ? tenantState.tenants.map(t => ({ ...t, title: t.firstName + " " + t.lastName })) : [];
    let subcontractors = !R.isNil(subcontractorState.subcontractors) ? subcontractorState.subcontractors.map(s => ({ ...s, title: s.firstName + " " + s.lastName })) : [];


    const tempInitialDocumentFormData = R.clone(initialDocumentFormData);
    tempInitialDocumentFormData.title.value = !R.isNil(documentState.document.title) && !R.isEmpty(documentState.document.title) ? documentState.document.title : "";
    tempInitialDocumentFormData.notes.value = !R.isNil(documentState.document.description) && !R.isEmpty(documentState.document.description) ? documentState.document.description : "";
    tempInitialDocumentFormData.type.value = !R.isNil(documentState.document.contractCategory) && !R.isEmpty(documentState.document.contractCategory) ? documentState.document.contractCategory : "";
    tempInitialDocumentFormData.date.value = !R.isNil(documentState.document.date) && !R.isEmpty(documentState.document.date) ? documentState.document.date : "";
    tempInitialDocumentFormData.tags.value = !R.isNil(documentState.document.keywords) && !R.isEmpty(documentState.document.keywords) ? documentState.document.keywords.split(",") : [documentState.document?.title] ?? "";

    const tempInitialCompanyForm = [R.clone(initialCompanyForm)];
    const tempInitialAssetForm = [R.clone(initialAssetForm)];
    const tempInitialTenantForm = [R.clone(initialTenantForm)];
    const tempInitialSubcontractorForm = [R.clone(initialSubcontractorForm)];
    const tempInitialAssetGroupForm = [R.clone(initialAssetGroupForm)];
    const companyTitle = companies.find(c => c.id === documentState.document.companyId)?.title;
    const assetTitle = assets.find(c => c.id === documentState.document.assetId)?.title;
    const tenantName = tenants.find(c => c.id === documentState.document.contactId);
    const assetGroupTitle = assetGroups.find(c => c.id === documentState.document.assetGroupId)?.title;
    const subcontractor = subcontractors.find(s => s.id === documentState.document.subcontractorId);
    tempInitialCompanyForm[0].value = documentState.document.companyId;
    tempInitialCompanyForm[0].label = !R.isNil(companyTitle) ? companyTitle : "";
    tempInitialAssetForm[0].value = !R.isNil(documentState.document.assetId) ? documentState.document.assetId : "";
    tempInitialAssetForm[0].label = !R.isNil(assetTitle) ? assetTitle : "";
    tempInitialTenantForm[0].value = !R.isNil(documentState.document.contactId) ? documentState.document.contactId : "";
    tempInitialTenantForm[0].label = !R.isNil(tenantName) ? `${tenantName.firstName} ${tenantName.lastName}` : "";
    tempInitialAssetGroupForm[0].value = !R.isNil(documentState.document.assetGroupId) ? documentState.document.assetGroupId : "";
    tempInitialAssetGroupForm[0].label = !R.isNil(assetGroupTitle) ? assetGroupTitle : "";
    tempInitialSubcontractorForm[0].value = !R.isNil(documentState.document.subcontractorId) ? documentState.document.subcontractorId : "";
    tempInitialSubcontractorForm[0].label = !R.isNil(subcontractor) ? `${subcontractor.firstName} ${subcontractor.lastName}` : "";


    return (
        <MainDocument
            theme={theme}
            documentState={documentState}
            documents={documents}
            companies={companies}
            assets={assets}
            assetGroups={assetGroups}
            tenants={tenants}
            subcontractors={subcontractors}
            initialDocumentFormData={tempInitialDocumentFormData}
            initialCompanyForm={tempInitialCompanyForm}
            initialAssetForm={tempInitialAssetForm}
            initialAssetGroupForm={tempInitialAssetGroupForm}
            initialTenantForm={tempInitialTenantForm}
            initialSubcontractorForm={tempInitialSubcontractorForm}
            resetDocumentFormData={initialDocumentFormData}
            resetCompanyForm={initialCompanyForm}
            resetAssetForm={initialAssetForm}
            resetAssetGroupForm={initialAssetGroupForm}
            resetTenantForm={initialTenantForm}
            resetSubcontractorForm={initialSubcontractorForm}
            companyId={companyId}
            id={id}
        />
    );
};

export default EditDocument;