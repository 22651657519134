import React from 'react';
import * as R from 'ramda';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from "@mui/material/TextField";

// Components: Common
import FormGlobalComplete from '../_common/globalComplete';
import FormProfilePhoto from '../_common/profilePhoto';
import { initialCompanyForm } from '../../../../constants/utils/formInitialData';
import { onChangeValidation } from '../../../../constants/utils/validators';

// Components: Views

export const FormCollaborator = ({ theme, formData, setFormData, companyUseAutocomplete, profilePhoto, setProfilePhoto, currentPhoto, id, ...others }) => {

    const handleOnChange = (event) => { setFormData(onChangeValidation(event.target, formData)); };


    return (
        <Stack spacing={2} width="100%" {...others}>
            <Container maxWidth="100%" sx={{ display: "inherit", flexDirection: "inherit", gap: 2 }}>
                <Stack direction="column" spacing={2}>
                    <Stack direction="column" spacing={2}>
                        <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                            <FormProfilePhoto theme={theme} currentPhoto={currentPhoto} profilePhoto={profilePhoto} setProfilePhoto={setProfilePhoto} />
                            <Stack direction="row" flex="1 1 auto" spacing={1}>
                                <TextField
                                    label='Prénom *'
                                    id="Prenom"
                                    type="text"
                                    fullWidth
                                    variant="filled"
                                    name="firstName"
                                    error={formData.firstName.error && !formData.firstName.isValid}
                                    helperText={formData.firstName.error}
                                    value={formData.firstName.value}
                                    onChange={(event) => { handleOnChange(event); }}
                                    disabled={!R.isNil(id)}
                                />
                                <TextField
                                    label='Nom *'
                                    id="Nom"
                                    type="text"
                                    fullWidth
                                    variant="filled"
                                    name="lastName"
                                    error={formData.lastName.error && !formData.lastName.isValid}
                                    helperText={formData.lastName.error}
                                    value={formData.lastName.value}
                                    onChange={(event) => { handleOnChange(event); }}
                                    disabled={!R.isNil(id)}
                                />
                            </Stack>
                        </Stack>
                        <TextField
                            label='Email *'
                            id="Email"
                            type="text"
                            fullWidth
                            variant="filled"
                            name="email"
                            error={formData.email.error && !formData.email.isValid}
                            helperText={formData.email.error}
                            value={formData.email.value}
                            onChange={(event) => { handleOnChange(event); }}
                            disabled={!R.isNil(id)}
                        />
                    </Stack>
                    <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                        <FormGlobalComplete
                            options={companyUseAutocomplete.options}
                            optionsFormData={companyUseAutocomplete.dropdownFormData}
                            setOptionsFormData={companyUseAutocomplete.setDropdownFormData}
                            handleSetOpen={companyUseAutocomplete.handleSetOpen}
                            handleSetOptionsFormData={companyUseAutocomplete.handleSetOptionsFormData}
                            multi={true}
                            name="company"
                            label="Société"
                            initialFormData={initialCompanyForm}
                            hasPermission={true}
                        />


                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
};

export default FormCollaborator;
