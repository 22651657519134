import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


export const BanksViewEmpty = ({theme, title, description, color, ...others}) => {
	return (
		<Container maxWidth="sm" sx={{margin: "auto"}} {...others}>
			<Typography variant="h3" fontWeight="medium" letterSpacing={-.25} component="h2" color={color ? (color === "primary" || color === "inherit" ? color : color + ".main") : "secondary"} lineHeight="1" mb={1} >{title ?  title : 'Creér ou sélectionner une banque pour commencer.'}</Typography>
			<Typography variant="body1"  maxWidth="xs" mb={4}>{description ?  description : "Pour afficher les détails d'une banque, assurez-vous d'en sélectionner une dans la liste."}</Typography>
			<Button component={RouterLink} to="/app/new/company" variant="contained" color={color ? color : "secondary"}>Commencer</Button>
		</Container>
	);
}

export default BanksViewEmpty;
