import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { GetCompanies } from '../../actions/company-api';
import * as LoadingsCompany from '../../constants/loadings/company';
import Loading from '../../_common/loading';
import { initialCompanyFormData } from '../../constants/utils/formInitialData';
import MainCompany from '../_common/forms/layout/company';



export const NewCompany = ({ theme, companyState }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        // eslint-disable-next-line 
    }, [companyState.companies]);

    if (companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)) return <Loading theme={theme} />;
    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];


    return (
        <MainCompany
            initialCompanyFormData={initialCompanyFormData}
            companyState={companyState}
            companies={companies}
        />
    );
};

export default NewCompany;