import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import * as R from 'ramda';

// Components
// Components: Global


// Components: Common
import * as LoadingsCompany from '../../constants/loadings/company';
import * as LoadingsAssetGroup from '../../constants/loadings/assetGroup';
import { GetCompanies } from '../../actions/company-api';
import { useDispatch } from 'react-redux';
import { initialAssetGroupFormData, initialCompanyForm } from '../../constants/utils/formInitialData';
import { GetAssetGroup, GetAssetGroups } from '../../actions/assetGroup-api';
import Loading from '../../_common/loading';
import MainAssetGroup from '../_common/forms/layout/assetGroup';


// Components: Views

export const EditAssetGroup = ({ theme, companyState, assetGroupState }) => {
    const dispatch = useDispatch();
    const { companyId, id } = useParams();

    useEffect(() => {
        dispatch(GetAssetGroup(companyId, id));

        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        if (R.isNil(assetGroupState.assetGroups)) {
            dispatch(GetAssetGroups());
        }
        // eslint-disable-next-line 
    }, []);

    if (assetGroupState.isLoading.includes(LoadingsAssetGroup.FETCHING_ASSETGROUPS) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)) {
        return <Loading theme={theme} />;
    }
    if (R.isNil(assetGroupState.assetGroup) || R.isEmpty(assetGroupState.assetGroup) || R.isNil(companyState.companies)) {
        return '';
    }

    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let assetGroups = !R.isNil(assetGroupState.assetGroups) ? assetGroupState.assetGroups.filter(ag => !ag.isDefault) : [];

    const tempInitialAssetGroupFormData = R.clone(initialAssetGroupFormData);
    tempInitialAssetGroupFormData.title.value = !R.isNil(assetGroupState.assetGroup.title) && !R.isEmpty(assetGroupState.assetGroup.title) ? assetGroupState.assetGroup.title : "";
    tempInitialAssetGroupFormData.city.value = !R.isNil(assetGroupState.assetGroup.address) && !R.isEmpty(assetGroupState.assetGroup.address) ? assetGroupState.assetGroup.address.locality : "";
    tempInitialAssetGroupFormData.address.value = !R.isNil(assetGroupState.assetGroup.address) && !R.isEmpty(assetGroupState.assetGroup.address) ? assetGroupState.assetGroup.address.addressLine1 : "";;
    tempInitialAssetGroupFormData.postalCode.value = !R.isNil(assetGroupState.assetGroup.address) && !R.isEmpty(assetGroupState.assetGroup.address) ? assetGroupState.assetGroup.address.postalCode : "";

    const tempInitialCompanyForm = [R.clone(initialCompanyForm)];
    const companyTitle = companies.find(c => c.id === assetGroupState.assetGroup.companyId)?.title;
    tempInitialCompanyForm[0].value = assetGroupState.assetGroup.companyId;
    tempInitialCompanyForm[0].label = !R.isNil(companyTitle) ? companyTitle : "";

    return (
        <MainAssetGroup
            theme={theme}
            assetGroupState={assetGroupState}
            assetGroup={assetGroupState.assetGroup}
            photo={assetGroupState.assetGroup.profileUrl?.url}
            companies={companies}
            assetGroups={assetGroups}
            initialAssetGroupFormData={tempInitialAssetGroupFormData}
            initialCompanyForm={tempInitialCompanyForm}
            resetCompanyForm={initialCompanyForm}
            resetAssetGroupFormData={initialAssetGroupFormData}
            companyId={companyId}
            id={id}
        />
    );
};

export default EditAssetGroup;