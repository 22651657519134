import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';


// Components
// Components: Global
// Components: Common

// Components: Views
import { EditAsset } from './asset';
import { EditCollaborator } from './collaborator';
import EditAssetGroup from './assetGroup';
import EditCompany from './company';
import EditTenant from './tenant';
import EditSubcontractor from './subcontractor';
import EditPayment from './payment';
import NewContrat from './contrat';
import EditDocument from './document';
import EditPassword from './password';


export const Edit = ({ theme, userState, companyState, assetGroupState, tenantState, assetState, subcontractorState, collaboratorState, documentState, rentalState, paymentState, accountState }) => {

    return (
        <Routes>
            <Route index end element={
                <Navigate to="/app/edit/document" />
            } />
            <Route path="asset/:companyId/:id" element={
                <EditAsset theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} documentState={documentState} />
            } />
            <Route path="immeuble/:companyId/:id" element={
                <EditAssetGroup theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} />
            } />
            <Route path="payment/:companyId/:id" element={
                <EditPayment theme={theme} userState={userState} paymentState={paymentState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} />
            } />
            <Route path="document/:companyId/:id" element={
                <EditDocument theme={theme} userState={userState} paymentState={paymentState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} documentState={documentState} />
            } />
            <Route path="password/:companyId/:id" element={
                <EditPassword theme={theme} userState={userState} paymentState={paymentState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} documentState={documentState} accountState={accountState} />
            } />
            <Route path="contrat/:companyId/:id" element={
                <NewContrat theme={theme} userState={userState} paymentState={paymentState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} rentalState={rentalState} />
            } />
            <Route path="collaborator/:id" element={
                <EditCollaborator theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} />
            } />
            <Route path="company/:id" element={
                <EditCompany theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} />
            } />
            <Route path="tenant/:id" element={
                <EditTenant theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} />
            } />
            <Route path="subcontractor/:id" element={
                <EditSubcontractor theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} />
            } />

            <Route path="*" element={<Navigate to="/404" />} />
            <Route path="/" element={
                <Navigate replace to="/app/edit/document" />
            } />
        </Routes>
    );
};

export default Edit;