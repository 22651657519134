import React from 'react';

// Components
// Components: Global
import Stack from '@mui/material/Stack';
import TextField from "@mui/material/TextField";

// Components: Common

// Components: Views

export const FormPlace = ({ theme, formData, handleOnChange, ...others }) => {


    return (
        <Stack direction={{ default: "column", md: "row" }} flex="1 1 auto" width="100%" spacing={1} {...others}>
            <TextField
                label='Adresse du bien *'
                id="Adresse"
                type="text"
                fullWidth
                variant="filled"
                name="address"
                error={formData.address.error && !formData.address.isValid}
                helperText={formData.address.error}
                value={formData.address.value}
                onChange={(event) => { handleOnChange(event); }}
            />
            <TextField
                label='Code Postal *'
                id="Postal"
                type="text"
                // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                fullWidth
                variant="filled"
                name="postalCode"
                error={formData.postalCode.error && !formData.postalCode.isValid}
                helperText={formData.postalCode.error}
                value={formData.postalCode.value}
                onChange={(event) => { handleOnChange(event); }}
            />
            <TextField
                label='Ville *'
                id="Ville"
                type="text"
                fullWidth
                variant="filled"
                name="city"
                error={formData.city.error && !formData.city.isValid}
                helperText={formData.city.error}
                value={formData.city.value}
                onChange={(event) => { handleOnChange(event); }}
            />
        </Stack>
    );
};

export default FormPlace;
