import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

import Logo from "../_common/logo";
import { ValidateToken } from "../actions/auth-api";
import * as Loadings from '../constants/loadings/auth';


export const Validation = () => {
    const authState = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const { token } = useParams();

    useEffect(() => {
        document.title = `🙌 Validation de compte Magestimmo!`;
    }, []);

    const handleValidateToken = () => {
        dispatch(ValidateToken(token))
            .then(() => {
                navigate('/auth/login');
            })
            .catch((error) => {
                console.error(error);
             });
    };
    
    return (
        <Container maxWidth="sm" sx={{ m: "auto" }}>
            <Stack direction="column" flex="1 1 auto" justifyContent="center" alignItems="center" my={5} spacing={2}>
                <Logo />
                <Typography variant="h2" fontWeight="normal" color='primary' lineHeight="1.2">Magestimmo</Typography>
            </Stack>
            <Stack direction="column" flex="1 1 auto" spacing={2} alignItems="center" sx={{ width: '20em', maxWidth: '100%', marginX: 'auto' }} mb={5}>
                <Box maxWidth="xs" sx={{ mb: 2, textAlign: "center" }}>
                    <Typography variant="h4" component="h2" lineHeight="1.2" mb={1}>Bienvenue !!</Typography>
                    <Typography variant="body1" color="text">Cliquez ci-dessous pour confirmer votre inscription</Typography>
                </Box>
                <LoadingButton loading={authState.isLoading.includes(Loadings.FETCHING_VALIDATION_TOKEN)} type="submit" onClick={handleValidateToken} color='primary' variant="contained" size="large">
                    Confirmer
                </LoadingButton>
            </Stack>
            {authState.isTokenValidated === false && <Alert sx={{ width: '100%', borderRadius: 3 }} severity="error">En ce moment il n'est pas possible confirmer votre enregistrement.</Alert>}
        </Container>
    );
};
