import React, { useEffect } from 'react';
import * as R from 'ramda';

// Components
// Components: Global

// Components: Common
import * as LoadingsCompany from '../../constants/loadings/company';
import * as LoadingsAssetGroup from '../../constants/loadings/assetGroup';
import { GetCompanies } from '../../actions/company-api';
import { useDispatch } from 'react-redux';
import { initialAssetGroupFormData, initialCompanyForm } from '../../constants/utils/formInitialData';
import { GetAssetGroups } from '../../actions/assetGroup-api';
import Loading from '../../_common/loading';
import MainAssetGroup from '../_common/forms/layout/assetGroup';


// Components: Views

export const NewAssetGroup = ({ theme, companyState, assetGroupState }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        if (R.isNil(assetGroupState.assetGroups)) {
            dispatch(GetAssetGroups());
        }
        // eslint-disable-next-line 
    }, []);

    if (assetGroupState.isLoading.includes(LoadingsAssetGroup.FETCHING_ASSETGROUPS) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)) {
        return <Loading theme={theme} />;
    }

    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let assetGroups = !R.isNil(assetGroupState.assetGroups) ? assetGroupState.assetGroups.filter(ag => !ag.isDefault) : [];


    return (
        <MainAssetGroup
            theme={theme}
            assetGroupState={assetGroupState}
            companies={companies}
            assetGroups={assetGroups}
            initialAssetGroupFormData={initialAssetGroupFormData}
            initialCompanyForm={[initialCompanyForm]}
            resetCompanyForm={initialCompanyForm}
            resetAssetGroupFormData={initialAssetGroupFormData}
        />
    );
};

export default NewAssetGroup;