import React from 'react';

// Components
// Components: Global
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Components: Views
import TenantsMenu from './menu';
import TenantsView from './view';

export const Tenants = ({ theme, userState, companyState, tenantState, assetState, subcontractorState, collaboratorState, documentState, rentalState, paymentState }) => {

    return (
        <Box component="section" className="layout" flex="1 1 auto" alignItems="center" width="100%" height="calc(100% - 6.5rem)" overflow="hidden auto" mt={{ md: -2 }}>
            <Typography component="h1" className="sr-only">Content</Typography>
            <TenantsMenu theme={theme} userState={userState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} />
            <TenantsView theme={theme} userState={userState} tenantState={tenantState} documentState={documentState} companyState={companyState} assetState={assetState} rentalState={rentalState} paymentState={paymentState} subcontractorState={subcontractorState} />
        </Box>
    );
};

export default Tenants;
