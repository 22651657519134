import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from '@mui/lab/LoadingButton';

import * as Loadings from '../../constants/loadings/company';
import { toastConf } from "../../constants/utils/toastConfig";
import { ExportCompanyToGoogleDrive } from '../../actions/company-api';



const ModalExportConfirmation = ({ modalClose, companyState, open, theme }) => {
    const dispatch = useDispatch();

    const exportCompanyFiles = async () => {
        try {
            await dispatch(ExportCompanyToGoogleDrive());
            toast.success("L'export vers Google Drive a démarré", toastConf);
            modalClose();
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <Dialog fullWidth maxWidth="xs" onClose={modalClose} open={open} theme={theme} sx={{ textAlign: "center" }}>
            <DialogTitle variant="h4" color="primary" sx={{ pt: 3, pb: 1 }}>Export vers Google Drive</DialogTitle>
            <DialogContent sx={{ alignItems: "center", pb: 1 }}>
                <DialogContentText variant="body" mb={2}>
                    Vous allez exporter vos données vers Google Drive. Une fois l'exportation lancée, les documents de Magestimmo seront envoyés vers Google Drive, et l'opération peut prendre du temps en fonction du volume de documents que vous avez. Vous retrouverez ensuite vos documents sur Google Drive une fois la synchronisation terminée.
                </DialogContentText>
            </DialogContent>
            <DialogActions disableSpacing={true} sx={{ flexDirection: "column", p: 3, pt: 0 }}>
                <LoadingButton
                    onClick={exportCompanyFiles}
                    disabled={companyState.isLoading.includes(Loadings.EXPORTING_COMPANY)}
                    loading={companyState.isLoading.includes(Loadings.EXPORTING_COMPANY)}
                    size="large" fullWidth variant="contained" theme={theme} color='primary' sx={{ px: 3 }}>Exporter</LoadingButton>
                <Button theme={theme} color="neutral" fullWidth onClick={() => {modalClose();}} sx={{ px: 3, mt: 1 }}>Annuler</Button>
            </DialogActions>
    </Dialog >
    );
};

export default ModalExportConfirmation;