import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link as RouterLink } from 'react-router-dom';
import * as R from 'ramda';

import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import Logo from './../_common/logo';
import { UpdatePassword } from "../actions/auth-api";
import * as Loadings from '../constants/loadings/auth';
import { inputTypes, minLengthRegex, confirmPasswordValidator } from "../constants/utils/validators";


export const Reset = () => {
    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm();
    const authState = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const { token } = useParams();

    useEffect(() => {
        document.title = `👏 Définition du mot de passe Magestimmo!`;
    }, []);

    const submitResetForm = (data) => {
        dispatch(UpdatePassword(token, {
            newPassword: data.password.trim(),
            confirmationPassword: data.passwordConfirm.trim(),
        })).then(() => { reset(); });
    };


    return (
			<Container maxWidth="sm" sx={{ m: "auto" }}>
				<Stack direction="column" flex="1 1 auto" justifyContent="center" alignItems="center" my={5} spacing={2}>
					<Logo />
					<Typography variant="h2" fontWeight="600" color="primary" lineHeight="1.2">
						Magestimmo
					</Typography>
				</Stack>
				<Stack direction="column" flex="1 1 auto" spacing={2} alignItems="center" sx={{ maxWidth: "100%", margiX: "auto" }} mb={5}>
					<Box sx={{ mb: 3, textAlign: "center" }}>
						<Typography variant="h4" component="h2" lineHeight="1" letterSpacing={-0.25} mb={1}>
							Définition du mot de passe
						</Typography>
						<Typography variant="body1" color="text">
							Veuillez insérer votre nouveau mot de passe à associer à votre compte.
						</Typography>
					</Box>
					{authState.recoverPassword ? null : (
						<Fragment>
							<Box name="Reset" onSubmit={handleSubmit(submitResetForm)} component="form" sx={{ width: "100%", maxWidth: "100%" }} alignItems="center" display="flex" flexDirection="column" autoComplete="off">
								<Stack mb={1} direction="column" flex="1 1 auto" spacing={0.5} alignItems="center" width="100%" sx={{ maxWidth: "100%", marginX: "auto" }}>
									<TextField
										label="Mot de passe"
										name="password"
										fullWidth={true}
										validator={"password"}
										required={true}
										type="password"
										size="medium"
										variant="filled"
										inputProps={register("password", { required: inputTypes.required.errorMessage, validate: (v) => !!v.trim(), pattern: { value: minLengthRegex, message: inputTypes.minLength.errorMessage } })}
										error={!!errors.password}
										helperText={errors.password ? errors.password.message : ""}
									/>
									<TextField
										fullWidth={true}
										label="Confirmer le mot de passe"
										name="passwordConfirm"
										required={true}
										size="medium"
										type="password"
										variant="filled"
										inputProps={register("passwordConfirm", { required: inputTypes.required.errorMessage, validate: value => confirmPasswordValidator(value, getValues('password')).error })}
										error={!!errors.passwordConfirm}
										helperText={errors.passwordConfirm ? errors.passwordConfirm.message : ""}
									/>
								</Stack>
								<LoadingButton loading={authState.isLoading.includes(Loadings.UPDATING_FORGOT_PASSWORD)} type="submit" color="primary" fullWidth={true} variant="contained" size="large">
									Valider
								</LoadingButton>
							</Box>
							{/* <Alert sx={{ width: '100%', borderRadius: 3 }} severity="error">Nom d'utilisateur ou mot de passe incorrect.</Alert> */}
							<Link to="/auth/login" component={RouterLink} color="secondary" underline="hover" mt={2}>
								C'est pas grave, <span style={{ fontWeight: "bold" }}>j'ai un compte</span>
							</Link>
						</Fragment>
					)}
					{R.isNil(authState.recoverPassword) ? null : authState.recoverPassword ? (
						<Alert sx={{ width: "100%", borderRadius: 3 }} severity="success">
							Récupérez le mot de passe avec succès!
							<Link to="/auth/login" component={RouterLink} underline="hover" mt={1} mx="auto">
								<span style={{ fontWeight: "bold" }}> Aller à la page de connexion</span>
							</Link>
						</Alert>
					) : (
						<Alert sx={{ width: "100%", borderRadius: 3 }} severity="error">
							Nous sommes désolé, une erreur s'est produite. Veuillez réessayer plus tard...
						</Alert>
					)}
				</Stack>
			</Container>
		);
};

export default Reset;