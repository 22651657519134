import React from 'react';
import {useSelector} from 'react-redux';
// Components
// Components: Global
import Box from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// Components: Views
import AssetsMenu from './menu';
import AssetsView from './view';


export const Assets = ({companyState, tenantState, assetState, assetGroupState, subcontractorState, collaboratorState, documentState, rentalState, accountState, paymentState }) => {
    const userState = useSelector((state) => state.user);
    return (
        <Box 
          component="section"
          className="layout"
          flexDirection="initial"
          mt={{ md: -2 }} 
          flex="1 1 auto" 
          alignItems="center"
          width="100%"
          height="100%"
          overflow="hidden auto"
        >
            <Typography component="h1" className="sr-only">Content</Typography>
            <AssetsMenu 
               companyState={companyState} 
               tenantState={tenantState} 
               assetState={assetState} 
               assetGroupState={assetGroupState} 
               subcontractorState={subcontractorState} 
               collaboratorState={collaboratorState} 
               documentState={documentState} 
               rentalState={rentalState} 
               accountState={accountState} 
               paymentState={paymentState}
               />
            <AssetsView
             userState={userState} 
             companyState={companyState} 
             tenantState={tenantState} 
             assetState={assetState} 
             assetGroupState={assetGroupState} 
             subcontractorState={subcontractorState} 
             collaboratorState={collaboratorState} 
             documentState={documentState} 
             rentalState={rentalState} 
             accountState={accountState} 
             paymentState={paymentState}
             />
        </Box>
    );
};

export default Assets;
