import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';


// Components
// Components: Global
import Box from '@mui/material/Box';

// Components: Common
// Components: Views
import BanksViewEmpty from './empty';
import BanksViewDetail from './detail';

export const BanksView = ({ theme, userState, companyState, tenantState, assetState, assetGroupState, subcontractorState, collaboratorState, documentState, rentalState, paymentState }) => {
    const { pathname } = useLocation();
    const showView = !pathname.endsWith('/banks');

    return (
        <Box display={{ default: showView ? 'flex' : 'none', lg: "flex" }} flexDirection="column" width="100%" overflow="hidden auto" backgroundColor="background.default" sx={{ borderRadius: 2, position: { default: showView && 'fixed', lg: 'relative' }, left: { default: showView && 0, lg: 'unset' }, right: { default: showView && 0, lg: 'unset' }, top: { default: showView && '5rem', lg: 'unset' }, height: { default: showView && 'calc(100% - 5rem)', lg: '100%' }, bottom: { default: showView && 0, lg: 'unset' }, zIndex: { default: showView && 2, lg: 'unset' } }}>
            <Routes>
                <Route index end element={
                    <BanksViewEmpty />
                } />
                <Route path=":id/*" element={
                    <BanksViewDetail theme={theme} userState={userState} tenantState={tenantState} documentState={documentState} companyState={companyState} assetState={assetState} assetGroupState={assetGroupState} rentalState={rentalState} paymentState={paymentState} subcontractorState={subcontractorState} />
                } />
                <Route path="*" element={<Navigate to="/app/companies" />} />
                <Route path="/" element={
                    <Navigate replace to="/app/companies" />
                } />
            </Routes>
        </Box>
    );
};

export default BanksView;
