import axios from 'axios';
import { subscriptionActions } from "../reducers/subscription";
import * as urls from '../constants/webapi/subscription';



export const GetSubscription = () => {

    return async dispatch => {
        dispatch(subscriptionActions.getSubscriptionRequest());
        return axios.get(urls.getSubscriptionUrl())
            .then((response) => {
                dispatch(subscriptionActions.getSubscriptionSuccess({
                    data: response.data
                }));
                return response.data;
            })
            .catch((error) => {
                dispatch(subscriptionActions.getSubscriptionFailure(error.response.data));
            });
    };
};


export const ManageSubscription = () => {

    return async dispatch => {
        dispatch(subscriptionActions.manageSubscriptionRequest());
        return axios.get(urls.manageSubscriptionUrl())
            .then((response) => {
                dispatch(subscriptionActions.manageSubscriptionSuccess({
                    data: response.data.uri
                }));
                return response.data.uri;
            })
            .catch((error) => {
                dispatch(subscriptionActions.manageSubscriptionFailure(error.response.data));
            });
    };
};