import React from 'react';
import * as R from 'ramda';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

// Components: Common
import FormUpload from '../_common/upload';
import FormMain from '../_common/main';
import FormNotes from '../_common/notes';
import FromLease from '../_common/lease';
import { onChangeValidation } from '../../../../constants/utils/validators';
import FormPhotos from '../_common/photos';

// Components: Views


export const FormContrat = ({ theme, docFiles, setDocFiles, formData, setFormData, selectCompanyByAsset, companyUseAutocomplete, assetUseAutocomplete, tenantUseAutocomplete, companyId, uploadedFiles, setUploadedFiles, ...others }) => {

    const handleOnChange = (event) => {
        setFormData(onChangeValidation(event.target, formData));
    };
    const handleClick = (event, item) => { event.target.value = ''; }; 


    return (
        <Stack spacing={2} flex="1 1 auto" width="100%" {...others}>
            <FormUpload theme={theme} photos={docFiles} setPhotos={setDocFiles} type="rental" />
            <Container maxWidth="100%" sx={{ display: "inherit", flexDirection: "inherit", gap: 2 }}>
                <Stack direction="column" spacing={4}>
                    <FormMain
                        companyId={companyId}
                        formData={formData}
                        setFormData={setFormData}
                        handleOnChange={handleOnChange}
                        companyUseAutocomplete={companyUseAutocomplete}
                        tenantUseAutocomplete={tenantUseAutocomplete}
                        assetUseAutocomplete={assetUseAutocomplete}
                        selectCompanyByAsset={selectCompanyByAsset}
                        resetOnSelectingAsset={
                            R.compose(
                                tenantUseAutocomplete.resetFormData,
                                selectCompanyByAsset,
                                assetUseAutocomplete.handleSetOptionsFormData)
                        }
                        resetOnSelectingCompany={
                            R.compose(
                                tenantUseAutocomplete.resetFormData,
                                assetUseAutocomplete.resetFormData,
                                companyUseAutocomplete.handleSetOptionsFormData
                            )
                        }
                    />
                    <FromLease formData={formData} setFormData={setFormData} handleOnChange={handleOnChange} />
                    <FormPhotos fileType="Rental" title="document" photos={docFiles} setPhotos={setDocFiles} uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} handleClick={handleClick} theme={theme} />
                    <FormNotes value={formData?.notes?.value} handleOnChange={handleOnChange} />
                </Stack>
                {/* <FormClefs theme={theme} handleChange={(newValue) => setFormData({ ...formData, tags: { ...formData.tags, value: newValue } })} value={formData.tags.value} /> */}
            </Container>
        </Stack>
    );
};

export default FormContrat;
