import React, { useEffect, useState } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as R from "ramda";

// Components
// Components: Global
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Components: Common
import { GetCompany, UpdateCompany } from "../../../../actions/company-api";
import { GetPayments } from "../../../../actions/payment-api";
import { GetAssets } from "../../../../actions/asset-api";
import { GetTenants } from "../../../../actions/tenant-api";
import { GetSubcontractors } from "../../../../actions/subcontractor-api";


import * as CompanyLoadings from "../../../../constants/loadings/company";
import * as PaymentLoadings from "../../../../constants/loadings/payment";

import Loading from "../../../../_common/loading";

// Components: View
import BanksViewDetailHeader from "./_common/header";
import BanksViewEmpty from "./../empty";
import { EQUAL_QUERY } from "../../../../constants/utils/filterQuery";
import { usePermissionModal } from "../../../../hooks/usePermissionModal";
import { useDeletePayment } from "../../../../hooks/useDeletePayment";
import { TransactionRow } from "../../../_common/transactionRow";
import ModalDelete from "../../../_common/modalDelete";

export const BanksViewDetail = ({ theme, userState, tenantState, documentState, companyState, rentalState, assetState, assetGroupState, subcontractorState, paymentState }) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [openDelete, setOpenDelete] = useState(false);
	const { openPermissionModal } = usePermissionModal();
	const [openDeletePay, setOpenDeletePay] = useState(false);
	const [selectedPayment, setSelectedPayment] = useState(false);
	const [paymentsExpanded, setPaymentsExpanded] = useState("panel-payments", true);

	const deletePayment = useDeletePayment();

	const modalOpenDelete = () => {
		setOpenDelete(true);
	};
	const modalCloseDelete = () => {
		setOpenDelete(false);
	};

	useEffect(() => {
		dispatch(GetCompany(id));
		dispatch(GetAssets({ filter: { [EQUAL_QUERY.companyId]: id } }));
		if (R.isNil(tenantState.tenants)) {
			dispatch(GetTenants());
		}
		if (R.isNil(subcontractorState.subcontractors)) {
			dispatch(GetSubcontractors());
		}
		if (R.isNil(paymentState.payments)) {
			dispatch(GetPayments());
		}
		// dispatch(GetTenants({ filter: { [EQUAL_QUERY.companyId]: id } }));
		// dispatch(GetSubcontractors({ filter: { [EQUAL_QUERY.companyId]: id } }));
		// eslint-disable-next-line
	}, [id]);

	const deleteCompanyProp = async () => {
		try {
			await dispatch(UpdateCompany({ ...companyState.company, bankName: null }));
			navigate("/app/banks");
		} catch (error) {
			console.log(error);
			// toast.error("Échec de l'opération, réessayez !", toastConf);
		}
	};

	if (companyState.isLoading.includes(CompanyLoadings.FETCHING_COMPANY) || paymentState.isLoading.includes(PaymentLoadings.FETCHING_PAYMENTS)) {
		return <Loading />;
	}
	if (R.isNil(companyState.company) || R.isEmpty(companyState.company)) {
		return <BanksViewEmpty color="danger" title="Ooops..." description="Quelque chose s'est mal passée. On dirait que cette banque n'existe pas !" />;
	}
	const permissions = userState.user.companyAccesses.find((c) => c.companyId === id).accessTypes;
	const handleAccordionChange = (panel) => (event, isExpanded) => {
		switch (panel) {
			default:
			case "panel-payments": {
				setPaymentsExpanded(isExpanded ? panel : false);
				break;
			}
		}
	};

	return (
		<React.Fragment>
			<React.Fragment>
				<BanksViewDetailHeader openPermissionModal={openPermissionModal} permissions={permissions} theme={theme} modalOpen={modalOpenDelete} item={companyState} tenantState={tenantState} totalTransactions={paymentState.payments?.filter((p) => p.companyId === companyState.company?.id).length} />
				<Container maxWidth="100%" sx={{ height: "100%" }}>
					<Stack maxWidth="100%" my={3} direction="column" spacing={4}>
						<Accordion sx={{ backgroundColor: "transparent", boxShadow: "none", border: "none", p: 0, ":before": { backgroundColor: "inherit" } }} disableGutters expanded={paymentsExpanded === "panel-payments"} onChange={handleAccordionChange("panel-payments")}>
							<AccordionSummary sx={{ minHeight: "auto", ".MuiAccordionSummary-content": { my: 1 }, p: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
								<Typography variant="h6" sx={{ lineHeight: 1 }}>
									Transactions
								</Typography>
							</AccordionSummary>
							<AccordionDetails sx={{ p: 0 }}>
								<Stack maxWidth="100%" direction="column" spacing={1}>
									{!R.isNil(paymentState.payments) && !R.isEmpty(paymentState.payments) && (
										<Stack direction="column" spacing={1}>
											{paymentState.payments
												.filter((r) => r.companyId === companyState.company.id)
												.map((payment) => (
													<TransactionRow
														key={payment.id}
														setOpenDeletePay={setOpenDeletePay}
														permissions={permissions}
														openPermissionModal={openPermissionModal}
														payment={payment}
														assetState={assetState}
														subcontractorState={subcontractorState}
														tenantState={tenantState}
														companyState={companyState}
														setSelectedPayment={setSelectedPayment}
													/>
												))}
										</Stack>
									)}
									{permissions.includes("Create") ? (
										<Button fullWidth component={RouterLink} to={`/app/new/payment?companyId=${companyState.company.id}`} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
											Ajouter un paiement
										</Button>
									) : (
										<Button onClick={openPermissionModal} fullWidth variant="contained" color="primary" sx={{ boxShadow: "none" }}>
											Ajouter un paiement
										</Button>
									)}
								</Stack>
							</AccordionDetails>
						</Accordion>
					</Stack>
				</Container>
			</React.Fragment>
			<ModalDelete theme={theme} item={companyState.company} type="payment" isLoading={companyState.isLoading.includes(CompanyLoadings.UPDATING_COMPANY)} isDisabled={companyState.isLoading.includes(CompanyLoadings.UPDATING_COMPANY)} dispatchDelete={deleteCompanyProp} modalClose={modalCloseDelete} open={openDelete} />
			<ModalDelete
				theme={theme}
				item={selectedPayment}
				type="payment"
				isLoading={deletePayment.isLoading}
				isDisabled={deletePayment.isDisabled}
				dispatchDelete={deletePayment.dispatchDelete(selectedPayment)}
				modalClose={() => {
					setOpenDeletePay(null);
				}}
				open={openDeletePay}
			/>
		</React.Fragment>
	);
};

export default BanksViewDetail;
