import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";


import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import * as UserLoadings from "../../constants/loadings/user";
import * as SubscriptionLoadings from "../../constants/loadings/subscription";

import { ManageSubscription } from "../../actions/subscription-api";


const ModalAbonnement = ({ modalClose, userState, open, stripeConfigured, theme }) => {
	const dispatch = useDispatch();
	const subscriptionState = useSelector((state) => state.subscription);

	const manageSubscription = () => {
		if (!R.isNil(subscriptionState.subscription.uri) && !R.isEmpty(subscriptionState.subscription.uri)) {
			window.location.replace(subscriptionState.subscription.uri);
		} else {
			dispatch(ManageSubscription())
				.then((url) => {
					window.location.replace(url);
				})
				.catch((error) => {
					console.log(error);
					// toast.error("Échec de l'opération, réessayez !", toastConf);
				});
		}
	};
	return (
		<Dialog fullWidth maxWidth="xs" onClose={modalClose} open={open} theme={theme} sx={{ textAlign: "center" }}>
			<DialogTitle variant="h4" color="info.main" sx={{ pt: 3, pb: 1 }}>
				Abonnement
			</DialogTitle>
			<DialogContent sx={{ alignItems: "center", pb: 1 }}>
				<DialogContentText variant="body" mb={2}>
					{!stripeConfigured ? "Gérer mon abonnement Magestimmo." : "La connexion a été correctement configurée."}
				</DialogContentText>
			</DialogContent>
			<DialogActions disableSpacing={true} sx={{ flexDirection: "column", p: 3, pt: 0 }}>
				{!stripeConfigured ? (
					<LoadingButton
						disabled={subscriptionState.isLoading.includes(SubscriptionLoadings.MANAGE_SUBSCRIPTION)}
						loading={subscriptionState.isLoading.includes(SubscriptionLoadings.MANAGE_SUBSCRIPTION) || subscriptionState.isLoading.includes(SubscriptionLoadings.FETCHING_SUBSCRIPTION)}
						onClick={manageSubscription}
						type="submit"
						size="large"
						variant="contained"
						theme={theme}
						color="info"
						sx={{ px: 3 }}>
						Gestion abonnement
					</LoadingButton>
				) : (
					<LoadingButton disabled={userState.isLoading.includes(UserLoadings.UPDATING_USER)} loading={userState.isLoading.includes(UserLoadings.UPDATING_USER).toString()} type="submit" size="large" variant="contained" theme={theme} color="error" sx={{ px: 3 }}>
						Désactiver accès
					</LoadingButton>
				)}
				<Button theme={theme} color="neutral" fullWidth onClick={modalClose} sx={{ px: 3, mt: 1 }}>
					Annuler
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ModalAbonnement;
