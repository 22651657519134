import axios from 'axios';
import { authActions } from "../reducers/auth";
import * as urls from '../constants/webapi/auth';


export const LoginUser = ({ userName, password }) => {
    return async dispatch => {
        dispatch(authActions.loginRequest());
        return axios.post(urls.getAuthTokenUrl(), { userName, password })
            .then((response) => {
                dispatch(authActions.loginSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(authActions.loginFailure(error.response.data));
            });
    };
};

export const Logout = () => {
    return async dispatch => {
        return dispatch(authActions.logoutSuccess());
    };
};


export const ValidateToken = (token) => {
    return async dispatch => {
        dispatch(authActions.getTokenValidationRequest());
        return new Promise((resolve, reject) => {
            axios.get(urls.getAuthValidateUrl(token))
                .then((response) => {
                    dispatch(authActions.getTokenValidationSuccess(response.data));
                    resolve();
                })
                .catch((error) => {
                    dispatch(authActions.getTokenValidationFailure(error.response.data));
                    reject();
                });
        });
    };
};


export const ForgotPassword = ({ email }) => {
    return async dispatch => {
        dispatch(authActions.forgotPasswordRequest());
        return new Promise((resolve, reject) => {
            axios.post(urls.getAuthResetUrl(), { email })
                .then((response) => {
                    dispatch(authActions.forgotPasswordSuccess(response.data));
                    resolve();
                })
                .catch((error) => {
                    dispatch(authActions.forgotPasswordFailure(error.response.data));
                    reject();
                });
        });
    };
};

export const UpdatePassword = (token, { newPassword, confirmationPassword }) => {

    return async dispatch => {
        dispatch(authActions.updatePasswordRequest());
        return new Promise((resolve, reject) => {
            axios.patch(urls.getAuthUpdateUrl(token), { newPassword, confirmationPassword })
                .then((response) => {
                    dispatch(authActions.updatePasswordSuccess(response.data));
                    resolve();
                })
                .catch((error) => {
                    dispatch(authActions.updatePasswordFailure(error.response.data));
                    reject();
                });
        });
    };
};


