import React from 'react';
import * as R from 'ramda';

// Components
// Components: Global
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import TextField from "@mui/material/TextField";
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

// Components: Common
import { onChangeValidation } from '../../../../constants/utils/validators';
import { DECAISSEMENT, ENCAISSEMENT } from '../../../../constants/utils/magicVariables';
import FormUpload from '../_common/upload';
import FormMain from '../_common/main';
import FormNotes from '../_common/notes';
import FormPhotos from '../_common/photos';
import FormGlobalComplete from '../_common/globalComplete';
import { paymentTypes } from '../../../../constants/utils/objectProperties';

// Components: Views

export const FormPayment = ({ theme, formData, setFormData, docFiles, setDocFiles, companyUseAutocomplete, subcontractorUseAutocomplete, assetUseAutocomplete, tenantUseAutocomplete, selectCompanyByAsset, paymentType, setPaymentType, companyId, uploadedFiles, setUploadedFiles, ...others }) => {
    const handleOnChange = (event) => { setFormData(onChangeValidation(event.target, formData)); };

    const handleClick = (event, newAlignment) => {
        const tempFormData = R.clone(formData);
        if (newAlignment === DECAISSEMENT) {
            tempFormData.dueDate = { ...tempFormData.dueDate, isRequired: false, isValid: false, error: null };
            tempFormData.totalHT = { ...tempFormData.totalHT, isRequired: true, isValid: false, error: null };
            tempFormData.totalTTC = { ...tempFormData.totalTTC, isRequired: false, isValid: false, error: null };
            // tempFormData.total = { ...tempFormData.totalTTC, isRequired: true, isValid: false, error: null };
            setPaymentType(DECAISSEMENT);
            setFormData(tempFormData);
            return;
        }
        if (newAlignment === ENCAISSEMENT) {
            tempFormData.dueDate = { ...tempFormData.dueDate, isRequired: true, isValid: false, error: null };
            tempFormData.totalHT = { ...tempFormData.totalHT, isRequired: true, isValid: false, error: null };
            tempFormData.totalTTC = { ...tempFormData.totalTTC, isRequired: true, isValid: false, error: null };
            // tempFormData.total = { ...tempFormData.totalTTC, isRequired: false, isValid: false, error: null };
            setFormData(tempFormData);
            setPaymentType(ENCAISSEMENT);
            return;
        }
        setPaymentType(null);
    };

    return (
        <Stack spacing={2} flex="1 1 auto" width="100%" {...others}>
            <FormUpload theme={theme} photos={docFiles} setPhotos={setDocFiles} type="payment" />
            <Container maxWidth="100%" sx={{ display: "inherit", flexDirection: "inherit", gap: 2 }}>
                <Stack direction="column" spacing={4}>
                    <Stack direction="column" spacing={2}>
                        <FormMain
                            companyId={companyId}
                            formData={formData}
                            setFormData={setFormData}
                            handleOnChange={handleOnChange}
                            companyUseAutocomplete={companyUseAutocomplete}
                            tenantUseAutocomplete={tenantUseAutocomplete}
                            assetUseAutocomplete={assetUseAutocomplete}
                            selectCompanyByAsset={selectCompanyByAsset}
                            resetOnSelectingAsset={
                                R.compose(
                                    tenantUseAutocomplete.resetFormData,
                                    selectCompanyByAsset,
                                    assetUseAutocomplete.handleSetOptionsFormData)
                            }
                            resetOnSelectingCompany={
                                R.compose(
                                    tenantUseAutocomplete.resetFormData,
                                    assetUseAutocomplete.resetFormData,
                                    companyUseAutocomplete.handleSetOptionsFormData
                                )
                            }
                        />
                        <Stack direction={{ default: "column", md: "row" }} spacing={1} my={1}>
                            <FormGlobalComplete
                                options={subcontractorUseAutocomplete.options}
                                optionsFormData={subcontractorUseAutocomplete.dropdownFormData}
                                setOptionsFormData={subcontractorUseAutocomplete.setDropdownFormData}
                                handleSetOpen={subcontractorUseAutocomplete.handleSetOpen}
                                handleSetOptionsFormData={subcontractorUseAutocomplete.handleSetOptionsFormData}
                                multi={false}
                                name="subcontractors"
                                label="Sous-traitant"
                            />
                        </Stack>
                        <Stack direction="column" spacing={1} py={2}>
                            <Paper elevation={0} sx={{ marginRight: "auto", width: "auto", borderRadius: 5 }}>
                                <ToggleButtonGroup exclusive aria-label="Type de paiement" onChange={(e, v) => { handleClick(e, v); }} value={paymentType} >
                                    <ToggleButton value={ENCAISSEMENT} aria-label="Encaissement">
                                        <Typography 
                                            color="text.secondary"
                                            display="inherit" alignItems="center" gap={1} variant="label">
                                                <ArrowUpwardIcon color="success" />Encaissement </Typography>
                                    </ToggleButton>
                                    <ToggleButton value={DECAISSEMENT} aria-label="Décaissement">
                                        <Typography 
                                           color="text.secondary" display="inherit" alignItems="center" gap={1} variant="label"><ArrowDownwardIcon color="error" />Décaissement</Typography>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Paper>
                            {!R.isNil(paymentType) ?
                                <Stack flex="1 1 auto" spacing={1} pt={1} width="100%">
                                    {paymentType === ENCAISSEMENT ?
                                        <React.Fragment>
                                            <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                                                {/* <TextField
                                                    label="Date d'échéance"
                                                    id="échéance"
                                                    type="text"
                                                    fullWidth
                                                    variant="filled"
                                                    onChange={(event) => handleData('échéance', event)}
                                                    value={formData.dueDate}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end"><CalendarTodayIcon /></InputAdornment>
                                                    }}
                                                /> */}
                                                <DesktopDatePicker
                                                    label="Date d'échéance *"
                                                    inputFormat="dd/MM/yyyy"
                                                    fullWidth
                                                    name="dueDate"
                                                    helperText={formData.dueDate.error}
                                                    value={R.isEmpty(formData.dueDate.value) ? null : new Date(formData.dueDate.value)}
                                                    onChange={(dueDate) => { 
                                                        if (isNaN(dueDate)) {return; }
                                                        setFormData({ ...formData, dueDate: { ...formData.dueDate, value: dueDate?.toISOString(), error: null, isValid: true } 
                                                    }); }}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            error={formData.dueDate.error && !formData.dueDate.isValid}
                                                            helperText={formData.dueDate.error}
                                                        />}
                                                />
                                                <TextField fullWidth id="select" label="Reçu comme"
                                                    variant='filled'
                                                    select
                                                    name="paymentCategory"
                                                    error={formData.paymentCategory.error && !formData.paymentCategory.isValid}
                                                    helperText={formData.paymentCategory.error}
                                                    value={formData.paymentCategory.value}
                                                    onChange={(event) => { handleOnChange(event); }}>
                                                    <MenuItem value={1}>Loyer</MenuItem>
                                                    <MenuItem value={2}>Caution</MenuItem>
                                                    <MenuItem value={3}>Facture</MenuItem>
                                                    <MenuItem value={4}>Crédit</MenuItem>
                                                </TextField>
                                                <TextField fullWidth id="select" label="Type d'opération"
                                                    name="paymentType"
                                                    error={formData.paymentType.error && !formData.paymentType.isValid}
                                                    helperText={formData.paymentType.error}
                                                    value={formData.paymentType.value}
                                                    onChange={(event) => { handleOnChange(event); }}
                                                    variant='filled'
                                                    select>
                                                    {Object.entries(paymentTypes).map(([key, value]) => {
                                                        return(<MenuItem value={key}>{value}</MenuItem>);
                                                    })}
                                                </TextField>
                                            </Stack>
                                            <TextField
                                                label='Nº transaction'
                                                id="Transaction"
                                                type="text"
                                                fullWidth
                                                variant="filled"
                                                name="transactionNumber"
                                                error={formData.transactionNumber.error && !formData.transactionNumber.isValid}
                                                helperText={formData.transactionNumber.error}
                                                value={formData.transactionNumber.value}
                                                onChange={(event) => { handleOnChange(event); }}
                                            />
                                            <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                                                <TextField
                                                    label='Montant HT *'
                                                    type="text"
                                                    fullWidth
                                                    variant="filled"
                                                    name="totalHT"
                                                    error={formData.totalHT.error && !formData.totalHT.isValid}
                                                    helperText={formData.totalHT.error}
                                                    value={formData.totalHT.value}
                                                    onChange={(event) => { handleOnChange(event); }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end"><EuroSymbolIcon /></InputAdornment>
                                                    }}
                                                />
                                                <TextField
                                                    label='Montant TTC *'
                                                    type="text"
                                                    fullWidth
                                                    variant="filled"
                                                    name="totalTTC"
                                                    error={formData.totalTTC.error && !formData.totalTTC.isValid}
                                                    helperText={formData.totalTTC.error}
                                                    value={formData.totalTTC.value}
                                                    onChange={(event) => { handleOnChange(event); }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end"><EuroSymbolIcon /></InputAdornment>
                                                    }}
                                                />
                                            </Stack>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                                                <TextField
                                                    label='Montant *'
                                                    type="text"
                                                    fullWidth
                                                    variant="filled"
                                                    name="totalHT"
                                                    error={formData.totalHT.error && !formData.totalHT.isValid}
                                                    helperText={formData.totalHT.error}
                                                    value={formData.totalHT.value}
                                                    onChange={(event) => { handleOnChange(event); }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end"><EuroSymbolIcon /></InputAdornment>
                                                    }}
                                                />
                                                <TextField fullWidth id="select" label="Reçu comme"
                                                    variant='filled'
                                                    select
                                                    name="paymentCategory"
                                                    error={formData.paymentCategory.error && !formData.paymentCategory.isValid}
                                                    helperText={formData.paymentCategory.error}
                                                    value={formData.paymentCategory.value}
                                                    onChange={(event) => { handleOnChange(event); }}>
                                                    <MenuItem value={1}>Loyer</MenuItem>
                                                    <MenuItem value={2}>Caution</MenuItem>
                                                    <MenuItem value={3}>Facture</MenuItem>
                                                    <MenuItem value={4}>Crédit</MenuItem>
                                                </TextField>
                                                <TextField fullWidth id="select" label="Type d'opération"
                                                    name="paymentType"
                                                    error={formData.paymentType.error && !formData.paymentType.isValid}
                                                    helperText={formData.paymentType.error}
                                                    value={formData.paymentType.value}
                                                    onChange={(event) => { handleOnChange(event); }}
                                                    variant='filled'
                                                    select>
                                                    {Object.entries(paymentTypes).map(([key, value]) => {
                                                        return(<MenuItem value={key}>{value}</MenuItem>);
                                                    })}
                                                </TextField>
                                            </Stack>
                                            <TextField
                                                label='Nº transaction'
                                                id="Transaction"
                                                fullWidth
                                                variant="filled"
                                                name="transactionNumber"
                                                error={formData.transactionNumber.error && !formData.transactionNumber.isValid}
                                                helperText={formData.transactionNumber.error}
                                                value={formData.transactionNumber.value}
                                                onChange={(event) => { handleOnChange(event); }}
                                            />
                                        </React.Fragment>
                                    }
                                </Stack> : 
                                !R.isNil(formData.totalHT.error) && <Typography variant="caption" color="error">
                                Veuillez sélectionner une option
                              </Typography>
                            }
                        </Stack>
                    </Stack>
                    <FormPhotos fileType="Payment" title="document" photos={docFiles} setPhotos={setDocFiles} uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} handleClick={handleClick} theme={theme} />
                    <FormNotes formData={formData} handleOnChange={handleOnChange} />
                </Stack>
            </Container>
        </Stack>
    );
};

export default FormPayment;
