import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as R from 'ramda';

// Components
// Components: Global
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// Components: Common
import ItemCard from '../../../_common/itemCard';

export const ResultsListItem = ({ link, name, item}) => {

    return (
        <ItemCard alignItems="center" to={link} component={RouterLink}>
            <Avatar variant="circular" sx={{ bgcolor: "grey.A300", width: '3rem', height: '3rem', fontWeight: 'bold' }} children={name.split(" ")[0].charAt(0) + (!R.isNil(name.split(" ")[1]) ? name.split(" ")[1].charAt(0) : '')} />
            <Stack direction="column" flex="1 1 auto" width="100%" gap={.5} flexWrap="wrap">
                <Stack direction="row" flex="1 1 auto" gap={.5} width="100%" flexWrap="wrap">
                    <Typography flex="1 1 auto" variant="text" sx={{ wordBreak: "break-all" }}>{name}</Typography>
                </Stack>
                <Typography flex="1 1 auto" variant="text" color="gray" sx={{ fontSize: ".925rem", wordBreak: "break-all" }}>{item.asset?.title}</Typography>
            </Stack>
        </ItemCard>
    );
};


export default ResultsListItem;