import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const AssetsMenuFooter = () => {
    const routerLocation = useLocation();
    return (
        <Box mt="auto" sx={{ borderRadius: 1 }} width="100%" position="sticky" bottom="0">
            <Container maxWidth="100%">
                {routerLocation.pathname.includes("immeubles") ?
                    <Button component={RouterLink} to="/app/new/immeuble" variant="contained" color="primary" sx={{ width: "100%" }}>Ajouter un immeuble</Button>
                    :
                    <Button component={RouterLink} to="/app/new/asset" variant="contained" color="primary" sx={{ width: "100%" }}>Ajouter un bien</Button>
                }
            </Container>
        </Box>
    );
};

export default AssetsMenuFooter;
