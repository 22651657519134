import React from 'react';

// Components
// Components: Global
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Components: Views
import CompaniesMenu from './menu';
import CompaniesView from './view';

export const Companies = ({ theme, userState, companyState, tenantState, assetState, assetGroupState, subcontractorState, collaboratorState, documentState, rentalState }) => {

	return (
		<Box component="section" className="layout" flex="1 1 auto" alignItems="center" width="100%" height="calc(100% - 6.5rem)" overflow="hidden auto" mt={{ md: -2 }}>
			<Typography component="h1" className="sr-only">Content</Typography>
			<CompaniesMenu theme={theme} userState={userState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} />
			<CompaniesView theme={theme} userState={userState} tenantState={tenantState} documentState={documentState} companyState={companyState} rentalState={rentalState} assetState={assetState} assetGroupState={assetGroupState} collaboratorState={collaboratorState}/>
		</Box>
	);
};

export default Companies;
