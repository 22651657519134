import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

// Components
// Components: Global
import Box from '@mui/material/Box';

// Components: Common
// Components: View
import AssetsViewEmpty from '../empty';
import AssetsViewDetailBien from './bien';
import AssetsViewDetailImmeubles from './immeubles';
import AssetsViewDetailVendu from './vendu';

export const AssetsViewDetail = ({  companyState, tenantState, assetState, assetGroupState, subcontractorState, collaboratorState, documentState, rentalState, accountState, paymentState }) => {
    const { pathname } = useLocation();
    const showView = !pathname.includes('/assets');
	return (
		<Routes>
			<Route 
			  path="*"
			  element={
				<Box 
				  display={{ default: showView ? 'flex' : 'none', lg: "flex" }} 
				  flexDirection="column"
				  height="100%" 
				  width="100%" 
				  overflow="hidden auto" 
				  backgroundColor="background.default" 
				  sx={{ 
					borderRadius: 2,
					position: { default: showView && 'fixed', lg: 'relative' },
					left: { default: showView && 0, lg: 'unset' },
					right: { default: showView && 0, lg: 'unset' },
					top: { default: showView && '5rem', lg: 'unset' },
					height: { default: showView && 'calc(100% - 5rem)', lg: 'inherit' },
					bottom: { default: showView && 0, lg: 'unset' },
					zIndex: { default: showView && 2, lg: 'unset' } 
					}}
				>
					<AssetsViewEmpty />
				</Box>
			} />
			<Route path=":companyId/:id/*" element={
				<AssetsViewDetailBien index={1} companyState={companyState} tenantState={tenantState} assetState={assetState} assetGroupState={assetGroupState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} documentState={documentState} rentalState={rentalState} accountState={accountState} paymentState={paymentState}/>
			} />
			<Route path="immeubles/:companyId/:id/*"element={
				<AssetsViewDetailImmeubles index={0}  companyState={companyState} tenantState={tenantState} assetState={assetState} assetGroupState={assetGroupState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} documentState={documentState} rentalState={rentalState} accountState={accountState} paymentState={paymentState}/>
			} />
			<Route path="vendu/:companyId/:id/*"element={
				<AssetsViewDetailVendu index={1} companyState={companyState} tenantState={tenantState} assetState={assetState} assetGroupState={assetGroupState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} documentState={documentState} rentalState={rentalState} accountState={accountState} paymentState={paymentState}/>
			} />
		</Routes>
	);
};

export default AssetsViewDetail;
