import React, { useMemo } from "react";
import * as R from "ramda";

// Components
// Components: Global
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import IconButton from "@mui/material/IconButton";
import { Masonry } from "@mui/lab";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
//import { useDispatch } from "react-redux";
import { imageTypes } from "../../../../constants/utils/filesTypes";
import { Box } from "@mui/material";

// Components: Common

// Components: Views

export const FormPhotos = ({
  theme,
  fileType,
  title,
  assetAttachements,
  photos,
  setPhotos,
  onImageChange,
  uploadedFiles,
  setUploadedFiles,
  handleClick,
  ...others
}) => {
  //const dispatch = useDispatch();
  const _photos = useMemo(() => photos, [photos]);
  const _uploadedFiles = useMemo(() => uploadedFiles, [uploadedFiles]);


  const pdfList = useMemo(() => {
    const res = (_photos || []).filter((p) => p?.type?.includes("pdf"));
    return Array.isArray(res) ? res : [];
  }, [_photos]);
 


  const imageList = useMemo(() => {
    const res = (_photos || []).filter((p) => p?.type?.includes("image"));
    return Array.isArray(res) ? res : [];
  }, [_photos]);

  const removePhoto = (index) => {
    setPhotos(photos.filter((item, i) => i !== index));
  };

  const removePdf = (index) => {
    let newPdf = [...pdfList];
    newPdf = newPdf.filter((item, i) => i !== index);
    setPhotos([...newPdf, ...imageList]);
  };

  const removeImage = (index) => {
    let newImg = [...imageList];
    newImg = newImg.filter((item, i) => i !== index);
    setPhotos([...pdfList, ...newImg]);
  };

  return (
    <Stack
      direction="column"
      flex="1 1 auto"
      spacing={1}
      width="100%"
      {...others}
    >
      <Typography variant="label" color="text.secondary">
        {title ? title : "Galerie"}
      </Typography>
      {assetAttachements && (
        <Card
          elevation={1}
          sx={{ display: "flex", flexDirection: "column", gap: 1, padding: 1 }}
          {...others}
        >
          <label htmlFor="uploadPhotos">
            <Button
              component="span"
              size="large"
              fullWidth
              variant="contained"
              theme={theme}
              color="secondary"
              sx={{ borderRadius: 3, px: 3 }}
              startIcon={<AddAPhotoIcon />}
            >
              Photos
            </Button>
          </label>
          <input
            id="uploadPhotos"
            accept="image/*"
            hidden
            type="file"
            name="myImage"
            multiple
            onChange={onImageChange}
            onClick={handleClick}
          />
        </Card>
      )}
      {!R.isEmpty(_photos) && !R.isNil(_photos) ? (
        <Masonry
          columns={{ default: 1, md: 2, lg: 4, xl: 6, hd: 8 }}
          spacing={1}
          sx={{ width: "100%" }}
        >
          {pdfList
            //.map((p) => URL.createObjectURL(p))
            .map((item, index) => (
              <ImageListItem
                key={item.name ? item.name + index : index}
                elevation={0}
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  borderRadius: 1,
                  height: "100% !important",
                  maxHeight: "8rem",
                }}
                {...others}
              >
                {!pdfList[index] ? (
                  <Avatar
                    loading="lazy"
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "inherit",
                    }}
                  >
                    <PictureAsPdfIcon width="100%" />
                  </Avatar>
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      overflow: "hidden",
                      borderRadius: "inherit",
                    }}
                  >
                    <iframe src={URL.createObjectURL(pdfList[index])}></iframe>
                  </Box>
                )}
                <ImageListItemBar
                  sx={{
                    borderRadius: "inherit",
                    background:
                      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, " +
                      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                  }}
                  title={pdfList[index].name}
                  position="bottom"
                  actionIcon={
                    <IconButton
                      sx={{ color: "white" }}
                      aria-label={`delete ${pdfList[index].name}`}
                      onClick={() => removePdf(index)}
                    >
                      <DeleteSweepIcon />
                    </IconButton>
                  }
                  actionPosition="right"
                />
              </ImageListItem>
            ))}
          {imageList
            .map((p) => URL.createObjectURL(p))
            .map((item, index) => (
              <ImageListItem
                key={item.name ? item.name + index : index}
                elevation={0}
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  borderRadius: 1,
                  height: "100% !important",
                  maxHeight: "8rem",
                }}
                {...others}
              >
                <Avatar
                  src={item}
                  alt={imageList[index].name.charAt(0)}
                  loading="lazy"
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "inherit",
                  }}
                >
                  {imageList[index].name.split(".")[1] === "pdf" ? (
                    <PictureAsPdfIcon width="100%" />
                  ) : (
                    <AttachFileIcon width="100%" />
                  )}
                </Avatar>
                <ImageListItemBar
                  sx={{
                    borderRadius: "inherit",
                    background:
                      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, " +
                      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                  }}
                  title={imageList[index].name}
                  position="bottom"
                  actionIcon={
                    <IconButton
                      sx={{ color: "white" }}
                      aria-label={`delete ${imageList[index].name}`}
                      onClick={() => removeImage(index)}
                    >
                      <DeleteSweepIcon />
                    </IconButton>
                  }
                  actionPosition="right"
                />
              </ImageListItem>
            ))}
        </Masonry>
      ) : (
        <Stack spacing={1} direction="row">
          <Typography color="text.hint" variant="text">
            Aucun nouveau{" "}
            {title ? (title === "documents" ? "document" : title) : "fichier"}
          </Typography>
        </Stack>
      )}
      {!R.isEmpty(_uploadedFiles) && !R.isNil(_uploadedFiles) && (
        <Masonry
          columns={{ default: 1, md: 2, lg: 4, xl: 6, hd: 8 }}
          spacing={1}
          sx={{ width: "100%" }}
        >
          {_uploadedFiles.map((item, index) => (
            <ImageListItem
              key={item.id}
              elevation={0}
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                gap: 1,
                borderRadius: 1,
                height: "100% !important",
                maxHeight: "8rem",
              }}
              {...others}
            >
              {imageTypes.includes(item?.contentType) ? (
                <Avatar
                  src={imageTypes.includes(item.contentType) ? item.url : null}
                  alt={item.fileName.charAt(0)}
                  loading="lazy"
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "inherit",
                  }}
                >
                  {item.fileName.split(".")[1] === "pdf" ? (
                    <PictureAsPdfIcon width="100%" />
                  ) : (
                    <AttachFileIcon width="100%" />
                  )}
                </Avatar>
              ) : null}
              {item?.fileName &&
                item?.url &&
                item.fileName?.split(".")[1] === "pdf" && (
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      overflow: "hidden",
                      borderRadius: "inherit",
                    }}
                  >
                    <iframe src={item.url}></iframe>
                  </Box>
                )}
              <ImageListItemBar
                sx={{
                  borderRadius: "inherit",
                  background:
                    "linear-gradient(to top, rgba(0,0,0,0.7) 0%, " +
                    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                }}
                title={item.fileName}
                position="bottom"
                actionIcon={
                  <IconButton
                    sx={{ color: "white" }}
                    aria-label={`delete ${item.fileName}`}
                    onClick={() => {
                      setUploadedFiles(
                        uploadedFiles.filter((item, i) => i !== index)
                      );
                      //dispatch(DeleteFiles([item], item.objectId, fileType)).then((data) => setUploadedFiles(uploadedFiles.filter((doc) => doc.id !== item.id)));
                    }}
                  >
                    <DeleteSweepIcon />
                  </IconButton>
                }
                actionPosition="right"
              />
            </ImageListItem>
          ))}
        </Masonry>
      )}
    </Stack>
  );
};

export default FormPhotos;
