import axios from 'axios';
import { userActions } from "../reducers/user";
import * as urls from '../constants/webapi/user';
import Auth from '../constants/utils/auth';



export const GetUser = (userId) => {

    return async dispatch => {
        dispatch(userActions.getUserRequest());
        return axios.get(urls.getUserByIdUrl(userId), {
            validateStatus: function (status) {
              return status >= 200; // Resolve only if the status code is less than 500
            }
          })
            .then((response) => {
                if (response.status === 401) {
                    Auth.clearAccessToken();
                    window.location.replace('/auth/login');
                    return;
                }
                dispatch(userActions.getUserSuccess(response?.data));
                return response?.data;
            }, (error) => {
                if (error?.response?.data?.status === 401) {
                    Auth.clearAccessToken();
                    // window.location.replace('/auth/login');
                }
                dispatch(userActions.getUserFailure(error.response.data));
            });
    };
};

export const UpdateUser = (userId, { firstName, lastName, phoneNumber }) => {
    return async dispatch => {
        dispatch(userActions.updateUserRequest());
        return new Promise((resolve, reject) => {
            axios.put(urls.getUserByIdUrl(userId), { firstName, lastName, phoneNumber })
                .then((response) => {
                    dispatch(userActions.updateUserSuccess({ firstName, lastName, phoneNumber }));
                    resolve();
                })
                .catch((error) => {
                    dispatch(userActions.updateUserFailure(error.response.data));
                    reject();
                });
        });
    };
};

export const UpdateUserPermission = (permission) => {
    return async dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(userActions.updateUserPermissionSuccess(permission));
            resolve();
        });
    };
};

export const RegisterUser = ({ lastName, firstName, email, password, passwordConfirmation, phoneNumber, tierId }) => {
    const userInfo = {
        lastName, firstName, email, password, passwordConfirmation, phoneNumber,tierId
    };

    return async dispatch => {
        dispatch(userActions.registerUserRequest());
        
        return axios.post(urls.getUserUrl(), userInfo)
            .then((response) => {
                dispatch(userActions.registerUserSuccess(response.data));
            })
            .catch((error) => {
                dispatch(userActions.registerUserFailure(error.response.data));
            });
    };
};

export const GetAvailableServices = () => {
    return async dispatch => {
        dispatch(userActions.getAvailableServicesRequest());
        return axios.get(urls.getUserGoogleProvidersUrl())
            .then((response) => {
                dispatch(userActions.getAvailableServicesSuccess(response.data));
            })
            .catch((error) => {
                dispatch(userActions.getAvailableServicesFailure(error.response.data));
            });
    };
};

export const GoogleAuthentication = () => {
    return async dispatch => {
        dispatch(userActions.googleAuthenticationRequest());
        return axios.post(urls.getUserGoogleAuthUrl())
            .then((response) => {
                dispatch(userActions.googleAuthenticationSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(userActions.googleAuthenticationFailure(error.response.data));
                throw error;
            });
    };
};

export const GoogleRevoke = () => {
    return async dispatch => {
        dispatch(userActions.googleRevokeRequest());
        return axios.post(urls.getUserGoogleRevokesUrl())
            .then((response) => {
                dispatch(userActions.googleRevokeSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(userActions.googleRevokeFailure(error.response.data));
                throw error;
            });
    };
};


export const SetPermissionModal = (payload) => {
    return async dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(userActions.setIsOpen(payload));
            resolve();
        });
    };
};

