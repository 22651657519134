import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import counterReducer from '../features/counter/counterSlice';
import AccountReducer from './account';
import AssetReducer from './asset';
import AssetGroupReducer from './assetGroup';
import AuthReducer from './auth';
import CollaboratorReducer from './collaborator';
import CompanyReducer from './company';
import DocumentReducer from './document';
import FileReducer from './file';
import NotificationReducer from './notification';
import OccurrenceReducer from './occurrence';
import PaymentReducer from './payment';
import RentalReducer from './rental';
import SubcontractorReducer from './subcontractor';
import SubscriptionReducer from './subscription';
import TenantReducer from './tenant';
import TierReducer from './tier';
import UserReducer from './user';
import SearchReducer from './search'; 
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage/session' 

const rootReducer = combineReducers({
    counter: counterReducer,
    account: AccountReducer,
    asset: AssetReducer,
    assetGroup: AssetGroupReducer,
    auth: AuthReducer,
    collaborator: CollaboratorReducer,
    company: CompanyReducer,
    document: DocumentReducer,
    file: FileReducer,
    notification: NotificationReducer,
    occurrence: OccurrenceReducer,
    payment: PaymentReducer,
    rental: RentalReducer,
    subcontractor: SubcontractorReducer,
    subscription: SubscriptionReducer,
    tenant: TenantReducer,
    tier: TierReducer,
    user: UserReducer,
    search: SearchReducer,
})

const persistConfig = {
    key: 'root',
    storage,
   // whitelist: ['asset']
  }
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  
export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
          /* ignore persistance actions */
          ignoredActions: [
            FLUSH,
            REHYDRATE,
            PAUSE,
            PERSIST,
            PURGE,
            REGISTER
          ],
        },
      }),
});
export const persistor = persistStore(store)