import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import { format } from 'date-fns';

// Components
// Components: Global
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HouseIcon from '@mui/icons-material/House';

// Components: Common
import { DeleteCompany, GetCompany } from '../../../../actions/company-api';
import { GetAssets } from '../../../../actions/asset-api';
import { GetAssetGroups } from '../../../../actions/assetGroup-api';
import { GetPayments } from '../../../../actions/payment-api';
import { GetDocuments } from '../../../../actions/document-api';
import { GetRentals } from '../../../../actions/rental-api';
import { GetTenants } from '../../../../actions/tenant-api';

import { dayMonthYear } from '../../../../constants/utils/date';
import { paymentTypes } from '../../../../constants/utils/objectProperties';
import * as CompanyLoadings from '../../../../constants/loadings/company';

import Loading from '../../../../_common/loading';
import ItemCard from '../../../_common/itemCard';


// Components: View
import CompaniesViewDetailHeader from './_common/header';
import CompaniesViewEmpty from './../empty';
import DocumentRow from '../../../_common/documentRow';
import ModalDelete from '../../../_common/modalDelete';
import { useDeleteDocument } from '../../../../hooks/useDeleteDocument';
import { EQUAL_QUERY } from '../../../../constants/utils/filterQuery';
import { usePermissionModal } from '../../../../hooks/usePermissionModal';
import { toastConf } from '../../../../constants/utils/toastConfig';

export const CompaniesViewDetail = ({ theme, tenantState, documentState, companyState, rentalState, assetState, assetGroupState, userState, collaboratorState }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openDeleteDoc, setOpenDeleteDoc] = useState(false);
    const [assetsExpanded, setAssetsExpanded] = useState("panel-assets", true);
    const [rentalsExpanded, setRentalsExpanded] = useState("panel-rentals", true);
    const [docsExpanded, setDocsExpanded] = useState("panel-docs", true);

    const { dispatchDelete, isLoading, isDisabled } = useDeleteDocument();
    const { openPermissionModal } = usePermissionModal();
    const modalOpenProfile = () => { setOpen(true); };

    useEffect(() => {
        if (R.isNil(tenantState.tenants)) {
            dispatch(GetTenants());
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(GetCompany(id));
        dispatch(GetAssets({ filter: { [EQUAL_QUERY.companyId]: id } }));
        dispatch(GetAssetGroups({ filter: { [EQUAL_QUERY.companyId]: id } }));
        dispatch(GetRentals({ filter: { [EQUAL_QUERY.companyId]: id } }));
        dispatch(GetDocuments({ filter: { [EQUAL_QUERY.companyId]: id } }));
        dispatch(GetPayments({ filter: { [EQUAL_QUERY.companyId]: id } }));
        // dispatch(GetCollaborators({ filter: { [EQUAL_QUERY.companyId]: id } }));
        // eslint-disable-next-line
    }, [id]);

    const deleteCompanyProp = async () => {
        try {
            await dispatch(DeleteCompany(companyState.company.id));
            navigate('/app/companies');
        } catch (error) {
            toast.error(`La suppression de cette société n'est pas disponible 
            car elle contient beaucoup d'informations. 
            Notre équipe de support a bien reçu la demande de suppression, 
            et la société sera supprimée dans les plus brefs délais !`, toastConf);
        }
    };

    const permissions = userState.user.companyAccesses.find(c => c.companyId === id)?.accessTypes || [];
	const handleAccordionChange = (panel) => (event, isExpanded) => {
		switch (panel) {
			default:
			case "panel-assets": {
				setAssetsExpanded(isExpanded ? panel : false);
				break;
			}
			case "panel-rentals": {
				setRentalsExpanded(isExpanded ? panel : false);
				break;
			}
			case "panel-docs": {
				setDocsExpanded(isExpanded ? panel : false);
				break;
			}
		}
	};

    return companyState.isLoading.includes(CompanyLoadings.FETCHING_COMPANY) ? (
			<Loading />
		) : (
			<React.Fragment>
				<CompaniesViewDetailHeader openPermissionModal={openPermissionModal} permissions={permissions} theme={theme} modalOpenProfile={modalOpenProfile} item={companyState} user={userState.user} tenantState={tenantState} />
				{R.isNil(companyState.company) || R.isEmpty(companyState.company) ? (
					<CompaniesViewEmpty color="danger" title="Ooops..." description="Quelque chose s'est mal passée. On dirait que cette société n'existe pas !" />
				) : (
					<Container maxWidth="100%" sx={{ height: "100%" }}>
						<Stack maxWidth="100%" my={3} direction="column" spacing={4}>
							<Accordion sx={{ backgroundColor: "transparent", boxShadow: "none", border: "none", p: 0, ":before": { backgroundColor: "inherit" } }} disableGutters expanded={assetsExpanded === "panel-assets"} onChange={handleAccordionChange("panel-assets")}>
								<AccordionSummary sx={{ minHeight: "auto", ".MuiAccordionSummary-content": { my: 1 }, p: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
									<Typography variant="h6" sx={{ lineHeight: 1 }}>
										Biens
									</Typography>
								</AccordionSummary>
								<AccordionDetails sx={{ p: 0 }}>
									<Stack maxWidth="100%" direction="column" spacing={1}>
										{!R.isNil(assetState.assets) && !R.isEmpty(assetState.assets) && (
											<Stack direction="column" spacing={1}>
												{assetState.assets
													.filter((r) => r.companyId === companyState.company.id)
													.map((asset) => (
														<ItemCard alignItems="top" to={`/app/assets/${asset.companyId}/${asset.id}`} component={RouterLink} key={asset.id}>
															<Avatar variant="circular" sx={{ bgcolor: "grey.A200", my: 0.25, width: "3rem", height: "3rem", fontWeight: "bold" }} children={asset.title[0]} src={!R.isNil(asset.profileUrl?.url) ? asset.profileUrl?.url : null} />
															<Stack direction="column" flex="1 1 auto" width="100%" gap={0.5}>
																<Stack direction="row" flex="1 1 auto" gap={2} width="100%">
																	<Typography flex="1 1 auto" variant="text" fontWeight="bold" sx={{ wordBreak: "break-all" }} color="text.dark">
																		{asset.title}
																	</Typography>
																	{!R.isNil(asset.assetState) && asset.assetState === 3 && (
																		<Typography fontWeight="bold" color="info.main" variant="caption" textTransform="uppercase">
																			Vendu
																		</Typography>
																	)}
																</Stack>
																<Stack direction={{ md: "row", lg: "column", xl: "row" }} gap={0.5} flex="1 1 auto" width="100%">
																	<Stack direction="row" gap={0.5} flex="1 1 auto" width="100%">
																		<HouseIcon flex="1 0 auto" fontSize="small" sx={{ lineHeight: "1" }} />
																		<Typography flex="1 1 auto" color="text.main" variant="body2" sx={{ wordBreak: "break-all", letterSpacing: -0.3 }}>
																			{!R.isNil(companyState.companies) && !R.isEmpty(companyState.companies) ? companyState.companies.find((c) => c.id === asset.companyId).title : "N/D"}
																		</Typography>
																	</Stack>
																	<Typography flex="1 0 auto" textAlign={{ md: "right", lg: "left", xl: "right" }} color="text.secondary" variant="body2" fontStyle="italic" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" maxWidth="sm">
																		{!R.isNil(asset.modifiedOn) ? format(new Date(asset.modifiedOn), dayMonthYear) : "DD/MM/YYYY"}
																	</Typography>
																</Stack>
															</Stack>
														</ItemCard>
													))}
											</Stack>
										)}
										{!R.isNil(assetGroupState.assetGroups) && !R.isEmpty(assetGroupState.assetGroups) && (
											<Stack direction="column" spacing={1}>
												{assetGroupState.assetGroups
													.filter((r) => r.companyId === companyState.company.id)
													.map(
														(assetGroup) =>
															!assetGroup.isDefault && (
																<ItemCard alignItems="top" to={`/app/assets/immeubles/${assetGroup.companyId}/${assetGroup.id}`} component={RouterLink} key={assetGroup.id}>
																	<Avatar variant="circular" sx={{ bgcolor: "grey.A200", my: 0.25, width: "3rem", height: "3rem", fontWeight: "bold" }} children={assetGroup.title[0]} src={!R.isNil(assetGroup.profileUrl) ? assetGroup.profileUrl?.url : null} />
																	<Stack direction="column" flex="1 1 auto" width="100%" gap={0.5}>
																		<Stack direction="row" flex="1 1 auto" gap={2} width="100%">
																			<Typography flex="1 1 auto" variant="text" fontWeight="bold" sx={{ wordBreak: "break-all", letterSpacing: -0.3 }} color="text.dark">
																				{assetGroup.title}
																			</Typography>
																			<Typography fontWeight="bold" color="info.main" variant="caption" textTransform="uppercase">
																				{assetState.assets ? assetState.assets.filter((property) => property.assetGroupId === assetGroup.id).length : "0"} biens
																			</Typography>
																		</Stack>
																		<Stack direction={{ md: "row", lg: "column", xl: "row" }} gap={0.5} flex="1 1 auto" width="100%">
																			<Stack direction="row" gap={0.5} flex="1 1 auto" width="100%">
																				<HouseIcon flex="1 0 auto" fontSize="small" sx={{ lineHeight: "1" }} />
																				<Typography flex="1 1 auto" color="text.main" variant="body2" sx={{ wordBreak: "break-all", letterSpacing: -0.3 }}>
																					{companyState.company.title}
																				</Typography>
																			</Stack>
																			<Typography flex="1 0 auto" textAlign={{ md: "right", lg: "left", xl: "right" }} color="text.secondary" variant="body2" fontStyle="italic" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" maxWidth="sm">
																				{!R.isNil(assetGroup.modifiedOn) ? format(new Date(assetGroup.modifiedOn), dayMonthYear) : "DD/MM/YYYY"}
																			</Typography>
																		</Stack>
																	</Stack>
																</ItemCard>
															)
													)}
											</Stack>
										)}
									</Stack>
								</AccordionDetails>
							</Accordion>
							<Divider />
							<Accordion sx={{ backgroundColor: "transparent", boxShadow: "none", border: "none", p: 0, ":before": { backgroundColor: "inherit" } }} disableGutters expanded={rentalsExpanded === "panel-rentals"} onChange={handleAccordionChange("panel-rentals")}>
								<AccordionSummary sx={{ minHeight: "auto", ".MuiAccordionSummary-content": { my: 1 }, p: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
									<Typography variant="h6" sx={{ lineHeight: 1 }}>
										Locations
									</Typography>
								</AccordionSummary>
								<AccordionDetails sx={{ p: 0 }}>
									<Stack maxWidth="100%" direction="column" spacing={1}>
										{!R.isNil(rentalState.rentals) && !R.isEmpty(rentalState.rentals) && (
											<Stack direction="column" spacing={2}>
												<Stack direction="column" spacing={1}>
													{/* <Typography variant="label" color="text.secondary">{rentalState.rentals?.filter(r => r.companyId === companyState.company?.id)?.filter(r => (!R.isNil(r.startDate) && (R.isNil(r.endDate) || new Date(r.endDate) >= new Date()))).length} Actives</Typography> */}
													{rentalState.rentals
														?.filter((r) => r.companyId === companyState.company?.id)
														?.filter((r) => !R.isNil(r.startDate) && (R.isNil(r.endDate) || new Date(r.endDate) >= new Date()))
														.map((rental, index) => (
															<ItemCard
																key={"r" + rental.title}
																to={`/app/contrats/${rental.companyId}/${rental.id}`}
																component={RouterLink}
																elevation={R.isNil(rental.startDate) ? 0 : 2}
																backgroundColor={R.isNil(rental.startDate) ? "transparent" : "background.paper"}
																border={R.isNil(rental.startDate) && "1px dashed"}
																borderColor={R.isNil(rental.startDate) && "grey.A300"}>
																<Avatar variant="circular" children={rental.title.charAt(0)} sx={{ bgcolor: "grey.A200", my: 0.25, width: "3rem", height: "3rem", fontWeight: "bold" }} />
																<Stack direction="column" spacing={{ default: 0.5, xl: 0 }} pr={{ default: 0, xl: 1 }} flex="1 1 auto" justifyContent={{ lg: "space-between" }}>
																	<Stack direction={{ default: "column", xl: "row" }} spacing={{ xl: 4 }} flex="1 1 auto" justifyContent={{ lg: "space-between" }}>
																		<Typography fontWeight="medium" color="text.dark">
																			{rental.title}
																		</Typography>
																		<Typography variant="body1">
																			<Box component="span" color="text.secondary">
																				Signature:{" "}
																			</Box>
																			{!R.isNil(rental.startDate) ? (
																				<Box component="span" ml={0.5}>
																					{format(new Date(rental.startDate), dayMonthYear)}
																				</Box>
																			) : (
																				<Box component="span" ml={0.5}>
																					Candidature
																				</Box>
																			)}
																		</Typography>
																	</Stack>
																	<Stack direction={{ default: "column", hd: "row" }} spacing={{ default: 1, lg: 4 }} flex="1 1 auto" justifyContent={{ hd: "space-between" }} alignItems={{ hd: "center" }}>
																		<Stack direction={{ default: "column", lg: "row" }} spacing={0.25} justifyContent={{ lg: "space-between" }}>
																			<Typography variant="body1">
																				<Box component="span" color="text.secondary">
																					Locataire:{" "}
																				</Box>
																				{!R.isNil(tenantState.tenants) && !R.isNil(rental.contactId) ? (
																					tenantState.tenants
																						.filter((contact) => rental.contactId.includes(contact.id))
																						.map((item, index) => (
																							<Typography color="text.main" component={RouterLink} to={`/app/tenants/${item.id}`} key={index} display="inline-flex" alignItems="center" aria-label="go to company" sx={{ textDecoration: "none", marginLeft: 0.25 }}>
																								{item.firstName} {item.lastName}
																							</Typography>
																						))
																				) : (
																					<Typography component="span" color="text.hint">
																						Le champ est vide
																					</Typography>
																				)}
																			</Typography>
																			<Typography sx={{ lineHeight: 1 }} display={{ default: "none", lg: "inherit" }} variant="h6" component="p" px={2} color="text.hint">
																				•
																			</Typography>
																			<Typography variant="body1">
																				<Box component="span" color="text.secondary">
																					Bien:{" "}
																				</Box>
																				{!R.isNil(assetState.assets) && !R.isNil(rental.assetId) ? (
																					assetState.assets
																						?.filter((asset) => rental.assetId.includes(asset.id))
																						.map((item, index) => (
																							<Typography color="text.main" component={RouterLink} to={`/app/assets/${item.companyId}/${item.id}`} key={index} display="inline-flex" alignItems="center" aria-label="go to company" sx={{ textDecoration: "none", marginLeft: 0.25 }}>
																								{item.title}
																							</Typography>
																						))
																				) : (
																					<Typography component="span" color="text.hint">
																						Le champ est vide
																					</Typography>
																				)}
																			</Typography>
																		</Stack>
																		<Stack direction={{ default: "column", lg: "row" }} flex="1 1 auto" spacing={{ default: 1, lg: 4 }} justifyContent={{ lg: "space-between" }}>
																			{rental.paymentType !== 0 && (
																				<Typography color="text.secondary" variant="body1">
																					Modalité de paiement:{" "}
																					<Box component="span" color="text.main">
																						{paymentTypes[rental.paymentType]}
																					</Box>
																				</Typography>
																			)}
																			<Typography color="text.secondary" variant="body1">
																				{/* Loyer sans Taxes: <Box component="span" color="text.main">{rental.rentPriceWithoutTax ? rental.rentPriceWithoutTax + '€' : 'N/A'} </Box> */}
																				Loyer:{" "}
																				<Box component="span" color="text.main">
																					{rental.rentPriceWithoutTax ? rental.rentPriceWithoutTax + "€" : "N/A"}{" "}
																				</Box>
																			</Typography>
																		</Stack>
																	</Stack>
																</Stack>
															</ItemCard>
														))}
												</Stack>
												<Stack direction="column" spacing={1}>
													<Typography variant="label" color="text.secondary">
														{rentalState.rentals?.filter((r) => r.companyId === companyState.company?.id)?.filter((r) => R.isNil(r.startDate)).length} Candidatures
													</Typography>
													{rentalState.rentals
														?.filter((r) => r.companyId === companyState.company?.id)
														?.filter((r) => R.isNil(r.startDate))
														.map((rental, index) => (
															<ItemCard key={"r" + rental.title} to={`/app/contrats/${rental.companyId}/${rental.id}`} component={RouterLink} elevation={0} backgroundColor="transparent" border={"1px dashed"} borderColor={"grey.A300"}>
																<Avatar variant="circular" children={rental.title.charAt(0)} sx={{ bgcolor: "grey.A200", my: 0.25, width: "3rem", height: "3rem", fontWeight: "bold" }} />
																<Stack direction="column" spacing={{ default: 0.5, xl: 0 }} pr={{ default: 0, xl: 1 }} flex="1 1 auto" justifyContent={{ lg: "space-between" }}>
																	<Stack direction={{ default: "column", xl: "row" }} spacing={{ xl: 4 }} flex="1 1 auto" justifyContent={{ lg: "space-between" }}>
																		<Typography fontWeight="medium" color="text.dark">
																			{rental.title}
																		</Typography>
																		<Typography variant="body1">
																			<Box component="span" color="text.secondary">
																				Signature:{" "}
																			</Box>
																			{!R.isNil(rental.startDate) ? (
																				<Box component="span" ml={0.5}>
																					{format(new Date(rental.startDate), dayMonthYear)}
																				</Box>
																			) : (
																				<Box component="span" ml={0.5}>
																					Candidature
																				</Box>
																			)}
																		</Typography>
																	</Stack>
																	<Stack direction={{ default: "column", hd: "row" }} spacing={{ default: 1, lg: 4 }} flex="1 1 auto" justifyContent={{ hd: "space-between" }} alignItems={{ hd: "center" }}>
																		<Stack direction={{ default: "column", lg: "row" }} spacing={0.25} justifyContent={{ lg: "space-between" }}>
																			<Typography variant="body1">
																				<Box component="span" color="text.secondary">
																					Locataire:{" "}
																				</Box>
																				{!R.isNil(tenantState.tenants) && !R.isNil(rental.contactId) ? (
																					tenantState.tenants
																						.filter((contact) => rental.contactId.includes(contact.id))
																						.map((item, index) => (
																							<Typography color="text.main" component={RouterLink} to={`/app/tenants/${item.id}`} key={index} display="inline-flex" alignItems="center" aria-label="go to company" sx={{ textDecoration: "none", marginLeft: 0.25 }}>
																								{item.firstName} {item.lastName}
																							</Typography>
																						))
																				) : (
																					<Typography component="span" color="text.hint">
																						Le champ est vide
																					</Typography>
																				)}
																			</Typography>
																			<Typography sx={{ lineHeight: 1 }} display={{ default: "none", lg: "inherit" }} variant="h6" component="p" px={2} color="text.hint">
																				•
																			</Typography>
																			<Typography variant="body1">
																				<Box component="span" color="text.secondary">
																					Bien:{" "}
																				</Box>
																				{!R.isNil(assetState.assets) && !R.isNil(rental.companyId) ? (
																					assetState.assets
																						?.filter((asset) => rental.companyId.includes(asset.id))
																						.map((item, index) => (
																							<Typography color="text.main" component={RouterLink} to={`/app/tenants/${item.companyId}/${item.id}`} key={index} display="inline-flex" alignItems="center" aria-label="go to company" sx={{ textDecoration: "none", marginLeft: 0.25 }}>
																								{item.title}
																							</Typography>
																						))
																				) : (
																					<Typography component="span" color="text.hint">
																						Le champ est vide
																					</Typography>
																				)}
																			</Typography>
																		</Stack>
																		<Stack direction={{ default: "column", lg: "row" }} flex="1 1 auto" spacing={{ default: 1, lg: 4 }} justifyContent={{ lg: "space-between" }}>
																			{rental.paymentType !== 0 && (
																				<Typography color="text.secondary" variant="body1">
																					Modalité de paiement:{" "}
																					<Box component="span" color="text.main">
																						{paymentTypes[rental.paymentType]}
																					</Box>
																				</Typography>
																			)}
																			<Typography color="text.secondary" variant="body1">
																				Loyer:{" "}
																				<Box component="span" color="text.main">
																					{rental.rentPriceWithoutTax ? rental.rentPriceWithoutTax + "€" : "N/A"}{" "}
																				</Box>
																				{/* Loyer sans Taxes: <Box component="span" color="text.main">{rental.rentPriceWithoutTax ? rental.rentPriceWithoutTax + '€' : 'N/A'} </Box> */}
																			</Typography>
																		</Stack>
																	</Stack>
																</Stack>
															</ItemCard>
														))}
												</Stack>
												<Stack direction="column" spacing={1}>
													<Typography variant="label" color="text.secondary">
														{rentalState.rentals.filter((r) => r.companyId === companyState.company?.id)?.filter((r) => !R.isNil(r.endDate) && new Date(r.endDate) < new Date()).length} Cloturées
													</Typography>
													{rentalState.rentals
														?.filter((r) => r.companyId === companyState.company?.id)
														?.filter((r) => !R.isNil(r.endDate) && new Date(r.endDate) < new Date())
														.map((rental, index) => (
															<ItemCard key={"r" + rental.title} to={`/app/contrats/${rental.companyId}/${rental.id}`} component={RouterLink} elevation={0} backgroundColor={"background.dim"}>
																<Avatar variant="circular" children={rental.title.charAt(0)} sx={{ bgcolor: "grey.A200", my: 0.25, width: "3rem", height: "3rem", fontWeight: "bold" }} />
																<Stack direction="column" spacing={{ default: 0.5, xl: 0 }} pr={{ default: 0, xl: 1 }} flex="1 1 auto" justifyContent={{ lg: "space-between" }}>
																	<Stack direction={{ default: "column", xl: "row" }} spacing={{ xl: 4 }} flex="1 1 auto" justifyContent={{ lg: "space-between" }}>
																		<Typography fontWeight="medium" color="text.dark">
																			{rental.title}
																		</Typography>
																		<Typography variant="body1">
																			<Box component="span" color="text.secondary">
																				Signature:{" "}
																			</Box>
																			{!R.isNil(rental.startDate) ? (
																				<Box component="span" ml={0.5}>
																					{format(new Date(rental.startDate), dayMonthYear)}
																				</Box>
																			) : (
																				<Box component="span" ml={0.5}>
																					Candidature
																				</Box>
																			)}
																		</Typography>
																	</Stack>
																	<Stack direction={{ default: "column", hd: "row" }} spacing={{ default: 1, lg: 4 }} flex="1 1 auto" justifyContent={{ hd: "space-between" }} alignItems={{ hd: "center" }}>
																		<Stack direction={{ default: "column", lg: "row" }} spacing={0.25} justifyContent={{ lg: "space-between" }}>
																			<Typography variant="body1">
																				<Box component="span" color="text.secondary">
																					Locataire:{" "}
																				</Box>
																				{!R.isNil(tenantState.tenants) && !R.isNil(rental.contactId) ? (
																					tenantState.tenants
																						.filter((contact) => rental.contactId.includes(contact.id))
																						.map((item, index) => (
																							<Typography color="text.main" component={RouterLink} to={`/app/tenants/${item.id}`} key={index} display="inline-flex" alignItems="center" aria-label="go to company" sx={{ textDecoration: "none", marginLeft: 0.25 }}>
																								{item.firstName} {item.lastName}
																							</Typography>
																						))
																				) : (
																					<Typography component="span" color="text.hint">
																						Le champ est vide
																					</Typography>
																				)}
																			</Typography>
																			<Typography sx={{ lineHeight: 1 }} display={{ default: "none", lg: "inherit" }} variant="h6" component="p" px={2} color="text.hint">
																				•
																			</Typography>
																			<Typography variant="body1">
																				<Box component="span" color="text.secondary">
																					Bien:{" "}
																				</Box>
																				{!R.isNil(assetState.assets) && !R.isNil(rental.companyId) ? (
																					assetState.assets
																						?.filter((asset) => rental.companyId.includes(asset.id))
																						.map((item, index) => (
																							<Typography color="text.main" component={RouterLink} to={`/app/tenants/${item.companyId}/${item.id}`} key={index} display="inline-flex" alignItems="center" aria-label="go to company" sx={{ textDecoration: "none", marginLeft: 0.25 }}>
																								{item.title}
																							</Typography>
																						))
																				) : (
																					<Typography component="span" color="text.hint">
																						Le champ est vide
																					</Typography>
																				)}
																			</Typography>
																		</Stack>
																		<Stack direction={{ default: "column", lg: "row" }} flex="1 1 auto" spacing={{ default: 1, lg: 4 }} justifyContent={{ lg: "space-between" }}>
																			{rental.paymentType !== 0 && (
																				<Typography color="text.secondary" variant="body1">
																					Modalité de paiement:{" "}
																					<Box component="span" color="text.main">
																						{paymentTypes[rental.paymentType]}
																					</Box>
																				</Typography>
																			)}
																			<Typography color="text.secondary" variant="body1">
																				{/* Loyer sans Taxes: <Box component="span" color="text.main">{rental.rentPriceWithoutTax ? rental.rentPriceWithoutTax + '€' : 'N/A'} </Box> */}
																				Loyer:{" "}
																				<Box component="span" color="text.main">
																					{rental.rentPriceWithoutTax ? rental.rentPriceWithoutTax + "€" : "N/A"}{" "}
																				</Box>
																			</Typography>
																		</Stack>
																	</Stack>
																</Stack>
															</ItemCard>
														))}
												</Stack>
											</Stack>
										)}
										{permissions.includes("Create") ? (
											<Button fullWidth component={RouterLink} to={`/app/new/contrat?companyId=${companyState.company.id}&origin=companyDetail`} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
												Ajouter un Contrat de location
											</Button>
										) : (
											<Button fullWidth variant="contained" onClick={openPermissionModal} color="primary" sx={{ boxShadow: "none" }}>
												Ajouter un Contrat de location
											</Button>
										)}
									</Stack>
								</AccordionDetails>
							</Accordion>
							<Divider />
							<Accordion sx={{ backgroundColor: "transparent", boxShadow: "none", border: "none", p: 0, ":before": { backgroundColor: "inherit" } }} disableGutters expanded={docsExpanded === "panel-docs"} onChange={handleAccordionChange("panel-docs")}>
								<AccordionSummary sx={{ minHeight: "auto", ".MuiAccordionSummary-content": { my: 1 }, p: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
									<Typography variant="h6" sx={{ lineHeight: 1 }}>
										Documents
									</Typography>
								</AccordionSummary>
								<AccordionDetails sx={{ p: 0 }}>
									<Stack maxWidth="100%" direction="column" spacing={1}>
										{!R.isNil(documentState.documents) && (
											<Stack direction="column" spacing={1}>
												{documentState.documents
													.filter((r) => r.companyId === companyState.company.id && r.documentType === "Document")
													.map((document) => (
														<DocumentRow permissions={permissions} openPermissionModal={openPermissionModal} key={document.id} setOpenDeleteDoc={setOpenDeleteDoc} document={document} theme={theme} />
													))}
											</Stack>
										)}
										{permissions.includes("Create") ? (
											<Button fullWidth component={RouterLink} to={`/app/new/document?companyId=${companyState.company.id}&origin=companyDetail`} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
												Ajouter un document
											</Button>
										) : (
											<Button fullWidth variant="contained" onClick={openPermissionModal} color="primary" sx={{ boxShadow: "none" }}>
												Ajouter un document
											</Button>
										)}
									</Stack>
								</AccordionDetails>
							</Accordion>
						</Stack>
					</Container>
				)}
				<ModalDelete
					theme={theme}
					item={companyState.company}
					isLoading={companyState.isLoading.includes(CompanyLoadings.DELETING_COMPANY)}
					isDisabled={companyState.isLoading.includes(CompanyLoadings.DELETING_COMPANY)}
					type="company"
					dispatchDelete={deleteCompanyProp}
					modalClose={() => {
						setOpen(false);
					}}
					open={open}
				/>
				<ModalDelete
					theme={theme}
					item={documentState.documents?.find((d) => d.id === openDeleteDoc)}
					type="document"
					isLoading={isLoading}
					isDisabled={isDisabled}
					dispatchDelete={dispatchDelete(openDeleteDoc)}
					modalClose={() => {
						setOpenDeleteDoc(null);
					}}
					open={!!openDeleteDoc}
				/>
				{/* <ModalPermission user={userState.user} isOpen={isOpen} setIsOpen={setIsOpen} /> */}
			</React.Fragment>
		);
};

export default CompaniesViewDetail;
