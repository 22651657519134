import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export const Loading = () => {
  return (
	<Box sx={{ display: 'flex', margin: 'auto'}}>
		<CircularProgress thickness={8} color="secondary" variant="indeterminate" />
	</Box>
  );
}

export default Loading;