import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import * as R from 'ramda';


// Components
// Components: Global
import { Avatar, Stack, Typography, Box, Divider } from '@mui/material';

// Components: Common
import ItemCard from '../../_common/itemCard';
import { normalize } from '../../../constants/utils/normalize';
import { permissionsType } from '../../../constants/utils/objectProperties';

export const CompaniesMenuList = ({ theme, items, userState, lastCardRef }) => {
    let hasInitials = {};
    const location = useLocation();

    return (
        R.sortBy(R.compose(R.toLower, R.prop('title')))(items).map((item, index) => {
            const firstLetter = normalize(item.title[0]).toUpperCase();
            hasInitials[firstLetter] = hasInitials[firstLetter] || 0;
            hasInitials[firstLetter] += 1;

            return (
                <Stack direction="column" width="100%" key={item.id} spacing={1}>
                    {hasInitials[firstLetter] === 1 &&
                        <Box component="header" position="relative" width="100%" >
                            <Typography variant="body1" fontWeight="bold" component="h3" color="primary" lineHeight="1.2" backgroundColor={theme.palette.background.default} sx={{ position: 'relative', display: 'inline', zIndex: "1", paddingRight: 1 }}>{firstLetter}</Typography>
                            <Divider sx={{ position: 'absolute', left: 0, right: 0, top: '50%' }} />
                        </Box>
                    }
                    <ItemCard to={'/app/companies/' + item.id + location.search} element={index === items.length - 1 ? lastCardRef : undefined} component={RouterLink}>
                        <Avatar variant="circular" sx={{ backgroundColor: R.isNil(item.profileUrl?.url) ? "grey.A300" : "background.paper", width: '3rem', height: '3rem', fontWeight: 'bold', border: ".0625rem solid", borderColor: (R.isNil(item.profileUrl?.url) ? "transparent" : "grey.main") }} imgProps={{ sx: { borderRadius: 'inherit' } }} children={!R.isNil(item.title) ? item.title.charAt(0) : (item.firstName.charAt(0) + item.lastName.charAt(0))} srcSet={!R.isNil(item.profileUrl?.url) ? item.profileUrl?.url : null} />
                        <Stack direction="column" flex="1 1 auto" width="100%">
                            <Typography variant="text">{item.title} {item.lastName}</Typography>
                            <Typography variant="body2" alignItems='center' display="inherit" gap={.5} color="text.secondary" sx={{ wordBreak: "break-all", letterSpacing: -.3 }}>
                                {permissionsType[userState.user.companyAccesses.find(c => c.companyId === item.id)?.accessTypes]}
                            </Typography>
                        </Stack>
                    </ItemCard>
                </Stack>
            );
        })
    );
};

export default CompaniesMenuList;
