import { createSlice } from '@reduxjs/toolkit';
import * as Loadings from '../constants/loadings/subscription';


const initState = {
    isLoading: [],
    subscription: undefined,
    stripeUrl: undefined
};

const SubscriptionReducer = createSlice({
    name: 'subscription',
    initialState: { ...initState },
    reducers: {
        //#region GET SUBSCRIPTION
        getSubscriptionRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_SUBSCRIPTION)) {
                state.isLoading.push(Loadings.FETCHING_SUBSCRIPTION);
            }
        },
        getSubscriptionSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_SUBSCRIPTION);
            state.subscription = payload.data;
        },
        getSubscriptionFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_SUBSCRIPTION);
        },
        //#endregion

        //#region MANAGE SUBSCRIPTION
        manageSubscriptionRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.MANAGE_SUBSCRIPTION)) {
                state.isLoading.push(Loadings.MANAGE_SUBSCRIPTION);
            }
        },
        manageSubscriptionSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.MANAGE_SUBSCRIPTION);
            state.stripeUrl = payload.data;
        },
        manageSubscriptionFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.MANAGE_SUBSCRIPTION);
        },
        //#endregion

    }
});

export const subscriptionActions = SubscriptionReducer.actions;
export default SubscriptionReducer.reducer;