import React from 'react';
import * as R from 'ramda';
import { Link as RouterLink } from 'react-router-dom';


// Components
// Components: Global
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

// Components: Common
import { entitiesNames } from '../../../../constants/utils/objectProperties';

// Components: View
import ResultsListItem from './item';
import ResultsListDoc from './doc';

export const ResultsList = ({ theme, results, ...others }) => {
    const paths = {
        tenants: '/app/tenants',
        subcontractors: '/app/subcontractors',
        documents: '/app/contrats',
        occurrences: '/app/occurrences',
        companies: '/app/companies',
        colaborators: '/app/collaborators',
        assets: '/app/assets',
        assetGroups: '/app/assets/immeubles',
    };

    const resultsToDisplay = Object.entries(results).reduce((acc, [key, datas]) => {
        if (key === "assets") {
            acc.push([key, datas.filter(a => a.isAssetGroup === false)]);
            acc.push(['assetGroups', datas.filter(a => a.isAssetGroup === true)]);
            return acc;
        }

		//Removing duplicate entries in data array
		const ids = datas.map(item => item.id);
		const filtered = datas.filter(({id}, index) => !ids.includes(id, index + 1))
        acc.push([key, filtered]);
        return acc;
    }, []);



    return (
        <Stack component="section" flexDirection="column" width="100%" backgroundColor="background.default" overflow="hidden auto" height="100%" spacing={3} {...others}>
            <Typography component="h4" className="sr-only">Result</Typography>
			{ resultsToDisplay.filter(([key, datas]) => !R.isEmpty(datas)).map(([key, datas]) => {
				return (
					<Box component="article" maxWidth="100%">
						<Container maxWidth="100%" sx={{ height: '100%' }}>
							<Box component="header" position="relative" width="100%" >
								<Typography variant="body1" fontWeight="bold" component="h5" color="primary" lineHeight="1.2" backgroundColor={theme.palette.background.default} sx={{ position: 'relative', display: 'inline', zIndex: "1", paddingRight: 1 }}>
									{entitiesNames[key]}
								</Typography>
								<Divider sx={{ position: 'absolute', left: 0, right: 0, top: '50%' }} />
							</Box>
							<Stack direction="column" spacing={1} mt={1.5} mb={2} flex='1 1 auto' sx={{ maxWidth: '100%' }}>
								{datas.map(data => {
									
									switch (key) {
										case 'tenants':
											return (<ResultsListItem key={data.id} link={`/app/tenants/${data.id}/`} name={data.name} item={data} id={data.id} />);
										case 'subcontractors':
											return (<ResultsListItem key={data.id} link={`/app/subcontractors/${data.id}/`} name={data.name} id={data.id} item={data} />);
										case 'resources':
											return (
												data.resourceType === 'Rental' ? 
													<ResultsListItem key={data.id} link={`/app/contrats/${data.companyId}/${data.id}`} name={data.title} id={data.id} item={data} />
													: 
													<ResultsListDoc key={data.id} name={data.title} id={data.id} item={data} />
											);
										case 'occurrences':
											return (<ResultsListItem key={data.id} link={`/app`} name={'ocurrences'} id={data.id} item={data} />);
										case 'companies':
											return (<ResultsListItem key={data.id} link={`/app/companies/${data.id}`} name={data.title} id={data.id} item={data} />);
										case 'colaborators':
											return (<ResultsListItem key={data.id} link={`/app/collaborators/${data.id}`} name={data.name} id={data.id} item={data} />);
										case 'assets':
											return (<ResultsListItem key={data.id} link={`/app/assets/${data.companyId}/${data.id}`} name={data.title} id={data.id} item={data} />);
										case 'assetGroups':
											return (<ResultsListItem key={data.id} link={`/app/assets/immeubles/${data.companyId}/${data.id}`} name={data.title} id={data.id} item={data} />);
										default:
											return '';
									}
								})}
							</Stack>
							{key !== 'documents' &&
								<Stack component="footer" direction="row" spacing={2} flex='1 1 auto' alignItems='center' justifyContent="end" sx={{ maxWidth: '100%' }}>
									<Link sx={{ textTransform: "capitalize" }} component={RouterLink} to={paths[key]} fontWeight="700" color={theme.palette.text.secondary} underline="hover">Tout Voir</Link>
								</Stack>
							}
						</Container>
					</Box>
				);
			})}
		</Stack>
    );
};


export default ResultsList;