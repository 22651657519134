import React, { useEffect } from "react";
import { Route, Routes, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import JwtDecode from "jwt-decode";
import axios from "axios";
import * as R from "ramda";
import { pdfjs } from 'react-pdf'; // Import pdfjs from react-pdf


import Auth from "../constants/utils/auth";
import * as UserLoadings from "../constants/loadings/user";

import { GetUser, SetPermissionModal } from "../actions/user-api";
import { GetSubscription } from "../actions/subscription-api";

// Components
// Components: Global
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// Components: Common
import Loading from "../_common/loading";
import BottomCard from "./_common/bottomCard";
import NavbarToolbar from "./_common/navbar/toolbar";
import NavbarPage from "./_common/navbar/page";
// import Navbar from './_common/navbar';

// Components: Views
import Menu from "./menu";
import Search from "./search";
import Dashboard from "./dashboard";
import Assets from "./assets";
import Tenants from "./tenants";
import Companies from "./companies";
import Collaborators from "./collaborators";
import Banks from "./banks";
import Rentals from "./contrats";     
import Subcontractors from "./subcontractors";
import Passwords from "./passwords";
import Documents from "./documents";

import New from "./new";
import Edit from "./edit";
import { ModalPermission } from "./_common/modalPermission";

import { ASSET_MAX_TIER_ERROR } from "./../constants/utils/magicVariables";
import { toastConf } from "./../constants/utils/toastConfig";
import { usePermissionModal } from "./../hooks/usePermissionModal";
import { useTheme } from '@mui/material/styles';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const App = () => {
	const theme = useTheme()
	const dispatch = useDispatch();
	const routerLocation = useLocation();
	const navigate = useNavigate();
	const userState = useSelector((state) => state.user);
	const companyState = useSelector((state) => state.company);
	const assetState = useSelector((state) => state.asset);
	const assetGroupState = useSelector((state) => state.assetGroup);
	const tenantState = useSelector((state) => state.tenant);
	const subcontractorState = useSelector((state) => state.subcontractor);
	const collaboratorState = useSelector((state) => state.collaborator);
	const documentState = useSelector((state) => state.document);
	const rentalState = useSelector((state) => state.rental);
	const accountState = useSelector((state) => state.account);
	const paymentState = useSelector((state) => state.payment);
	const searchState = useSelector((state) => state.search);
	const fileState = useSelector((state) => state.file);
	const subscriptionState = useSelector((state) => state.subscription);

	const { openPermissionModal } = usePermissionModal();

	axios.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			if (error.response.status === 402) {
				navigate("/app/menu?abonnement=true");
			} else if (error.response.status === 403) {
				return openPermissionModal();
			} else if (error.response.status === 422) {
				toast.error(ASSET_MAX_TIER_ERROR, toastConf);
			} else {
				toast.error("Échec de l'opération, réessayez !", toastConf);
			}
		}
	);

	axios.interceptors.request.use(
		(request) => {
			if(Auth.getAccessToken()) {
               request.headers[`Authorization`] = `Bearer ${Auth.getAccessToken()}`;
			}
			return request;
		},
		
	);

	useEffect(() => {
		const accessToken = Auth.getAccessToken();
		if (accessToken) {
			try {
				const { exp, nameid } = JwtDecode(accessToken);
				const expirationDateTime = new Date(exp * 1000);
				const currentDateTime = new Date();
				if (expirationDateTime < currentDateTime) {
					axios.defaults.headers.common["Authorization"] = "";
					sessionStorage.removeItem("access_token");
					window.location = "/auth/login";
				}
				axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
				dispatch(GetUser(nameid));
			} catch (error) {
				window.location = "/auth/login";
			}
		}
		
		// eslint-disable-next-line
	}, [Auth.getAccessToken()]);

	useEffect(() => {
		const accessToken = Auth.getAccessToken();
		if (!accessToken) {
			window.location = "/auth/signin";
		}
		// eslint-disable-next-line
	}, [Auth.getAccessToken()]);

	useEffect(() => {
		if (!R.isNil(userState?.user)) {
			dispatch(GetSubscription());
		}
		// eslint-disable-next-line
	}, [userState?.user]);

	if (userState.isLoading.includes(UserLoadings.FETCHING_USER)) {
		return <Loading />;
	}

	if (R.isNil(userState.user)) {
		return null;
	}

	return (
		<React.Fragment>
			<ToastContainer theme="colored" />
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Box alignItems="center" display="flex" justifyContent="space-between" flexDirection="column" flex="1 1 auto" height="inherit">
					<NavbarToolbar theme={theme} />
					<Stack component="article" direction="column" flex="1 1 auto" alignItems="center" width="100%" height={{ default: "inherit" }} mt={{ default: -1 }} overflow={{ default: "hidden auto" }}>
						<Typography component="h1" className="sr-only">
							Page
						</Typography>
						<NavbarPage
						theme={theme}
						user={userState.user}
						routerLocation={routerLocation}
						location={routerLocation.pathname.includes("new") && "Ajouter Nouveau"} 
					/>
						<Routes>
							<Route
								//index
								path="/accueil"
								element={
									<Dashboard 
									/>
								}
							/>
							<Route
								path="menu/*"
								element={
									<Menu
										theme={theme}
										userState={userState}
										companyState={companyState}
										tenantState={tenantState}
										assetState={assetState}
										assetGroupState={assetGroupState}
										subcontractorState={subcontractorState}
										collaboratorState={collaboratorState}
										documentState={documentState}
										rentalState={rentalState}
										accountState={accountState}
										paymentState={paymentState}
										fileState={fileState}
										subscriptionState={subscriptionState}
									/>
								}
							/>
							<Route
								path="search/*"
								element={
									<Search
										theme={theme}
										searchState={searchState}
										userState={userState}
										companyState={companyState}
										tenantState={tenantState}
										assetState={assetState}
										assetGroupState={assetGroupState}
										subcontractorState={subcontractorState}
										collaboratorState={collaboratorState}
										documentState={documentState}
										rentalState={rentalState}
										accountState={accountState}
										paymentState={paymentState}
									/>
								}
							/>
							<Route
								path="assets/*"
								element={
									<Assets
										companyState={companyState}
										tenantState={tenantState}
										assetState={assetState}
										assetGroupState={assetGroupState}
										subcontractorState={subcontractorState}
										collaboratorState={collaboratorState}
										documentState={documentState}
										rentalState={rentalState}
										accountState={accountState}
										paymentState={paymentState}
									/>
								}
							/>
							<Route
								path="tenants/*"
								element={
									<Tenants
										theme={theme}
										userState={userState}
										companyState={companyState}
										tenantState={tenantState}
										assetState={assetState}
										assetGroupState={assetGroupState}
										subcontractorState={subcontractorState}
										collaboratorState={collaboratorState}
										documentState={documentState}
										rentalState={rentalState}
										accountState={accountState}
										paymentState={paymentState}
									/>
								}
							/>
							<Route
								path="companies/*"
								element={
									<Companies
										theme={theme}
										userState={userState}
										companyState={companyState}
										tenantState={tenantState}
										assetState={assetState}
										assetGroupState={assetGroupState}
										subcontractorState={subcontractorState}
										collaboratorState={collaboratorState}
										documentState={documentState}
										rentalState={rentalState}
										accountState={accountState}
										paymentState={paymentState}
									/>
								}
							/>
							<Route
								path="collaborators/*"
								element={
									<Collaborators
										theme={theme}
										userState={userState}
										companyState={companyState}
										tenantState={tenantState}
										assetState={assetState}
										assetGroupState={assetGroupState}
										subcontractorState={subcontractorState}
										collaboratorState={collaboratorState}
										documentState={documentState}
										rentalState={rentalState}
										accountState={accountState}
										paymentState={paymentState}
									/>
								}
							/>
							<Route
								path="contrats/*"
								element={
									<Rentals
										theme={theme}
										userState={userState}
										companyState={companyState}
										tenantState={tenantState}
										assetState={assetState}
										assetGroupState={assetGroupState}
										subcontractorState={subcontractorState}
										collaboratorState={collaboratorState}
										documentState={documentState}
										rentalState={rentalState}
										accountState={accountState}
										paymentState={paymentState}
									/>
								}
							/>
							<Route
								path="documents/*"
								element={
									<Documents
										theme={theme}
										userState={userState}
										companyState={companyState}
										tenantState={tenantState}
										assetState={assetState}
										assetGroupState={assetGroupState}
										subcontractorState={subcontractorState}
										collaboratorState={collaboratorState}
										documentState={documentState}
										rentalState={rentalState}
										accountState={accountState}
										paymentState={paymentState}
										fileState={fileState}
										subscriptionState={subscriptionState}
									/>
								}
							/>
							<Route
								path="banks/*"
								element={
									<Banks
										theme={theme}
										userState={userState}
										companyState={companyState}
										tenantState={tenantState}
										assetState={assetState}
										assetGroupState={assetGroupState}
										subcontractorState={subcontractorState}
										collaboratorState={collaboratorState}
										documentState={documentState}
										rentalState={rentalState}
										accountState={accountState}
										paymentState={paymentState}
										fileState={fileState}
									/>
								}
							/>
							<Route
								path="subcontractors/*"
								element={
									<Subcontractors
										theme={theme}
										userState={userState}
										companyState={companyState}
										tenantState={tenantState}
										assetState={assetState}
										assetGroupState={assetGroupState}
										subcontractorState={subcontractorState}
										collaboratorState={collaboratorState}
										documentState={documentState}
										rentalState={rentalState}
										accountState={accountState}
										paymentState={paymentState}
									/>
								}
							/>
							<Route
								path="passwords/*"
								element={
									<Passwords
										theme={theme}
										userState={userState}
										companyState={companyState}
										tenantState={tenantState}
										assetState={assetState}
										assetGroupState={assetGroupState}
										subcontractorState={subcontractorState}
										collaboratorState={collaboratorState}
										documentState={documentState}
										rentalState={rentalState}
										accountState={accountState}
										paymentState={paymentState}
									/>
								}
							/>
							<Route
								path="new/*"
								element={
									<New
										theme={theme}
										userState={userState}
										companyState={companyState}
										tenantState={tenantState}
										assetState={assetState}
										assetGroupState={assetGroupState}
										subcontractorState={subcontractorState}
										collaboratorState={collaboratorState}
										documentState={documentState}
										rentalState={rentalState}
										accountState={accountState}
										paymentState={paymentState}
									/>
								}
							/>
							<Route
								path="edit/*"
								element={
									<Edit
										theme={theme}
										userState={userState}
										companyState={companyState}
										tenantState={tenantState}
										assetState={assetState}
										assetGroupState={assetGroupState}
										subcontractorState={subcontractorState}
										collaboratorState={collaboratorState}
										documentState={documentState}
										rentalState={rentalState}
										accountState={accountState}
										paymentState={paymentState}
									/>
								}
							/>
							<Route path="*" element={<Navigate to="/404" />} />
							<Route path="/" element={<Navigate replace to="/app/accueil" />} />
						</Routes>
					</Stack>
					<ModalPermission
						userState={userState}
						isOpen={userState.isOpen}
						setIsOpen={(v) => {
							dispatch(SetPermissionModal(v));
						}}
					/>
					<BottomCard theme={theme} />
				</Box>
			</LocalizationProvider>
		</React.Fragment>
	);
};

export default App;
