import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Components
// Components: Global
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';


export const DocumentsMenuFooter = ({theme}) => {
    return (
        <Container maxWidth="100%" sx={{mt: "auto"}}>
            <Button component={RouterLink} to="/app/new/document" variant="contained" color="primary" sx={{width: "100%"}}>Ajouter un contrat <Box component="span" ml={.5} display={{default: 'none', hd: 'inherit'}}>de location</Box></Button>
        </Container>
    );
}

export default DocumentsMenuFooter;
