import React, { useState } from 'react';
import * as R from 'ramda';

// Components
// Components: Global
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArticleIcon from '@mui/icons-material/Article';
import ItemCard from './itemCard';
import { downloadFile } from '../../constants/utils/download';
import { Modal } from '@mui/base';
import ModalReadPdf from './modalReadPdf';
import { set } from 'lodash';

const FileRow = ({ file, permissions, openPermissionModal, setOpenDeleteFile, fileType, companyId }) => {
    const  [open, setOpen] = useState(false);
    const [optionsMenu, setOptionsMenu] = useState(null);
    const optionsMenuClick = (e) => { e.preventDefault(); setOptionsMenu(e.currentTarget); };
    const showMe = Boolean(optionsMenu);

    const modalClose = () => {
        setOpen(false);
    }
    const download = async () => {

        downloadFile(file)   
    };


    return (
        <>
        <ItemCard   key={file.id} padding={1} borderRadius={2} alignItems={{ default: "start", md: "center" }}>
            <Avatar onClick={()=> setOpen(true)} variant="rounded" children={file.fileName[0]} sx={{ bgcolor: "background.default", fontWeight: 'bold' }}>
                <ArticleIcon sx={{ color:"secondary.main" }} />
            </Avatar>
            <Stack 
              onClick={()=> setOpen(true)}
              alignItems={{ xl: "center" }} 
              direction={{ default: "column", xl: "row" }} 
              spacing={{ xl: 2 }} flex='1 1 auto' justifyContent="space-between">
                <Stack direction="row" spacing={2} flex="1 1 auto" maxWidth={{ default: "100%", xl: "30em" }}>
                    <Typography fontWeight="medium" color="text.dark" sx={{cursor:"pointer"}}>
                        {file.fileName || 'N/A'}
                    </Typography>
                </Stack>
            </Stack>
            <Box position={{ default: "absolute", xs: "relative" }} top={{ default: -8, xs: "unset" }} right={{ default: 8, xs: "unset" }}>
                <IconButton sx={{ justifyContent: "space-between", maxWidth: { xs: "fit-content" } }} variant="contained" color="inherit" id="optionButtom" aria-controls={showMe ? 'optionMenu' : undefined} aria-haspopup="true" aria-expanded={showMe ? 'true' : undefined} onClick={optionsMenuClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu id="optionMenu" anchorEl={optionsMenu} open={showMe} onClose={() => setOptionsMenu(null)} MenuListProps={{ 'aria-labelledby': 'optionButtom' }}>
                    <MenuItem disabled={R.isEmpty(file)} variant="text" color="inherit" size="inherit" key="Telecharger" onClick={download}>
                        Télécharger
                    </MenuItem>
                    <MenuItem disabled={R.isEmpty(file)} variant="text" color="inherit" size="inherit" key="Telecharger" onClick={() => setOpen(true)}>
                        Lire
                    </MenuItem>
                    <MenuItem onClick={() => { permissions.includes("Delete") ? setOpenDeleteFile(file, fileType, companyId) : openPermissionModal(); }} variant="text" color="inherit" size="inherit" key="effacer">
                        Effacer
                    </MenuItem>
                </Menu>
            </Box>
        </ItemCard>
        {open && <ModalReadPdf modalClose={modalClose} open={open} file={file} /> }
        </>
    );
};

export default FileRow;