import React from 'react';
import * as R from 'ramda';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from "@mui/material/TextField";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Paper from '@mui/material/Paper';

// Components: Common
import { initialCompanyForm } from '../../../../constants/utils/formInitialData';
import { onChangeValidation } from '../../../../constants/utils/validators';
import { PARTICULAR } from '../../../../constants/utils/magicVariables';

import FormProfilePhoto from '../_common/profilePhoto';
import FormGlobalComplete from '../_common/globalComplete';

// Components: Views

export const FormSubcontractor = ({ theme, formData, setFormData, category, companyUseAutocomplete, setCategory, currentPhoto, profilePhoto, setProfilePhoto, ...others }) => {

    const handleOnChange = (event) => { setFormData(onChangeValidation(event.target, formData)); };

    const changeSubcontractorType = (event, action) => {
        let formDataTmp = R.clone(formData);
        formDataTmp.social.isRequired = action !== PARTICULAR;
        setCategory(action);
        setFormData(formDataTmp);
    };


    return (
        <Stack spacing={2} width="100%" {...others}>
            <Container maxWidth="100%" sx={{ display: "inherit", flexDirection: "inherit", gap: 2 }}>
                <Stack direction="column" spacing={2}>
                    <Paper elevation={0} sx={{ marginX: "auto", width: "auto", borderRadius: 5 }}>
                        <ToggleButtonGroup exclusive aria-label="tenant type"
                            onChange={changeSubcontractorType}
                            value={category}>
                            <ToggleButton value={1} aria-label="Particulier">
                                <Typography variant="button">Particulier</Typography>
                            </ToggleButton>
                            <ToggleButton value={2} aria-label="entreprise">
                                <Typography variant="button">Entreprise</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Paper>
                    {category === PARTICULAR ?
                        <Stack direction="column" spacing={2}>
                            <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                                <FormProfilePhoto theme={theme} currentPhoto={currentPhoto} profilePhoto={profilePhoto} setProfilePhoto={setProfilePhoto} />
                                <Stack direction="row" flex="1 1 auto" spacing={1}>
                                    <TextField
                                        label='Prénom'
                                        id="Prenom"
                                        type="text"
                                        fullWidth
                                        variant="filled"
                                        name="firstName"
                                        error={formData.firstName.error && !formData.firstName.isValid}
                                        helperText={formData.firstName.error}
                                        value={formData.firstName.value}
                                        onChange={(event) => { handleOnChange(event); }}
                                    />
                                    <TextField
                                        label='Nom'
                                        id="Nom"
                                        type="text"
                                        fullWidth
                                        variant="filled"
                                        name="lastName"
                                        error={formData.lastName.error && !formData.lastName.isValid}
                                        helperText={formData.lastName.error}
                                        value={formData.lastName.value}
                                        onChange={(event) => { handleOnChange(event); }}
                                    />
                                </Stack>
                            </Stack>
                            <TextField
                                label='Profession'
                                id="Profession"
                                type="text"
                                fullWidth
                                variant="filled"
                                name="profession"
                                error={formData.profession.error && !formData.profession.isValid}
                                helperText={formData.profession.error}
                                value={formData.profession.value}
                                onChange={(event) => { handleOnChange(event); }}
                            />
                            <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                                <TextField
                                    label='Téléphone'
                                    id="Téléphone"
                                    type="text"
                                    fullWidth
                                    variant="filled"
                                    name="phone"
                                    error={formData.phone.error && !formData.phone.isValid}
                                    helperText={formData.phone.error}
                                    value={formData.phone.value}
                                    onChange={(event) => { handleOnChange(event); }}
                                />
                                <TextField
                                    label='Email'
                                    id="Email"
                                    type="text"
                                    fullWidth
                                    variant="filled"
                                    name="email"
                                    error={formData.email.error && !formData.email.isValid}
                                    helperText={formData.email.error}
                                    value={formData.email.value}
                                    onChange={(event) => { handleOnChange(event); }}
                                />
                            </Stack>
                        </Stack>
                        :
                        <Stack direction="column" spacing={2}>
                            <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                                <FormProfilePhoto theme={theme} currentPhoto={currentPhoto} profilePhoto={profilePhoto} setProfilePhoto={setProfilePhoto} />
                                <TextField
                                    label='Raison sociale *'
                                    id="RaisonSocial"
                                    type="text"
                                    fullWidth
                                    variant="filled"
                                    name="social"
                                    error={formData.social.error && !formData.social.isValid}
                                    helperText={formData.social.error}
                                    value={formData.social.value}
                                    onChange={(event) => { handleOnChange(event); }}
                                />
                            </Stack>
                            <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                                <TextField
                                    label='Prénom'
                                    id="Prenom"
                                    type="text"
                                    fullWidth
                                    variant="filled"
                                    name="firstName"
                                    error={formData.firstName.error && !formData.firstName.isValid}
                                    helperText={formData.firstName.error}
                                    value={formData.firstName.value}
                                    onChange={(event) => { handleOnChange(event); }}
                                />
                                <TextField
                                    label='Nom'
                                    id="Nom"
                                    type="text"
                                    fullWidth
                                    variant="filled"
                                    name="lastName"
                                    error={formData.lastName.error && !formData.lastName.isValid}
                                    helperText={formData.lastName.error}
                                    value={formData.lastName.value}
                                    onChange={(event) => { handleOnChange(event); }}
                                />
                            </Stack>
                            <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                                <TextField
                                    label='Téléphone'
                                    id="Téléphone"
                                    type="text"
                                    fullWidth
                                    variant="filled"
                                    name="phone"
                                    error={formData.phone.error && !formData.phone.isValid}
                                    helperText={formData.phone.error}
                                    value={formData.phone.value}
                                    onChange={(event) => { handleOnChange(event); }}
                                />
                                <TextField
                                    label='Email'
                                    id="Email"
                                    type="text"
                                    fullWidth
                                    variant="filled"
                                    name="email"
                                    error={formData.email.error && !formData.email.isValid}
                                    helperText={formData.email.error}
                                    value={formData.email.value}
                                    onChange={(event) => { handleOnChange(event); }}
                                />
                            </Stack>
                        </Stack>
                    }
                </Stack>
				<Stack direction="column" spacing={1} mt={2}>
					<Typography variant="label" color="text.secondary">Associé à</Typography>
					<FormGlobalComplete
						options={companyUseAutocomplete.options}
						optionsFormData={companyUseAutocomplete.dropdownFormData}
						setOptionsFormData={companyUseAutocomplete.setDropdownFormData}
						handleSetOpen={companyUseAutocomplete.handleSetOpen}
						handleSetOptionsFormData={companyUseAutocomplete.handleSetOptionsFormData}
						multi={true}
						name="company"
						label="Société"
						initialFormData={initialCompanyForm}
					/>
                </Stack>
            </Container>

        </Stack>
    );
};

export default FormSubcontractor;
