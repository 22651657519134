import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

// Components: Common
import * as LoadingsCompany from '../../constants/loadings/company';
import * as LoadingsTenant from '../../constants/loadings/tenant';
import * as LoadingsSubcontractor from '../../constants/loadings/subcontractor';
import * as LoadingsAsset from '../../constants/loadings/asset';
import { initialAssetForm, initialCompanyForm, initialPaymentFormData, initialSubcontractorForm, initialTenantForm } from '../../constants/utils/formInitialData';
import { GetCompanies } from '../../actions/company-api';
import { GetAssets } from '../../actions/asset-api';
import { GetTenants } from '../../actions/tenant-api';
import { GetSubcontractors } from '../../actions/subcontractor-api';
import Loading from '../../_common/loading';
import MainPayment from '../_common/forms/layout/payment';
import { GetPayment, GetPayments } from '../../actions/payment-api';
import { useParams } from 'react-router';



export const EditPayment = ({ theme, companyState, assetState, tenantState, paymentState, subcontractorState }) => {
    const dispatch = useDispatch();
    const { companyId, id } = useParams();

    useEffect(() => {
        dispatch(GetPayment(companyId, id));

        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        if (R.isNil(assetState.assets)) {
            dispatch(GetAssets());
        }
        if (R.isNil(tenantState.tenants)) {
            dispatch(GetTenants());
        }
        if (R.isNil(subcontractorState.subcontractors)) {
            dispatch(GetSubcontractors());
        }
        if (R.isNil(paymentState.payments)) {
            dispatch(GetPayments());
        }
        // eslint-disable-next-line 
    }, []);


    if (tenantState.isLoading.includes(LoadingsTenant.FETCHING_TENANTS) ||
        assetState.isLoading.includes(LoadingsAsset.FETCHING_ASSETS) ||
        subcontractorState.isLoading.includes(LoadingsSubcontractor.FETCHING_SUBCONTRACTORS) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)) {
        return <Loading theme={theme} />;
    }

    if (R.isNil(paymentState.payment) || R.isEmpty(paymentState.payment) ||
        R.isNil(companyState.companies) ||
        R.isNil(subcontractorState.subcontractors) ||
        R.isNil(assetState.assets)) {
        return '';
    }

    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let assets = !R.isNil(assetState.assets) ? assetState.assets : [];
    let tenants = !R.isNil(tenantState.tenants) ? tenantState.tenants.map(t => ({ ...t, title: t.firstName + " " + t.lastName })) : [];
    let subcontractors = !R.isNil(subcontractorState.subcontractors) ? subcontractorState.subcontractors.map(s => ({ ...s, title: s.firstName + " " + s.lastName })) : [];

    const tempInitialPaymentFormData = R.clone(initialPaymentFormData);
    tempInitialPaymentFormData.title.value = !R.isNil(paymentState.payment.title) && !R.isEmpty(paymentState.payment.title) ? paymentState.payment.title : "";
    tempInitialPaymentFormData.date.value = !R.isNil(paymentState.payment.date) && !R.isEmpty(paymentState.payment.date) ? paymentState.payment.date : "";
    tempInitialPaymentFormData.paymentType.value = !R.isNil(paymentState.payment.paymentType) && !R.isEmpty(paymentState.payment.paymentType) ? paymentState.payment.paymentType : "";
    tempInitialPaymentFormData.dueDate.value = !R.isNil(paymentState.payment.paymentDeadline) && !R.isEmpty(paymentState.payment.paymentDeadline) ? paymentState.payment.paymentDeadline : "";
    tempInitialPaymentFormData.notes.value = !R.isNil(paymentState.payment.description) && !R.isEmpty(paymentState.payment.description) ? paymentState.payment.description : "";
    tempInitialPaymentFormData.transactionNumber.value = !R.isNil(paymentState.payment.transactionNumber) && !R.isEmpty(paymentState.payment.transactionNumber) ? paymentState.payment.transactionNumber : "";
    tempInitialPaymentFormData.totalTTC.value = !R.isNil(paymentState.payment.amount) && !R.isEmpty(paymentState.payment.amount) ? paymentState.payment.amount : "";
    tempInitialPaymentFormData.totalHT.value = !R.isNil(paymentState.payment.amountWithoutTax) && !R.isEmpty(paymentState.payment.amountWithoutTax) ? isNaN(parseInt(paymentState.payment.amountWithoutTax)) ? "" : Math.abs(parseInt(paymentState.payment.amountWithoutTax)) : "";
    tempInitialPaymentFormData.paymentCategory.value = !R.isNil(paymentState.payment.paymentCategory) && !R.isEmpty(paymentState.payment.paymentCategory) ? paymentState.payment.paymentCategory : "";

    const tempInitialCompanyForm = [R.clone(initialCompanyForm)];
    const tempInitialAssetForm = [R.clone(initialAssetForm)];
    const tempInitialTenantForm = [R.clone(initialTenantForm)];
    const tempInitialSubcontractorForm = [R.clone(initialSubcontractorForm)];
    const companyTitle = companies.find(c => c.id === paymentState.payment.companyId)?.title;
    const assetTitle = assets.find(c => c.id === paymentState.payment.assetId)?.title;
    const tenantName = tenants.find(c => c.id === paymentState.payment.contactId);
    const subcontractorName = subcontractors.find(c => c.id === paymentState.payment.subcontractorId);
    tempInitialCompanyForm[0].value = paymentState.payment.companyId;
    tempInitialCompanyForm[0].label = !R.isNil(companyTitle) ? companyTitle : "";
    tempInitialAssetForm[0].value = !R.isNil(paymentState.payment.assetId) ? paymentState.payment.assetId : "";
    tempInitialAssetForm[0].label = !R.isNil(assetTitle) ? assetTitle : "";
    tempInitialTenantForm[0].value = !R.isNil(paymentState.payment.contactId) ? paymentState.payment.contactId : "";
    tempInitialTenantForm[0].label = !R.isNil(tenantName) ? `${tenantName.firstName} ${tenantName.lastName}` : "";
    tempInitialSubcontractorForm[0].value = !R.isNil(paymentState.payment.subcontractorId) ? paymentState.payment.subcontractorId : "";
    tempInitialSubcontractorForm[0].label = !R.isNil(subcontractorName) ? `${subcontractorName.firstName} ${subcontractorName.lastName}` : "";


    return (
        <MainPayment
            theme={theme}
            paymentState={paymentState}
            documents={paymentState.payment.files}
            companies={companies}
            assets={assets}
            tenants={tenants}
            subcontractors={subcontractors}
            initialPaymentFormData={tempInitialPaymentFormData}
            initialCompanyForm={tempInitialCompanyForm}
            initialTenantForm={tempInitialTenantForm}
            initialSubcontractorForm={tempInitialSubcontractorForm}
            initialAssetForm={tempInitialAssetForm}
            resetPaymentFormData={initialPaymentFormData}
            resetCompanyForm={initialCompanyForm}
            resetTenantForm={initialTenantForm}
            resetSubcontractorForm={initialSubcontractorForm}
            resetAssetForm={initialAssetForm}
            paymentTypeStatus={paymentState.payment.amount > 0}
            companyId={companyId}
            id={id}
        />
    );
};

export default EditPayment;