import { Routes, Route, Navigate } from "react-router-dom"
import Auth from "../auth"
import NoMatch from '../_common/noMatch';
// Components: Views
import { App } from '../app';
import Authentication from '../constants/utils/auth';


export const PrivateRoutes = () => {
    return (
        <Routes>
           <Route path="app/*" element={
                            <App />
                        } />
                        {["auth/*", "recover/*"].map(path =>
                            <Route key={path} path={path} element={
                                <Auth />
                            } />
                        )}
                        <Route path="404" element={<NoMatch />} />
                        <Route path="500" element={<NoMatch error="500" />} />
                        <Route path="*" element={<Navigate to="/404" />} />
                        <Route path="/" element={
                            Authentication.getAccessToken() ?
                                <Navigate replace to="/app" />
                                :
                                <Navigate replace to="/auth/login" />
                        } />             
       </Routes>
    )
}