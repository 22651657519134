import { useParams } from 'react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

// Components: Common
import * as LoadingsCompany from '../../constants/loadings/company';
import * as LoadingsSubcontractor from '../../constants/loadings/subcontractor';
import { initialSubcontractorFormData, initialCompanyForm } from '../../constants/utils/formInitialData';
import { GetCompanies } from '../../actions/company-api';
import { GetSubcontractor, GetSubcontractors } from '../../actions/subcontractor-api';
import Loading from '../../_common/loading';
import MainSubcontractor from '../_common/forms/layout/subcontractor';



export const EditSubcontractor = ({ theme, subcontractorState, companyState }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        dispatch(GetSubcontractor(id));

        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        if (R.isNil(subcontractorState.subcontractors)) {
            dispatch(GetSubcontractors());
        }
        // eslint-disable-next-line 
    }, []);

    if (
        subcontractorState.isLoading.includes(LoadingsSubcontractor.FETCHING_SUBCONTRACTORS) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)) {
        return <Loading theme={theme} />;
    }

    if (R.isNil(subcontractorState.subcontractor) || R.isEmpty(subcontractorState.subcontractor) || R.isNil(companyState.companies)) {
        return '';
    }

    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let subcontractors = !R.isNil(subcontractorState.subcontractors) ? subcontractorState.subcontractors.map(s => ({ ...s, title: s.firstName + " " + s.lastName })) : [];

    const tempInitialSubcontractorFormData = R.clone(initialSubcontractorFormData);
    tempInitialSubcontractorFormData.firstName.value = !R.isNil(subcontractorState.subcontractor.firstName) && !R.isEmpty(subcontractorState.subcontractor.firstName) ? subcontractorState.subcontractor.firstName : "";
    tempInitialSubcontractorFormData.lastName.value = !R.isNil(subcontractorState.subcontractor.lastName) && !R.isEmpty(subcontractorState.subcontractor.lastName) ? subcontractorState.subcontractor.lastName : "";
    tempInitialSubcontractorFormData.profession.value = !R.isNil(subcontractorState.subcontractor.occupation) && !R.isEmpty(subcontractorState.subcontractor.occupation) ? subcontractorState.subcontractor.occupation : "";
    tempInitialSubcontractorFormData.social.value = !R.isNil(subcontractorState.subcontractor.companyName) && !R.isEmpty(subcontractorState.subcontractor.companyName) ? subcontractorState.subcontractor.companyName : "";
    tempInitialSubcontractorFormData.email.value = !R.isNil(subcontractorState.subcontractor.email) && !R.isEmpty(subcontractorState.subcontractor.email) ? subcontractorState.subcontractor.email : "";
    tempInitialSubcontractorFormData.phone.value = !R.isNil(subcontractorState.subcontractor.phoneNumber) && !R.isEmpty(subcontractorState.subcontractor.phoneNumber) ? subcontractorState.subcontractor.phoneNumber : "";

    let tempInitialCompanyForm = [R.clone(initialCompanyForm)];
    tempInitialCompanyForm = subcontractorState.subcontractor.companyIds.map(id => ({ ...initialCompanyForm, value: id, label: companies.find(c => c.id === id)?.title }));

    return (
        <MainSubcontractor
            theme={theme}
            subcontractorState={subcontractorState}
            photo={subcontractorState.subcontractor.profileUrl?.url}
            subcontractors={subcontractors}
            companies={companies}
            initialSubcontractorFormData={tempInitialSubcontractorFormData}
            initialCompanyForm={tempInitialCompanyForm}
            resetSubcontractorFormData={initialSubcontractorFormData}
            resetCompanyForm={initialCompanyForm}
            contactCategory={subcontractorState.subcontractor.contactCategory}
            id={id}
        />
    );
};

export default EditSubcontractor;