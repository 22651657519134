
import * as R from 'ramda';
import { compare } from './normalize';
export const inputTypes = {
	required: { errorMessage: "Ce champ est obligatoire" },
	name: { type: "name", errorMessage: "Ce champ est obligatoire" },
	postal: { type: "postal", errorMessage: "Ce champ est obligatoire" },
	email: { type: "email", errorMessage: "Email invalide" },
	phone: { type: "phone", errorMessage: "Numéro de téléphone invalide" },
	id: { type: "id", errorMessage: "Identification invalide" },
	username: { type: "username", errorMessage: "Nom d'utilisateur invalide" },
	number: { type: "number", errorMessage: "Doit être un numéro" },
	transactionNumber: { type: "transactionNumber", errorMessage: "Doit être un numéro avec 6 chiffres" },
	url: { type: "url", errorMessage: "Url invalide" },
	password: { type: "password", errorMessage: "Ce champ est obligatoire" },
	passwordConfirm: { type: "passwordConfirm", errorMessage: "La confirmation du mot de passe ne correspond pas au mot de passe" },
	minLength: { type: "minLength", errorMessage: "Le mot de passe doit comporter au moins 6 caractères." },
};
export const numberRegex = /^(0|[1-9][0-9]*)$/;
export const minLengthRegex = /^.{6,}$/;

export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
// export const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
// export const urlRegex = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;
export const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const checkEmptyValidator = (text) => {
    if (R.isNil(text)) {
        return { isValid: false, error: inputTypes.name.errorMessage };
    }
    if (typeof text === 'number') {
        return { isValid: true, error: null };
    }
    if (R.isEmpty(text.trim())) {
        return { isValid: false, error: inputTypes.name.errorMessage };
    }
    return { isValid: true, error: null };
};

export const confirmPasswordValidator = (confirmPassword, password) => {
    if (confirmPassword.trim() !== password.trim()) {
        return { isValid: false, error: inputTypes.passwordConfirm.errorMessage };
    }
    return { isValid: true, error: null };
};

export const emailValidator = (email) => {
    if (!emailRegex.test(email)) {
        return { isValid: false, error: inputTypes.email.errorMessage };
    }
    return { isValid: true, error: null };
};

export const numberValidator = (number) => {
    if (isNaN(parseInt(number))) {
        return { isValid: false, error: inputTypes.number.errorMessage };
    }
    return { isValid: true, error: null };
};

export const urlValidator = (url) => {
    if (!urlRegex.test(url)) {
        return { isValid: false, error: inputTypes.url.errorMessage };
    }
    return { isValid: true, error: null };
};

export const phoneValidator = (phone) => {
    const regex = /^0[1-6]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/;
    if (!regex.test(phone)) {
        return { isValid: false, error: inputTypes.phone.errorMessage };
    }
    return { isValid: true, error: null };
};

export const passwordLengthValidator = (value) => {
    
    if (!minLengthRegex.test(value)) {
        return { isValid: false, error: inputTypes.minLength.errorMessage };
    }
    return { isValid: true, error: null };
};


export const validateField = (name, type, formData) => {
    const checkField = checkEmptyValidator(formData[name].value);
    if (!checkField.isValid) return checkField;

    switch (type) {
        case inputTypes.email.type:
            return emailValidator(formData[name].value);
        case inputTypes.passwordConfirm.type:
            return confirmPasswordValidator(formData[name].value, formData.password.value);
        case inputTypes.number.type:
            return numberValidator(formData[name].value);
        case inputTypes.phone.type:
            return phoneValidator(formData[name].value);
        case inputTypes.url.type:
            return urlValidator(formData[name].value);
        case inputTypes.minLength.type:
            return passwordLengthValidator(formData[name].value);
        default:
            return checkField;
    }
};


export const onChangeValidation = (target, form) => {
    const { name, value } = target;
    let newForm = R.clone(form);
    newForm[name].value = value;
    if (!newForm[name].isRequired && typeof newForm[name].value === 'string' && !R.isEmpty(newForm[name].value)) {
        const { isValid, error } = validateField(name, newForm[name].type, newForm);
        newForm[name] = { ...newForm[name], isValid, error };
    } else {
        newForm[name] = { ...newForm[name], isValid: true, error: null };
    }
    if (newForm[name].isRequired) {
        const { isValid, error } = validateField(name, newForm[name].type, newForm);
        newForm[name] = { ...newForm[name], isValid, error };
    }
    return newForm;
};

export const onSubmitValidation = (form) => {
    let newFrom = R.clone(form);
    let formEntries = Object.entries(newFrom);
    let formValidated = formEntries
        .reduce((acc, [fieldName, fieldData]) => {
            if (!fieldData.isRequired && typeof fieldData.value === 'string' && !R.isEmpty(fieldData.value)) {
                const { isValid, error } = validateField(fieldName, fieldData.type, acc.form);
                acc.form[fieldName] = { ...fieldData, isValid, error };
                acc.isValid = !isValid ? false : acc.isValid;
            } else {
                acc.form[fieldName] = { ...fieldData, isValid: true, error: null };
            }
            if (fieldData.isRequired) {
                const { isValid, error } = validateField(fieldName, fieldData.type, acc.form);
                acc.form[fieldName] = { ...fieldData, isValid, error };
                acc.isValid = !isValid ? false : acc.isValid;
            }
            return acc;
        }, { form: newFrom, isValid: true });

    return formValidated;
};

export const onSubmitMultipleValidation = (multiForm) => {
    let newFrom = R.clone(multiForm);
    let formValidated = newFrom
        .reduce((acc, field, index) => {
            if (!field.isRequired) return acc;
            const { isValid, error } = validateField(index, field.type, acc.form);
            acc.form[index] = { ...field, isValid, error };
            acc.isValid = !isValid ? false : acc.isValid;
            return acc;
        }, { form: newFrom, isValid: true });

    return formValidated;
};

export const validateTitle = ({ items, properties, value, id }) => {
    items = items || [];
    if (R.isNil(id)) return !R.isNil(items.find(item => compare(properties.map(p => item[p]).join(""), value)));
    return !R.isNil(items.filter(item => item.id !== id).find(item => compare(properties.map(p => item[p]).join(""), value)));
};