import axios from 'axios';
import * as R from 'ramda';
import { assetGroupsActions } from "../reducers/assetGroup";
import * as urls from '../constants/webapi/assetGroup';
import { PAGE_SIZE, SMALL_PAGE_SIZE } from '../constants/utils/pageSize';
import { urlBuilder } from '../constants/utils/urlBuilder';




export const GetAssetGroups = ({ pageSize, pageNumber, sortBy, sortDesc, filter } = {}) => {
    const size = !R.isNil(pageSize) && !R.isEmpty(pageSize) ? pageSize : PAGE_SIZE;
    const page = !R.isNil(pageNumber) && !R.isEmpty(pageNumber) ? pageNumber : 1;
    const sort = !R.isNil(sortBy) && !R.isEmpty(sortBy) ? sortBy : undefined;
    const desc = !R.isNil(sortDesc) && !R.isEmpty(sortDesc) ? sortDesc : false;
    const filters = !R.isNil(filter) && !R.isEmpty(filter) ? urlBuilder(filter) : undefined;

    return async dispatch => {
        dispatch(assetGroupsActions.getAssetGroupsRequest());
        return axios.get(urls.getAssetGroupsUrl({ size, page, sort, desc, filters }))
            .then((response) => {
                dispatch(assetGroupsActions.getAssetGroupsSuccess({
                    data: response?.data,
                    pageSize: size,
                    pageNumber: page,
                    sortBy: sort,
                    sortDesc: desc
                }));
            })
            .catch((error) => {
                dispatch(assetGroupsActions.getAssetGroupsFailure(error.response.data));
            });
    };
};
export const GetDashboardAssetGroups = ({ pageSize, pageNumber, sortBy, sortDesc, filter } = {}) => {
    const params = { size: SMALL_PAGE_SIZE, page: 1, sort: "Title", desc: false };

    return async dispatch => {
        dispatch(assetGroupsActions.getDashboardAssetGroupsRequest());
        return axios.get(urls.getAssetGroupsUrl(params))
            .then((response) => {
                dispatch(assetGroupsActions.getDashboardAssetGroupsSuccess({
                    data: response?.data,
                    pageSize: params.size,
                    pageNumber: params.page,
                    sortBy: params.sort,
                    sortDesc: params.desc
                }));
            })
            .catch((error) => {
                dispatch(assetGroupsActions.getDashboardAssetGroupsFailure(error.response.data));
            });
    };
};

export const CreateAssetGroup = (assetGroup) => {
    return async dispatch => {
        dispatch(assetGroupsActions.createAssetGroupRequest());
        return axios.post(urls.getAssetGroupUrl(assetGroup.companyId), assetGroup)
            .then((response) => {
                dispatch(assetGroupsActions.createAssetGroupSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(assetGroupsActions.createAssetGroupFailure(error.response.data));
            });
    };
};

export const GetAssetGroup = (companyId, assetGroupId) => {
    return async dispatch => {
        dispatch(assetGroupsActions.getAssetGroupRequest());
        return axios.get(urls.getAssetGroupByIdUrl(companyId, assetGroupId))
            .then((response) => {
                dispatch(assetGroupsActions.getAssetGroupSuccess(response.data));
            })
            .catch((error) => {
                dispatch(assetGroupsActions.getAssetGroupFailure(error.response.data));
            });
    };
};

export const UpdateAssetGroup = (assetGroup) => {
    return async dispatch => {
        dispatch(assetGroupsActions.updateAssetGroupRequest());
        return axios.put(urls.getAssetGroupByIdUrl(assetGroup.companyId, assetGroup.id), assetGroup)
            .then((response) => {
                dispatch(assetGroupsActions.updateAssetGroupSuccess(assetGroup));
            })
            .catch((error) => {
                dispatch(assetGroupsActions.updateAssetGroupFailure(error.response.data));
            });
    };
};

export const DeleteAssetGroup = (companyId, assetGroupId) => {
    return async dispatch => {
        dispatch(assetGroupsActions.deleteAssetGroupRequest());
        return axios.delete(urls.getAssetGroupByIdUrl(companyId, assetGroupId))
            .then((response) => {
                dispatch(assetGroupsActions.deleteAssetGroupSuccess({ id: assetGroupId }));
                return response.data;
            })
            .catch((error) => {
                dispatch(assetGroupsActions.deleteAssetGroupFailure(error.response.data));
                throw error;
            });
    };
};