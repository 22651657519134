import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';


// Components
// Components: Global
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// Components: Common
import { GetCompanies } from '../../../../actions/company-api';
import { GetAssets } from '../../../../actions/asset-api';
import { GetAssetGroups } from '../../../../actions/assetGroup-api';
import { GetPayments } from '../../../../actions/payment-api';
import { GetAccount } from '../../../../actions/account-api';
import { GetTenants } from '../../../../actions/tenant-api';
import { GetDocuments } from '../../../../actions/document-api';

import * as AccountLoadings from '../../../../constants/loadings/account';

import Loading from '../../../../_common/loading';
import ModalDelete from './_common/modalDelete';

// Components: View
import PasswordsViewDetailHeader from './_common/header';
import PasswordsViewEmpty from '../empty';
import { usePermissionModal } from '../../../../hooks/usePermissionModal';

export const PasswordsViewDetail = ({ theme, userState, companyState, tenantState, assetState, assetGroupState, subcontractorState, collaboratorState, documentState, rentalState, accountState }) => {
    const { companyId, id } = useParams();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { openPermissionModal } = usePermissionModal();
    let item = accountState?.account;

    const modalOpenProfile = () => {
        setOpen(true);
    };

    const modalCloseProfile = () => {
        setOpen(false);
    };

    // useEffect(() => {
    //     if (R.isNil(tenantState.tenants) || R.isNil(tenantState.tenants)) {
    //         dispatch(GetTenants());
    //     }
    //     // eslint-disable-next-line
    // }, []);

    useEffect(() => {
        if (R.isNil(companyState.companies) || R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(GetAccount(companyId, id));
        dispatch(GetPayments({ filter: { 'ContactId=': id } }));
        dispatch(GetDocuments({ filter: { 'ContactId=': id } }));
        if (R.isNil(assetState.assets)) {
            dispatch(GetAssets());
        }
        if (R.isNil(tenantState.tenants)) {
            dispatch(GetTenants());
        }
        if (R.isNil(assetGroupState.assetGroups)) {
            dispatch(GetAssetGroups());
        }
        // eslint-disable-next-line
    }, [companyId, id]);

    const permissions = userState.user.companyAccesses.find(c => c.companyId === companyId)?.accessTypes || [];


    return (
        accountState.isLoading.includes(AccountLoadings.FETCHING_ACCOUNT) ?
            <Loading />
            :
            <React.Fragment>
                <PasswordsViewDetailHeader openPermissionModal={openPermissionModal} permissions={permissions} theme={theme} modalOpenProfile={modalOpenProfile} rentalState={rentalState} tenantState={tenantState} companyState={companyState} assetState={assetState} companyId={companyId} accountState={accountState} />
                {R.isNil(accountState.account) || R.isEmpty(accountState.account) ?
                    <PasswordsViewEmpty color="danger" title="Ooops..." description="Quelque chose s'est mal passée. On dirait que ce mot de passe n'existe pas !" />
                    :
                    <Container maxWidth="100%" sx={{ height: "100%" }}>
                        <Stack maxWidth="100%" my={3} direction="column" spacing={4}>
                            <Stack direction="column" spacing={1}>
                                <Typography variant="label" color="text.secondary">Identifiants</Typography>
                                <Stack direction="column" spacing={.5}>
                                    <Stack direction={{ xs: "column", hd: "row" }} spacing={.5}>
                                        <Stack direction={{ xs: "column", lg: "row" }} spacing={.5}>
                                            <Typography variant="body1">Username: {!R.isNil(item.userName) ? <Typography component="span" fontWeight="bold">{item.userName}</Typography> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>

                                            <Typography sx={{ lineHeight: 1 }} display={{ default: 'none', lg: 'inherit' }} variant="h6" component="p" px={3} color="text.hint">•</Typography>

                                            <Typography variant="body1">Mot de passe: {!R.isNil(item.password) ? <Typography component="span" fontWeight="bold">{item.password}</Typography> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>
                                        </Stack>
                                        <Typography sx={{ lineHeight: 1 }} display={{ default: 'none', hd: 'inherit' }} variant="h6" component="p" px={3} color="text.hint">•</Typography>
                                        <Typography variant="body1">URL: {!R.isNil(item.url) ? <Typography component="span" fontWeight="bold">{item.url}</Typography> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>
                                    </Stack>
                                    <Typography variant="body1">Locataire: {!R.isNil(item.contactId) ? <Typography component="span" fontWeight="bold">{!R.isNil(tenantState.tenants) && tenantState.tenants.filter(contact => item.contactId.includes(contact.id)).map(c => c.firstName + ' ' + c.lastName).join(', ')}</Typography> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Container>
                }
                <ModalDelete
                    theme={theme}
                    companyState={companyState}
                    rentalState={rentalState}
                    modalCloseProfile={modalCloseProfile}
                    open={open}
                    item={accountState.account}
                />
            </React.Fragment>
    );
};

export default PasswordsViewDetail;
