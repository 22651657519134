import { useLocation } from 'react-router-dom';

export const useSearchParams = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const start = searchParams.get('start');
    let page = searchParams.get('page');
    page = isNaN(parseInt(page)) ? 1 : parseInt(page);


    return {
        page,
        start
    };
};
