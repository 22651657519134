import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import * as R from 'ramda';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';


import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Components: Common
import * as DocumentLoadings from '../../../../../constants/loadings/document';
import { dayMonthYear } from '../../../../../constants/utils/date';
import ItemCard from '../../../../_common/itemCard';

// Components: View

export const DocumentsViewDetailHeader = ({ theme, title, modalOpenProfile, documentState, tenantState, companyState, assetState, permissions, openPermissionModal }) => {
    let item = documentState.document;
    const [anchorOption, setAnchorOption] = useState(null);
    const optionsMenu = [
        { path: '/app/edit/document/', name: 'Éditer information' }
    ];
    const showMe = Boolean(anchorOption);

    const handleClick = (e) => {
        e.preventDefault();
        setAnchorOption(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorOption(null);
    };

    return (
        documentState.isLoading.includes(DocumentLoadings.FETCHING_DOCUMENT) ?
            <Box component="header" py={2} pt={{ md: 4 }}>
                <Container maxWidth="100%" sx={{ alignItems: 'center', justifyContent: "space-evenly" }}>
                    <ItemCard loading />
                </Container>
            </Box>
            :
            !R.isNil(item) &&
            <Box component="header" sx={{ zIndex: 0, borderBottomLeftRadius: '1.25rem', borderBottomRightRadius: '1.25rem', backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundPosition: 'center bottom' }} py={2} pt={{ md: 4 }} position="relative" top="0" maxWidth="100%" backgroundColor="background.paper">
                <Container maxWidth="100%" sx={{ alignItems: 'center', justifyContent: "space-evenly" }} >
                    <Stack direction="column" flex="1 1 auto" justifyContent="space-between" spacing={1}>
                        <Stack direction={{ default: "column", xs: "row" }} sx={{ gap: 2 }} justifyContent="space-between">
                            <Avatar sx={{ color: "secondary.contrastText", backgroundColor: "secondary.main", width: '4.5rem', height: '4.5rem', fontWeight: 'bold' }} imgProps={{ sx: { borderRadius: 'inherit' } }} children={!R.isNil(item.title) ? item.title.charAt(0) : !R.isNil(title) ? title.charAt(0) : (item.title.charAt(0))} src={(!R.isNil(item.profileUrl?.url) ? item.profileUrl?.url : null)} />
                            <Stack direction="column" spacing={.25} flex="1 1 auto" my="auto">
                                <Typography variant="h5" sx={{ lineHeight: 1 }}>{item.title}</Typography>
                                <Typography variant="body1" fontStyle='italic' color="text.secondary">{!R.isNil(item.startDate) ? format(new Date(item.startDate), dayMonthYear) : 'Document'}</Typography>
                            </Stack>
                            <Box position="relative" mt={.75}>
                                <IconButton sx={{ justifyContent: "space-between", maxWidth: { sm: "fit-content" } }} variant="contained" color="inherit" id="optionButtom" aria-controls={showMe ? 'optionMenu' : undefined} aria-haspopup="true" aria-expanded={showMe ? 'true' : undefined} onClick={handleClick}>
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu id="optionMenu" anchorEl={anchorOption} open={showMe} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'optionButtom' }}>
                                    {optionsMenu.map(({ path, name }, index) => (
                                        permissions.includes("Update") ?
                                            <MenuItem component={RouterLink} to={path ? (path.split("/").includes('edit') ? path + item.companyId + '/' + item.id : path) : ''} key={name} onClick={handleClose}>
                                                {name}
                                            </MenuItem>
                                            :
                                            <MenuItem key={name} onClick={() => { openPermissionModal(); handleClose(); }}>
                                                {name}
                                            </MenuItem>
                                    ))}
                                    {
                                        permissions.includes("Delete") ?
                                            <MenuItem onClick={modalOpenProfile} variant="text" color="inherit" size="inherit">
                                                Effacer
                                            </MenuItem>
                                            :
                                            <MenuItem onClick={() => { openPermissionModal(); handleClose(); }} variant="text" color="inherit" size="inherit">
                                                Effacer
                                            </MenuItem>
                                    }
                                    <Divider />
                                    <MenuItem component={RouterLink} to={"/app/documents"}>
                                        <CloseIcon fontSize='medium' /> <Box component="span" sx={{ color: 'text.secondary', mx: 1 }}>Fermer</Box>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Stack>
                        <Stack direction="column" spacing={.5}>
                            <Stack direction="column" spacing={.5}>
                                <Stack direction={{ xs: "column", xl: "row" }} spacing={.5} pt={1}>
                                    <Typography variant="body1">
                                        Société:
                                        {!R.isNil(companyState.companies) && !R.isNil(item.companyId) ?
                                            companyState.companies.filter(company => item.companyId.includes(company.id)).map((item, index) => (
                                                <Typography fontWeight="bold" component={RouterLink} to={`/app/companies/${item.id}`} key={index} color="inherit" display="inline-flex" alignItems="center" aria-label="go to company" sx={{ textDecoration: "none", marginLeft: .25 }}>{item.title}</Typography>
                                            ))
                                            :
                                            <Typography component="span" color="text.hint">Le champ est vide</Typography>
                                        }
                                    </Typography>
                                    <Typography sx={{ lineHeight: 1 }} display={{ default: 'none', xl: 'inherit' }} variant="h6" component="p" px={3} color="text.hint">•</Typography>
                                    <Stack direction={{ xs: "column", md: "row" }} spacing={.5}>
                                        <Typography variant="body1">
                                            Bien:
                                            {!R.isNil(assetState.assets) && !R.isNil(item.assetId) ?
                                                assetState.assets.filter(asset => item.assetId.includes(asset.id)).map((item, index) => (
                                                    <Typography fontWeight="bold" component={RouterLink} to={`/app/assets/${item.companyId}/${item.id}`} key={index} color="inherit" display="inline-flex" alignItems="center" aria-label="go to company" sx={{ textDecoration: "none", marginLeft: .25 }}>{item.title}</Typography>
                                                ))
                                                :
                                                <Typography component="span" color="text.hint">Le champ est vide</Typography>
                                            }
                                        </Typography>

                                        <Typography sx={{ lineHeight: 1 }} display={{ default: 'none', md: 'inherit' }} variant="h6" component="p" px={3} color="text.hint">•</Typography>

                                        <Typography variant="body1">
                                            Locataire:
                                            {!R.isNil(tenantState.tenants) && !R.isNil(item.contactId) ?
                                                tenantState.tenants.filter(contact => item.contactId.includes(contact.id)).map((item, index) => (
                                                    <Typography fontWeight="bold" component={RouterLink} to={`/app/tenants/${item.id}`} key={index} color="inherit" display="inline-flex" alignItems="center" aria-label="go to company" sx={{ textDecoration: "none", marginLeft: .25 }}>{item.firstName} {item.lastName}</Typography>
                                                ))
                                                :
                                                <Typography component="span" color="text.hint">Le champ est vide</Typography>
                                            }
                                        </Typography>
                                    </Stack>
                                </Stack>
                                {/* <Stack direction={{ xs: "column", lg: "row" }} spacing={.5}>
									<Typography variant="body1">Modalité de paiement: {!R.isNil(paymentTypes[item.paymentType]) ? <Typography component="span" fontWeight="bold">{paymentTypes[item.paymentType]}</Typography> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>

									<Typography sx={{ lineHeight: 1 }} display={{ default: 'none', lg: 'inherit' }} variant="h6" component="p" px={3} color="text.hint">•</Typography>

									<Typography variant="body1">Jour de paiement: {!R.isNil(item.paymentDay) ? <Typography component="span" fontWeight="bold">{format(new Date(item.paymentDay), dayMonthYear)}</Typography> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>
								</Stack> */}
                            </Stack>
                            {/* <Divider /> */}
                            <Typography variant="body1">Pièce jointe: {!R.isNil(item.files.length) ? <Typography component="span" fontWeight="bold">{item.files.length}</Typography> : <Typography component="span" color="text.hint">0</Typography>}</Typography>
                        </Stack>
                    </Stack>
                </Container>
                {(!R.isNil(item.profileUrl) ? item.profileUrl : item.profileUrl?.url) && <Box position="absolute" sx={{ borderRadius: 'inherit', backdropFilter: 'blur(2px)', backgroundImage: ('linear-gradient(180deg,' + theme.palette.background.dim + '30%, ' + theme.palette.background.main + ' 100%)'), zIndex: -1 }} display="block" left="0" bottom="0" top="0" right="0"></Box>}
            </Box>
    );
};

export default DocumentsViewDetailHeader;
