import React from 'react';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from "@mui/material/TextField";

// Components: Common
import FormPlace from '../_common/place';
import FormGlobalComplete from '../_common/globalComplete';
import FormProfilePhoto from '../_common/profilePhoto';
import { onChangeValidation } from '../../../../constants/utils/validators';

// Components: Views

export const FormImmeuble = ({ theme, formData, setFormData, companyUseAutocomplete, profilePhoto, setProfilePhoto, currentPhoto, companyId, ...others }) => {

    const handleOnChange = (event) => { setFormData(onChangeValidation(event.target, formData)); };


    return (
        <Stack spacing={2} flex="1 1 auto" width="100%" {...others}>
            <Container maxWidth="100%" sx={{ display: "inherit", flexDirection: "inherit", gap: 2 }}>
                <Typography className="sr-only">Immeuble Form</Typography>
                <Stack direction="column" spacing={1}>
                    <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                        <FormProfilePhoto theme={theme} currentPhoto={currentPhoto} profilePhoto={profilePhoto} setProfilePhoto={setProfilePhoto} />
                        <TextField
                            label='Nom *'
                            id="Société"
                            type="text"
                            fullWidth
                            variant="filled"
                            name="title"
                            error={formData.title.error && !formData.title.isValid}
                            helperText={formData.title.error}
                            value={formData.title.value}
                            onChange={(event) => { handleOnChange(event); }}
                        />
                    </Stack>
                    <FormPlace formData={formData} handleOnChange={handleOnChange} />
                    <FormGlobalComplete
                        options={companyUseAutocomplete.options}
                        optionsFormData={companyUseAutocomplete.dropdownFormData}
                        setOptionsFormData={companyUseAutocomplete.setDropdownFormData}
                        handleSetOpen={companyUseAutocomplete.handleSetOpen}
                        handleSetOptionsFormData={companyUseAutocomplete.handleSetOptionsFormData}
                        multi={false}
                        name="company"
                        label="Société"
                        companyId={companyId}
                    />
                </Stack>
            </Container>
        </Stack>
    );
};

export default FormImmeuble;
