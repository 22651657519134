import * as consts from './constants';

const breakpoints = {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
        default: 0,
        xs: consts.xsBreakpoint,
        sm: consts.smBreakpoint,
        md: consts.mdBreakpoint,
        lg: consts.lgBreakpoint,
        xl: consts.xlBreakpoint,
        hd: consts.hdBreakpoint,
        uhd: consts.uhdBreakpoint,
		desk: consts.DesktopBreakpoint,
    }
  };
  
  export default breakpoints;
  