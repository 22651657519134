import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from 'ramda';
import { useForm } from "react-hook-form";
import { Link as RouterLink } from 'react-router-dom';


import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import Logo from './../_common/logo';
import { emailRegex, inputTypes } from "../constants/utils/validators";
import { ForgotPassword } from "../actions/auth-api";
import * as Loadings from '../constants/loadings/auth';

export const Oublie = () => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    const authState = useSelector(state => state.auth);


    useEffect(() => {
        document.title = `🚨 Magestimmo mot de passe oublié ?`;
    }, []);

    const submitEmailForm = (data) => {
        dispatch(ForgotPassword({ email: data.email.trim() }))
            .then(() => { reset(); });
    };

    return (
        <Container maxWidth="sm" sx={{ m: "auto" }}>
            <Stack direction="column" flex="1 1 auto" justifyContent="center" alignItems="center" my={5} spacing={2}>
                <Logo />
                <Typography variant="h2" fontWeight="600" color='primary'>Magestimmo</Typography>
            </Stack>
            <Stack direction="column" flex="1 1 auto" spacing={2} alignItems="center" sx={{ maxWidth: '100%', margin: 'auto' }}>
                <Container maxWidth="xs" sx={{ mb: 3, textAlign: "center" }}>
                    <Typography variant="h4" component="h2" lineHeight="1.2" mb={1}>Mot de passe oublié ?</Typography>
                    <Typography variant="body1" color="text">Veuillez saisir l'adresse Email associée à votre compte et nous vous enverrons par email un lien pour réinitialiser votre mot de passe.</Typography>
                </Container>
                <Box name="Oublie" onSubmit={handleSubmit(submitEmailForm)} component="form" sx={{ width: '100%', maxWidth: '100%' }} alignItems='center' display="flex" flexDirection="column" autoComplete="off">
                    <Stack mb={1} direction="column" flex="1 1 auto" spacing={.5} alignItems="center" width="100%" sx={{ maxWidth: '100%', marginX: 'auto' }}>
                        <TextField
                            label='Identifiant de connexion'
                            fullWidth={true}
                            size="medium"
                            name="email"
                            type={"email"}
                            variant="filled"
                            inputProps={register("email", { required: inputTypes.required.errorMessage, pattern: { value: emailRegex, message: inputTypes.email.errorMessage } })}
                            error={!!errors.email}
                            helperText={errors.email ? errors.email.message : ''}
                        />
                    </Stack>
                    {/* <Alert sx={{ width: '100%', borderRadius: 3, marginBottom: 2 }} severity="error">Identifiant de connexion incorrect.</Alert> */}
                    <LoadingButton loading={authState.isLoading.includes(Loadings.FETCHING_FORGOT_PASSWORD)} type="submit" color='primary' fullWidth={true} variant="contained" size="large">
                        réinitialiser
                    </LoadingButton>
                </Box>
                <Link to='/auth/login' component={RouterLink} color="secondary" underline="hover" mt={2}>C'est pas grave, <span style={{ fontWeight: "bold" }}>j'ai un compte</span></Link>
                {
                    R.isNil(authState.forgotPasswordEmail) ?
                        null
                        :
                        authState.forgotPasswordEmail ?
                            <Alert sx={{ width: '100%', borderRadius: 3, marginBottom: 2 }} severity="success">
                                Si vous êtes inscrit chez nous, vous allez recevoir un email qui vous indique la procédure pour récupérer votre mot de passe.
                            </Alert>
                            :
                            <Alert sx={{ width: '100%', borderRadius: 3, marginBottom: 2 }} severity="error">
                                Nous sommes désolé, une erreur s'est produite. Veuillez réessayer plus tard...
                            </Alert>
                }
            </Stack>
        </Container>
    );
};

export default Oublie;