import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import * as R from 'ramda';

// Components: Common
import * as LoadingsCompany from '../../constants/loadings/company';
import * as LoadingsTenant from '../../constants/loadings/tenant';
import * as LoadingsSubcontractor from '../../constants/loadings/subcontractor';
import * as LoadingsAsset from '../../constants/loadings/asset';
import { initialAssetForm, initialCompanyForm, initialPaymentFormData, initialSubcontractorForm, initialTenantForm } from '../../constants/utils/formInitialData';
import { GetCompanies } from '../../actions/company-api';
import { GetAssets } from '../../actions/asset-api';
import { GetTenants } from '../../actions/tenant-api';
import { GetSubcontractors } from '../../actions/subcontractor-api';
import Loading from '../../_common/loading';
import MainPayment from '../_common/forms/layout/payment';
import { GetPayments } from '../../actions/payment-api';



export const NewPayment = ({ theme, companyState, assetState, tenantState, paymentState, subcontractorState }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const search = new URLSearchParams(location.search);
    const query = {
        companyId: search.get("companyId"),
        assetId: search.get("assetId"),
        tenantId: search.get("tenantId"),
        subcontractorId: search.get("subcontractorId"),
    };

    useEffect(() => {
        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        if (R.isNil(assetState.assets)) {
            dispatch(GetAssets());
        }
        if (R.isNil(tenantState.tenants)) {
            dispatch(GetTenants());
        }
        if (R.isNil(subcontractorState.subcontractors)) {
            dispatch(GetSubcontractors());
        }
        if (R.isNil(paymentState.payments)) {
            dispatch(GetPayments());
        }
        // eslint-disable-next-line 
    }, []);


    if (tenantState.isLoading.includes(LoadingsTenant.FETCHING_TENANTS) ||
        assetState.isLoading.includes(LoadingsAsset.FETCHING_ASSETS) ||
        subcontractorState.isLoading.includes(LoadingsSubcontractor.FETCHING_SUBCONTRACTORS) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)) {
        return <Loading theme={theme} />;
    }

    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let assets = !R.isNil(assetState.assets) ? assetState.assets : [];
    let tenants = !R.isNil(tenantState.tenants) ? tenantState.tenants.map(t => ({ ...t, title: t.firstName + " " + t.lastName })) : [];
    let subcontractors = !R.isNil(subcontractorState.subcontractors) ? subcontractorState.subcontractors.map(s => ({ ...s, title: s.firstName + " " + s.lastName })) : [];


    const foundCompany = companies.find(c => c.id === query.companyId);
    const foundAsset = assets.find(c => c.id === query.assetId);
    const foundTenant = tenants.find(c => c.id === query.tenantId);
    const foundSubcontractor = subcontractors.find(c => c.id === query.subcontractorId);

    const tempInitialCompanyForm = R.clone(initialCompanyForm);
    const tempInitialAssetForm = R.clone(initialAssetForm);
    const tempInitialTenantForm = R.clone(initialTenantForm);
    const tempInitialSubcontractorForm = R.clone(initialSubcontractorForm);

    if (!R.isNil(query.companyId) && !R.isNil(foundCompany)) {
        tempInitialCompanyForm.value = query.companyId;
        tempInitialCompanyForm.label = foundCompany.title;
    }
    if (!R.isNil(query.assetId) && !R.isNil(foundAsset)) {
        tempInitialAssetForm.value = query.assetId;
        tempInitialAssetForm.label = foundAsset.title;
    }
    if (!R.isNil(query.tenantId) && !R.isNil(foundTenant)) {
        tempInitialTenantForm.value = query.tenantId;
        tempInitialTenantForm.label = foundTenant.title;
    }
    if (!R.isNil(query.subcontractorId) && !R.isNil(foundSubcontractor)) {
        tempInitialSubcontractorForm.value = query.subcontractorId;
        tempInitialSubcontractorForm.label = foundSubcontractor.title;
    }


    return (
        <MainPayment
            theme={theme}
            paymentState={paymentState}
            companies={companies}
            assets={assets}
            tenants={tenants}
            subcontractors={subcontractors}
            initialPaymentFormData={initialPaymentFormData}
            // initialCompanyForm={[initialCompanyForm]}
            // initialTenantForm={[initialTenantForm]}
            // initialSubcontractorForm={[initialSubcontractorForm]}
            // initialAssetForm={[initialAssetForm]}
            initialCompanyForm={[tempInitialCompanyForm]}
            initialAssetForm={[tempInitialAssetForm]}
            initialTenantForm={[tempInitialTenantForm]}
            initialSubcontractorForm={[tempInitialSubcontractorForm]}
            resetPaymentFormData={initialPaymentFormData}
            resetCompanyForm={initialCompanyForm}
            resetTenantForm={initialTenantForm}
            resetSubcontractorForm={initialSubcontractorForm}
            resetAssetForm={initialAssetForm}
        />
    );
};

export default NewPayment;