import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useLocation } from "react-router-dom";
import * as R from "ramda";
import { format } from "date-fns";

// Components
// Components: Global
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Masonry from "@mui/lab/Masonry";
import Fab from "@mui/material/Fab";

import AddIcon from "@mui/icons-material/Add";
import CastIcon from "@mui/icons-material/Cast";
import GroupsIcon from "@mui/icons-material/Groups";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import HouseIcon from "@mui/icons-material/House";

// Components: Common
import ItemCard from "../_common/itemCard";
import * as CompanyLoadings from "../../constants/loadings/company";
import * as AssetLoadings from "../../constants/loadings/asset";
import * as TentantLoadings from "../../constants/loadings/tenant";
import * as SubcontractorLoadings from "../../constants/loadings/subcontractor";
import * as CollaboratorLoadings from "../../constants/loadings/collaborator";
import { dayMonthYear } from "../../constants/utils/date";
import { GetDashboardCompanies } from "../../actions/company-api";
import { GetDashboardAssets } from "../../actions/asset-api";
import { GetDashboardTenants } from "../../actions/tenant-api";
import { GetDashboardSubcontractors } from "../../actions/subcontractor-api";
import { GetDashboardCollaborators } from "../../actions/collaborator-api";
import { GetDashboardAssetGroups } from "../../actions/assetGroup-api";

import { permissionsType } from "../../constants/utils/objectProperties";
import { getPermissionType } from "../../constants/utils/formHandlers";
import { useTheme } from "@mui/material/styles";

// Components: Views

export const Dashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const userState = useSelector((state) => state.user);
  const user = useMemo(() => userState?.user, [userState]);
  const companyState = useSelector((state) => state.company);
  const assetState = useSelector((state) => state.asset);
  const assetGroupState = useSelector((state) => state.assetGroup);
  const tenantState = useSelector((state) => state.tenant);
  const subcontractorState = useSelector((state) => state.subcontractor);
  const collaboratorState = useSelector((state) => state.collaborator);

  useEffect(() => { 
    dispatch(GetDashboardAssets()); 
    dispatch(GetDashboardCompanies()); 
    dispatch(GetDashboardTenants()); 
    dispatch(GetDashboardSubcontractors()); 
    dispatch(GetDashboardCollaborators()); 
    dispatch(GetDashboardAssetGroups());
    
    // eslint-disable-next-line
  }, [location.pathname]);

  const loading = useMemo(() => {
    return (
      assetState?.isLoading?.includes(AssetLoadings.FETCHING_ASSETS) ||
      tenantState?.isLoading?.includes(TentantLoadings.FETCHING_TENANTS) ||
      companyState?.isLoading?.includes(CompanyLoadings.FETCHING_COMPANIES) ||
      collaboratorState?.isLoading?.includes(
        CollaboratorLoadings.FETCHING_COLLABORATORS
      ) ||
      subcontractorState?.isLoading.includes(
        SubcontractorLoadings.FETCHING_SUBCONTRACTORS
      )
    );
  }, [
    assetState?.isLoading,
    collaboratorState?.isLoading,
    companyState?.isLoading,
    subcontractorState?.isLoading,
    tenantState?.isLoading,
  ]);

  const assets = useMemo(() => {
    let _assets = [];
    if (
      !R.isNil(assetState.dashBoardAssets) &&
      !R.isEmpty(assetState.dashBoardAssets)
    ) {
      _assets = R.clone(_assets).concat(
        R.clone(assetState.dashBoardAssets).splice(0, 4)
      );
    }
    if (
      !R.isNil(assetGroupState.dashboardAssetGroups) &&
      !R.isEmpty(assetGroupState.dashboardAssetGroups)
    ) {
      _assets = R.clone(_assets)
        .splice(0, 4)
        .concat(R.clone(assetGroupState.dashboardAssetGroups).splice(0, 4));
    } 
    return _assets;
  }, [assetGroupState.dashboardAssetGroups, assetState.dashBoardAssets]);
  return (
    <Stack
      component="section"
      direction="column"
      flex="1 1 auto"
      alignItems="center"
      width="100%"
    >
      <Typography component="h2" className="sr-only">
        Content
      </Typography>
      <Masonry columns={{ xs: 1, lg: 2 }} spacing={1} sx={{ width: "100%" }}>
        <Stack spacing={2} maxWidth="100%" id="Premiere">
          <Box component="article" maxWidth="100%" id="assets">
            <Container maxWidth="100%" sx={{ height: "100%" }}>
              <Stack
                component="header"
                direction="row"
                my={2}
                spacing={2}
                flex="1 1 auto"
                alignItems="center"
                justifyContent="space-between"
                sx={{ maxWidth: "100%" }}
              >
                <Typography
                  fontWeight="bold"
                  variant="h5"
                  component="h2"
                  letterSpacing={-0.25}
                  lineHeight="1.2"
                >
                  <ApartmentIcon
                    fontSize="inherit"
                    color="primary"
                    sx={{ verticalAlign: "middle", mr: 1, mt: -0.5 }}
                  />
                  <Link
                    component={RouterLink}
                    to="/app/assets"
                    underline="none"
                    color="inherit"
                  >
                    Mes biens
                  </Link>
                </Typography>
                <Fab
                  component={RouterLink}
                  to="/app/new/asset"
                  color="secondary"
                  size="small"
                  aria-label="add"
                  sx={{ zIndex: "1" }}
                >
                  <AddIcon />
                </Fab>
              </Stack>
              <Stack
                direction="column"
                spacing={1}
                mb={2}
                flex="1 1 auto"
                sx={{ maxWidth: "100%" }}
              >
                {!R.isNil(assets) && !R.isEmpty(assets) ? (
                  R.sortBy(R.compose(R.toLower, R.prop("title")))(assets).map(
                    (asset) => {
                      return (
                        <ItemCard
                          alignItems="top"
                          key={asset.id}
                          to={
                            R.isNil(asset.assetType)
                              ? `/app/assets/immeubles/${asset.companyId}/${asset.id}`
                              : `/app/assets/${asset.companyId}/${asset.id}`
                          }
                          component={RouterLink}
                        >
                          <Avatar
                            variant="circular"
                            sx={{
                              backgroundColor: R.isNil(asset.profileUrl?.url)
                                ? "grey.A300"
                                : "background.paper",
                              width: "3rem",
                              height: "3rem",
                              fontWeight: "bold",
                              border: ".0625rem solid",
                              borderColor: R.isNil(asset.profileUrl?.url)
                                ? "transparent"
                                : "grey.main",
                            }}
                            imgProps={{ sx: { borderRadius: "inherit" } }}
                            children={
                              !R.isNil(asset.title)
                                ? asset.title.charAt(0)
                                : asset.firstName.charAt(0) +
                                  asset.lastName.charAt(0)
                            }
                            srcSet={
                              !R.isNil(asset.profileUrl?.url)
                                ? asset.profileUrl?.url
                                : null
                            }
                            alt={asset.title}
                          />
                          <Stack
                            direction="column"
                            flex="1 1 auto"
                            width="100%"
                            gap={0.5}
                          >
                            <Stack
                              direction="row"
                              flex="1 1 auto"
                              gap={2}
                              width="100%"
                            >
                              <Typography
                                flex="1 1 auto"
                                variant="text"
                                fontWeight="bold"
                                sx={{ wordBreak: "break-all" }}
                              >
                                {asset.title}
                              </Typography>
                              {!R.isNil(asset.assetState) &&
                                asset.assetState === 3 && (
                                  <Typography
                                    textAlign="right"
                                    fontWeight="bold"
                                    color="danger.main"
                                    variant="caption"
                                    textTransform="uppercase"
                                  >
                                    Vendu
                                  </Typography>
                                )}
                              {R.isNil(asset.assetType) && (
                                <Typography
                                  fontWeight="bold"
                                  color="info.main"
                                  variant="caption"
                                  textTransform="uppercase"
                                >
                                  {assets
                                    ? assets.filter(
                                        (property) =>
                                          property.assetGroupId === asset.id
                                      ).length
                                    : "0"}{" "}
                                  biens
                                </Typography>
                              )}
                            </Stack>
                            <Stack
                              direction={{ xs: "row" }}
                              gap={{ default: 0.5, hd: 2 }}
                              flex={{ lg: "inherit", hd: "initial" }}
                              justifyContent="space-between"
                              alignItems={{
                                md: "center",
                                lg: "start",
                                hd: "center",
                              }}
                              width={{ lg: "100%", hd: "auto" }}
                            >
                              <Stack
                                direction="row"
                                gap={1}
                                alignItems="center"
                                width="inherit"
                                flex="1 1 auto"
                                justifyContent="space-between"
                              >
                                {/* <Typography color="text.secondary" variant="body2">
																	Associé a: 
																</Typography>	 */}
                                <Box
                                  display="inherit"
                                  alignItems="center"
                                  gap={0.5}
                                  width="100%"
                                >
                                  <Typography
                                    variant="body2"
                                    alignItems="center"
                                    display="inherit"
                                    gap={0.5}
                                  >
                                    <AccountBalanceWalletIcon
                                      flex="1 0 auto"
                                      fontSize="small"
                                      sx={{ lineHeight: "1" }}
                                    />
                                    <Box
                                      flex="1 1 auto"
                                      color="text.main"
                                      component="span"
                                      sx={{
                                        wordBreak: "break-all",
                                        letterSpacing: -0.3,
                                      }}
                                    >
                                      {!R.isNil(
                                        companyState.dashBoardCompanies
                                      ) &&
                                      !R.isEmpty(
                                        companyState.dashBoardCompanies
                                      )
                                        ? companyState.dashBoardCompanies.find(
                                            (c) => c.id === asset.companyId
                                          )?.title
                                        : "N/D"}
                                    </Box>
                                  </Typography>
                                  <Box display="inherit" gap="inherit">
                                    <Typography color="text.disabled" px={1}>
                                      •
                                    </Typography>
                                    {R.isNil(asset.assetType) ? (
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        gap={1}
                                      >
                                        <HomeWorkIcon
                                          color="text.main"
                                          flex="1 0 auto"
                                          fontSize="small"
                                          sx={{ lineHeight: "1" }}
                                        />
                                        <Typography
                                          color="text.secondary"
                                          flex="1 1 auto"
                                          variant="body2"
                                          sx={{
                                            wordBreak: "break-all",
                                            letterSpacing: -0.3,
                                          }}
                                        >
                                          Immeuble
                                        </Typography>
                                      </Box>
                                    ) : (
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        gap={0.5}
                                      >
                                        <HouseIcon
                                          color="text.main"
                                          flex="1 0 auto"
                                          fontSize="small"
                                          sx={{ lineHeight: "1" }}
                                        />
                                        <Typography
                                          color="text.secondary"
                                          flex="1 1 auto"
                                          variant="body2"
                                          sx={{
                                            wordBreak: "break-all",
                                            letterSpacing: -0.3,
                                          }}
                                        >
                                          Appartement
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                width="inherit"
                                gap={2}
                                flex="1 1 auto"
                                justifyContent="space-between"
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                > 
                                </Stack>
                                <Typography
                                  flex="1 0 auto"
                                  textAlign="right"
                                  color="text.secondary"
                                  variant="body2"
                                  fontStyle="italic"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                  maxWidth="6.25rem"
                                >
                                  {!R.isNil(asset.createdOn) &&
                                  R.isNil(asset.assetType)
                                    ? format(
                                        new Date(asset.createdOn),
                                        dayMonthYear
                                      )
                                    : !R.isNil(asset.assetType) &&
                                      !R.isNil(asset.modifiedOn)
                                    ? format(
                                        new Date(asset.modifiedOn),
                                        dayMonthYear
                                      )
                                    : "DD/MM/YYYY"}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </ItemCard>
                      );
                    }
                  )
                ) : (
                  <ItemCard loading={loading} />
                )}
              </Stack> 
            </Container>
          </Box>
          <Box component="article" maxWidth="100%" id="tenants">
            <Container maxWidth="100%" sx={{ height: "100%" }}>
              <Stack
                component="header"
                direction="row"
                my={2}
                spacing={2}
                flex="1 1 auto"
                alignItems="center"
                justifyContent="space-between"
                sx={{ maxWidth: "100%" }}
              >
                <Typography
                  fontWeight="bold"
                  variant="h5"
                  component="h3"
                  letterSpacing={-0.25}
                  lineHeight="1.2"
                >
                  <GroupsIcon
                    fontSize="inherit"
                    color="primary"
                    sx={{ verticalAlign: "middle", mr: 1, mt: -0.5 }}
                  />
                  <Link
                    component={RouterLink}
                    to="/app/tenants"
                    underline="none"
                    color="inherit"
                  >
                    Mes locataires
                  </Link>
                </Typography>
                <Fab
                  component={RouterLink}
                  to="/app/new/tenant"
                  color="secondary"
                  size="small"
                  aria-label="add"
                  sx={{ zIndex: "1" }}
                >
                  <AddIcon />
                </Fab>
              </Stack>
              <Stack
                direction="column"
                spacing={1}
                mb={2}
                flex="1 1 auto"
                sx={{ maxWidth: "100%" }}
              >
                {!R.isNil(tenantState.dashBoardTenants) &&
                !R.isEmpty(tenantState.dashBoardTenants) ? (
                  tenantState.dashBoardTenants.map((tenant) => {
                    return (
                      <ItemCard
                        key={tenant.id}
                        to={`/app/tenants/${tenant.id}`}
                        component={RouterLink}
                      >
                        <Avatar
                          variant="circular"
                          sx={{
                            backgroundColor: R.isNil(tenant.profileUrl?.url)
                              ? "grey.A300"
                              : "background.paper",
                            width: "3rem",
                            height: "3rem",
                            fontWeight: "bold",
                            border: ".0625rem solid",
                            borderColor: R.isNil(tenant.profileUrl?.url)
                              ? "transparent"
                              : "grey.main",
                          }}
                          imgProps={{ sx: { borderRadius: "inherit" } }}
                          children={
                            !R.isNil(tenant.title)
                              ? tenant.title.charAt(0)
                              : tenant.firstName.charAt(0) +
                                tenant.lastName.charAt(0)
                          }
                          srcSet={
                            !R.isNil(tenant.profileUrl?.url)
                              ? tenant.profileUrl?.url
                              : null
                          }
                          alt={tenant.firstName}
                        />
                        <Stack direction="column" flex="1 1 auto" width="100%">
                          <Typography variant="text" sx={{}}>
                            {tenant.firstName} {tenant.lastName}
                          </Typography>
                        </Stack>
                      </ItemCard>
                    );
                  })
                ) : (
                  <ItemCard loading={loading} />
                )}
              </Stack>
              <Stack
                component="footer"
                direction="row"
                spacing={2}
                flex="1 1 auto"
                alignItems="center"
                justifyContent="end"
                sx={{ maxWidth: "100%" }}
              >
                <Link
                  sx={{ textTransform: "capitalize" }}
                  component={RouterLink}
                  to="/app/tenants"
                  fontWeight="700"
                  color={theme.palette.text.secondary}
                  underline="hover"
                >
                  Tout Voir
                </Link>
              </Stack>
            </Container>
          </Box>
        </Stack>
        <Stack spacing={2} maxWidth="100%" id="autres">
          <Box component="article" maxWidth="100%" id="Sociétés">
            <Container maxWidth="100%" sx={{ height: "100%" }}>
              <Stack
                component="header"
                direction="row"
                my={2}
                spacing={2}
                flex="1 1 auto"
                alignItems="center"
                justifyContent="space-between"
                sx={{ maxWidth: "100%" }}
              >
                <Typography
                  fontWeight="bold"
                  variant="h5"
                  component="h3"
                  letterSpacing={-0.25}
                  lineHeight="1.2"
                >
                  <AccountBalanceWalletIcon
                    fontSize="inherit"
                    color="primary"
                    sx={{ verticalAlign: "middle", mr: 1, mt: -0.5 }}
                  />
                  <Link
                    component={RouterLink}
                    to="/app/companies"
                    underline="none"
                    color="inherit"
                  >
                    Comptabilités / Sociétés
                  </Link>
                </Typography>
                <Fab
                  component={RouterLink}
                  to="/app/new/company"
                  color="secondary"
                  size="small"
                  aria-label="add"
                  sx={{ zIndex: "1" }}
                >
                  <AddIcon />
                </Fab>
              </Stack>
              <Stack
                direction="column"
                spacing={1}
                mb={2}
                flex="1 1 auto"
                sx={{ maxWidth: "100%" }}
              >
                {!R.isNil(companyState.dashBoardCompanies) &&
                !R.isEmpty(companyState.dashBoardCompanies) ? (
                  R.clone(companyState.dashBoardCompanies)
                    .splice(0, 4)
                    .map((company) => {
                      return (
                        <ItemCard
                          key={company.id}
                          to={`/app/companies/${company.id}`}
                          component={RouterLink}
                        >
                          <Avatar
                            variant="circular"
                            sx={{
                              backgroundColor: R.isNil(company.profileUrl?.url)
                                ? "grey.A300"
                                : "background.paper",
                              width: "3rem",
                              height: "3rem",
                              fontWeight: "bold",
                              border: ".0625rem solid",
                              borderColor: R.isNil(company.profileUrl?.url)
                                ? "transparent"
                                : "grey.main",
                            }}
                            imgProps={{ sx: { borderRadius: "inherit" } }}
                            children={
                              !R.isNil(company.title)
                                ? company.title.charAt(0)
                                : company.firstName.charAt(0) +
                                  company.lastName.charAt(0)
                            }
                            srcSet={
                              !R.isNil(company.profileUrl?.url)
                                ? company.profileUrl?.url
                                : null
                            }
                            alt={company.title}
                          />
                          <Stack
                            direction="column"
                            flex="1 1 auto"
                            width="100%"
                          >
                            <Typography variant="text" sx={{}}>
                              {company.title || "N/A"}
                            </Typography>
                            <Typography
                              variant="body2"
                              alignItems="center"
                              display="inherit"
                              gap={0.5}
                              color="text.secondary"
                              sx={{
                                wordBreak: "break-all",
                                letterSpacing: -0.3,
                              }}
                            >
                              {
                                permissionsType[
                                  getPermissionType(
                                    userState.user.companyAccesses.find(
                                      (c) => c.companyId === company.id
                                    )?.accessTypes
                                  )
                                ]
                              }
                            </Typography>
                          </Stack>
                        </ItemCard>
                      );
                    })
                ) : (
                  <ItemCard loading={loading} />
                )}
              </Stack>
              <Stack
                component="footer"
                direction="row"
                spacing={2}
                flex="1 1 auto"
                alignItems="center"
                justifyContent="end"
                sx={{ maxWidth: "100%" }}
              >
                <Link
                  sx={{ textTransform: "capitalize" }}
                  component={RouterLink}
                  to="/app/companies"
                  fontWeight="700"
                  color={theme.palette.text.secondary}
                  underline="hover"
                >
                  Tout Voir
                </Link>
              </Stack>
            </Container>
          </Box>
          <Box component="article" maxWidth="100%" id="partages">
            <Container maxWidth="100%" sx={{ height: "100%" }}>
              <Stack
                component="header"
                direction="row"
                my={2}
                spacing={2}
                flex="1 1 auto"
                alignItems="center"
                justifyContent="space-between"
                sx={{ maxWidth: "100%" }}
              >
                <Typography
                  fontWeight="bold"
                  variant="h5"
                  component="h3"
                  lineHeight="1.2"
                  letterSpacing={-0.25}
                >
                  <CastIcon
                    fontSize="inherit"
                    color="primary"
                    sx={{ verticalAlign: "middle", mr: 1, mt: -0.5 }}
                  />
                  <Link
                    component={RouterLink}
                    to="/app/collaborators"
                    underline="none"
                    color="inherit"
                  >
                    Mes partages d'accès
                  </Link>
                </Typography>
                <Fab
                  component={RouterLink}
                  to="/app/new/share"
                  color="secondary"
                  size="small"
                  aria-label="add"
                  sx={{ zIndex: "1" }}
                >
                  <AddIcon />
                </Fab>
              </Stack>
              <Stack
                direction="column"
                spacing={1}
                mb={2}
                flex="1 1 auto"
                sx={{ maxWidth: "100%" }}
              >
                {!R.isNil(collaboratorState.dashBoardCollaborators) &&
                !R.isEmpty(collaboratorState.dashBoardCollaborators) ? (
                  collaboratorState.dashBoardCollaborators.map(
                    (collaborator) => {
                      return (
                        <ItemCard
                          key={collaborator.id}
                          to={`/app/collaborators/${collaborator.id}`}
                          component={RouterLink}
                          backgroundColor={
                            collaborator.id === user.id
                              ? "background.master"
                              : "background.paper"
                          }
                          elevation={collaborator.id === user.id ? 0 : null}
                        >
                          <Avatar
                            variant="circular"
                            sx={{
                              backgroundColor: R.isNil(
                                collaborator.profileUrl?.url
                              )
                                ? "grey.A300"
                                : "background.paper",
                              width: "3rem",
                              height: "3rem",
                              fontWeight: "bold",
                              border: ".0625rem solid",
                              borderColor: R.isNil(collaborator.profileUrl?.url)
                                ? "transparent"
                                : "grey.main",
                            }}
                            imgProps={{ sx: { borderRadius: "inherit" } }}
                            children={
                              !R.isNil(collaborator.title)
                                ? collaborator.title.charAt(0)
                                : collaborator.firstName.charAt(0) +
                                  collaborator.lastName.charAt(0)
                            }
                            srcSet={
                              !R.isNil(collaborator.profileUrl?.url)
                                ? collaborator.profileUrl?.url
                                : null
                            }
                            alt={collaborator.firstName}
                          />
                          <Stack
                            direction="column"
                            flex="1 1 auto"
                            width="100%"
                          >
                            <Typography variant="text" sx={{}}>
                              {collaborator.firstName} {collaborator.lastName}{" "}
                              {collaborator.id === user.id ? (
                                <Box
                                  component="span"
                                  color="text.secondary"
                                  fontSize="small"
                                >
                                  (Moi)
                                </Box>
                              ) : null}
                            </Typography>
                          </Stack>
                        </ItemCard>
                      );
                    }
                  )
                ) : (
                  <ItemCard loading={loading} />
                )}
              </Stack>
              <Stack
                component="footer"
                direction="row"
                spacing={2}
                flex="1 1 auto"
                alignItems="center"
                justifyContent="end"
                sx={{ maxWidth: "100%" }}
              >
                <Link
                  sx={{ textTransform: "capitalize" }}
                  component={RouterLink}
                  to="/app/collaborators"
                  fontWeight="700"
                  color={theme.palette.text.secondary}
                  underline="hover"
                >
                  Tout Voir
                </Link>
              </Stack>
            </Container>
          </Box>
          <Box component="article" maxWidth="100%" id="traitants">
            <Container maxWidth="100%" sx={{ height: "100%" }}>
              <Stack
                component="header"
                direction="row"
                my={2}
                spacing={2}
                flex="1 1 auto"
                alignItems="center"
                justifyContent="space-between"
                sx={{ maxWidth: "100%" }}
              >
                <Typography
                  fontWeight="bold"
                  variant="h5"
                  component="h3"
                  lineHeight="1.2"
                  letterSpacing={-0.25}
                >
                  <ListAltIcon
                    fontSize="inherit"
                    color="primary"
                    sx={{ verticalAlign: "middle", mr: 1, mt: -0.5 }}
                  />
                  <Link
                    component={RouterLink}
                    to="/app/subcontractors"
                    underline="none"
                    color="inherit"
                  >
                    Mes sous-traitants
                  </Link>
                </Typography>
                <Fab
                  component={RouterLink}
                  to="/app/new/subcontractor"
                  color="secondary"
                  size="small"
                  aria-label="add"
                  sx={{ zIndex: "1" }}
                >
                  <AddIcon />
                </Fab>
              </Stack>
              <Stack
                direction="column"
                spacing={1}
                mb={2}
                flex="1 1 auto"
                sx={{ maxWidth: "100%" }}
              >
                {!R.isNil(subcontractorState.dashBoardSubcontractors) &&
                !R.isEmpty(subcontractorState.dashBoardSubcontractors) ? (
                  subcontractorState.dashBoardSubcontractors.map(
                    (subcontractor) => {
                      return (
                        <ItemCard
                          key={subcontractor.id}
                          to={`/app/subcontractors/${subcontractor.id}`}
                          component={RouterLink}
                        >
                          <Avatar
                            variant="circular"
                            sx={{
                              backgroundColor: R.isNil(
                                subcontractor.profileUrl?.url
                              )
                                ? "grey.A300"
                                : "background.paper",
                              width: "3rem",
                              height: "3rem",
                              fontWeight: "bold",
                              border: ".0625rem solid",
                              borderColor: R.isNil(
                                subcontractor.profileUrl?.url
                              )
                                ? "transparent"
                                : "grey.main",
                            }}
                            imgProps={{ sx: { borderRadius: "inherit" } }}
                            children={
                              !R.isNil(subcontractor.title)
                                ? subcontractor.title.charAt(0)
                                : subcontractor.firstName.charAt(0) +
                                  subcontractor.lastName.charAt(0)
                            }
                            srcSet={
                              !R.isNil(subcontractor.profileUrl?.url)
                                ? subcontractor.profileUrl?.url
                                : null
                            }
                            alt={subcontractor.firstName}
                          />
                          <Stack
                            direction="column"
                            flex="1 1 auto"
                            width="100%"
                          >
                            <Typography
                              variant="text"
                              sx={{}}
                            >{`${subcontractor.firstName} ${subcontractor.lastName}`}</Typography>
                          </Stack>
                        </ItemCard>
                      );
                    }
                  )
                ) : (
                  <ItemCard loading={loading} />
                )}
              </Stack>
              <Stack
                component="footer"
                direction="row"
                spacing={2}
                flex="1 1 auto"
                alignItems="center"
                justifyContent="end"
                sx={{ maxWidth: "100%" }}
              >
                <Link
                  sx={{ textTransform: "capitalize" }}
                  component={RouterLink}
                  to="/app/subcontractors"
                  fontWeight="700"
                  color={theme.palette.text.secondary}
                  underline="hover"
                >
                  Tout Voir
                </Link>
              </Stack>
            </Container>
          </Box>
        </Stack>
      </Masonry>
    </Stack>
  );
};

export default Dashboard;
