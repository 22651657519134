import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as R from 'ramda';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';

import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Components: Common
import ItemCard from '../../../../_common/itemCard';

// Components: View

export const BanksViewDetailHeader = ({ theme, title, modalOpen, item, totalTransactions, permissions, openPermissionModal }) => {
    let itemSrc = item?.company;
    const [anchorOption, setAnchorOption] = useState(null);
    const optionsMenu = [
        { path: '/app/edit/company/', name: 'Éditer information' },
        // { path: null, name: 'Télécharger tous les documents' },
    ];
    const showMe = Boolean(anchorOption);

    const handleClick = (e) => {
        e.preventDefault();
        setAnchorOption(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorOption(null);
    };

    return (
        R.isNil(itemSrc) ?
            <Box component="header" py={2} pt={{ md: 4 }}>
                <Container maxWidth="100%" sx={{ alignItems: 'center', justifyContent: "space-evenly" }}>
                    <ItemCard loading />
                </Container>
            </Box>
            :
            <Box component="header" sx={{ zIndex: 0, borderBottomLeftRadius: '1.25rem', borderBottomRightRadius: '1.25rem', backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundPosition: 'center bottom' }} py={2} pt={{ md: 4 }} position="relative" top="0" maxWidth="100%" backgroundColor="background.paper">
                <Container maxWidth="100%" sx={{ alignItems: 'center', justifyContent: "space-evenly" }} >
                    <Stack direction="column" flex="1 1 auto" justifyContent="space-between" spacing={1}>
                        <Stack direction={{ default: "column", xs: "row" }} sx={{ gap: 2 }} justifyContent="space-between">
                            <Avatar sx={{ color: "secondary.contrastText", backgroundColor: "secondary.main", width: '4.5rem', height: '4.5rem', fontWeight: 'bold' }} imgProps={{ sx: { borderRadius: 'inherit' } }} children={itemSrc.bankName ? itemSrc.bankName[0] : 'B'} />
                            <Stack direction="column" spacing={.25} flex="1 1 auto" my="auto">
                                <Typography variant="h5" sx={{ lineHeight: 1 }}>{itemSrc.bankName ? itemSrc.bankName : ('Banque de ' + itemSrc.title)}</Typography>
                                <Typography variant="body1" fontStyle='italic' color="text.secondary">Total des transactions: <strong>{totalTransactions}</strong></Typography>
                            </Stack>
                            <Box position="relative" mt={.75}>
                                <IconButton sx={{ justifyContent: "space-between", maxWidth: { sm: "fit-content" } }} variant="contained" color="inherit" id="optionButtom" aria-controls={showMe ? 'optionMenu' : undefined} aria-haspopup="true" aria-expanded={showMe ? 'true' : undefined} onClick={handleClick}>
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu id="optionMenu" anchorEl={anchorOption} open={showMe} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'optionButtom' }}>
                                    {optionsMenu.map(({ path, name }, index) => (
                                        permissions.includes("Update") ?
                                            <MenuItem component={RouterLink} to={path ? (path.split("/").includes('edit') ? path + itemSrc.id : path) : ''} key={name} onClick={handleClose}>
                                                {name}
                                            </MenuItem>
                                            :
                                            <MenuItem key={name} onClick={() => { openPermissionModal(); handleClose(); }}>
                                                {name}
                                            </MenuItem>
                                    ))}
                                    {/* <MenuItem onClick={modalOpen} variant="text" color="inherit" size="inherit">
                                        Effacer
                                    </MenuItem> */}
                                    <Divider />
                                    <MenuItem component={RouterLink} to={"/app/banks"}>
                                        <CloseIcon fontSize='medium' /> <Box component="span" sx={{ color: 'text.secondary', mx: 1 }}>Fermer</Box>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Stack>
                        <Stack direction="column" spacing={.5} py={1}>
                            <Typography color="inherit" display="flex" alignItems="center" aria-label="go to immeuble">
                                Associé à:
                                <Box fontWeight="bold" color="inherit" mx={1} display="flex" alignItems="center" sx={{ textDecoration: "none" }} component={RouterLink} to={"/app/companies/" + itemSrc.id}>
                                    {/* <AccountBalanceWalletIcon fontSize="small" />  */}
                                    {itemSrc.title}
                                </Box>
                            </Typography>
                        </Stack>
                    </Stack>
                </Container>
                {(!R.isNil(itemSrc.profileUrl) ? itemSrc.profileUrl : itemSrc.profileUrl?.url) && <Box position="absolute" sx={{ borderRadius: 'inherit', backdropFilter: 'blur(2px)', backgroundImage: ('linear-gradient(180deg,' + theme.palette.background.dim + '30%, ' + theme.palette.background.main + ' 100%)'), zIndex: -1 }} display="block" left="0" bottom="0" top="0" right="0"></Box>}
            </Box>
    );
};

export default BanksViewDetailHeader;
