import { createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';
import * as Loadings from '../constants/loadings/asset';
import { SMALL_PAGE_SIZE } from '../constants/utils/pageSize';


const initState = {
    isLoading: [],
    assets: undefined,
    dashBoardAssets: undefined,
    asset: undefined,
    soldAsset: undefined,
    soldAssets: undefined
};

const AssetsReducer = createSlice({
    name: 'asset',
    initialState: { ...initState },
    reducers: {
        //#region GET ASSESTS
        getAssetsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_ASSETS)) {
                state.isLoading.push(Loadings.FETCHING_ASSETS);
            }
        },
        getAssetsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ASSETS);
            if (!payload.filters) {
                state.assets = payload.data;
                return;
            }
            state.assetsFilter = payload.data;
        },
        getAssetsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ASSETS);
        },

        getDashboardAssetsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_ASSETS)) {
                state.isLoading.push(Loadings.FETCHING_ASSETS);
            }
        },
        getDashboardAssetsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ASSETS);
            state.dashBoardAssets = R.clone(payload.data).splice(0, 4);
        },
        getDashboardAssetsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ASSETS);
        },
        //#endregion

        //#region CREATE ASSET
        createAssetRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_ASSET)) {
                state.isLoading.push(Loadings.CREATING_ASSET);
            }
        },
        createAssetSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_ASSET);
            if (R.isNil(state.assets)) {
                state.assets = [];
            }
            if (!R.isNil(state.dashBoardAssets) && state.dashBoardAssets.length < SMALL_PAGE_SIZE) {
                state.dashBoardAssets.push(payload);
            }
            if (R.isNil(state.dashBoardAssets)) {
                state.dashBoardAssets = [];
                state.dashBoardAssets.push(payload);
            }
            state.assets.push(payload);
        },
        createAssetFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_ASSET);
        },
        //#endregion

        //#region GET ASSET
        getAssetRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_ASSET)) {
                state.isLoading.push(Loadings.FETCHING_ASSET);
            }
        },
        getAssetSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ASSET);
            state.asset = payload;
        },
        getAssetFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ASSET);
        },
        //#endregion

        //#region UPDATE ASSET
        updateAssetRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_ASSET)) {
                state.isLoading.push(Loadings.UPDATING_ASSET);
            }
        },
        updateAssetSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_ASSET);
            state.assets = state.assets.map(asset => {
                if (asset.id === payload.id) {
                    return payload;
                }
                return asset;
            });
            if (payload.assetState === 3) {
                state.soldAssets = state.soldAssets ? state.soldAssets : [];
                state.soldAssets.push(payload);
            } else {
                state.soldAssets = state.soldAssets?.filter(a => a.id !== payload.id);
            }
        },
        updateAssetFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_ASSET);
        },
        //#endregion

        //#region DELETE ASSET
        deleteAssetRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_ASSET)) {
                state.isLoading.push(Loadings.DELETING_ASSET);
            }
        },
        deleteAssetSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_ASSET);
            state.asset = undefined;
            state.assets = state.assets.filter(a => a.id !== payload.id);
            if (!R.isNil(state.dashBoardAssets)) {
                state.dashBoardAssets = state.dashBoardAssets.filter(a => a.id !== payload.id);
            }
        },
        deleteAssetFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_ASSET);
        },
        //#endregion

        //#region GET SOLD ASSESTS
        getSoldAssetsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_SOLD_ASSETS)) {
                state.isLoading.push(Loadings.FETCHING_SOLD_ASSETS);
            }
        },
        getSoldAssetsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_SOLD_ASSETS);
            state.soldAssets = payload.data;
        },
        getSoldAssetsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_SOLD_ASSETS);
        },
        //#endregion

        //#region CREATE SOLD ASSET
        createSoldAssetRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_SOLD_ASSET)) {
                state.isLoading.push(Loadings.CREATING_SOLD_ASSET);
            }
        },
        createSoldAssetSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_SOLD_ASSET);
            if (R.isNil(state.soldAssets)) {
                state.soldAssets = [];
            }
            state.soldAssets.push(payload);
        },
        createSoldAssetFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_SOLD_ASSET);
        },
        //#endregion

        //#region GET SOLD ASSET
        getSoldAssetRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_SOLD_ASSET)) {
                state.isLoading.push(Loadings.FETCHING_SOLD_ASSET);
            }
        },
        getSoldAssetSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_SOLD_ASSET);
            state.soldAsset = payload;
        },
        getSoldAssetFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_SOLD_ASSET);
        },
        //#endregion

        //#region UPDATE SOLD ASSET
        updateSoldAssetRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_SOLD_ASSET)) {
                state.isLoading.push(Loadings.UPDATING_SOLD_ASSET);
            }
        },
        updateSoldAssetSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_SOLD_ASSET);
        },
        updateSoldAssetFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_SOLD_ASSET);
        },
        //#endregion

        //#region DELETE SOLD ASSET
        deleteSoldAssetRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_SOLD_ASSET)) {
                state.isLoading.push(Loadings.DELETING_SOLD_ASSET);
            }
        },
        deleteSoldAssetSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_SOLD_ASSET);
            state.soldAsset = undefined;
            state.soldAssets = state.soldAssets.filter(a => a.id !== payload.id);
        },
        deleteSoldAssetFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_SOLD_ASSET);
        },
        //#endregion
    }
});

export const assetsActions = AssetsReducer.actions;
export default AssetsReducer.reducer;