import { createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';
import * as Loadings from '../constants/loadings/subcontractor';
import { SMALL_PAGE_SIZE } from '../constants/utils/pageSize';


const initState = {
    isLoading: [],
    subcontractors: undefined,
    dashBoardSubcontractors: undefined,
    subcontractor: undefined
};

const SubcontractorsReducer = createSlice({
    name: 'subcontractor',
    initialState: { ...initState },
    reducers: {
        //#region GET SUBCONTRACTORS
        getSubcontractorsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_SUBCONTRACTORS)) {
                state.isLoading.push(Loadings.FETCHING_SUBCONTRACTORS);
            }
        },
        getSubcontractorsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_SUBCONTRACTORS);
            state.subcontractors = payload.data;
        },
        getSubcontractorsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_SUBCONTRACTORS);
        },

        getDashboardSubcontractorsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_SUBCONTRACTORS)) {
                state.isLoading.push(Loadings.FETCHING_SUBCONTRACTORS);
            }
        },
        getDashboardSubcontractorsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_SUBCONTRACTORS);
            state.dashBoardSubcontractors = R.clone(payload.data).splice(0, 4);
        },
        getDashboardSubcontractorsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_SUBCONTRACTORS);
        },
        //#endregion

        //#region CREATE SUBCONTRACTOR
        createSubcontractorRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_SUBCONTRACTOR)) {
                state.isLoading.push(Loadings.CREATING_SUBCONTRACTOR);
            }
        },
        createSubcontractorSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_SUBCONTRACTOR);
            state.subcontractor = payload;
            if (R.isNil(state.subcontractors)) {
                state.subcontractors = [];
            }
            if (!R.isNil(state.dashBoardSubcontractors) && state.dashBoardSubcontractors.length < SMALL_PAGE_SIZE) {
                state.dashBoardSubcontractors.push(payload);
            }
            if (R.isNil(state.dashBoardSubcontractors)) {
                state.dashBoardSubcontractors = [];
                state.dashBoardSubcontractors.push(payload);
            }
            state.subcontractors.push(payload);
        },
        createSubcontractorFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_SUBCONTRACTOR);
        },
        //#endregion

        //#region GET SUBCONTRACTOR
        getSubcontractorRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_SUBCONTRACTOR)) {
                state.isLoading.push(Loadings.FETCHING_SUBCONTRACTOR);
            }
        },
        getSubcontractorSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_SUBCONTRACTOR);
            state.subcontractor = payload;
        },
        getSubcontractorFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_SUBCONTRACTOR);
        },
        //#endregion

        //#region UPDATE SUBCONTRACTOR
        updateSubcontractorRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_SUBCONTRACTOR)) {
                state.isLoading.push(Loadings.UPDATING_SUBCONTRACTOR);
            }
        },
        updateSubcontractorSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_SUBCONTRACTOR);
        },
        updateSubcontractorFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_SUBCONTRACTOR);
        },
        //#endregion

        //#region DELETE SUBCONTRACTOR
        deleteSubcontractorRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_SUBCONTRACTOR)) {
                state.isLoading.push(Loadings.DELETING_SUBCONTRACTOR);
            }
        },
        deleteSubcontractorSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_SUBCONTRACTOR);
            state.subcontractor = undefined;
            state.subcontractors = state.subcontractors.filter(s => s.id !== payload.id);
            if (!R.isNil(state.dashBoardSubcontractors)) {
                state.dashBoardSubcontractors = state.dashBoardSubcontractors.filter(s => s.id !== payload.id);
            }
        },
        deleteSubcontractorFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_SUBCONTRACTOR);
        },
        //#endregion
    }
});

export const subcontractorsActions = SubcontractorsReducer.actions;
export default SubcontractorsReducer.reducer;