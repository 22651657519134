/* eslint-disable jsx-a11y/iframe-has-title */
import './css/iframe.css'
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { useTheme } from "@mui/material";
const ModalReadPdf= ({ modalClose, open, file}) => {
	const theme = useTheme()

	const imgErrorFunction = (e) => {
        e.target.src = '/img/notfound.jpg';
    }

	const renderMedia = () => {
		if (file?.contentType?.includes("pdf")) {	
			return (<iframe src={file?.url} className='pdf-read'></iframe>)
		}
		else if(file?.contentType?.includes("image")) {
			return (
			<img src={file?.url} alt={file?.fileName} onError={imgErrorFunction} />)
		}
		return null;
	}

	return (
		<Dialog fullWidth maxWidth="lg" onClose={modalClose} open={open} theme={theme}>					
			<ImageListItem elevation={0} sx={{ position: "relative", display: "flex", flexDirection: "column", gap: 1, height: "100%" }}>
					<div style={{ overflow: "hidden", height: "500px", display: "flex", justifyContent: 'center' }}>
						{
							(file?.url) && renderMedia()
						}
					</div>
				
				<ImageListItemBar
					sx={{
						borderRadius: "inherit",
						background: "linear-gradient(to top, rgba(0,0,0,0.7) 0%, " + "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
					}}
					title={file?.fileName}
					position="bottom"
				/>
			</ImageListItem>
		</Dialog>
	);
};

export default ModalReadPdf;
