import React from 'react';

// Components
// Components: Global
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

// Components: Common

// Components: Views

export const FormNotes = ({ theme, title, value, handleOnChange, ...others }) => {


    return (
        <Stack direction="column" flex="1 1 auto" spacing={2} width="100%" {...others}>
            <Typography variant="label" color="text.secondary">{title ? title : 'Notes'}</Typography>
            <TextField
                className="rounded-0"
                multiline={true}
                rows={10}
                id="Presentation"
                type="text"
                fullWidth
                variant="filled"
                name="notes" 
                value={value}
                onChange={(event) => { handleOnChange(event); }}
            />
        </Stack>
    );
};

export default FormNotes;
