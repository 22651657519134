import axios from 'axios';
import * as R from 'ramda';
import { tenantsActions } from "../reducers/tenant";
import * as urls from '../constants/webapi/tenant';
import { urlBuilder } from '../constants/utils/urlBuilder';
import { PAGE_SIZE, SMALL_PAGE_SIZE } from '../constants/utils/pageSize';



export const GetTenants = ({ pageSize, pageNumber, sortBy, sortDesc, filter } = {}) => {
    const size = !R.isNil(pageSize) && !R.isEmpty(pageSize) ? pageSize : PAGE_SIZE;
    const page = !R.isNil(pageNumber) && !R.isEmpty(pageNumber) ? pageNumber : 1;
    const sort = !R.isNil(sortBy) && !R.isEmpty(sortBy) ? sortBy : undefined;
    const desc = !R.isNil(sortDesc) && !R.isEmpty(sortDesc) ? sortDesc : false;
    const filters = !R.isNil(filter) && !R.isEmpty(filter) ? urlBuilder(filter) : undefined;

    return async dispatch => {
        dispatch(tenantsActions.getTenantsRequest());
        return axios.get(urls.getTenantsUrl({ size, page, sort, desc, filters }))
            .then((response) => {
                dispatch(tenantsActions.getTenantsSuccess({
                    data: response.data,
                    pageSize: size,
                    pageNumber: page,
                    sortBy: sort,
                    sortDesc: desc
                }));
            })
            .catch((error) => {
                dispatch(tenantsActions.getTenantsFailure(error.response.data));
            });
    };
};

export const GetDashboardTenants = ({ pageSize, pageNumber, sortBy, sortDesc, filter } = {}) => {
    const params = { size: SMALL_PAGE_SIZE, page: 1, sort: "firstName", desc: false };

    return async dispatch => {
        dispatch(tenantsActions.getDashboardTenantsRequest());
        return axios.get(urls.getTenantsUrl(params))
            .then((response) => {
                dispatch(tenantsActions.getDashboardTenantsSuccess({
                    data: response.data,
                    pageSize: params.size,
                    pageNumber: params.page,
                    sortBy: params.sort,
                    sortDesc: params.desc
                }));
            })
            .catch((error) => {
                dispatch(tenantsActions.getDashboardTenantsFailure(error.response.data));
            });
    };
};

export const CreateTenant = (tenant) => {
    return async dispatch => {
        dispatch(tenantsActions.createTenantRequest());
        return axios.post(urls.getTenantUrl(), tenant)
            .then((response) => {
                dispatch(tenantsActions.createTenantSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(tenantsActions.createTenantFailure(error.response.data));
                throw error;
            });
    };
};

export const GetTenant = (tenantId) => {
    return async dispatch => {
        dispatch(tenantsActions.getTenantRequest());
        return axios.get(urls.getTenantByIdUrl(tenantId))
            .then((response) => {
                dispatch(tenantsActions.getTenantSuccess(response.data));
            })
            .catch((error) => {
                dispatch(tenantsActions.getTenantFailure(error.response.data));
            });
    };
};

export const UpdateTenant = (tenant) => {
    return async dispatch => {
        dispatch(tenantsActions.updateTenantRequest());
        return axios.put(urls.getTenantByIdUrl(tenant.id), tenant)
            .then((response) => {
                dispatch(tenantsActions.updateTenantSuccess(tenant));
                return response.data;
            })
            .catch((error) => {
                dispatch(tenantsActions.updateTenantFailure(error.response.data));
                throw error;
            });
    };
};

export const DeleteTenant = (tenantId) => {
    return async dispatch => {
        dispatch(tenantsActions.deleteTenantRequest());
        return axios.delete(urls.getTenantByIdUrl(tenantId))
            .then((response) => {
                dispatch(tenantsActions.deleteTenantSuccess({ id: tenantId }));
                return response.data;
            })
            .catch((error) => {
                dispatch(tenantsActions.deleteTenantFailure(error.response.data));
                throw error;
            });
    };
};