import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useLocation } from 'react-router-dom';
import * as R from 'ramda';
import { useForm } from "react-hook-form";

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import Logo from './../_common/logo';
import Loading from './../_common/loading';

import { confirmPasswordValidator, emailRegex, inputTypes, minLengthRegex } from "../constants/utils/validators";
import { RegisterUser } from "../actions/user-api";
import * as Loadings from '../constants/loadings/user';
import * as LoadingsTier from '../constants/loadings/tier';
import { tiers } from "../constants/utils/magicVariables";

import { GetTiers } from '../actions/tier-api';


export const Registry = () => {
    const { register, handleSubmit, getValues, formState: { errors } } = useForm();
    const location = useLocation();
    const userState = useSelector(state => state.user);
    const tierState = useSelector(state => state.tier);

    const dispatch = useDispatch();
    const [privacy, setPrivacy] = useState({ value: false, isRequired: true, isValid: false, error: undefined, });

    useEffect(() => {
        document.title = '😀 Créer votre compte Magestimmo';
        dispatch(GetTiers())

    }, []);

    const submitLoginForm = (data) => {
        const queries = new URLSearchParams(location.search);

        //When gettings tiers dynamically
        //let tiers = tierState.tiers || tiers;
        let tierType = tiers.find((t) => { return t.name == queries.get('tierType') }) || tiers.find((t) => { return t.name.toLowerCase() == 'free' });

        dispatch(RegisterUser({
            firstName: data.firstName?.trim(),
            lastName: data.lastName?.trim(),
            email: data.email?.trim(),
            password: data.password?.trim(),
            passwordConfirmation: data.passwordConfirm?.trim(),
            phoneNumber: data.phone,
            tierId: tierType.id
        }))
    };

    if (tierState.isLoading.includes(LoadingsTier.FETCHING_TIERS)) {
        return (
            <Loading />
        );
    }

    return (
			<Container maxWidth="lg" sx={{ m: "auto" }}>
				<Stack direction="column" flex="1 1 auto" justifyContent="center" alignItems="center" my={5} spacing={2}>
					<Logo />
					<Typography variant="h2" fontWeight="600" color="primary" lineHeight="1.2">
						Magestimmo
					</Typography>
				</Stack>
				<Stack direction="column" flex="1 1 auto" spacing={2} alignItems="center" sx={{ width: "40em", maxWidth: "100%", marginX: "auto" }} mb={5}>
					{userState.isRegistered ? (
						<Alert sx={{ width: "100%", borderRadius: 3, marginBottom: 2 }} severity="success">
							Veuillez consulter votre email et cliquer sur le lien envoyé pour confirmer la création de votre compte.
						</Alert>
					) : (
						<Box name="Registry" onSubmit={handleSubmit(submitLoginForm)} component="form" sx={{ width: "100%", maxWidth: "100%" }} alignItems="center" display="flex" flexDirection="column" noValidate autoComplete="off">
							<Stack flex="1 1 auto" alignItems="center" width="100%" sx={{ maxWidth: "100%", marginX: "auto", flexDirection: { xs: "column", md: "row" }, gap: 0.5 }} mb={0.5}>
								<Stack flex="1 1 auto" alignItems="center" width="100%" sx={{ maxWidth: "100%", marginX: "auto", flexDirection: { xs: "column", sm: "row" }, gap: 0.5 }}>
									<TextField
										fullWidth={true}
										label="Prénom"
										name="firstName"
										required={true}
										size="medium"
										variant="filled"
										inputProps={register("firstName", { required: inputTypes.required.errorMessage, validate: (v) => !!v.trim() })}
										error={!!errors.firstName}
										helperText={errors.firstName ? errors.firstName.message : ""}
									/>
									<TextField fullWidth={true} label="Nom" name="lastName" required={true} size="medium" variant="filled" inputProps={register("lastName", { required: inputTypes.required.errorMessage, validate: (v) => !!v.trim() })} error={!!errors.lastName} helperText={errors.lastName ? errors.lastName.message : ""} />
								</Stack>
								<TextField fullWidth={true} label="Téléphone" name="phone" type="tel" pattern="" size="medium" variant="filled" sx={{ maxWidth: { xs: "100%", md: "33.3%" } }} error={!!errors.phone} helperText={errors.phone ? errors.phone.message : ""} />
							</Stack>
							<Stack direction="column" flex="1 1 auto" mb={2} spacing={0.5} alignItems="center" width="100%" sx={{ maxWidth: "100%", marginX: "auto" }}>
								<TextField
									label="Email"
									name="email"
									fullWidth={true}
									required={true}
									size="medium"
									variant="filled"
									inputProps={register("email", { required: inputTypes.required.errorMessage, pattern: { value: emailRegex, message: inputTypes.email.errorMessage } })}
									error={!!errors.email}
									helperText={errors.email ? errors.email.message : ""}
								/>
								<Stack direction="row" flex="1 1 auto" alignItems="center" width="100%" sx={{ maxWidth: "100%", marginX: "auto", flexDirection: { xs: "column", sm: "row" }, gap: 0.5 }}>
									<TextField
										label="Mot de passe"
										name="password"
										fullWidth={true}
										validator={"password"}
										type="password"
										required={true}
										size="medium"
										variant="filled"
										inputProps={register("password", { required: inputTypes.required.errorMessage, pattern: { value: minLengthRegex, message: inputTypes.minLength.errorMessage, validate: (v) => !!v.trim() } })}
										error={!!errors.password}
										helperText={errors.password ? errors.password.message : ""}
									/>
									<TextField
										fullWidth={true}
										label="Confirmer le mot de passe"
										name="passwordConfirm"
										required={true}
										type="password"
										size="medium"
										variant="filled"
										inputProps={register("passwordConfirm", { required: inputTypes.required.errorMessage, validate: (value) => confirmPasswordValidator(value, getValues("password")).error })}
										error={!!errors.passwordConfirm}
										helperText={errors.passwordConfirm ? errors.passwordConfirm.message : ""}
									/>
								</Stack>
							</Stack>

							<Stack direction="column" flex="1 1 auto" alignItems="start" width="100%">
								<FormControlLabel
									required
									control={
										<Checkbox
											onChange={(e) => {
												setPrivacy({ ...privacy, value: e.target.checked, isValid: e.target.checked });
											}}
											color="success"
											size="medium"
										/>
									}
									label={
										<React.Fragment>
											J'ai lu et accepté la{" "}
											<a target="_blank" href="https://www.magestimmo.com/index.php/politique-de-confidentialite" variant="text" color="inherit" size="inherit" rel="noreferrer">
												politique de confidentialité
											</a>{" "}
											et les{" "}
											<a target="_blank" href="https://www.magestimmo.com/index.php/conditions-generales-dutilisation" variant="text" color="inherit" size="inherit" rel="noreferrer">
												conditions générales d'utilisation
											</a>
										</React.Fragment>
									}
								/>
								<Stack direction="column" spacing={2} flex="1 1 auto" justifyContent="space-between" alignItems="center" width="100%" my={2}>
									<LoadingButton disabled={!privacy.value || userState.isLoading.includes(Loadings.REGISTERING_USER)} loading={userState.isLoading.includes(Loadings.REGISTERING_USER)} type="submit" color="primary" fullWidth={true} variant="contained" size="large">
										Valider
									</LoadingButton>
									<Link to="/auth/login" component={RouterLink} color="secondary" underline="hover">
										C'est pas grave, <span style={{ fontWeight: "bold" }}>j'ai un compte</span>
									</Link>
									{R.isNil(userState.isRegistered)
										? null
										: userState.isRegistered === false && (
												<Alert sx={{ width: "100%", borderRadius: 3, marginBottom: 2 }} severity="error">
													Votre inscription a échoué, veuillez réessayer plus tard...
												</Alert>
										  )}
								</Stack>
							</Stack>
						</Box>
					)}
				</Stack>
			</Container>
		);
};

export default Registry;