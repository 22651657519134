import { createSlice } from '@reduxjs/toolkit';



const initState = {
    isLoadings: [],
    notification: undefined
};

const NotificationsReducer = createSlice({
    name: 'notification',
    initialState: { ...initState },
    reducers: {
        getNotificationsRequest(state, { payload }) { },
        getNotificationsSuccess(state, { payload }) { },
        getNotificationsFailure(state, { payload }) { },

        createNotificationRequest(state, { payload }) { },
        createNotificationSuccess(state, { payload }) { },
        createNotificationFailure(state, { payload }) { },

        getNotificationRequest(state, { payload }) { },
        getNotificationSuccess(state, { payload }) { },
        getNotificationFailure(state, { payload }) { },

        updateNotificationRequest(state, { payload }) { },
        updateNotificationSuccess(state, { payload }) { },
        updateNotificationFailure(state, { payload }) { },

        deleteNotificationRequest(state, { payload }) { },
        deleteNotificationSuccess(state, { payload }) { },
        deleteNotificationFailure(state, { payload }) { },
    }
});

export const notificationsActions = NotificationsReducer.actions;
export default NotificationsReducer.reducer;