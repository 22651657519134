import axios from 'axios';
import * as R from 'ramda';
import { rentalsActions } from "../reducers/rental";
import * as urls from '../constants/webapi/rental';
import { urlBuilder } from '../constants/utils/urlBuilder';
import { PAGE_SIZE } from '../constants/utils/pageSize';


export const GetRentals = ({ pageSize, pageNumber, sortBy, sortDesc, filter } = {}) => {
    const size = !R.isNil(pageSize) && !R.isEmpty(pageSize) ? pageSize : PAGE_SIZE;
    const page = !R.isNil(pageNumber) && !R.isEmpty(pageNumber) ? pageNumber : 1;
    const sort = !R.isNil(sortBy) && !R.isEmpty(sortBy) ? sortBy : undefined;
    const desc = !R.isNil(sortDesc) && !R.isEmpty(sortDesc) ? sortDesc : false;
    const filters = !R.isNil(filter) && !R.isEmpty(filter) ? urlBuilder(filter) : undefined;

    return async dispatch => {
        dispatch(rentalsActions.getRentalsRequest());
        return axios.get(urls.getRentalsUrl({ size, page, sort, desc, filters }))
            .then((response) => {
                dispatch(rentalsActions.getRentalsSuccess({
                    data: response.data,
                    pageSize: size,
                    pageNumber: page,
                    sortBy: sort,
                    sortDesc: desc
                }));
            })
            .catch((error) => {
                dispatch(rentalsActions.getRentalsFailure(error.response.data));
            });
    };
};

export const CreateRental = (rental) => {
    return async dispatch => {
        dispatch(rentalsActions.createRentalRequest());
        return axios.post(urls.getRentalUrl(rental.companyId), rental)
            .then((response) => {
                dispatch(rentalsActions.createRentalSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(rentalsActions.createRentalFailure(error.response.data));
                throw error;
            });
    };
};

export const GetRental = (companyId, rentalId) => {
    return async dispatch => {
        dispatch(rentalsActions.getRentalRequest());
        return axios.get(urls.getRentalByIdUrl(companyId, rentalId))
            .then((response) => {
                dispatch(rentalsActions.getRentalSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(rentalsActions.getRentalFailure(error.response.data));
            });
    };
};

export const UpdateRental = (rental) => {
    return async dispatch => {
        dispatch(rentalsActions.updateRentalRequest());
        return axios.put(urls.getRentalByIdUrl(rental.companyId, rental.id), rental)
            .then((response) => {
                dispatch(rentalsActions.updateRentalSuccess(rental));
            })
            .catch((error) => {
                dispatch(rentalsActions.updateRentalFailure(error.response.data));
                throw error;
            });
    };
};

export const DeleteRental = (companyId, rentalId) => {
    return async dispatch => {
        dispatch(rentalsActions.deleteRentalRequest());
        return axios.delete(urls.getRentalByIdUrl(companyId, rentalId))
            .then((response) => {
                dispatch(rentalsActions.deleteRentalSuccess({ id: rentalId }));
                return response.data;
            })
            .catch((error) => {
                dispatch(rentalsActions.deleteRentalFailure(error.response.data));
                throw error;
            });
    };
};