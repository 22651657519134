import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from 'ramda';


import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';
import Container from '@mui/material/Container';

import { onSubmitMultipleValidation, onSubmitValidation, validateTitle } from "../../../../constants/utils/validators";
import { initialCompanyForm, initialSubcontractorFormData } from "../../../../constants/utils/formInitialData";
import * as Loadings from '../../../../constants/loadings/subcontractor';
import * as LoadingsFile from '../../../../constants/loadings/file';
import { useAutoComplete } from "../../../../hooks/useAutoComplete";
import CompanyModalContent from "./companyModalcontent";
import { PARTICULAR } from "../../../../constants/utils/magicVariables";
import FormSubcontractor from "../info/subcontractor";
import { CreateSubcontractor } from "../../../../actions/subcontractor-api";
import { UploadProfilePhoto } from "../../../../actions/file-api";



const SubcontractorModalContent = ({ modalClose, open, theme, newTitle, handleAddNew, ...others }) => {
    const dispatch = useDispatch();
    const companyState = useSelector(state => state.company);
    const fileState = useSelector(state => state.file);
    const subcontractorState = useSelector(state => state.subcontractor);
    const subcontractors = !R.isNil(subcontractorState.subcontractors) ? subcontractorState.subcontractors : [];
    const [formData, setFormData] = useState(initialSubcontractorFormData);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [category, setCategory] = useState(PARTICULAR);
    const [hasCurrentTitle, setHasCurrentTitle] = useState(null);
    const payload = { items: subcontractors, properties: ["firstName", "lastName"], value: formData.firstName.value.trim() + formData.lastName.value.trim(), id: null };

    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    const companyUseAutocomplete = useAutoComplete([initialCompanyForm], companies, initialCompanyForm);

    useEffect(() => {
        setFormData({ ...formData, firstName: { ...formData.firstName, value: newTitle } });
        // eslint-disable-next-line 
    }, [newTitle]);


    const submitForm = async (event) => {
        const companyFormValidation = onSubmitMultipleValidation(companyUseAutocomplete.dropdownFormData);
        const validatedForm = onSubmitValidation(formData);
        if (validateTitle(payload)) {
            setHasCurrentTitle(true);
            return setTimeout(() => { setHasCurrentTitle(null); }, 4000);
        }
        if (!validatedForm.isValid || !companyFormValidation.isValid) {
            companyUseAutocomplete.setDropdownFormData(companyFormValidation.form);
            setFormData(validatedForm.form);
            return;
        }
        try {
            const subcontractor = {
                contactCategory: category,
                firstName: R.isEmpty(formData.firstName.value) ? null : formData.firstName.value.trim(),
                lastName: R.isEmpty(formData.lastName.value) ? null : formData.lastName.value.trim(),
                occupation: R.isEmpty(formData.profession.value) ? null : formData.profession.value.trim(),
                email: R.isEmpty(formData.email.value) ? null : formData.email.value.trim(),
                companyName: R.isEmpty(formData.social.value) ? null : formData.social.value.trim(),
                phoneNumber: R.isEmpty(formData.phone.value) ? null : formData.phone.value.trim(),
                files: [],
                address: null,
                companyIds: companyUseAutocomplete.dropdownFormData.map(c => c.value)
            };
            const newSubcontractor = await dispatch(CreateSubcontractor(subcontractor));
            if (!R.isNil(profilePhoto)) {
                await dispatch(UploadProfilePhoto({ file: profilePhoto, id: newSubcontractor.id, profile: "Subcontractor" }));
            }
            modalClose();
            handleAddNew({
                companyId: newSubcontractor.companyId,
                id: newSubcontractor.id,
                firstName: formData.firstName.value.trim() + " " + formData.lastName.value.trim()
            });
            setFormData(initialSubcontractorFormData);
        } catch (error) {
            console.log(error);
            // toast.error("Échec de l'opération, réessayez !", toastConf);
        }
    };


    return (
        <Box {...others}>
            {hasCurrentTitle &&
                <Container maxWidth="100%" sx={{ display: "inherit", justifyContent: "inherit", gap: 2 }}>
                    <Alert sx={{ width: '100%', borderRadius: 3, marginY: 2 }} severity="error">
                        Un sous-traitant existe déjà avec ce nom. Veuillez le sélectionner ou alors choisir un autre nom de sous-traitant.
                    </Alert>
                </Container>
            }
            <DialogTitle variant="h4" color="primary" sx={{ pt: theme.spacing(3), pb: theme.spacing(1) }}>Ajouter Sous-traitant</DialogTitle>
            <Stack direction="column" spacing={2}>
                <FormSubcontractor
                    companyUseAutocomplete={companyUseAutocomplete}
                    profilePhoto={profilePhoto}
                    category={category}
                    setCategory={setCategory}
                    setProfilePhoto={setProfilePhoto}
                    formData={formData}
                    setFormData={setFormData}
                    theme={theme}
                />
                {companyUseAutocomplete.isOpen &&
                    <Container>
                        <CompanyModalContent
                            modalClose={companyUseAutocomplete.setClose}
                            open={companyUseAutocomplete.isOpen}
                            theme={theme}
                            newCompanyName={companyUseAutocomplete.newOptionName}
                            handleAddNewCompany={companyUseAutocomplete.handleAddNew}
                            sx={{ backgroundColor: "background.dim", borderRadius: 2 }}
                        />
                    </Container>
                }
            </Stack>
            <DialogActions disableSpacing={true} sx={{ flexDirection: "column-reverse", p: theme.spacing(3) }}>
                <Button theme={theme} color="neutral" fullWidth onClick={modalClose} sx={{ px: theme.spacing(3) }}>Annuler</Button>
                <LoadingButton
                    onClick={(event) => submitForm(event)}
                    loading={subcontractorState.isLoading.includes(Loadings.CREATING_SUBCONTRACTOR) || fileState.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
                    disabled={subcontractorState.isLoading.includes(Loadings.CREATING_SUBCONTRACTOR) || fileState.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
                    type="submit" size="large" fullWidth variant="contained" theme={theme} color='primary' sx={{ px: theme.spacing(3), mb: theme.spacing(1) }}>Enregistrer</LoadingButton>
            </DialogActions>
        </Box>
    );
};

export default SubcontractorModalContent;