import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as R from 'ramda';

// Components
// Components: Global
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';

// Components: Common
import FormCompany from '../info/company';
import { onSubmitValidation, validateTitle } from '../../../../constants/utils/validators';
import { CreateCompany, UpdateCompany, GetDashboardCompanies } from '../../../../actions/company-api';
import * as Loadings from '../../../../constants/loadings/company';
import * as LoadingsFile from '../../../../constants/loadings/file';
import { UpdateUserPermission } from '../../../../actions/user-api';
import { permissionsTypeMap } from '../../../../constants/utils/objectProperties';

// Components: Views



export const MainCompany = ({ theme, companyState, companies, initialCompanyFormData, id }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stateFile = useSelector(state => state.file);
    const [formData, setFormData] = useState(initialCompanyFormData);
    const [hasCurrentTitle, setHasCurrentTitle] = useState(null);
    const payload = { items: companies, properties: ["title"], value: formData.title.value, id: id };


    const submitForm = async (event) => {
        event.preventDefault();
        const validatedForm = onSubmitValidation(formData);
        if (!validatedForm.isValid) {
            return setFormData(validatedForm.form);
        }
        if (validateTitle(payload)) {
            setHasCurrentTitle(true);
            return setTimeout(() => { setHasCurrentTitle(null); }, 4000);
        }
        try {
            let company = {
                title: formData.title.value.trim(),
                vatNumber: R.isNil(formData.vatNumber.value) || R.isEmpty(formData.vatNumber.value) ? null : formData.vatNumber.value.trim(),
                bankName: R.isNil(formData.bank.value) || R.isEmpty(formData.bank.value) ? null : formData.bank.value.trim(),
                url: R.isNil(formData.url.value) || R.isEmpty(formData.url.value) ? null : formData.url.value.trim(),
                legalStatus: R.isNil(formData.legalStatus.value) || R.isEmpty(formData.legalStatus.value) ? null : formData.legalStatus.value.trim()
            };
            if (R.isNil(id)) {
                company = await dispatch(CreateCompany(company));
                await dispatch(UpdateUserPermission({ companyId: company.id, accessTypes: permissionsTypeMap.Admin }));
            } else {
                company.id = id;
                await dispatch(UpdateCompany(company));
            }

            dispatch(GetDashboardCompanies());
            navigate(`/app/companies/${company.id}`);
        } catch (error) {
            console.log(error);
            // toast.error("Échec de l'opération, réessayez !", toastConf);
        }
    };



    return (
        <React.Fragment>
            {
                hasCurrentTitle &&
                <Container maxWidth="100%" sx={{ display: "inherit", justifyContent: "inherit", gap: 2 }}>
                    <Alert sx={{ width: '100%', borderRadius: 3, marginY: 2 }} severity="error">
                        Une société existe déjà avec ce nom. Veuillez la sélectionner ou alors choisir un autre nom de société.
                    </Alert>
                </Container>
            }
            <Stack spacing={2} component="form" onSubmit={(event) => submitForm(event)} flex="1 1 auto" width="100%" height="100%" overflow="hidden auto">
                <FormCompany theme={theme} mt={2} flex="1 1 auto" formData={formData} setFormData={setFormData} />
                <Stack direction={{ default: "column", md: "row" }} justifyContent="end" alignItems="center" pb={2} spacing={2}>
                    <Container maxWidth="sm" sx={{ display: "inherit", justifyContent: "inherit", flex: "1 1 auto", marginLeft: 0 }}>
                        <Typography variant="body2" color="text.secondary">Vous pourrez ajouter des documents relatifs à cet élément après l'avoir Enregistré, à l'aide du bouton + en bas de l'écran.</Typography>
                    </Container>
                    <Container sx={{ display: "inherit", justifyContent: "inherit", gap: 2, flex: "0" }}>
                        <Button color="text" onClick={() => navigate(-1)}>Annuler</Button>
                        <LoadingButton
                            loading={companyState.isLoading.includes(Loadings.CREATING_COMPANY) || companyState.isLoading.includes(Loadings.UPDATING_COMPANY) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
                            disabled={companyState.isLoading.includes(Loadings.CREATING_COMPANY) || companyState.isLoading.includes(Loadings.UPDATING_COMPANY) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
                            size="large" type="submit" variant="contained" color='primary'>
                            Enregistrer
                        </LoadingButton>
                    </Container>
                </Stack>
            </Stack>
        </React.Fragment>
    );
};

export default MainCompany;