import React, { useEffect, useState } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as R from "ramda";
import { format } from "date-fns";

// Components
// Components: Global
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Components: Common
import { GetCompanies } from "../../../../actions/company-api";
import { GetAssets } from "../../../../actions/asset-api";
import { GetAssetGroups } from "../../../../actions/assetGroup-api";
import { GetPayments } from "../../../../actions/payment-api";
import { GetDocument, DeleteDocument } from "../../../../actions/document-api";
import { GetTenants } from "../../../../actions/tenant-api";
import { GetRentals } from "../../../../actions/rental-api";

import { dayMonthYear } from "../../../../constants/utils/date";
import { paymentTypes } from "../../../../constants/utils/objectProperties";
import * as DocumentLoadings from "../../../../constants/loadings/document";

import Loading from "../../../../_common/loading";
import FileRow from "../../../_common/fileRow";
import ModalDelete from "../../../_common/modalDelete";
import { useDeleteFile } from "../../../../hooks/useDeleteFile";

// Components: View
import DocumentsViewDetailHeader from "./_common/header";
import DocumentsViewEmpty from "../empty";
import { usePermissionModal } from "../../../../hooks/usePermissionModal";

export const DocumentsViewDetail = ({ theme, userState, companyState, tenantState, assetState, paymentState, assetGroupState, subcontractorState, collaboratorState, documentState, rentalState, fileState }) => {
	const { companyId, id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [open, setOpen] = useState(false);
	const [openDeleteFile, setOpenDeleteFile] = useState(false);
	const [filesExpanded, setFilesExpanded] = useState("panel-files", true);

	const { dispatchDelete, isLoading, isDisabled } = useDeleteFile();
	const { openPermissionModal } = usePermissionModal();

	const deleteRentalProp = async () => {
		await dispatch(DeleteDocument(documentState.document.companyId, documentState.document.id));
		navigate("/app/documents");
	};
	const deleteFileProp = async () => {
		// if(!selectedFile) return;
		// await dispatch(DeleteFiles([selectedFile], selectedFile.id, 'Rental'));
	};

	let item = documentState.document;

	if (item && fileState.files) {
		fileState.files = item?.files || [];
	}

	const modalOpenProfile = () => {
		setOpen(true);
	};
	const modalCloseProfile = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (R.isNil(companyState.companies) || R.isEmpty(companyState.companies)) {
			dispatch(GetCompanies());
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		dispatch(GetDocument(companyId, id));

		if (R.isNil(assetState.assets)) {
			dispatch(GetAssets());
		}
		if (R.isNil(tenantState.tenants)) {
			dispatch(GetTenants());
		}
		if (R.isNil(assetGroupState.assetGroups)) {
			dispatch(GetAssetGroups());
		}
		if (R.isNil(paymentState.payments)) {
			dispatch(GetPayments());
		}
		if (R.isNil(rentalState.rentals)) {
			dispatch(GetRentals());
		}
		// eslint-disable-next-line
	}, [companyId, id]);

	const permissions = userState.user.companyAccesses.find((c) => c.companyId === companyId)?.accessTypes || [];
	const handleAccordionChange = (panel) => (event, isExpanded) => {
		switch (panel) {
			default:
			case "panel-files": {
				setFilesExpanded(isExpanded ? panel : false);
				break;
			}
		}
	};
	return documentState.isLoading.includes(DocumentLoadings.FETCHING_DOCUMENT) ? (
		<Loading />
	) : (
		<React.Fragment>
			<DocumentsViewDetailHeader openPermissionModal={openPermissionModal} permissions={permissions} theme={theme} modalOpenProfile={modalOpenProfile} documentState={documentState} tenantState={tenantState} companyState={companyState} assetState={assetState} companyId={companyId} />
			{R.isNil(documentState.document) || R.isEmpty(documentState.document) ? (
				<DocumentsViewEmpty color="danger" title="Ooops..." description="Quelque chose s'est mal passée. On dirait que ce contrat n'existe pas !" />
			) : (
				<Container maxWidth="100%" sx={{ height: "100%" }}>
					<Stack maxWidth="100%" my={3} direction="column" spacing={4}>
						<Stack direction="column" spacing={1}>
							<Typography variant="label" color="text.secondary">
								Détail
							</Typography>
							<Stack direction="column" spacing={0.5}>
								<Stack direction={{ xs: "column", lg: "row" }} spacing={0.5}>
									<Typography variant="body1">
										Categorie:{" "}
										{!R.isNil(item.contractCategory) || !R.isNil(item.contractCategory) ? (
											<Typography component="span" fontWeight="bold">
												{document.documentType === 'Rental' ? 'Contrat' : document.documentType === 'Payment' ? 'Paiement' :
                            						document.contractCategory === 1 ? 'Juridique' : document.contractCategory === 2 ? 'Impôts' : document.contractCategory === 3 ? 'Personnel' : "Comptable"}
											</Typography>
										) : (
											<Typography component="span" color="text.hint">
												Le champ est vide
											</Typography>
										)}
									</Typography>

									<Typography sx={{ lineHeight: 1 }} display={{ default: "none", lg: "inherit" }} variant="h6" component="p" px={3} color="text.hint">
										•
									</Typography>

									<Typography variant="body1">
										Mots clefs:{" "}
										{!R.isNil(item.keywords) ? (
											<Typography component="span" fontWeight="bold">
												{item.keywords}
											</Typography>
										) : (
											<Typography component="span" color="text.hint">
												Le champ est vide
											</Typography>
										)}
									</Typography>
								</Stack>
								<Stack direction={{ xs: "column", hd: "row" }} spacing={0.5}>
									<Stack direction={{ xs: "column", lg: "row" }} spacing={0.5}>
										<Typography variant="body1">
										Notes:{" "}
											{!R.isNil(item.description) ? (
												<Typography component="span" fontWeight="bold">
													{item.description}
												</Typography>
											) : (
												<Typography component="span" color="text.hint">
													Le champ est vide
												</Typography>
											)}
										</Typography>
									</Stack>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
					<Divider sx={{ my: 5 }} />
					<Accordion sx={{ backgroundColor: "transparent", boxShadow: "none", border: "none", p: 0, ":before": { backgroundColor: "inherit" } }} disableGutters expanded={filesExpanded === "panel-files"} onChange={handleAccordionChange("panel-files")}>
						<AccordionSummary sx={{ minHeight: "auto", ".MuiAccordionSummary-content": { my: 1 }, p: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
								<Typography variant="h6" sx={{ lineHeight: 1 }}>
									Pièce jointe
								</Typography>
						</AccordionSummary>
						<AccordionDetails sx={{ p: 0 }}>
							<Stack maxWidth="100%" direction="column" spacing={1}>
								{!R.isNil(documentState.document.files) && item.files.length ? (
									<Stack direction="column" spacing={1}>
										{item.files.map((file) => (
											<FileRow permissions={permissions} openPermissionModal={openPermissionModal} key={file.id} setOpenDeleteFile={setOpenDeleteFile} file={file} fileType="Rental" companyId={companyId} />
										))}
									</Stack>
								) : permissions.includes("Update") ? (
									<Button fullWidth component={RouterLink} to={"/app/edit/contrat/" + item.companyId + "/" + item.id} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
										Ajouter une pièce jointe
									</Button>
								) : (
									<Button fullWidth onClick={openPermissionModal} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
										Ajouter une pièce jointe
									</Button>
								)}
							</Stack>
						</AccordionDetails>
					</Accordion>
				</Container>
			)}
			<ModalDelete
				theme={theme}
				companyState={companyState}
				isLoading={documentState.isLoading.includes(DocumentLoadings.DELETING_DOCUMENT)}
				isDisabled={documentState.isLoading.includes(DocumentLoadings.DELETING_DOCUMENT)}
				item={documentState.document}
				documentState={documentState}
				modalClose={() => {
					setOpen(false);
				}}
				dispatchDelete={deleteRentalProp}
				open={open}
			/>

			<ModalDelete
				theme={theme}
				item={documentState.document?.files?.find((f) => f.id === openDeleteFile?.id)}
				type="file"
				title={documentState.document?.files?.find((f) => f.id === openDeleteFile?.id)?.fileName}
				isLoading={isLoading}
				isDisabled={isDisabled}
				dispatchDelete={dispatchDelete(openDeleteFile, "Rental", companyId)}
				modalClose={() => {
					setOpenDeleteFile(null);
				}}
				open={!!openDeleteFile}
			/>
		</React.Fragment>
	);
};

export default DocumentsViewDetail;
