import React, { useEffect, useState } from "react";
import { Link as RouterLink, useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import * as R from "ramda";

// Components
// Components: Global
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Components: Common
import { DeleteSoldAsset, GetSoldAsset } from "../../../../actions/asset-api";
import { GetAssetGroups } from "../../../../actions/assetGroup-api";
import { GetTenants } from "../../../../actions/tenant-api";
import { GetRentals } from "../../../../actions/rental-api";
import { GetDocuments } from "../../../../actions/document-api";
import { GetPayments } from "../../../../actions/payment-api";
import * as AssetLoadings from "../../../../constants/loadings/asset";
import { GetCompanies } from "../../../../actions/company-api";
import { dayMonthYear } from "../../../../constants/utils/date";
import { paymentTypes } from "../../../../constants/utils/objectProperties";
import Loading from "../../../../_common/loading";
import ItemCard from "../../../_common/itemCard";
import AssetsViewDetailEmpty from "../empty";

// Components: Views
import AssetsViewDetailHeader from "./_common/header";
import ModalDelete from "../../../_common/modalDelete";
import DocumentRow from "../../../_common/documentRow";
import { useDeleteDocument } from "../../../../hooks/useDeleteDocument";
import { EQUAL_QUERY } from "../../../../constants/utils/filterQuery";
import { usePermissionModal } from "../../../../hooks/usePermissionModal";
import { useTheme } from '@mui/material/styles';

export const AssetsViewDetailVendu = ({ companyState, tenantState, assetState, subcontractorState, collaboratorState, assetGroupState, documentState, rentalState }) => {
	const theme = useTheme()
 	const { companyId, id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const showView = !pathname.endsWith("/vendu");
	const [open, setOpen] = useState(false);
	const [openDeleteDoc, setOpenDeleteDoc] = useState(false);
	const [rentalsExpanded, setRentalsExpanded] = useState("panel-rentals", true);
	const [docsExpanded, setDocsExpanded] = useState("panel-docs", true);
	const { dispatchDelete, isLoading, isDisabled } = useDeleteDocument();
	const { openPermissionModal } = usePermissionModal();
	const userState = useSelector((state) => state.user);

	useEffect(() => {
		if (R.isNil(companyState.companies) || R.isNil(companyState.companies)) {
			dispatch(GetCompanies());
		}
		if (R.isNil(tenantState.tenants)) {
			dispatch(GetTenants());
		}
		if (R.isNil(assetGroupState.assetGroups)) {
			dispatch(GetAssetGroups());
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		dispatch(GetSoldAsset(companyId, id));
		dispatch(GetRentals({ filter: { [EQUAL_QUERY.assetId]: id } }));
		dispatch(GetDocuments({ filter: { [EQUAL_QUERY.assetId]: id } }));
		dispatch(GetPayments({ filter: { [EQUAL_QUERY.assetId]: id } }));
		// dispatch(GetOccurrences({ occurrenceType: 'asset', companyId, occurrenceTypeId: id }));
		// eslint-disable-next-line
	}, [companyId, id]);

	const deleteAssetProp = async () => {
		await dispatch(DeleteSoldAsset(assetState.soldAsset.companyId, assetState.soldAsset.id));
		navigate("/app/assets/vendu");
	};

	const permissions = userState.user.companyAccesses.find((c) => c.companyId === companyId)?.accessTypes || [];
	const handleAccordionChange = (panel) => (event, isExpanded) => {
		switch (panel) {
			default:
			case "panel-rentals": {
				setRentalsExpanded(isExpanded ? panel : false);
				break;
			}
			case "panel-docs": {
				setDocsExpanded(isExpanded ? panel : false);
				break;
			}
		}
	};

	return (
		<Box
			display={{ default: showView ? "flex" : "none", lg: "flex" }}
			flexDirection="column"
			height="100%"
			width="100%"
			overflow="hidden auto"
			backgroundColor={theme.palette.background.default}
			sx={{
				borderRadius: 2,
				position: { default: showView && "fixed", lg: "relative" },
				left: { default: showView && 0, lg: "unset" },
				right: { default: showView && 0, lg: "unset" },
				top: { default: showView && "5rem", lg: "unset" },
				height: { default: showView && "calc(100% - 5rem)", lg: "inherit" },
				bottom: { default: showView && 0, lg: "unset" },
				zIndex: { default: showView && 2, lg: "unset" },
			}}>
			{assetState.isLoading.includes(AssetLoadings.FETCHING_SOLD_ASSET) ? (
				<Loading />
			) : (
				<React.Fragment>
					<AssetsViewDetailHeader
						permissions={permissions}
						openPermissionModal={openPermissionModal}
						theme={theme}
						isVendu
						modalOpenDelete={() => {
							setOpen(true);
						}}
						open={open}
						userState={userState}
						companyId={companyId}
						companyState={companyState}
						assetState={assetState}
						assetGroupState={assetGroupState}
						tenantState={tenantState}
						subcontractorState={subcontractorState}
						collaboratorState={collaboratorState}
					/>
					{R.isNil(assetState.soldAsset) || R.isEmpty(assetState.soldAsset) ? (
						<AssetsViewDetailEmpty title="Ooops..." description="Quelque chose s'est mal passée. On dirait que ce bien n'existe pas !" />
					) : (
						<React.Fragment>
							<Container maxWidth="100%" sx={{ height: "100%" }}>
								<Stack maxWidth="100%" my={3} direction="column" spacing={4}>
									<Accordion sx={{ backgroundColor: "transparent", boxShadow: "none", border: "none", p: 0, ":before": { backgroundColor: "inherit" } }} disableGutters expanded={rentalsExpanded === "panel-rentals"} onChange={handleAccordionChange("panel-rentals")}>
										<AccordionSummary sx={{ minHeight: "auto", ".MuiAccordionSummary-content": { my: 1 }, p: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
											<Typography variant="h6" sx={{ lineHeight: 1 }}>
												Locations
											</Typography>
										</AccordionSummary>
										<AccordionDetails sx={{ p: 0 }}>
											<Stack maxWidth="100%" direction="column" spacing={1}>
												{!R.isNil(rentalState.rentals) && !R.isEmpty(rentalState.rentals) && (
													<Stack direction="column" spacing={1}>
														{rentalState.rentals
															.filter((r) => r.assetId === assetState.soldAsset.id)
															.map((rental) => (
																<ItemCard key={rental.id} to={`/app/contrats/${rental.companyId}/${rental.id}`} component={RouterLink}>
																	<Avatar variant="circular" children={rental.title.charAt(0)} sx={{ bgcolor: "grey.A200", my: 0.25, width: "3rem", height: "3rem", fontWeight: "bold" }} />
																	<Stack direction="column" spacing={{ default: 0.5, xl: 0 }} flex="1 1 auto" justifyContent={{ lg: "space-between" }}>
																		<Stack direction={{ default: "column", xl: "row" }} spacing={{ xl: 4 }} flex="1 1 auto" justifyContent={{ lg: "space-between" }}>
																			<Typography fontWeight="medium">{rental.title}</Typography>
																			{rental.paymentType !== 0 && (
																				<Typography color="text.secondary" variant="body1">
																					Modalité de paiement:{" "}
																					<Box component="span" color="text.dark">
																						{paymentTypes[rental.paymentType]}
																					</Box>
																				</Typography>
																			)}
																		</Stack>
																		<Stack direction={{ default: "column", hd: "row" }} spacing={{ xl: 1 }} flex="1 1 auto" justifyContent={{ hd: "space-between" }} alignItems={{ hd: "center" }}>
																			<Typography color="text.secondary" flex="1 1 auto" variant="body1">
																				Signature:
																				<Box component="span" color="text.dark">
																					{!R.isNil(rental.startDate) && format(new Date(rental.startDate), dayMonthYear)}
																				</Box>
																			</Typography>
																			<Stack direction={{ default: "column", lg: "row" }} flex="1 1 auto" spacing={{ default: 1, lg: 4 }} justifyContent={{ lg: "space-between" }}>
																				<Typography color="text.secondary" variant="body1">
																					Loyer sans taxe:{" "}
																					<Box component="span" color="text.dark">
																						{rental.rentPriceWithoutTax} €
																					</Box>
																				</Typography>
																				<Typography color="text.secondary" variant="body1">
																					Loyer:{" "}
																					<Box component="span" color="text.dark">
																						{rental.rentPriceWithoutTax ? rental.rentPriceWithoutTax + "€" : "N/A"}{" "}
																					</Box>
																					{/* Loyer sans Taxes: <Box component="span" color="text.dark">{rental.rentPriceWithoutTax ? rental.rentPriceWithoutTax + '€' : 'N/A'} </Box> */}
																				</Typography>
																			</Stack>
																		</Stack>
																	</Stack>
																</ItemCard>
															))}
													</Stack>
												)}
												{permissions.includes("Create") ? (
													<Button fullWidth component={RouterLink} to={`/app/new/contrat?assetId=${assetState.asset.id}&companyId=${assetState.asset.companyId}&origin=assetDetail`} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
														Ajouter un Contrat de location
													</Button>
												) : (
													<Button fullWidth onClick={openPermissionModal} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
														Ajouter un Contrat de location
													</Button>
												)}
											</Stack>
										</AccordionDetails>
									</Accordion>
									<Divider />
									<Accordion sx={{ backgroundColor: "transparent", boxShadow: "none", border: "none", p: 0, ":before": { backgroundColor: "inherit" } }} disableGutters expanded={docsExpanded === "panel-docs"} onChange={handleAccordionChange("panel-docs")}>
										<AccordionSummary sx={{ minHeight: "auto", ".MuiAccordionSummary-content": { my: 1 }, p: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
											<Typography variant="h6" sx={{ lineHeight: 1 }}>
												Documents
											</Typography>
										</AccordionSummary>
										<AccordionDetails sx={{ p: 0 }}>
											<Stack maxWidth="100%" direction="column" spacing={1}>
												{!R.isNil(documentState.documents) && (
													<Stack direction="column" spacing={1}>
														{documentState.documents
															.filter((r) => r.assetId === assetState.soldAsset?.id && r.documentType === "Document")
															.map((document) => (
																<DocumentRow permissions={permissions} openPermissionModal={openPermissionModal} key={document.id} setOpenDeleteDoc={setOpenDeleteDoc} document={document} theme={theme} />
															))}
													</Stack>
												)}
												{permissions.includes("Create") ? (
													<Button fullWidth component={RouterLink} to={`/app/new/document?assetId=${assetState.asset.id}&companyId=${assetState.asset.companyId}&origin=assetDetail`} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
														Ajouter un document
													</Button>
												) : (
													<Button fullWidth onClick={openPermissionModal} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
														Ajouter un document
													</Button>
												)}
											</Stack>
										</AccordionDetails>
									</Accordion>
								</Stack>
							</Container>
							<ModalDelete
								theme={theme}
								isVendu
								item={assetState.soldAsset}
								isLoading={assetState.isLoading.includes(AssetLoadings.DELETING_SOLD_ASSET)}
								isDisabled={assetState.isLoading.includes(AssetLoadings.DELETING_SOLD_ASSET)}
								type="soldAsset"
								dispatchDelete={deleteAssetProp}
								modalClose={() => {
									setOpen(false);
								}}
								open={open}
							/>
							<ModalDelete
								theme={theme}
								item={documentState.documents?.find((d) => d.id === openDeleteDoc)}
								type="document"
								isLoading={isLoading}
								isDisabled={isDisabled}
								dispatchDelete={dispatchDelete(openDeleteDoc)}
								modalClose={() => {
									setOpenDeleteDoc(null);
								}}
								open={!!openDeleteDoc}
							/>
						</React.Fragment>
					)}
				</React.Fragment>
			)}
		</Box>
	);
};

export default AssetsViewDetailVendu;
