import React, { useEffect, useState } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as R from "ramda";

// Components
// Components: Global
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// Components: Common
import { DeleteSubcontractor, GetSubcontractor } from "../../../../actions/subcontractor-api";
import { GetCompanies } from "../../../../actions/company-api";
import { GetPayments } from "../../../../actions/payment-api";
import { GetDocuments } from "../../../../actions/document-api";

import * as SubcontractorLoadings from "../../../../constants/loadings/subcontractor";

import Loading from "../../../../_common/loading";
import ItemCard from "../../../_common/itemCard";

// Components: View
import SubcontractorsViewDetailHeader from "./_common/header";
import SubcontractorsViewEmpty from "../empty";
import { useDeleteDocument } from "../../../../hooks/useDeleteDocument";
import DocumentRow from "../../../_common/documentRow";
import ModalDelete from "../../../_common/modalDelete";
import { usePermissionModal } from "../../../../hooks/usePermissionModal";
import { useDeletePayment } from "../../../../hooks/useDeletePayment";
import { TransactionRow } from "../../../_common/transactionRow";

export const SubcontractorsViewDetail = ({ theme, userState, companyState, tenantState, assetState, assetGroupState, subcontractorState, collaboratorState, documentState, rentalState, paymentState }) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [docsExpanded, setDocsExpanded] = useState("panel-docs", true);
	const [paymentsExpanded, setPaymentsExpanded] = useState("panel-payments", true);
	const [openTransaction, setOpenTransaction] = useState({});
	const [optionsMenu, setOptionsMenu] = useState(null);
	const [openDeleteDoc, setOpenDeleteDoc] = useState(false);
	const [openDeletePay, setOpenDeletePay] = useState(false);
	const [selectedPayment, setSelectedPayment] = useState(false);
	const deleteDocument = useDeleteDocument();
	const deletePayment = useDeletePayment();
	const { openPermissionModal } = usePermissionModal();
	const showMe = false;
	const optionsMenuClick = (e) => {
		e.preventDefault();
		setOptionsMenu(e.currentTarget);
	};
	const optionsMenuClose = () => {
		setOptionsMenu(null);
	};

	useEffect(() => {
		if (R.isNil(companyState.companies) || R.isEmpty(companyState.companies)) {
			dispatch(GetCompanies());
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		dispatch(GetSubcontractor(id));
		dispatch(GetDocuments({ filter: { "SubcontractorId=": id } }));
		dispatch(GetPayments({ filter: { "SubcontractorId=": id } }));
		// eslint-disable-next-line
	}, [id]);

	const toggleTransaction = (item) => {
		setOpenTransaction({
			...openTransaction,
			[item]: !openTransaction[item],
		});
	};

	const deleteSubcontractorProp = async () => {
		await dispatch(DeleteSubcontractor(subcontractorState.subcontractor.id));
		navigate("/app/subcontractors");
	};

	if (subcontractorState.isLoading.includes(SubcontractorLoadings.FETCHING_SUBCONTRACTOR)) {
		return <Loading />;
	}
	if (R.isNil(subcontractorState.subcontractor) || R.isEmpty(subcontractorState.subcontractor)) {
		return <SubcontractorsViewEmpty color="danger" title="Ooops..." description="Quelque chose s'est mal passée. On dirait que ce sous-traitant n'existe pas !" />;
	}

	const permissions = userState.user.companyAccesses.reduce((acc, c) => {
		if (subcontractorState.subcontractor.companyIds.includes(c.companyId)) return [...acc, ...c.accessTypes];
		return acc;
	}, []);

	const handleAccordionChange = (panel) => (event, isExpanded) => {
		switch (panel) {
			default:
			case "panel-docs": {
				setDocsExpanded(isExpanded ? panel : false);
				break;
			}
			case "panel-payments": {
				setPaymentsExpanded(isExpanded ? panel : false);
				break;
			}
		}
	};

	return (
		<React.Fragment>
			<React.Fragment>
				<SubcontractorsViewDetailHeader openPermissionModal={openPermissionModal} permissions={permissions} theme={theme} modalOpenProfile={() => setOpen(true)} item={subcontractorState?.subcontractor} companyState={companyState} />
				<Container maxWidth="100%" sx={{ height: "100%" }}>
					<Stack maxWidth="100%" my={3} direction="column" spacing={4}>
						<Stack maxWidth="100%" my={3} direction="column" spacing={1}>
							<Typography variant="label" color="text.secondary">
								Associé à
							</Typography>
							{!R.isNil(companyState.companies) && !R.isEmpty(companyState.companies) && (
								<Stack direction="column" spacing={1}>
									{companyState.companies
										.filter((p) => subcontractorState.subcontractor.companyIds.includes(p.id))
										.map((item, index) => (
											<ItemCard key={index} padding={1} backgroundColor="background.dim" borderRadius={2} elevation={0} component={RouterLink} to={"/app/companies/" + item.id}>
												<Avatar variant="rounded" children={item.title.split(" ")[0].charAt(0) + (!R.isNil(item.title.split(" ")[1]) ? item.title.split(" ")[1].charAt(0) : "")} sx={{ bgcolor: "background.paper", color: "text.dark", fontWeight: "bold" }} />
												<Stack direction="row" spacing={2} flex="1 1 auto" justifyContent="space-between">
													<Typography color="text.dark" variant="body1">
														{item.title}
													</Typography>
												</Stack>
												<Stack direction="row" spacing={0} justifyContent="space-between">
													<Avatar variant="rounded" sx={{ bgcolor: "transparent", fontWeight: "bold" }}>
														<ChevronRightIcon sx={{ color: "text.secondary" }} />
													</Avatar>
												</Stack>
											</ItemCard>
										))}
								</Stack>
							)}
						</Stack>
						<Divider />
						<Accordion sx={{ backgroundColor: "transparent", boxShadow: "none", border: "none", p: 0, ":before": { backgroundColor: "inherit" } }} disableGutters expanded={docsExpanded === "panel-docs"} onChange={handleAccordionChange("panel-docs")}>
							<AccordionSummary sx={{ minHeight: "auto", ".MuiAccordionSummary-content": { my: 1 }, p: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
								<Typography variant="h6" sx={{ lineHeight: 1 }}>
									Documents
								</Typography>
							</AccordionSummary>
							<AccordionDetails sx={{ p: 0 }}>
								<Stack maxWidth="100%" direction="column" spacing={1}>
									{!R.isNil(documentState.documents) && (
										<Stack direction="column" spacing={1}>
											{documentState.documents
												.filter((r) => r.subcontractorId === subcontractorState.subcontractor.id)
												.map((document) => (
													<DocumentRow permissions={permissions} openPermissionModal={openPermissionModal} key={document.id} setOpenDeleteDoc={setOpenDeleteDoc} document={document} theme={theme} />
												))}
										</Stack>
									)}
									{permissions.includes("Create") ? (
										<Button fullWidth component={RouterLink} to={`/app/new/document?subcontractorId=${subcontractorState.subcontractor.id}&companyId=${subcontractorState.subcontractor.companyIds[0]}&origin=subcontractorDetail`} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
											Ajouter un document
										</Button>
									) : (
										<Button fullWidth onClick={openPermissionModal} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
											Ajouter un document
										</Button>
									)}
								</Stack>
							</AccordionDetails>
						</Accordion>
						<Divider />
						<Accordion sx={{ backgroundColor: "transparent", boxShadow: "none", border: "none", p: 0, ":before": { backgroundColor: "inherit" } }} disableGutters expanded={paymentsExpanded === "panel-payments"} onChange={handleAccordionChange("panel-payments")}>
							<AccordionSummary sx={{ minHeight: "auto", ".MuiAccordionSummary-content": { my: 1 }, p: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
								<Typography variant="h6" sx={{ lineHeight: 1 }}>
									Transactions
								</Typography>
							</AccordionSummary>
							<AccordionDetails sx={{ p: 0 }}>
								<Stack maxWidth="100%" direction="column" spacing={1}>
									{!R.isNil(paymentState.payments) && (
										<Stack direction="column" spacing={1}>
											{paymentState.payments
												.filter((p) => subcontractorState.subcontractor.companyIds.includes(p.companyId))
												.map((payment) => (
													<TransactionRow
														key={payment.id}
														setOpenDeletePay={setOpenDeletePay}
														permissions={permissions}
														openPermissionModal={openPermissionModal}
														payment={payment}
														assetState={assetState}
														subcontractorState={subcontractorState}
														tenantState={tenantState}
														companyState={companyState}
														setSelectedPayment={setSelectedPayment}
													/>
												))}
										</Stack>
									)}
									{permissions.includes("Create") ? (
										<Button fullWidth component={RouterLink} to={`/app/new/payment?subcontractorId=${subcontractorState.subcontractor.id}&companyId=${subcontractorState.subcontractor.companyIds[0]}&origin=subcontractorDetail`} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
											Ajouter un paiement
										</Button>
									) : (
										<Button fullWidth onClick={openPermissionModal} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
											Ajouter un paiement
										</Button>
									)}
								</Stack>
							</AccordionDetails>
						</Accordion>
					</Stack>
				</Container>
			</React.Fragment>
			<ModalDelete
				theme={theme}
				companyState={companyState}
				rentalState={rentalState}
				item={subcontractorState.subcontractor}
				title={subcontractorState.subcontractor?.firstName + " " + subcontractorState.subcontractor?.lastName}
				isLoading={subcontractorState.isLoading.includes(SubcontractorLoadings.DELETING_SUBCONTRACTOR)}
				isDisabled={subcontractorState.isLoading.includes(SubcontractorLoadings.DELETING_SUBCONTRACTOR)}
				modalClose={() => {
					setOpen(false);
				}}
				dispatchDelete={deleteSubcontractorProp}
				open={open}
			/>
			<ModalDelete
				theme={theme}
				item={documentState.documents?.find((d) => d.id === openDeleteDoc)}
				type="document"
				isLoading={deleteDocument.isLoading}
				isDisabled={deleteDocument.isDisabled}
				dispatchDelete={deleteDocument.dispatchDelete(openDeleteDoc)}
				modalClose={() => {
					setOpenDeleteDoc(null);
				}}
				open={!!openDeleteDoc}
			/>
			<ModalDelete
				theme={theme}
				item={selectedPayment}
				type="payment"
				isLoading={deletePayment.isLoading}
				isDisabled={deletePayment.isDisabled}
				dispatchDelete={deletePayment.dispatchDelete(selectedPayment)}
				modalClose={() => {
					setOpenDeletePay(null);
				}}
				open={openDeletePay}
			/>
		</React.Fragment>
	);
};

export default SubcontractorsViewDetail;
