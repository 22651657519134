import React from 'react';
import * as R from 'ramda';

// Components
// Components: Global
import Typography from '@mui/material/Typography';

// Components: Common
import { permissionsType } from '../../../../constants/utils/objectProperties';

// Components: Views
import SearchViewEmpty from './../../empty';


export const SharedAccessList = ({ collaborators, companies }) => {
    return (
        !R.isNil(collaborators) ? collaborators.map(colab => {
            const ids = colab.companyAccesses.map(u => u.companyId);
            const companiesDatas = !R.isNil(collaborators) ? companies.filter(c => ids.find(i => c.id === i)) : [];
            return (
                companiesDatas.map(item => (
                    <React.Fragment>
                        <Typography variant="h6" fontWeight="normal">{colab.firstName} {colab.lastName}</Typography>
                        <Typography variant="subtitle2" text={item.title} />
                        <Typography variant="body2">
						{permissionsType[colab.companyAccesses.find(c => c.companyId === item.id).accessTypes]}
						</Typography>
                    </React.Fragment>
                ))
            );
        })
		:
		<SearchViewEmpty title="Aucun Partages d'accès!" description="Veuillez vérifier que le mot est correctement écrit, effacez votre recherche et réessayez..." />
		// <p>Partages d'accès (empty State)</p>
    );
};

export default SharedAccessList;
