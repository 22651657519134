import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Components
// Components: Global
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

// Components: Common
import LogoIcon from '../assets/brand/Masgestimmo-icon.svg';
import LogoDark from '../assets/brand/Masgestimmo-punchout.svg';
import LogoLight from '../assets/brand/Masgestimmo.svg';

const Logo = ({ activeMode, extendedLogo, navbar, setWidth, responsive }) => {
    return (
        <Box name="logo" sx={{ display: responsive && { default: 'none', md: 'flex' }, marginLeft: navbar && 0, borderRadius: navbar && 1, backgroundColor: navbar && "white", padding: navbar && 1, width: setWidth ? setWidth : navbar ? '3em' : '8em', maxWidth: '100%' }} m="auto" alignItems='center' display="flex" flexDirection="column" noValidate autoComplete="off">
            <Link underline="none" component={RouterLink} to="/app" style={{ textAlign: "inherit", display: "flex" }}>
                <img src={navbar || !extendedLogo ? LogoIcon : activeMode !== 'dark' ? LogoLight : LogoDark} alt="logo" height="100%" width="100%" fit="none" style={{ width: '100%', height: 'auto' }} />
            </Link>
        </Box>
    );
};

export default Logo;
