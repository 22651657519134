import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { GetCompanies, GetCompany } from '../../actions/company-api';
import * as LoadingsCompany from '../../constants/loadings/company';
import Loading from '../../_common/loading';
import { initialCompanyFormData } from '../../constants/utils/formInitialData';
import MainCompany from '../_common/forms/layout/company';
import { useParams } from 'react-router';



export const EditCompany = ({ theme, companyState }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        dispatch(GetCompany(id));

        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        // eslint-disable-next-line 
    }, []);


    if (companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)) return <Loading theme={theme} />;

    if (R.isNil(companyState.company) || R.isEmpty(companyState.company) || R.isNil(companyState.companies)) {
        return '';
    }

    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];

    const tempInitialCompanyFormData = R.clone(initialCompanyFormData);
    tempInitialCompanyFormData.title.value = !R.isNil(companyState.company.title) && !R.isEmpty(companyState.company.title) ? companyState.company.title : "";
    tempInitialCompanyFormData.legalStatus.value = !R.isNil(companyState.company.legalStatus) && !R.isEmpty(companyState.company.legalStatus) ? companyState.company.legalStatus : "";
    tempInitialCompanyFormData.vatNumber.value = !R.isNil(companyState.company.vatNumber) && !R.isEmpty(companyState.company.vatNumber) ? companyState.company.vatNumber : "";
    tempInitialCompanyFormData.url.value = !R.isNil(companyState.company.url) && !R.isEmpty(companyState.company.url) ? companyState.company.url : "";;
    tempInitialCompanyFormData.bank.value = !R.isNil(companyState.company.bankName) && !R.isEmpty(companyState.company.bankName) ? companyState.company.bankName : "";;

    return (
        <MainCompany
            theme={theme}
            initialCompanyFormData={tempInitialCompanyFormData}
            companyState={companyState}
            companies={companies}
            id={id}
        />
    );
};

export default EditCompany;;