import React from 'react';
import * as R from 'ramda';

// Components
// Components: Global
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

// Components: Common

// Components: Views

export const FormOptions = ({ theme, options, value, fieldName, formData, setFormData, formLabel, ...others }) => {
    const getLabel = formLabel ? formLabel : "Categorie";
    // const optionsToDisplay = options ? options : ['Juridique', 'Impôt', 'Personnel', "Comptable"];
    // const [value, setValue] = useState(optionsToDisplay[0]);

    const handleClick = (event) => {
        let tempFormData = R.clone(formData);
        tempFormData[fieldName].value = event.target.value;
        setFormData(tempFormData);
    };

    return (
        formLabel ?
            <Stack direction="column" flex="1 1 auto" spacing={1} width="100%" {...others}>
                <Typography variant="label" color="text.secondary">{getLabel}</Typography>
                <RadioGroup row
                    aria-labelledby="demo-docType-label"
                    onChange={handleClick}
                    value={value}
                    name="docType">
                    {Object.keys(options).map((key, index) => (
                        <FormControlLabel
                            value={key}
                            key={index}
                            label={options[key]}
                            control={<Radio color="secondary" />}
                        />
                    ))}
                </RadioGroup>
            </Stack>
            :
            <RadioGroup row
                aria-labelledby="demo-docType-label"
                onChange={handleClick}
                value={value}
                name="docType">
                {Object.keys(options).map((key, index) => (
                    <FormControlLabel
                        value={key}
                        key={index}
                        label={options[key]}
                        control={<Radio color="secondary" />}
                    />
                ))}
            </RadioGroup>

    );
};

export default FormOptions;
