import { createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';
import * as Loadings from '../constants/loadings/company';
import { SMALL_PAGE_SIZE } from '../constants/utils/pageSize';


const initState = {
    isLoading: [],
    companies: undefined,
    dashBoardCompanies: undefined,
    company: undefined
};

const CompaniesReducer = createSlice({
    name: 'company',
    initialState: { ...initState },
    reducers: {
        //#region GET COMPANIES
        getCompaniesRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_COMPANIES)) {
                state.isLoading.push(Loadings.FETCHING_COMPANIES);
            }
        },
        getCompaniesSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COMPANIES);
            state.companies = payload.data;
        },
        getCompaniesFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COMPANIES);
        },

        getDashboardCompaniesRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_COMPANIES)) {
                state.isLoading.push(Loadings.FETCHING_COMPANIES);
            }
        },
        getDashboardCompaniesSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COMPANIES);
            state.dashBoardCompanies = payload.data;
        },
        getDashboardCompaniesFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COMPANIES);
        },
        //#endregion

        //#region CREATE COMPANY
        createCompanyRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_COMPANY)) {
                state.isLoading.push(Loadings.CREATING_COMPANY);
            }
        },
        createCompanySuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_COMPANY);
            if (R.isNil(state.companies)) {
                state.companies = [];
            }
            if (!R.isNil(state.dashBoardCompanies) && state.dashBoardCompanies.length < SMALL_PAGE_SIZE) {
                state.dashBoardCompanies.push(payload);
            }
            if (R.isNil(state.dashBoardCompanies)) {
                state.dashBoardCompanies = [];
                state.dashBoardCompanies.push(payload);
            }
            state.companies.push(payload);
        },
        createCompanyFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_COMPANY);
            state.companies.push(payload);
        },
        //#endregion

        //#region GET COMPANY
        getCompanyRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_COMPANY)) {
                state.isLoading.push(Loadings.FETCHING_COMPANY);
            }
        },
        getCompanySuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COMPANY);
            state.company = payload;
        },
        getCompanyFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COMPANY);
        },
        //#endregion

        //#region UPDATE COMPANY
        updateCompanyRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_COMPANY)) {
                state.isLoading.push(Loadings.UPDATING_COMPANY);
            }
        },
        updateCompanySuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_COMPANY);
            state.company = payload;
            state.companies = state.companies.map(c => {
                if (c.id === payload.id) {
                    return payload;
                }
                return c;
            });
        },
        updateCompanyFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_COMPANY);
        },
        //#region 

        //#region DELETE COMPANY
        deleteCompanyRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_COMPANY)) {
                state.isLoading.push(Loadings.DELETING_COMPANY);
            }
        },
        deleteCompanySuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_COMPANY);
            state.company = undefined;
            state.companies = state.companies.filter(c => c.id !== payload.id);
            if (!R.isNil(state.dashBoardCompanies)) {
                state.dashBoardCompanies = state.dashBoardCompanies.filter(c => c.id !== payload.id);
            }
        },
        deleteCompanyFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_COMPANY);
        },
        //#region 

        //#region EXPORT COMPANY
        exportCompanyRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.EXPORTING_COMPANY)) {
                state.isLoading.push(Loadings.EXPORTING_COMPANY);
            }
        },
        exportCompanySuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.EXPORTING_COMPANY);
        },
        exportCompanyFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.EXPORTING_COMPANY);
        },
        //#region 
    }
});

export const companiesActions = CompaniesReducer.actions;
export default CompaniesReducer.reducer;