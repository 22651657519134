export const announcementDocumentTypes = {
    1: "Pièce d'identitée",
    2: 'Contrat de travail',
    3: '3 derniers bulletins de salaire',
};

export const assetState = { // asset sold or still available
    1: 'Actif',
    3: 'Vendu'
};

export const associationType = {
    1: "Bien",
    2: "Immeuble",
    3: "Comptabilité",
    4: "Locataire",
    5: "Collaborateur",
    6: "Sous-traitant",
    7: "Intervention"
};

export const categoryType = {
    1: 'Juridique',
    2: 'Impôt',
    3: 'Personnel',
    4: 'Comptable',
    // 5: 'Contrat de location'
};

export const duration = {
    1: 'Courte durée',
    2: 'Longue durée'
};

export const outflowTypes = {
    1: 'Travaux',
    2: 'Divers',
};

export const paymentCategories = {
    1: 'Loyer',
    2: 'Caution',
    3: 'Facture',
    4: 'Crédit',
    // 5: "Autre"
};

export const bankMovementsFrequences = {
    1: 'Annuel',
    2: 'Mensuel',
    3: 'Hebdomadaire',
    4: 'Journellement',
    5: 'Ponctuel',
};

export const paymentTypes = {
    1: 'Virement',
    2: 'Prélèvement',
    3: 'CB',
    4: 'Espèces'
};

export const permissionsType = {
    Admin: "Admin",
    Delete: "Co-propriétaire (ajout, modification et suppression d'informations)",
    Update: "Écriture (ajout et modification d'informations)",
    Create: "Créer",
    Read: 'Lecture'
};
export const permissionsTypeMap = {
    Admin: ["Read", "Create", "Update", "Delete", "Admin"],
    Delete: ["Read", "Create", "Update", "Delete"],
    Update: ["Read", "Create", "Update"],
    Create: ["Read", "Create"],
    Read: ["Read"]
};

export const documentType = {
    contract: "Contract",
    rental: "Rental",
    payment: "Payment"
};

export const sortBy = {
    asc: 'Ascending',
    desc: 'Descending'
};

export const availableServicesNaming = {
    googleDrive: 'GoogleDrive'
};

export const entitiesNames = {
    tenants: 'Locataires',
    assetGroups: 'Immeubles',
    subcontractors: 'Sous-traitants',
    resources: 'Documents',
    occurrences: 'Occurrences',
    companies: 'Comptabilités / Sociétés',
    assets: 'Biens',
	colaborators: 'Collaborateurs'
};


export const getKeyByValue = (obj, value) => Object.keys(obj).find(key => obj[key] === value);