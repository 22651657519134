import { createSlice } from '@reduxjs/toolkit';
import * as Loadings from '../constants/loadings/auth';



const initState = {
    isLoading: [],
    user: undefined,
    isLoggedIn: undefined,
    recoverPassword: undefined,
    forgotPasswordEmail: undefined,
    isTokenValidated: undefined,
    error: {}
};

const AuthReducer = createSlice({
    name: 'auth',
    initialState: { ...initState },
    reducers: {
        //#region USER LOGIN
        loginRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_LOGIN)) {
                state.isLoading.push(Loadings.FETCHING_LOGIN);
            }
            state.isLoggedIn = undefined;
        },
        loginSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_LOGIN);
            state.isLoggedIn = true;
        },
        loginFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_LOGIN);
            state.isLoggedIn = false;
        },
        //#endregion

        //#region USER LOGOUT 
        logoutRequest(state, { payload }) { },
        logoutSuccess(state, { payload }) { },
        logoutFailure(state, { payload }) { },
        //#endregion

        //#region GET TOKEN VALIDATION
        getTokenValidationRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_VALIDATION_TOKEN)) {
                state.isLoading.push(Loadings.FETCHING_VALIDATION_TOKEN);
            }
            state.isTokenValidated = undefined;
        },
        getTokenValidationSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_VALIDATION_TOKEN);
            state.isTokenValidated = true;
        },
        getTokenValidationFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_VALIDATION_TOKEN);
            state.isTokenValidated = false;
        },
        //#endregion

        //#region FORGOT PASSWORD
        forgotPasswordRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_FORGOT_PASSWORD)) {
                state.isLoading.push(Loadings.FETCHING_FORGOT_PASSWORD);
            }
            state.forgotPasswordEmail = undefined;
        },
        forgotPasswordSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_FORGOT_PASSWORD);
            state.forgotPasswordEmail = true;
        },
        forgotPasswordFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_FORGOT_PASSWORD);
            state.forgotPasswordEmail = false;
        },
        //#endregion

        //#region UPDATE FORGOT PASSWORD
        updatePasswordRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_FORGOT_PASSWORD)) {
                state.isLoading.push(Loadings.UPDATING_FORGOT_PASSWORD);
            }
            state.recoverPassword = undefined;
        },
        updatePasswordSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_FORGOT_PASSWORD);
            state.recoverPassword = true;
        },
        updatePasswordFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_FORGOT_PASSWORD);
            state.recoverPassword = false;
            state.error = payload.errors;
        },
        //#endregion
    }
});

export const authActions = AuthReducer.actions;
export default AuthReducer.reducer;