import { createSlice } from '@reduxjs/toolkit';
import * as Loadings from '../constants/loadings/occurrence';



const initState = {
    isLoading: [],
    occurrences: undefined,
    occurrence: undefined
};

const OccurrencesReducer = createSlice({
    name: 'occurrence',
    initialState: { ...initState },
    reducers: {
        //#region GET OCCURRENCES
        getOccurrencesRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_OCCURRENCES)) {
                state.isLoading.push(Loadings.FETCHING_OCCURRENCES);
            }
        },
        getOccurrencesSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_OCCURRENCES);
            state.occurrences = payload;
        },
        getOccurrencesFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_OCCURRENCES);
        },
        //#endregion

        //#region CREATE OCCURRENCE
        createOccurrenceRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_OCCURRENCE)) {
                state.isLoading.push(Loadings.CREATING_OCCURRENCE);
            }
        },
        createOccurrenceSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_OCCURRENCE);
        },
        createOccurrenceFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_OCCURRENCE);
        },
        //#endregion

        //#region GET OCCURRENCE
        getOccurrenceRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_OCCURRENCE)) {
                state.isLoading.push(Loadings.FETCHING_OCCURRENCE);
            }
        },
        getOccurrenceSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_OCCURRENCE);
            state.occurrence = payload;
        },
        getOccurrenceFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_OCCURRENCE);
        },
        //#endregion

        //#region UPDATE OCCURRENCE
        updateOccurrenceRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_OCCURRENCE)) {
                state.isLoading.push(Loadings.UPDATING_OCCURRENCE);
            }
        },
        updateOccurrenceSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_OCCURRENCE);
        },
        updateOccurrenceFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_OCCURRENCE);
        },
        //#endregion

        //#region DELETE OCCURENCE
        deleteOccurrenceRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_OCCURRENCE)) {
                state.isLoading.push(Loadings.DELETING_OCCURRENCE);
            }
        },
        deleteOccurrenceSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_OCCURRENCE);
        },
        deleteOccurrenceFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_OCCURRENCE);
        },
        //#endregion
    }
});

export const occurrencesActions = OccurrencesReducer.actions;
export default OccurrencesReducer.reducer;