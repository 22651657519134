import axios from "axios";


export default class Auth {

    static setAccessToken(token) {
        if (token) {
             this.accessToken = token;
             axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
             sessionStorage.setItem('accessToken', token);
        } else {
            this.accessToken = sessionStorage.getItem('accessToken');
            this.userName = sessionStorage.getItem('userName');
            axios.defaults.headers.common['Authorization'] = `Bearer ${this.accessToken}`;
        }
    }

    static getAccessToken() {
        if (!this.accessToken) {
            this.accessToken = sessionStorage.getItem('accessToken');
        }
        return this.accessToken;
    }

    static clearAccessToken() {
        this.accessToken = undefined;
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('userName');
        axios.defaults.headers.common['Authorization'] = "";
    }

}