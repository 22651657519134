import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

// Components: Common
import * as LoadingsCompany from '../../constants/loadings/company';
import * as LoadingsTenant from '../../constants/loadings/tenant';
import { initialCompanyForm, initialPasswordFormData, initialTenantForm } from '../../constants/utils/formInitialData';
import { GetCompanies } from '../../actions/company-api';
import { GetTenants } from '../../actions/tenant-api';
import Loading from '../../_common/loading';
import MainPassword from '../_common/forms/layout/password';
import { GetAccounts } from '../../actions/account-api';


export const NewPassword = ({ theme, companyState, tenantState, accountState }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (R.isNil(accountState.accounts)) {
            dispatch(GetAccounts());
        }
        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        if (R.isNil(tenantState.tenants)) {
            dispatch(GetTenants());
        }
        // eslint-disable-next-line 
    }, []);

    if (tenantState.isLoading.includes(LoadingsTenant.FETCHING_TENANTS) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)) {
        return <Loading theme={theme} />;
    }
    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let tenants = !R.isNil(tenantState.tenants) ? tenantState.tenants.map(t => ({ ...t, title: t.firstName + " " + t.lastName })) : [];
    let accounts = !R.isNil(accountState.accounts) ? accountState.accounts : [];

    return (
        <MainPassword
            theme={theme}
            accountState={accountState}
            accounts={accounts}
            tenants={tenants}
            companies={companies}
            initialCompanyForm={[initialCompanyForm]}
            initialTenantForm={[{ ...initialTenantForm, isRequired: true }]}
            initialPasswordFormData={initialPasswordFormData}
            resetCompanyForm={initialCompanyForm}
            resetPasswordFormData={initialPasswordFormData}
            resetTenantForm={{ ...initialTenantForm, isRequired: true }}
        />
    );
};

export default NewPassword;