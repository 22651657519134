import React, { useState } from "react";
import Dropzone from "react-dropzone";

// Components
// Components: Global
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { imageTypes } from "../../../../constants/utils/filesTypes";

export const FormUpload = ({
  theme,
  assetAttachements,
  photos,
  setPhotos,
  type,
  ...others
}) => {
  const [maxSize, setMaxSize] = useState(false);
  const FILE_LIMITE_MB = 4000; //MEGABITE
  const ONE_MEGABYTE = 1024;

  const validateMaxFileSize = (files) =>
    Array.from(files).some((file) => file.size / ONE_MEGABYTE > FILE_LIMITE_MB);

  const uploadImages = (images) => {
    const img = Array.from(images).filter((file) =>
      imageTypes.includes(file.type)
    );
    if (img) {
      setPhotos((p) => (p ? p.concat(img) : img));
    }
  };

  //  const uploadImages = (images) => setPhotos((p) => p.concat(Array.from(images).filter((file) => imageTypes.includes(file.type))));

  const uploadDocuments = (documents) => {
    var docs = Array.from(documents).filter((d) => {
      return d.type === "application/pdf";
    });
    if (docs.length) setPhotos((prev) => [...prev, ...docs]);
  };

  const addFiles = (acceptedFiles) => {
    if (validateMaxFileSize(acceptedFiles)) {
      setMaxSize(true);
      return setTimeout(() => {
        setMaxSize(false);
      }, 5000);
    }

    var pdfs = Array.from(acceptedFiles).filter((f) => {
      return f.type === "application/pdf";
    });
    var imgs = Array.from(acceptedFiles).filter((f) => {
      return f.type !== "application/pdf";
    });
    uploadImages(imgs);
    uploadDocuments(pdfs);
  };

  return (
    <Dropzone onDrop={addFiles} noClick>
      {({ getRootProps, getInputProps }) => (
        <Card
          sx={{
            overflow: "unset",
            paddingTop: 5.5,
            paddingBottom: 5,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            width: "100%",
            boxShadow: theme.shadows[1],
          }}
          {...others}
        >
          <Container
            {...getRootProps()}
            maxWidth="100%"
            spacing={0}
            sx={{
              alignItems: "center",
              justifyContent: "space-evenly",
              flexDirection: "column",
            }}
          >
            <Stack
              direction="column"
              alignItems="center"
              textAlign="center"
              spacing={2}
            >
              <Stack
                alignItems="center"
                textAlign="center"
                justifyContent="center"
              >
                <Typography
                  variant="h2"
                  color="grey.A200"
                  sx={{ lineHeight: "0" }}
                >
                  <CloudUploadIcon fontSize="inherit" color="inherit" />
                </Typography>
                <Stack
                  direction={{ sm: "row" }}
                  alignItems="center"
                  textAlign="center"
                  spacing={1}
                >
                  <Typography variant="body1" color="">
                    Glissez/déplacez un fichier
                  </Typography>
                  {/* <Typography variant="body1" color="">Glissez/déplacez  {type === "asset" ? "une image" : "un document"}</Typography> */}
                  <Typography variant="body1" color="text.secondary">
                    ou
                  </Typography>
                  <Box
                    color="secondary.main"
                    component="label"
                    sx={{ 
                      boxShadow: "none",
                      cursor: "pointer",
                    }}
                  >
                    Parcourir ici ...
                    <input
                      hidden
                      multiple={true}
                      accept={"image/*,application/pdf"}
                      type="file"
                      onChange={(e) => {
                        addFiles(e.target.files);
                        e.target.value = "";
                      }}
                    />
                  </Box>
                </Stack>
              </Stack>
              {assetAttachements && (
                <Stack
                  direction="row"
                  alignItems="center"
                  textAlign="center"
                  spacing={1}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    component="label"
                    sx={{ boxShadow: "none" }}
                  >
                    Importez le PDF du bien
                    <input
                      hidden
                      accept="application/pdf"
                      multiple
                      type="file"
                    />
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    component="label"
                    sx={{ boxShadow: "none" }}
                  >
                    Importez votre image de profil
                    <input
                      hidden
                      accept="application/img"
                      multiple
                      type="file"
                    />
                  </Button>
                </Stack>
              )}
              {maxSize ? (
                <Typography
                  variant="body2"
                  color="grey"
                  maxWidth="sm"
                  style={{ color: "red" }}
                >
                  {type === "asset"
                    ? "Largeur minimale de 1 600 pixels recommandée."
                    : ""}{" "}
                  Le fichier est trop volumineux. Veuillez compresser votre
                  fichier ou choisir un autre fichier.
                </Typography>
              ) : (
                <Typography variant="body2" color="grey" maxWidth="sm">
                  {type === "asset"
                    ? "Largeur minimale de 1 600 pixels recommandée."
                    : ""}{" "}
                  Max 4 Mo
                </Typography>
              )}
            </Stack>
          </Container>
        </Card>
      )}
    </Dropzone>
  );
};

export default FormUpload;
