import { createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';
import * as Loadings from '../constants/loadings/tenant';
import { PAGE_SIZE, SMALL_PAGE_SIZE } from '../constants/utils/pageSize';


const initState = {
    isLoading: [],
    tenants: undefined,
    dashBoardTenants: undefined,
    hasMorePage: false,
    tenant: undefined
};

const TenantsReducer = createSlice({
    name: 'tenant',
    initialState: { ...initState },
    reducers: {
        //#region GET TENANTS
        getTenantsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_TENANTS)) {
                state.isLoading.push(Loadings.FETCHING_TENANTS);
            }
        },
        getTenantsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_TENANTS);
            state.tenants = payload.data;
            state.hasMorePage = payload.data.length > PAGE_SIZE;
        },
        getTenantsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_TENANTS);
        },

        getDashboardTenantsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_TENANTS)) {
                state.isLoading.push(Loadings.FETCHING_TENANTS);
            }
        },
        getDashboardTenantsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_TENANTS);
            state.dashBoardTenants = R.clone(payload.data).splice(0, 4);
        },
        getDashboardTenantsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_TENANTS);
        },
        //#endregion

        //#region CREATE TENANT
        createTenantRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_TENANT)) {
                state.isLoading.push(Loadings.CREATING_TENANT);
            }
        },
        createTenantSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_TENANT);
            if (R.isNil(state.tenants)) {
                state.tenants = [];
            }
            if (!R.isNil(state.dashBoardTenants) && state.dashBoardTenants.length < SMALL_PAGE_SIZE) {
                state.dashBoardTenants.push(payload);
            }
            if (R.isNil(state.dashBoardTenants)) {
                state.dashBoardTenants = [];
                state.dashBoardTenants.push(payload);
            }
            state.tenants.push(payload);
        },
        createTenantFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_TENANT);
        },
        //#endregion

        //#region GET TENANT
        getTenantRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_TENANT)) {
                state.isLoading.push(Loadings.FETCHING_TENANT);
            }
        },
        getTenantSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_TENANT);
            state.tenant = payload;
        },
        getTenantFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_TENANT);
        },
        //#endregion

        //#region UPDATE TENANT
        updateTenantRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_TENANT)) {
                state.isLoading.push(Loadings.UPDATING_TENANT);
            }
        },
        updateTenantSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_TENANT);
        },
        updateTenantFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_TENANT);
        },
        //#endregion

        //#region DELETE TENANT
        deleteTenantRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_TENANT)) {
                state.isLoading.push(Loadings.DELETING_TENANT);
            }
        },
        deleteTenantSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_TENANT);
            state.tenant = undefined;
            state.tenants = state.tenants.filter(t => t.id !== payload.id);
            if (!R.isNil(state.dashBoardTenants)) {
                state.dashBoardTenants = state.dashBoardTenants.filter(t => t.id !== payload.id);
            }
        },
        deleteTenantFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_TENANT);
        },
        //#endregion
    }
});

export const tenantsActions = TenantsReducer.actions;
export default TenantsReducer.reducer;