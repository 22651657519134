import React, { Fragment } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import * as R from 'ramda';

// Components
// Components: Global
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

// Components: Common
import ItemCard from '../../../../_common/itemCard';
import { dayMonthYear } from '../../../../../constants/utils/date';
import { normalize } from '../../../../../constants/utils/normalize';
import { Box, Divider } from '@mui/material';


export const AssetsMenuViewList = ({ theme, soldAssets, companies, lastCardRef }) => {
    let hasInitials = {};
    const location = useLocation();

    return (
        <Fragment>
            {
                R.sortBy(R.compose(R.toLower, R.prop('title')))(soldAssets).map((item, index) => {
                    const firstLetter = normalize(item.title[0]).toUpperCase();
                    hasInitials[firstLetter] = hasInitials[firstLetter] || 0;
                    hasInitials[firstLetter] += 1;

                    return (
                        <Stack direction="column" flex="1 1 auto" width="100%" key={item.id} spacing={1}>
                            {
                                hasInitials[firstLetter] === 1 &&
                                <Box component="header" position="relative" width="100%" >
                                    <Typography variant="body1" fontWeight="bold" component="h3" color="primary" lineHeight="1.2" backgroundColor={theme.palette.background.default} sx={{ position: 'relative', display: 'inline', zIndex: "1", paddingRight: 1 }}>{firstLetter}</Typography>
                                    <Divider sx={{ position: 'absolute', left: 0, right: 0, top: '50%' }} />
                                </Box>
                            }
                            <ItemCard alignItems="top" to={`/app/assets/vendu/${item.companyId}/${item.id}` + location.search} component={RouterLink} element={index === soldAssets.length - 1 ? lastCardRef : undefined}>
								<Avatar variant="circular" sx={{backgroundColor: R.isNil(item.profileUrl?.url) ? "grey.A300" :  "background.paper",  width: '3rem', height: '3rem', fontWeight: 'bold', border: ".0625rem solid", borderColor: (R.isNil(item.profileUrl?.url) ? "transparent" : "grey.main") }} imgProps={{ sx: { borderRadius: 'inherit' } }} children={!R.isNil(item.title) ? item.title.charAt(0) : (item.firstName.charAt(0) + item.lastName.charAt(0))} srcSet={!R.isNil(item.profileUrl?.url) ? item.profileUrl?.url : null} />
								<Stack direction="column" flex="1 1 auto" width="100%" gap={.5} flexWrap="wrap">
									<Stack direction="row" flex="1 1 auto" gap={.5} width="100%" flexWrap="wrap">
										<Typography flex="1 1 auto" variant="text" fontWeight="bold" sx={{ letterSpacing: -.3 }}>{item.title}</Typography>
										<Typography fontWeight="bold" color="danger.main" variant="caption" textTransform="uppercase">Vendu</Typography>
                                    </Stack>
									<Stack direction={{ md: "row", lg: "column", xl: "row" }} gap={.5} justifyContent="space-between" flexWrap="wrap" className="truncated">
										<Box display="inherit" alignItems="center" gap={.5} flexWrap="wrap" maxWidth="60%">
											<AccountBalanceWalletIcon fontSize="small" sx={{ lineHeight: "1" }} />
											<Typography color="text.main" variant="body2" className="truncate">
                                                {!R.isNil(companies) && !R.isEmpty(companies) ?
                                                    companies.find(c => c.id === item.companyId).title
                                                    :
                                                    'N/D'
                                                }
                                            </Typography>
                                        </Box>
                                		<Typography textAlign={{ md: "right", lg: "left", xl: "right" }} className="truncate" color="text.secondary" variant="body2" fontStyle="italic"  maxWidth="6.25rem">
                                            {!R.isNil(item.modifiedOn) ?
                                                format(new Date(item.modifiedOn), dayMonthYear)
                                                :
                                                'DD/MM/YYYY'
                                            }
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </ItemCard>
                        </Stack>
                    );
                })
            }
        </Fragment>
    );
};

export default AssetsMenuViewList;
