import React from "react";
import { useForm } from "react-hook-form";

// Components
// Components: Global
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";

// Components: Common
import { inputTypes } from "../../constants/utils/validators";


const ModalDelete = ({ modalClose, assetState, isDisabled, isLoading, open, theme, item, isImmeuble, isVendu, type, dispatchDelete, title }) => {
    const { register, handleSubmit, resetField, formState: { errors } } = useForm();


    const submitForm = async (data) => {
        try {
            await dispatchDelete();
            resetField('name');
            modalClose();
        } catch (error) { 
            // toast.error("Échec de l'opération, réessayez !", toastConf);
        }
    };
    

    return (
        // R.isNil(item) ?
        //     <Dialog fullWidth maxWidth="xs" onClose={modalClose} open={open} theme={theme}>
        //         <Loading />
        //     </Dialog>
        //     :
        <Dialog component="form" onSubmit={handleSubmit(submitForm)} fullWidth maxWidth="xs" onClose={modalClose} open={open} theme={theme}>
            <DialogTitle variant="h4" color="danger.main" sx={{ pt: 3, pb: 1, lineHeight: 1.2, fontWeight: "normal", letterSpacing: -.5 }}>Effacer <br /><strong color="text.main">{item ? item.title ? item.title : title : "" }</strong> ?</DialogTitle>
            <DialogContent>
                <DialogContentText variant="body" color="text.primary">
                    Veuillez confirmer cette action. <strong>Cette action NE PEUT PAS être annulée</strong>.
                </DialogContentText>
                <Box maxWidth={true} width="100%" style={{ gap: 1 }} mt={2} sx={{ '.MuiInputBase-root': { borderRadius: 4 } }}>
                    {/* <Typography>Veuillez saisir le mot "Supprimer" pour continuer :</Typography> */}
                    <Stack direction="row" spacing={1}>
                        <TextField
                            label='Veuillez saisir le mot "Supprimer" pour continuer.'
                            id="delete"
                            type="text"
                            fullWidth
                            variant="filled"
                            name="name"
                            inputProps={register("name", { required: inputTypes.required.errorMessage, validate: { validate: value => value.toLowerCase().trim() === 'supprimer' || "Cela ne correspond pas" } })}
                            error={!!errors.name}
                            helperText={errors.name ? errors.name.message : ''}
                        />
                    </Stack>
                </Box>
            </DialogContent>
            <DialogActions disableSpacing={true} sx={{ flexDirection: "column-reverse", p: theme.spacing(3), pt: 0 }}>
                <Button theme={theme} color="neutral" fullWidth onClick={modalClose} sx={{ px: theme.spacing(3) }}>Annuler</Button>
                <LoadingButton disabled={isDisabled} loading={isLoading} type="submit" size="large" fullWidth variant="contained" theme={theme} color="danger" sx={{ px: theme.spacing(3), mb: theme.spacing(1) }}>Oui! Je veux supprimer</LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ModalDelete;