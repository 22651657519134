import React from 'react';


// Components
// Components: Global
// Components: Common
// Components: Views
import AssetsViewDetail from './detail';


export const AssetsView = ({  companyState, tenantState, assetState, assetGroupState, subcontractorState, collaboratorState, documentState, rentalState, accountState, paymentState }) => {
     
    return (
		<AssetsViewDetail  companyState={companyState} tenantState={tenantState} assetState={assetState} assetGroupState={assetGroupState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} documentState={documentState} rentalState={rentalState} accountState={accountState} paymentState={paymentState}/>
    );
};

export default AssetsView;
