import { createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';
import * as Loadings from '../constants/loadings/document';



const initState = {
    isLoading: [],
    documents: undefined,
    document: undefined
};

const DocumentsReducer = createSlice({
    name: 'document',
    initialState: { ...initState },
    reducers: {
        //#region GET COMPANIES
        getDocumentsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_DOCUMENTS)) {
                state.isLoading.push(Loadings.FETCHING_DOCUMENTS);
            }
        },
        getDocumentsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_DOCUMENTS);
            state.documents = payload.data;
        },
        getDocumentsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_DOCUMENTS);
        },
        //#region 

        //#region CREATE DOCUMENT
        createDocumentRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_DOCUMENT)) {
                state.isLoading.push(Loadings.CREATING_DOCUMENT);
            }
        },
        createDocumentSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_DOCUMENT);
        },
        createDocumentFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_DOCUMENT);
        },
        //#endregion

        //#region GET DOCUMENT
        getDocumentRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_DOCUMENT)) {
                state.isLoading.push(Loadings.FETCHING_DOCUMENT);
            }
        },
        getDocumentSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_DOCUMENT);
            state.document = payload;
        },
        getDocumentFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_DOCUMENT);
        },
        //#endregion

        //#region UPDATE DOCUMENT
        updateDocumentRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_DOCUMENT)) {
                state.isLoading.push(Loadings.UPDATING_DOCUMENT);
            }
        },
        updateDocumentSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_DOCUMENT);
        },
        updateDocumentFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_DOCUMENT);
        },
        //#endregion

        //#region DELETE DOCUMENT
        deleteDocumentRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_DOCUMENT)) {
                state.isLoading.push(Loadings.DELETING_DOCUMENT);
            }
        },
        deleteDocumentSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_DOCUMENT);
            if (!R.isNil(state.documents)) {
                state.documents = state.documents.filter(doc => doc.id !== payload.id);
            }
        },
        deleteDocumentFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_DOCUMENT);
        },
        //#endregion

        //#region CLEAR DOCUMENT
        clearDocument(state) {
            state.document = undefined;
        },

        clearDocuments(state) {
            state.documents= [];
        }
        //#endregion
    }
});

export const documentsActions = DocumentsReducer.actions;
export default DocumentsReducer.reducer;