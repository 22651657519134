import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";
import { useNavigate, useLocation } from "react-router";
import { toast } from "react-toastify";

// Components
// Components: Global
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Alert, LoadingButton } from "@mui/lab";
import { Dialog } from "@mui/material";

// Components: Common
import FormDocument from "../info/document";
import { useAutoComplete } from "../../../../hooks/useAutoComplete";
import * as Loadings from "../../../../constants/loadings/document";
import * as LoadingsFile from "../../../../constants/loadings/file";
import { onSubmitMultipleValidation, onSubmitValidation, validateTitle } from "../../../../constants/utils/validators";
import { CreateDocument, UpdateDocument } from "../../../../actions/document-api";
import { toastConf } from "../../../../constants/utils/toastConfig";
import { UploadFiles, DeleteFiles } from "../../../../actions/file-api";
import AssetModalContent from "../modals/assetModalContent";
import TenantModalContent from "../modals/tenantModalContent";
import SubcontractorModalContent from "../modals/subcontractorModalContent";
import CompanyModalContent from "../modals/companyModalcontent";
import AssetGroupModalContent from "../modals/assetGroupModalContent";
import { usePermissionModal } from "../../../../hooks/usePermissionModal";

// Components: Views

export const MainDocument = ({
	theme,
	documentState,
	documents,
	companies,
	assets,
	assetGroups,
	tenants,
	subcontractors,
	initialDocumentFormData,
	initialCompanyForm,
	initialAssetForm,
	initialAssetGroupForm,
	initialTenantForm,
	initialSubcontractorForm,
	resetDocumentFormData,
	resetCompanyForm,
	resetAssetForm,
	resetAssetGroupForm,
	resetTenantForm,
	resetSubcontractorForm,
	companyId,
	id,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const stateFile = useSelector((state) => state.file);
	const assetState = useSelector((state) => state.asset);
	const assetGroupState = useSelector((state) => state.assetGroup);
	const companyState = useSelector((state) => state.company);
	const subcontractorState = useSelector((state) => state.subcontractor);
	const tenantState = useSelector((state) => state.tenant);

	const [formData, setFormData] = useState(initialDocumentFormData);
	const [docFiles, setDocFiles] = useState([]);
	const [uploadedFiles, setUploadedFiles] = useState(
		null //R.isNil(documentState.document?.files) || R.isEmpty(documentState.document?.files) ? null : documentState.document.files
	);
	useEffect(() => {
		if(!R.isNil(documentState?.document?.files)){
			setUploadedFiles(documentState.document.files)
		}
		else {
			setUploadedFiles(null)
		}
	}, [documentState?.document?.files]);
	const [hasCurrentTitle, setHasCurrentTitle] = useState(null);
	const payload = { items: documents, properties: ["title"], value: formData.title.value, id: id };
	const { openPermissionModal } = usePermissionModal();

	const companyUseAutocomplete = useAutoComplete(initialCompanyForm, companies, initialCompanyForm);
	const assetUseAutocomplete = useAutoComplete(initialAssetForm, assets, initialAssetForm);
	const assetGroupUseAutocomplete = useAutoComplete(initialAssetGroupForm, assetGroups, initialAssetGroupForm);
	const tenantUseAutocomplete = useAutoComplete(initialTenantForm, tenants, initialTenantForm);
	const subcontractorUseAutocomplete = useAutoComplete(initialSubcontractorForm, subcontractors, initialSubcontractorForm);

	const selectCompanyByAssetGroup = ({ id, index } = {}) => {
		if (R.isNil(id) || !id) return;
		const companyformDataTemp = R.clone(companyUseAutocomplete.dropdownFormData);
		let companyId = assetGroups.find((a) => a.id === id)?.companyId;
		let foundCompany = companies?.find((c) => c.id === companyId);
		companyformDataTemp[index] = { isRequired: true, error: null, isValid: false, label: foundCompany.title, value: foundCompany.id };
		companyUseAutocomplete.setDropdownFormData(companyformDataTemp);
	};
	const selectCompanyByAsset = ({ id, index } = {}) => {
		if (R.isNil(id) || !id) return;
		const companyformDataTemp = R.clone(companyUseAutocomplete.dropdownFormData);
		let companyId = assets.find((a) => a.id === id)?.companyId;
		let foundCompany = companies?.find((c) => c.id === companyId);
		companyformDataTemp[index] = { isRequired: true, error: null, isValid: false, label: foundCompany.title, value: foundCompany.id };
		companyUseAutocomplete.setDropdownFormData(companyformDataTemp);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		let newDocument;
		const validatedForm = onSubmitValidation(formData);
		const validatedCompanyForm = onSubmitMultipleValidation(companyUseAutocomplete.dropdownFormData);
		if (validateTitle(payload)) {
			setHasCurrentTitle(true);
			return setTimeout(() => {
				setHasCurrentTitle(null);
			}, 4000);
		}
		if (!validatedForm.isValid || !validatedCompanyForm.isValid) {
			setFormData(validatedForm.form);
			companyUseAutocomplete.setDropdownFormData(validatedCompanyForm.form);
			return;
		}
		try {
			const companyId = companyUseAutocomplete.dropdownFormData[0].value;
			const assetGroupId = assetGroupUseAutocomplete.dropdownFormData[0].value;
			const tenantId = tenantUseAutocomplete.dropdownFormData[0].value;
			const assetId = assetUseAutocomplete.dropdownFormData[0].value;
			const subcontractorId = subcontractorUseAutocomplete.dropdownFormData[0].value;

			const document = {
				title: formData.title.value.trim(),
				description: R.isEmpty(formData.notes.value) ? null : formData.notes.value,
				keywords: R.isEmpty(formData.tags.value) ? null : formData.tags.value.join(","),
				documentAssociationType: 1,
				companyId: companyId,
				assetId: R.isEmpty(assetId) ? null : assetId,
				subcontractorId: R.isEmpty(subcontractorId) ? null : subcontractorId,
				assetGroupId: R.isEmpty(assetGroupId) ? null : assetGroupId,
				contactId: R.isEmpty(tenantId) ? null : tenantId,
				date: formData.date.value,
				contractCategory: formData.type.value,
				documentType: "Document",
			};
			if (R.isNil(id)) {
				newDocument = await dispatch(CreateDocument(document));
				document.id = newDocument.id;
			} else {
				document.id = id;
				newDocument = await dispatch(UpdateDocument(document));
			}
			if (!R.isEmpty(uploadedFiles) && !R.isNil(uploadedFiles)) {
				let toDelete = documentState.document && documentState.document.files ? documentState.document.files.filter((file) => !uploadedFiles.map((m) => m.id).includes(file.id)) : null;
				if (toDelete.length) {
					await dispatch(DeleteFiles(toDelete, document.id, "Document"));
				}
			}
			if (R.isEmpty(uploadedFiles) && documentState.document && documentState.document.files.length) {
				await dispatch(DeleteFiles(documentState.document.files, document.id, "Document"));
			}
			if (!R.isEmpty(docFiles)) {
				await dispatch(UploadFiles(docFiles, document.id, "Document"));
			}
			navigate(getOrigin());
			setFormData(resetDocumentFormData);
			setDocFiles([]);
			setUploadedFiles([]);
			companyUseAutocomplete.setDropdownFormData([resetCompanyForm]);
			assetUseAutocomplete.setDropdownFormData([resetAssetForm]);
			assetGroupUseAutocomplete.setDropdownFormData([resetAssetGroupForm]);
			tenantUseAutocomplete.setDropdownFormData([resetTenantForm]);
			subcontractorUseAutocomplete.setDropdownFormData([resetSubcontractorForm]);
			toast.success("Le document a été enregistré !", toastConf);
		} catch (error) {
			console.log(error);
			// if (error.response.status === 403) return openPermissionModal();
			// toast.error("Échec de l'opération, réessayez !", toastConf);
		}
	}; 
	const getOrigin = () => {
		const queries = new URLSearchParams(location.search);
		var origin = queries.get("origin");
		navigate(-1)
	};

	var selectedAsset = assetState.assets?.find((a) => {
		return a.id === assetUseAutocomplete.dropdownFormData[0]?.value;
	});

	const _docFiles= useMemo(() => docFiles,[docFiles])
	const _uploadedFiles = useMemo(() => uploadedFiles,[uploadedFiles]) 
	//const _docFiles = useMemo(() => docFiles,[docFiles])
	return (
		<React.Fragment>
			{hasCurrentTitle && (
				<Container maxWidth="100%" sx={{ display: "inherit", justifyContent: "inherit", gap: 2 }}>
					<Alert sx={{ width: "100%", borderRadius: 3, marginY: 2 }} severity="error">
						Un document existe déjà avec ce nom. Veuillez le sélectionner ou alors choisir un autre nom de document.
					</Alert>
				</Container>
			)}
			<Stack spacing={2} onSubmit={handleSubmit} component="form" flex="1 1 auto" width="100%" height="100%" overflow="hidden auto">
				<FormDocument
					docFiles={_docFiles}
					setDocFiles={setDocFiles}
					uploadedFiles={_uploadedFiles}
					setUploadedFiles={setUploadedFiles}
					formData={formData}
					setFormData={setFormData}
					selectCompanyByAssetGroup={selectCompanyByAssetGroup}
					selectCompanyByAsset={selectCompanyByAsset}
					companyUseAutocomplete={companyUseAutocomplete}
					assetUseAutocomplete={assetUseAutocomplete}
					assetGroupUseAutocomplete={assetGroupUseAutocomplete}
					tenantUseAutocomplete={tenantUseAutocomplete}
					subcontractorUseAutocomplete={subcontractorUseAutocomplete}
					companyId={companyId}
					theme={theme}
					mt={{ md: -2 }}
				/>
				<Stack direction={{ default: "column", md: "row" }} justifyContent="end" alignItems="center" pb={2} spacing={2}>
					<Container maxWidth="sm" sx={{ display: "inherit", justifyContent: "inherit", flex: "1 1 auto", marginLeft: 0 }}>
						<Typography variant="body2" color="text.secondary">
							Vous pourrez ajouter des documents relatifs à cet élément après l'avoir Enregistré, à l'aide du bouton + en bas de l'écran.
						</Typography>
					</Container>
					<Container sx={{ display: "inherit", justifyContent: "inherit", gap: 2, flex: "0" }}>
						<Button color="text" onClick={() => navigate(-1)}>
							Annuler
						</Button>
						<LoadingButton
							type="submit"
							loading={documentState.isLoading.includes(Loadings.CREATING_DOCUMENT) || documentState.isLoading.includes(Loadings.UPDATING_DOCUMENT) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_FILES)}
							disabled={documentState.isLoading.includes(Loadings.CREATING_DOCUMENT) || documentState.isLoading.includes(Loadings.UPDATING_DOCUMENT) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_FILES) || hasCurrentTitle}
							size="large"
							variant="contained"
							color="primary">
							Enregistrer
						</LoadingButton>
					</Container>
				</Stack>
			</Stack>
			<Dialog fullWidth maxWidth="md" onClose={companyUseAutocomplete.setClose} open={companyUseAutocomplete.isOpen} theme={theme}>
				<CompanyModalContent modalClose={companyUseAutocomplete.setClose} open={companyUseAutocomplete.isOpen} theme={theme} newCompanyName={companyUseAutocomplete.newOptionName} handleAddNewCompany={companyUseAutocomplete.handleAddNew} />
			</Dialog>
			<Dialog fullWidth maxWidth="md" onClose={assetUseAutocomplete.setClose} open={assetUseAutocomplete.isOpen} theme={theme}>
				<AssetModalContent modalClose={assetUseAutocomplete.setClose} open={assetUseAutocomplete.isOpen} theme={theme} newTitle={assetUseAutocomplete.newOptionName} handleAddNew={assetUseAutocomplete.handleAddNew} />
			</Dialog>
			<Dialog fullWidth maxWidth="md" onClose={tenantUseAutocomplete.setClose} open={tenantUseAutocomplete.isOpen} theme={theme}>
				<TenantModalContent modalClose={tenantUseAutocomplete.setClose} open={tenantUseAutocomplete.isOpen} theme={theme} newTitle={tenantUseAutocomplete.newOptionName} handleAddNew={tenantUseAutocomplete.handleAddNew} />
			</Dialog>
			<Dialog fullWidth maxWidth="md" onClose={subcontractorUseAutocomplete.setClose} open={subcontractorUseAutocomplete.isOpen} theme={theme}>
				<SubcontractorModalContent modalClose={subcontractorUseAutocomplete.setClose} open={subcontractorUseAutocomplete.isOpen} theme={theme} newTitle={subcontractorUseAutocomplete.newOptionName} handleAddNew={subcontractorUseAutocomplete.handleAddNew} />
			</Dialog>
			<Dialog fullWidth maxWidth="md" onClose={assetGroupUseAutocomplete.setClose} open={assetGroupUseAutocomplete.isOpen} theme={theme}>
				<AssetGroupModalContent
					modalClose={assetGroupUseAutocomplete.setClose}
					open={assetGroupUseAutocomplete.isOpen}
					theme={theme}
					newTitle={assetGroupUseAutocomplete.newOptionName}
					handleAddNew={assetGroupUseAutocomplete.handleAddNew}
					newAddress={selectedAsset?.address?.addressLine1}
					newCity={selectedAsset?.address?.locality}
					newPostalCode={selectedAsset?.address?.postalCode}
				/>
			</Dialog>
		</React.Fragment>
	);
};

export default MainDocument;
