import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

// Components: Common
import * as LoadingsCompany from '../../constants/loadings/company';
import * as LoadingsTenant from '../../constants/loadings/tenant';
import { initialTenantFormData, initialCompanyForm } from '../../constants/utils/formInitialData';
import { GetCompanies } from '../../actions/company-api';
import { GetTenant, GetTenants } from '../../actions/tenant-api';
import Loading from '../../_common/loading';
import MainTenant from '../_common/forms/layout/tenant';


export const EditTenant = ({ theme, companyState, tenantState }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        dispatch(GetTenant(id));

        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        if (R.isNil(tenantState.tenants)) {
            dispatch(GetTenants());
        }
        // eslint-disable-next-line 
    }, []);

    if (tenantState.isLoading.includes(LoadingsTenant.FETCHING_TENANTS) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)) {
        return <Loading theme={theme} />;
    }

    if (R.isNil(tenantState.tenant) || R.isEmpty(tenantState.tenant) || R.isNil(companyState.companies)) {
        return '';
    }

    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let tenants = !R.isNil(tenantState.tenants) ? tenantState.tenants.map(t => ({ ...t, title: t.firstName + " " + t.lastName })) : [];

    const tempInitialTenantFormData = R.clone(initialTenantFormData);
    tempInitialTenantFormData.firstName.value = !R.isNil(tenantState.tenant.firstName) && !R.isEmpty(tenantState.tenant.firstName) ? tenantState.tenant.firstName : "";
    tempInitialTenantFormData.lastName.value = !R.isNil(tenantState.tenant.lastName) && !R.isEmpty(tenantState.tenant.lastName) ? tenantState.tenant.lastName : "";
    tempInitialTenantFormData.profession.value = !R.isNil(tenantState.tenant.occupation) && !R.isEmpty(tenantState.tenant.occupation) ? tenantState.tenant.occupation : "";
    tempInitialTenantFormData.social.value = !R.isNil(tenantState.tenant.companyName) && !R.isEmpty(tenantState.tenant.companyName) ? tenantState.tenant.companyName : "";
    tempInitialTenantFormData.email.value = !R.isNil(tenantState.tenant.email) && !R.isEmpty(tenantState.tenant.email) ? tenantState.tenant.email : "";
    tempInitialTenantFormData.phone.value = !R.isNil(tenantState.tenant.phoneNumber) && !R.isEmpty(tenantState.tenant.phoneNumber) ? tenantState.tenant.phoneNumber : "";

    let tempInitialCompanyForm = [R.clone(initialCompanyForm)];
    tempInitialCompanyForm = tenantState.tenant.companyIds.map(id => ({ ...initialCompanyForm, value: id, label: companies.find(c => c.id === id)?.title }));


    return (
        <MainTenant
            theme={theme}
            tenantState={tenantState}
            photo={tenantState.tenant.profileUrl?.url}
            companies={companies}
            tenants={tenants}
            initialTenantFormData={tempInitialTenantFormData}
            initialCompanyForm={tempInitialCompanyForm}
            resetTenantFormData={initialTenantFormData}
            resetCompanyForm={initialCompanyForm}
            contactCategory={tenantState.tenant.contactCategory}
            id={id}
        />
    );
};

export default EditTenant;