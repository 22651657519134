import { createSlice } from '@reduxjs/toolkit';
import * as Loadings from '../constants/loadings/account';



const initState = {
    isLoading: [],
    accounts: undefined,
    account: undefined
};

const CompaniesReducer = createSlice({
    name: 'account',
    initialState: { ...initState },
    reducers: {
        //#region GET ACCOUNTS
        getAccountsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_ACCOUNTS)) {
                state.isLoading.push(Loadings.FETCHING_ACCOUNTS);
            }
        },
        getAccountsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ACCOUNTS);
            state.accounts = payload.data;
        },
        getAccountsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ACCOUNTS);
        },
        //#endregion

        //#region CREATE ACCOUNT
        createAccountRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_ACCOUNT)) {
                state.isLoading.push(Loadings.CREATING_ACCOUNT);
            }
        },
        createAccountSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_ACCOUNT);
        },
        createAccountFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_ACCOUNT);
        },

        //#region GET ACCOUNT
        getAccountRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_ACCOUNT)) {
                state.isLoading.push(Loadings.FETCHING_ACCOUNT);
            }
        },
        getAccountSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ACCOUNT);
            state.account = payload;
        },
        getAccountFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ACCOUNT);
        },
        //#endregion

        //#region UPDATE ACCOUNT
        updateAccountRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_ACCOUNT)) {
                state.isLoading.push(Loadings.UPDATING_ACCOUNT);
            }
        },
        updateAccountSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_ACCOUNT);
        },
        updateAccountFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_ACCOUNT);
        },
        //#endregion

        //#region DELETE ACCOUNT
        deleteAccountRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_ACCOUNT)) {
                state.isLoading.push(Loadings.DELETING_ACCOUNT);
            }
        },
        deleteAccountSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_ACCOUNT);
            state.account = undefined;
            state.accounts = state.accounts.filter(a => a.id !== payload.id);
        },
        deleteAccountFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_ACCOUNT);
        },
        //#endregion
    }
});

export const accountsActions = CompaniesReducer.actions;
export default CompaniesReducer.reducer;