import React, { useState, useEffect } from 'react';
import { useLocation, Link as RouterLink, useNavigate } from 'react-router-dom';
import * as R from 'ramda';

// Components
// Components: Global
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import CloseIcon from '@mui/icons-material/Close';

// Components: Common
import Logo from '../../../_common/logo';


export const NavbarToolbar = ({ theme, location, noIconClose }) => {
    const navigate = useNavigate();
    const routerLocation = useLocation();
    const search = new URLSearchParams(routerLocation.search);
    const sortBy = search.get('sortBy');
    const text = search.get('text');
    const [searchText, setSearchText] = useState(R.isEmpty(text) || R.isNil(text) ? '' : text);

    useEffect(() => {
        setSearchText(text);
    }, [text]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (R.isEmpty(searchText)) {
            return navigate(`/app/search`);
        }
        navigate(`/app/search?text=${searchText}`);
    };


    return (
        <AppBar component="nav" elevation={1} direction="column" spacing={0} square position="static" sx={{ zIndex: '2', backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`, backgroundColor: theme.palette.primary.main }}>
            <Typography component="h1" fontWeight="normal" textTransform="capitalize" className="sr-only">Toolbar</Typography>
            <Container maxWidth="100%">
                <Toolbar color="primary" disableGutters sx={{ paddingTop: 2, paddingBottom: 2, gap: 2 }}>
                    <Logo navbar />
                    {routerLocation.pathname.includes('search') ?
                        <React.Fragment>
                            <Paper component={"form"} onSubmit={handleSubmit} elevation={1} sx={{ p: '.125rem .25rem', display: 'flex', alignItems: 'center', borderRadius: 6, flex: '1 1 auto' }}>
                                <InputBase
                                    placeholder='Rechercher'
                                    color="inherit"
                                    sx={{ pl: '.5rem' }}
                                    inputProps={{ 'aria-label': 'Rechercher' }}
                                    onChange={(e) => { setSearchText(e.target.value); }}
                                    value={searchText}
                                    fullWidth
                                />
                                <IconButton type="submit" color="secondary" sx={{ backgroundColor: theme.palette.secondary.dim }} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                            </Paper>
                            {!R.isNil(noIconClose) &&
                                <IconButton type="submit" color="inherit" sx={{ backgroundColor: theme.palette.background.light }}>
                                    <CloseIcon />
                                </IconButton>
                            }
                        </React.Fragment>
                        :
                        <IconButton type="submit" color="inherit" component={RouterLink} to="/app/search" sx={{ backgroundColor: theme.palette.background.light }} aria-label="search">
                            <SearchIcon title="icon search"/>
                        </IconButton>
                    }
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default NavbarToolbar;
