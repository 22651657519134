import React from 'react';
import * as R from 'ramda';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from "@mui/material/TextField";

// Components: Common
import { initialAssetGroupForm, initialCompanyForm } from '../../../../constants/utils/formInitialData';
import { onChangeValidation } from '../../../../constants/utils/validators';
import { assetState, duration } from '../../../../constants/utils/objectProperties';

import FormPlace from '../_common/place';
import FormNotes from '../_common/notes';
import FormClefs from '../_common/clefs';
import FormPhotos from '../_common/photos';
import FormUpload from '../_common/upload';
import FormOptions from '../_common/option';
import FormProfilePhoto from '../_common/profilePhoto';
import FormGlobalComplete from '../_common/globalComplete';

// Components: Views

export const FormAsset = ({ theme, resetAssetGroup, selectCompany, companyUseAutocomplete, formdata, setFormData, currentPhoto, assetGroupUseAutocomplete, profilePhoto, setProfilePhoto, uploadedFiles, setUploadedFiles, docFiles, setDocFiles, children, companyId, ...others }) => {
    const locationcontract = ['Courte durée', 'Longue durée'];
    const assetsstatus = ['Actif', 'Vendu'];
    const handleClick = (event, item) => { event.target.value = ''; };
    const handleOnChange = (event) => { setFormData(onChangeValidation(event.target, formdata)); };


    return (
        <Stack spacing={2} width="100%" {...others}>
            <FormUpload theme={theme} photos={docFiles} setPhotos={setDocFiles} type="asset" />
            <Container maxWidth="100%" sx={{ display: "inherit", flexDirection: "inherit", gap: 2 }}>
                <Stack direction="column" spacing={4}>
                    <Stack direction="column" spacing={2}>
                        {/* <Box component="label" sx={{boxShadow: 'none', padding: 0, position: 'relative'}}>
                            <Typography color="grey.A400" sx={{ paddingY: 1.5, paddingX: 1.5, border: '1px solid' + theme.palette.grey.A300 , borderRadius: 5}}>Attacher acte d'immatriculation du bien</Typography>
                            <input hidden accept="application/pdf" multiple type="file" />
                        </Box> */}
                        <Stack direction="column" spacing={2}>
                            <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                                <FormProfilePhoto theme={theme} currentPhoto={currentPhoto} profilePhoto={profilePhoto} setProfilePhoto={setProfilePhoto} />
                                <TextField
                                    label='Titre *'
                                    id="Titre"
                                    type="text"
                                    fullWidth
                                    variant="filled"
                                    name="title"
                                    error={formdata.title.error && !formdata.title.isValid}
                                    helperText={formdata.title.error}
                                    value={formdata.title.value}
                                    onChange={(event) => { handleOnChange(event); }}
                                />
                                <TextField
                                    id="Wifi"
                                    label="Wifi"
                                    type="text"
                                    fullWidth
                                    variant="filled"
                                    name="wifi"
                                    error={formdata.wifi.error && !formdata.wifi.isValid}
                                    helperText={formdata.wifi.error}
                                    value={formdata.wifi?.value}
                                    onChange={(event) => { handleOnChange(event); }}
                                />
                            </Stack>
                            <FormPlace handleOnChange={handleOnChange} formData={formdata} />
                            <Stack flex="1 1 auto" direction={{ default: "column", md: "row" }} spacing={1}>
                                {/* <TextField
                                    label='Immeuble'
                                    id="Immeuble"
                                    type="text"
                                    fullWidth
                                    variant="filled"
                                    value={formData.Immeuble}
                                    onChange={(event) => handleData('Immeuble', event)}
                                /> */}
                                <FormGlobalComplete
                                    options={assetGroupUseAutocomplete.options}
                                    optionsFormData={assetGroupUseAutocomplete.dropdownFormData}
                                    setOptionsFormData={assetGroupUseAutocomplete.setDropdownFormData}
                                    handleSetOpen={assetGroupUseAutocomplete.handleSetOpen}
                                    // handleSetOptionsFormData={assetGroupUseAutocomplete.handleSetOptionsFormData}
                                    handleSetOptionsFormData={R.compose(selectCompany, assetGroupUseAutocomplete.handleSetOptionsFormData)}
                                    multi={false}
                                    name="assetGroup"
                                    label="Immeuble"
                                    initialFormData={initialAssetGroupForm}
                                />
                                <FormGlobalComplete
                                    options={companyUseAutocomplete.options}
                                    optionsFormData={companyUseAutocomplete.dropdownFormData}
                                    setOptionsFormData={companyUseAutocomplete.setDropdownFormData}
                                    handleSetOpen={companyUseAutocomplete.handleSetOpen}
                                    handleSetOptionsFormData={R.compose(assetGroupUseAutocomplete.resetFormData, companyUseAutocomplete.handleSetOptionsFormData)}
                                    multi={false}
                                    name="company"
                                    label="Société"
                                    initialFormData={initialCompanyForm}
                                    companyId={companyId}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                    {children}
                    <FormPhotos fileType="Asset" photos={docFiles} setPhotos={setDocFiles} handleClick={handleClick} uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} theme={theme} />
                    <Stack direction="column" spacing={1}>
                        <Typography variant="label" color="text.secondary">Type de location</Typography>
                        <FormOptions value={formdata.locationType.value} fieldName="locationType" formData={formdata} setFormData={setFormData} options={duration} />
                    </Stack>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="label" color="text.secondary">État du bien</Typography>
                        <FormOptions value={formdata.status.value} fieldName="status" formData={formdata} setFormData={setFormData} options={assetState} />
                    </Stack>
                    <FormNotes formData={formdata} handleOnChange={handleOnChange} title="Présentation du bien (pour les annonces)" />
                </Stack>
                <FormClefs theme={theme} handleChange={(newValue) => setFormData({ ...formdata, tags: { ...formdata.tags, value: newValue } })} value={formdata.tags.value} />
            </Container>
        </Stack>
    );
};

export default FormAsset;
