import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import { toast } from 'react-toastify';


// Components
// Components: Global
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArticleIcon from '@mui/icons-material/Article';
import PaymentsIcon from '@mui/icons-material/Payments';

import { toastConf } from "../../../../constants/utils/toastConfig";


// Components: Common
import ItemCard from '../../../_common/itemCard';
import ModalCarousel from '../../../_common/modalCarousel';
import { GetDocument } from '../../../../actions/document-api';
import { documentsActions } from "../../../../reducers/document";
import { GetPayment } from '../../../../actions/payment-api';
import { paymentsActions } from '../../../../reducers/payment';
import { GetRental } from '../../../../actions/rental-api';
import { rentalsActions } from '../../../../reducers/rental';


export const ResultsListDoc = ({ item, theme }) => {

	const [optionsMenu, setOptionsMenu] = useState(null);
	const [openCarousel, setOpenCarousel] = useState(false);
	const [files, setFiles] = useState([]);

	const showMe = Boolean(optionsMenu);
	const dispatch = useDispatch();

	const optionsMenuClick = (e) => {
		e.preventDefault();
		setOptionsMenu(e.currentTarget);
	};
	const optionsMenuClose = () => { setOptionsMenu(null); };

	const modalOpenCarousel = () => {
		getDocumentByType()
		setOpenCarousel(true);
	};
	const modalCloseCarousel = () => {
		setOpenCarousel(false);
	};


	const getDocumentByType = () => {
		if (!item) {
			return;
		}

		let docType = item.documentType;

		switch (docType) {

			case "Document":

				dispatch(GetDocument(item.companyId, item.id)).then((doc) => {

					if (!R.isNil(doc.files) && !R.isEmpty(doc.files)) {
						setFiles(doc.files);
					}
					else {
						toast.warning("Le fichier n'est pas disponible", toastConf)
					}

					dispatch(documentsActions.clearDocument());
				})

				break;

			case "Payment":

				dispatch(GetPayment(item.companyId, item.id)).then((doc) => {

					if (!R.isNil(doc.files) && !R.isEmpty(doc.files)) {
						setFiles(doc.files)
					}
					else {
						toast.warning("Le fichier n'est pas disponible", toastConf)
					}

					dispatch(paymentsActions.clearPayment());
				})

				break;

			case "Rental":

				dispatch(GetRental(item.companyId, item.id)).then((doc) => {

					if (!R.isNil(doc.files) && !R.isEmpty(doc.files)) {
						setFiles(doc.files)
					}
					else {
						toast.warning("Le fichier n'est pas disponible", toastConf)
					}

					dispatch(rentalsActions.clearRental());
				})

				break;

			default:
				toast.warning("Le fichier n'est pas disponible", toastConf);
		}

	};

	return (
		<ItemCard alignItems="center">
			<Avatar variant="rounded" children={item.title[0]} sx={{ bgcolor: "background.default", fontWeight: "bold" }}>
				{item.resourceType === "Payment" ? <PaymentsIcon sx={{ color: "secondary.main" }} /> : <ArticleIcon sx={{ color: "primary.main" }} />}
			</Avatar>
			<Stack direction="column" flex="1 1 auto" width="100%" flexWrap="wrap">
				<Stack direction="row" flex="1 1 auto" gap={0.5} width="100%" flexWrap="wrap">
					<Typography flex="1 1 auto" variant="text" sx={{ wordBreak: "break-all" }}>
						{item.title}
					</Typography>
				</Stack>
				<Typography flex="1 1 auto" variant="text" color="gray" sx={{ fontSize: ".875rem", wordBreak: "break-all" }}>
					{item.asset?.title}
				</Typography>
				<Typography flex="1 1 auto" variant="text" color="gray" sx={{ fontSize: ".875rem", wordBreak: "break-all" }}>
					{item.assetGroup?.title}
				</Typography>
				<Typography flex="1 1 auto" variant="text" color="gray" sx={{ fontSize: ".875rem", wordBreak: "break-all" }}>
					{item.company?.title}
				</Typography>
				<Typography flex="1 1 auto" variant="text" color="gray" sx={{ fontSize: ".875rem", wordBreak: "break-all" }}>
					{item.tenant?.name}
				</Typography>
				<Typography flex="1 1 auto" variant="text" color="gray" sx={{ fontSize: ".875rem", wordBreak: "break-all" }}>
					{item.subcontractor?.name}
				</Typography>
			</Stack>
			<Box position="relative" mt={0.25}>
				<IconButton sx={{ justifyContent: "space-between", maxWidth: { sm: "fit-content" } }} variant="contained" color="inherit" id="optionButtom" aria-controls={showMe ? "optionMenu" : undefined} aria-haspopup="true" aria-expanded={showMe ? "true" : undefined} onClick={optionsMenuClick}>
					<MoreVertIcon />
				</IconButton>
				<Menu id="optionMenu" anchorEl={optionsMenu} open={showMe} onClose={optionsMenuClose} MenuListProps={{ "aria-labelledby": "optionButtom" }}>
					<MenuItem component={RouterLink} to={"/app/edit/" + (item.resourceType === "Payment" ? "payment/" : item.resourceType === "Rental" ? "contrat/" : "document/") + item.companyId + "/" + item.id}>
						Éditer information
					</MenuItem>
					{/* <MenuItem variant="text" color="inherit" size="inherit" key="Telecharger">
						Télécharger tous les documents
					</MenuItem> */}
					<Divider />
					{!R.isNil(item.tenantId) && !R.isEmpty(item.tenantId) && item.tenantId !== "00000000-0000-0000-0000-000000000000" && (
						<MenuItem variant="text" color="inherit" size="inherit" key="voirLocataire" component={RouterLink} to={"/app/tenants/" + item.tenantId}>
							Voir Locataire
						</MenuItem>
					)}
					{!R.isNil(item.companyId) && !R.isEmpty(item.companyId) && item.companyId !== "00000000-0000-0000-0000-000000000000" && (
						<Stack>
							{item.resourceType === "Payment" ? (
								<MenuItem variant="text" color="inherit" size="inherit" key="voirBank" component={RouterLink} to={"/app/banks/" + item.companyId}>
									Voir Mouvements Bancaires
								</MenuItem>
							) : (
								<MenuItem variant="text" color="inherit" size="inherit" key="voirSociete" component={RouterLink} to={"/app/companies/" + item.companyId}>
									Voir Société
								</MenuItem>
							)}
							{!R.isNil(item.assetId) && !R.isEmpty(item.assetId) && item.assetId !== "00000000-0000-0000-0000-000000000000" && (
								<MenuItem variant="text" color="inherit" size="inherit" key="voirBien" component={RouterLink} to={"/app/assets/" + item.companyId + "/" + item.assetId}>
									Voir Bien
								</MenuItem>
							)}
							{!R.isNil(item.assetGroupId) && !R.isEmpty(item.assetGroupId) && item.assetGroupId !== "00000000-0000-0000-0000-000000000000" && (
								<MenuItem variant="text" color="inherit" size="inherit" key="voirImmeuble" component={RouterLink} to={"/app/assets/immeubles/" + item.companyId + "/" + item.assetGroupId}>
									Voir Immeuble
								</MenuItem>
							)}
							{ item.documentType === "Document" && (
								<MenuItem variant="text" color="inherit" size="inherit" key="voirImmeuble" component={RouterLink} to={"/app/documents/" + item.companyId + "/" + item.assetGroupId}>
									Voir document
								</MenuItem>
							)}
							<Divider />
							{
								<MenuItem disabled={item.files < 1} variant="text" color="inherit" size="inherit" key="Voir fichiers" onClick={modalOpenCarousel}>
									Ouvrir le fichier
								</MenuItem>
							}
						</Stack>
					)}
					{!R.isNil(item.subcontractorId) && !R.isEmpty(item.subcontractorId) && item.subcontractorId !== "00000000-0000-0000-0000-000000000000" && (
						<MenuItem variant="text" color="inherit" size="inherit" key="voirSousTraitant" component={RouterLink} to={"/app/subcontractors/" + item.subcontractorId}>
							Voir Sous-traitant
						</MenuItem>
					)}
				</Menu>
			</Box>
			<ModalCarousel theme={theme} items={files} modalClose={modalCloseCarousel} open={openCarousel} />
		</ItemCard>
	);
};


export default ResultsListDoc;