import { useRef, useEffect } from 'react';
import { SCROLL_LIMIT } from '../constants/utils/pageSize';
import { useNavigate } from 'react-router-dom';

export const useInfinitScroll = (page, hasMorePage, location) => {
    const lastCardRef = useRef();
    const cardWrapperRef = useRef();
    const navigate = useNavigate();


    useEffect(() => {
        cardWrapperRef.current?.addEventListener('scroll', updatePagination);
        return () => { cardWrapperRef.current?.removeEventListener('scroll', updatePagination); };
        // eslint-disable-next-line
    }, [location.search, hasMorePage]);


    function updatePagination() {
        if (lastCardRef.current && lastCardRef.current.getBoundingClientRect().y < SCROLL_LIMIT) {
            if (!hasMorePage) return;
            const searchParams = new URLSearchParams(location.search);
            searchParams.set('page', page);
            navigate(`${location.pathname}?` + searchParams.toString());
        }
    }

    return {
        lastCardRef,
        cardWrapperRef,
    };
};
