import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

// Components
// Components: Global
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyIcon from '@mui/icons-material/Key';
import EditIcon from '@mui/icons-material/Edit';
import BookIcon from '@mui/icons-material/Book';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

// Components: Common
import { DeleteCollaborator, GetCollaborator } from '../../../../actions/collaborator-api';
import { GetCompanies } from '../../../../actions/company-api';

import * as CollaboratorLoadings from '../../../../constants/loadings/collaborator';
import { permissionsType } from '../../../../constants/utils/objectProperties';

import ItemCard from '../../../_common/itemCard';
import Loading from '../../../../_common/loading';
import CollaboratorsViewEmpty from './../empty';

import CollaboratorsViewDetailHeader from './_common/header';
import ModalDelete from '../../../_common/modalDelete';
import { getPermissionType } from '../../../../constants/utils/formHandlers';
import { usePermissionModal } from '../../../../hooks/usePermissionModal';

// Components: View

export const CollaboratorsViewDetail = ({ theme, userState, tenantState, documentState, companyState, rentalState, collaboratorState, subcontractorState }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
	const [accessesExpanded, setAccessesExpanded] = useState("panel-accesses", true);

    const { openPermissionModal } = usePermissionModal();

    const modalOpenProfile = () => { setOpen(true); };
    const modalCloseProfile = () => { setOpen(false); };

    useEffect(() => {
        if (R.isNil(companyState.companies) ||
            R.isEmpty(companyState.companies)) {
            dispatch(GetCompanies());
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(GetCollaborator(id));
        // eslint-disable-next-line
    }, [id]);

    const deleteCollaboratorProp = async () => {
        await dispatch(DeleteCollaborator(collaboratorState.collaborator.id));
        navigate('/app/collaborators');
    };

    if (collaboratorState.isLoading.includes(CollaboratorLoadings.FETCHING_COLLABORATOR)) {
        return <Loading />;
    }
    if (R.isNil(collaboratorState.collaborator) || R.isEmpty(collaboratorState.collaborator)) {
        return <CollaboratorsViewEmpty color="danger" title="Ooops..." description="Quelque chose s'est mal passée. On dirait que ce collaborateur n'existe pas !" />;
    }

    const permissions = userState.user.companyAccesses
        .filter((ca) => !R.isNil(collaboratorState.collaborator.companyAccesses.find(c => c.companyId === ca.companyId)))
        .reduce((acc, c) => [...acc, ...c.accessTypes], []);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
			switch (panel) {
				default:
				case "panel-accesses": {
					setAccessesExpanded(isExpanded ? panel : false);
					break;
				}
			}
		};
    return (
			<React.Fragment>
				<CollaboratorsViewDetailHeader openPermissionModal={openPermissionModal} permissions={permissions} theme={theme} modalOpenProfile={modalOpenProfile} collaboratorState={collaboratorState} companyState={companyState} tenantState={tenantState} />
				<Container maxWidth="100%" sx={{ height: "100%" }}>
					<Stack maxWidth="100%" my={3} direction="column" spacing={4}>
						<Accordion sx={{ backgroundColor: "transparent", boxShadow: "none", border: "none", p: 0, ":before": { backgroundColor: "inherit" } }} disableGutters expanded={accessesExpanded === "panel-accesses"} onChange={handleAccordionChange("panel-accesses")}>
							<AccordionSummary sx={{ minHeight: "auto", ".MuiAccordionSummary-content": { my: 1 }, p: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
								<Typography variant="h6">Permissions</Typography>
							</AccordionSummary>
							<AccordionDetails sx={{ p: 0 }}>
								<Stack maxWidth="100%" direction="column" spacing={1}>
									{!R.isNil(collaboratorState.collaborator.companyAccesses) && !R.isEmpty(collaboratorState.collaborator.companyAccesses) && (
										<Stack direction="column" spacing={1}>
											{collaboratorState.collaborator.companyAccesses.map((item) => (
												<ItemCard alignItems={{ md: "center", lg: "start", xl: "center" }} key={item.id} padding={1} backgroundColor="background.dim" borderRadius={2} elevation={0}>
													<Avatar variant="rounded" children={document.title[0]} sx={{ bgcolor: "background.paper", fontWeight: "bold" }}>
														{item.accessTypes.find((accessType) => accessType === "Admin") ? (
															<KeyIcon sx={{ color: "secondary.main" }} />
														) : item.accessTypes.find((accessType) => accessType === "Delete") ? (
															<KeyIcon sx={{ color: "warning.main" }} />
														) : item.accessTypes.find((accessType) => accessType === "Update") ? (
															<EditIcon sx={{ color: "success.main" }} />
														) : item.accessTypes.find((accessType) => accessType === "Create") ? (
															<EditIcon sx={{ color: "primary.main" }} />
														) : item.accessTypes.find((accessType) => accessType === "Read") ? (
															<BookIcon sx={{ color: "info.main" }} />
														) : (
															<QuestionMarkIcon sx={{ color: "text.muted" }} />
														)}
													</Avatar>
													<Stack direction={{ default: "column", hd: "row" }} gap={{ hd: 2 }} flex="1 1 auto" alignItems={{ hd: "center" }} justifyContent="space-between">
														<Typography>{permissionsType[getPermissionType(item.accessTypes)] || "N/A"}</Typography>
														<Typography color="text.dark" variant="body2">
															{!R.isNil(companyState.companies) && !R.isEmpty(companyState.companies) && companyState.companies.filter((company) => item.companyId.includes(company.id)).map((c) => c.title)}
														</Typography>
													</Stack>
													<Stack direction="row" spacing={0} justifyContent="space-between">
														<Avatar variant="rounded" children={document.title[0]} sx={{ bgcolor: "transparent", fontWeight: "bold" }} component={RouterLink} to={"/app/companies/" + item.companyId}>
															<ChevronRightIcon sx={{ color: "text.secondary" }} />
														</Avatar>
													</Stack>
												</ItemCard>
											))}
										</Stack>
									)}
								</Stack>
							</AccordionDetails>
						</Accordion>
					</Stack>
				</Container>
				<ModalDelete
					theme={theme}
					collaboratorState={collaboratorState}
					item={collaboratorState.collaborator}
					title={collaboratorState.collaborator?.firstName + " " + collaboratorState.collaborator?.lastName}
					isLoading={collaboratorState.isLoading.includes(CollaboratorLoadings.DELETING_COLLABORATOR)}
					isDisabled={collaboratorState.isLoading.includes(CollaboratorLoadings.DELETING_COLLABORATOR)}
					dispatchDelete={deleteCollaboratorProp}
					modalClose={modalCloseProfile}
					open={open}
				/>
			</React.Fragment>
		);
};

export default CollaboratorsViewDetail;
