import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as R from 'ramda';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';


import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Components: Common
import ItemCard from '../../../../_common/itemCard';

// Components: View

export const TenantsViewDetailHeader = ({ theme, title, modalOpenProfile, companyState, tenantState, permissions, openPermissionModal }) => {
    let item = tenantState.tenant;
    const [anchorOption, setAnchorOption] = useState(null);
    const optionsMenu = [
        // { path: null, name: 'Importer photo' },
        { path: '/app/edit/tenant/', name: 'Éditer information' },
        // { path: null, name: 'Télécharger tous les documents' },
    ];
    const showMe = Boolean(anchorOption);

    const handleClick = (e) => {
        e.preventDefault();
        setAnchorOption(e.currentTarget);
    };

    const handleClose = () => { setAnchorOption(null); };

    return (
        R.isNil(item) ?
            <ItemCard loading />
            :
            <Box component="header" sx={{ zIndex: 0, borderBottomLeftRadius: '1.25rem', borderBottomRightRadius: '1.25rem', backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundPosition: 'center bottom' }} py={2} pt={{ md: 4 }} position="relative" top="0" maxWidth="100%" backgroundColor="background.paper">
                <Container maxWidth="100%" sx={{ alignItems: 'center', justifyContent: "space-evenly" }} >
                    <Stack direction="column" flex="1 1 auto" justifyContent="space-between" spacing={1}>
                        <Stack direction={{ default: "column", xs: "row" }} sx={{ gap: 2 }} justifyContent="space-between">
                            <Avatar sx={{ color: "secondary.contrastText", backgroundColor: R.isNil(item.profileUrl?.url) ? "secondary.main" : "background.paper", width: '4.5rem', height: '4.5rem', fontWeight: 'bold', border: item.profileUrl?.url && ('.125rem solid' + theme.palette.secondary.main), padding: !R.isNil(item.profileUrl?.url) && '.25rem' }} imgProps={{ sx: { borderRadius: 'inherit' } }} children={!R.isNil(item.title) ? item.title.charAt(0) : !R.isNil(title) ? title.charAt(0) : (item.firstName.charAt(0) + item.lastName.charAt(0))} srcSet={!R.isNil(item.profileUrl?.url) ? item.profileUrl?.url : null} />
                            <Stack direction="column" spacing={.25} flex="1 1 auto" my="auto">
                                <Typography variant="h5" sx={{ lineHeight: 1 }}>{item.firstName} {item.lastName}</Typography>
                                <Typography variant="body1" fontStyle='italic' color={theme.palette.text.secondary}>Profil {item.contactCategory === 1 ? "Particulier" : "Entreprise"}</Typography>
                            </Stack>
                            <Box position="relative" mt={.75}>
                                <IconButton sx={{ justifyContent: "space-between", maxWidth: { sm: "fit-content" } }} variant="contained" color="inherit" id="optionButtom" aria-controls={showMe ? 'optionMenu' : undefined} aria-haspopup="true" aria-expanded={showMe ? 'true' : undefined} onClick={handleClick}>
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu id="optionMenu" anchorEl={anchorOption} open={showMe} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'optionButtom' }}>
                                    {optionsMenu.map(({ path, name }, index) => (
                                        permissions.includes("Update") ?
                                            <MenuItem component={RouterLink} to={path ? (path.split("/").includes('edit') ? path + item.id : path) : ''} key={name} onClick={handleClose}>
                                                {name}
                                            </MenuItem>
                                            :
                                            <MenuItem key={name} onClick={() => { openPermissionModal(); handleClose(); }} >
                                                {name}
                                            </MenuItem>
                                    ))}
                                    {permissions.includes("Delete") ?
                                        <MenuItem onClick={modalOpenProfile} variant="text" color="inherit" size="inherit">
                                            Effacer
                                        </MenuItem>
                                        :
                                        <MenuItem onClick={() => { openPermissionModal(); handleClose(); }} variant="text" color="inherit" size="inherit">
                                            Effacer
                                        </MenuItem>
                                    }
                                    <Divider />
                                    <MenuItem component={RouterLink} to={"/app/tenants"}>
                                        <CloseIcon fontSize='medium' /> <Box component="span" sx={{ color: 'text.secondary', mx: 1 }}>Fermer</Box>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Stack>
                        <Stack direction="column" spacing={.5} py={1}>
                            <Stack direction={{ xs: "column", hd: "row" }} spacing={.5}>
                                <Stack direction={{ xs: "column", lg: "row" }} spacing={.5}>
                                    <Typography variant="body1">Téléphone: {!R.isNil(item.phoneNumber) ? <strong>{item.phoneNumber}</strong> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>

                                    <Typography sx={{ lineHeight: 1 }} display={{ default: 'none', lg: 'inherit' }} variant="h6" component="p" px={2} color={theme.palette.text.hint}>•</Typography>

                                    <Typography variant="body1">Email: {!R.isNil(item.email) ? <strong>{item.email}</strong> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>

                                </Stack>
                                <Typography sx={{ lineHeight: 1 }} display={{ default: 'none', hd: 'inherit' }} variant="h6" component="p" px={2} color={theme.palette.text.hint}>•</Typography>
                                {(item.contactCategory === 1) ?
                                    <Typography variant="body1">Profession: {!R.isNil(item.occupation) ? <strong>{item.occupation}</strong> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>
                                    :
                                    <Typography variant="body1">Raison Sociale: {!R.isNil(item.companyName) ? <strong>{item.companyName}</strong> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>
                                }
                            </Stack>
                            
                            <Typography variant="body1" color="inherit" sx={{ textDecoration: "none", lineHeight: 1 }} component={RouterLink} to={"/app/companies/" + companyState.companies?.filter(company => item.companyIds.includes(company.id)).map(c => c.id)}>Associé à: <strong> {!R.isNil(companyState.companies) && !R.isEmpty(companyState.companies) && companyState.companies.filter(company => item.companyIds.includes(company.id)).map(c => c.title).join(', ')} </strong></Typography>
                        </Stack>
                    </Stack>
                </Container>
                {/* {item.profileUrl?.url && <Box position="absolute" sx={{ borderRadius: 'inherit', backdropFilter: 'blur(2px)', backgroundImage: ('linear-gradient(180deg,' + theme.palette.background.dim + '30%, ' + theme.palette.background.main + ' 100%)'), zIndex: -1 }} display="block" left="0" bottom="0" top="0" right="0"></Box>} */}
            </Box>
    );
};

export default TenantsViewDetailHeader;
