import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import * as R from 'ramda';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from "@mui/material/TextField";

import { GetUser, UpdateUser } from "../../actions/user-api";
import { UploadProfilePhoto } from '../../actions/file-api';
import * as Loadings from '../../constants/loadings/user';
import * as LoadingsFile from '../../constants/loadings/file';
import { inputTypes } from "../../constants/utils/validators";
import FormProfilePhoto from '../_common/forms/_common/profilePhoto';




const ModalEditProfile = ({ fileState, modalClose, photo, userState, open, theme }) => {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const user = userState.user;
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [currentPhoto, setCurrentPhoto] = useState(!R.isNil(user.profileUrl) ? user.profileUrl.url : null);
    const dispatch = useDispatch();

    useEffect(() => {
        setValue('firstName', user.firstName, { shouldValidate: true });
        setValue('lastName', user.lastName, { shouldValidate: true });
        setValue('phoneNumber', user.phoneNumber, { shouldValidate: false });
        // eslint-disable-next-line
    }, []);


    const submitForm = async (data) => {
        const userModel = {
            firstName: data.firstName.trim(),
            lastName: data.lastName.trim(),
            phoneNumber: data.phoneNumber
        };
        try {
            await dispatch(UpdateUser(user.id, userModel));
            if (!R.isNil(profilePhoto)) {
                await dispatch(UploadProfilePhoto({ file: profilePhoto, id: user.id, profile: "User" }));
                await dispatch(GetUser(user.id));
            }
            modalClose();
        } catch (error) {
            console.log(error);
            // toast.error("Échec de l'opération, réessayez !", toastConf);
        }
    };

    return (
        <Dialog fullWidth maxWidth="xs" onClose={modalClose} open={open} theme={theme}>
            <DialogTitle variant="h4" color="primary" sx={{ pt: theme.spacing(3), pb: theme.spacing(1) }}>Modifier votre profil</DialogTitle>
            <DialogContent>
                <DialogContentText variant="body">
                    Vous pouvez mettre à jour les informations de votre profil, notamment votre nom et votre numéro de téléphone, associés à votre compte.
                </DialogContentText>
                <Box component="form" onSubmit={handleSubmit(submitForm)} maxWidth={true} width="100%" style={{ gap: theme.spacing(1) }} mt={theme.spacing(3)} sx={{ '.MuiInputBase-root': { borderRadius: theme.spacing(4) } }}>
                    <Stack direction="row" spacing={1}>
                        <FormProfilePhoto theme={theme} currentPhoto={currentPhoto} profilePhoto={profilePhoto} setProfilePhoto={setProfilePhoto} />
                        <TextField
                            label='Prénom'
                            id="Prénom"
                            type="text"
                            fullWidth
                            variant="filled"
                            inputProps={register("firstName", { required: inputTypes.required.errorMessage, validate: v => !!v.trim() })}
                            error={!!errors.firstName}
                            helperText={errors.firstName ? errors.firstName.message : ''}
                        />
                        <TextField
                            label='Nom'
                            id="Nom"
                            type="text"
                            fullWidth
                            variant="filled"
                            inputProps={register("lastName", { required: inputTypes.required.errorMessage, validate: v => !!v.trim() })}
                            error={!!errors.lastName}
                            helperText={errors.lastName ? errors.lastName.message : ''}
                        />
                    </Stack>
                    <Stack direction="column" spacing={1} my={1}>
                        <TextField
                            label='Téléphone'
                            id="Téléphone"
                            type="text"
                            // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            fullWidth
                            variant="filled"
                            inputProps={register("phoneNumber", { required: inputTypes.required.errorMessage })}
                            error={!!errors.phoneNumber}
                            helperText={errors.phoneNumber ? errors.phoneNumber.message : ''}
                        />
                        {/* <TextField
                            margin="dense"
                            id="email"
                            label="Email"
                            type="email"
                            fullWidth
                            variant="filled"
                            inputProps={register("email", { required: inputTypes.required.errorMessage, pattern: { value: emailRegex, message: inputTypes.email.errorMessage } })}
                            error={!!errors.email}
                            helperText={errors.email ? errors.email.message : ''}
                        // sx={{ '.MuiInputBase-root': { borderRadius: theme.spacing(6) } }}
                        /> */}
                    </Stack>
                    <Stack direction="column" spacing={1}>
                        {/* <TextField
                            id="newpass"
                            label='Mot de passe'
                            type="password"
                            fullWidth
                            variant="filled"
                            inputProps={register("password", { required: inputTypes.required.errorMessage, validate: v => !!v.trim() })}
                            error={!!errors.password}
                            helperText={errors.password ? errors.password.message : ''}

                        /> */}
                        {/* <TextField
                            id="confirmPass"
                            label='Confirmer le mot de passe'
                            type="password"
                            fullWidth
                            variant="filled"
                            required={true}
                            inputProps={register("passwordConfirm", { required: inputTypes.required.errorMessage, validate: value => value === getValues('password') })}
                            error={!!errors.passwordConfirm}
                            helperText={errors.passwordConfirm ? errors.passwordConfirm.message : ''}
                        /> */}
                    </Stack>
                    <LoadingButton
                        disabled={userState.isLoading.includes(Loadings.UPDATING_USER) || fileState.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
                        loading={userState.isLoading.includes(Loadings.UPDATING_USER) || fileState.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)} type="submit" size="large" fullWidth variant="contained" theme={theme} color='primary' sx={{ px: theme.spacing(3), mb: theme.spacing(1) }}>Enregistrer</LoadingButton>
                    <Button theme={theme} color="neutral" fullWidth onClick={modalClose} sx={{ px: theme.spacing(3) }}>Annuler</Button>
                </Box>
            </DialogContent>
            {/* <DialogActions disableSpacing={true} sx={{ flexDirection: "column-reverse", p: theme.spacing(3), pt: 0 }}>
                <Button theme={theme} color="neutral" fullWidth onClick={modalClose} sx={{ px: theme.spacing(3) }}>Annuler</Button>
                <Button size="large" fullWidth variant="contained" theme={theme} color='primary' sx={{ px: theme.spacing(3), mb: theme.spacing(1) }}>Enregistrer</Button>
            </DialogActions> */}
        </Dialog >
    );
};

export default ModalEditProfile;