import axios from 'axios';
import * as R from 'ramda';
import { collaboratorsActions } from "../reducers/collaborator";
import * as urls from '../constants/webapi/collaborator';
import { PAGE_SIZE, SMALL_PAGE_SIZE } from '../constants/utils/pageSize';
import { urlBuilder } from '../constants/utils/urlBuilder';



export const GetCollaborators = ({ pageSize, pageNumber, sortBy, sortDesc, filter } = {}) => {
    const size = !R.isNil(pageSize) && !R.isEmpty(pageSize) ? pageSize : PAGE_SIZE;
    const page = !R.isNil(pageNumber) && !R.isEmpty(pageNumber) ? pageNumber : 1;
    const sort = !R.isNil(sortBy) && !R.isEmpty(sortBy) ? sortBy : undefined;
    const desc = !R.isNil(sortDesc) && !R.isEmpty(sortDesc) ? sortDesc : false;
    const filters = !R.isNil(filter) && !R.isEmpty(filter) ? urlBuilder(filter) : undefined;

    return async dispatch => {
        dispatch(collaboratorsActions.getCollaboratorsRequest());
        return axios.get(urls.getCollaboratorsUrl({ size, page, sort, desc, filters }))
            .then((response) => {
                dispatch(collaboratorsActions.getCollaboratorsSuccess({
                    data: response.data,
                    pageSize: size,
                    pageNumber: page,
                    sortBy: sort,
                    sortDesc: desc
                }));
            })
            .catch((error) => {
                dispatch(collaboratorsActions.getCollaboratorsFailure(error.response.data));
            });
    };
};

export const GetDashboardCollaborators = ({ pageSize, pageNumber, sortBy, sortDesc } = {}) => {
    const params = { size: SMALL_PAGE_SIZE, page: 1, sort: "firstName", desc: false };

    return async dispatch => {
        dispatch(collaboratorsActions.getDashboardCollaboratorsRequest());
        return axios.get(urls.getCollaboratorsUrl(params))
            .then((response) => {
                dispatch(collaboratorsActions.getDashboardCollaboratorsSuccess({
                    data: response.data,
                    pageSize: params.size,
                    pageNumber: params.page,
                    sortBy: params.sort,
                    sortDesc: params.desc
                }));
            })
            .catch((error) => {
                dispatch(collaboratorsActions.getDashboardCollaboratorsFailure(error.response.data));
            });
    };
};

export const CreateCollaborator = (collaborator) => {
    return async dispatch => {
        dispatch(collaboratorsActions.createCollaboratorRequest());
        return axios.post(urls.getCollaboratorUrl(), collaborator)
            .then((response) => {
                dispatch(collaboratorsActions.createCollaboratorSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(collaboratorsActions.createCollaboratorFailure(error.response.data));
                throw error;
            });
    };
};

export const GetCollaborator = (collaboratorId) => {
    return async dispatch => {
        dispatch(collaboratorsActions.getCollaboratorRequest());
        return axios.get(urls.getCollaboratorByIdUrl(collaboratorId))
            .then((response) => {
                dispatch(collaboratorsActions.getCollaboratorSuccess(response.data));
            })
            .catch((error) => {
                dispatch(collaboratorsActions.getCollaboratorFailure(error.response.data));
            });
    };
};

export const UpdateCollaborator = (collaborator) => {
    return async dispatch => {
        dispatch(collaboratorsActions.updateCollaboratorRequest());
        return axios.put(urls.getCollaboratorByIdUrl(collaborator.id), collaborator)
            .then((response) => {
                dispatch(collaboratorsActions.updateCollaboratorSuccess(collaborator));
                return response.data;
            })
            .catch((error) => {
                dispatch(collaboratorsActions.updateCollaboratorFailure(error.response.data));
                throw error;
            });
    };
};

export const DeleteCollaborator = (collaboratorId) => {
    return async dispatch => {
        dispatch(collaboratorsActions.deleteCollaboratorRequest());
        return axios.delete(urls.getCollaboratorByIdUrl(collaboratorId))
            .then((response) => {
                dispatch(collaboratorsActions.deleteCollaboratorSuccess({ id: collaboratorId }));
                return response.data;
            })
            .catch((error) => {
                dispatch(collaboratorsActions.deleteCollaboratorFailure(error.response.data));
                throw error;
            });
    };
};