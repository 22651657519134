import React from 'react';

// Components
// Components: Global
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Components: Views
import ContratsMenu from './menu';
import ContratsView from './view';

export const Contrats = ({ theme, userState, companyState, tenantState, assetState, assetGroupState, subcontractorState, collaboratorState, documentState, rentalState, paymentState, fileState }) => {

    return (
        <Box component="section" className="layout" flex="1 1 auto" alignItems="center" width="100%" height="calc(100% - 6.5rem)" overflow="hidden auto" mt={{ md: -2 }}>
            <Typography component="h1" className="sr-only">Content</Typography>
            <ContratsMenu theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} documentState={documentState} rentalState={rentalState} paymentState={paymentState} />
            <ContratsView theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} documentState={documentState} rentalState={rentalState} paymentState={paymentState} fileState={fileState} />
        </Box>
    );
};

export default Contrats;
