import * as R from 'ramda';

export const BASE_URL = process.env.REACT_APP_APIURL;


// export const getAssetGroupsUrl = ({ page, size, sort, desc }) => `${BASE_URL}AssetGroup/list?Page=${page}&PageSize=${size}${R.isNil(sort) ? "" : "&Sorts=" + sort}${R.isNil(desc) ? "" : desc ? "&sortByDesc=true" : "&sortByDesc=false"}`;
export const getAssetGroupsUrl = ({ page, size, sort, desc, filters }) => `${BASE_URL}AssetGroup?Page=${page}&PageSize=${size}${R.isNil(sort) ? "" : + desc ? "&Sorts=-" + sort : "&Sorts=" + sort}${filters ? "&filters=" + filters : ""}`;

export const getAssetGroupUrl = (companyId) => `${BASE_URL}AssetGroup/${companyId}`;
export const getAssetGroupByIdUrl = (companyId, assetGroupId) => `${BASE_URL}AssetGroup/${companyId}/${assetGroupId}`;
