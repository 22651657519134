import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import palette from './palette';

const components = createTheme({
	MuiCssBaseline: {
		styleOverrides: {
			// '@font-face': {
			//   fontFamily: 'Rubik',
			//   fontStyle: 'normal',
			//   fontDisplay: 'swap',
			//   fontWeight: '300 900',
			//   src: `local('Rubik'), local('rubik-all-600-normal'), url(${Rubik}) format('woff2')`,
			//   unicodeRange: `U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF`,
			// },
			'*': {
				boxSizing: 'border-box',
			},
			html: {
				width: '100%',
				height: '100%',
			},
			body: {
				margin: 0,
				padding: 0,
				height: 'inherit',
			},
			'.truncate' : {
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				flex: 1,
			},
			'.truncated' : {
				flex: 1,
				minWidth: 0, /* or some value */
				'> *' : {
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				},
			},
			'.truncate-auto' : {
				flex: '1 1 auto',
				'@media (min-width: 1280px)': {
					'.truncate-auto' : {
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						flex: 1,
					},
				},
			},
			'@media (min-width: 667px)': {
				'.truncated-auto' : {
					flex: 1,
					minWidth: 0, /* or some value */
					'> *' : {
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					},
				},
			},
			'#root, .layout': {
				display: 'flex',
				height: 'inherit',
			},
			"*[dir='rtl'] .buyNowImg": {
				transform: 'scaleX(-1)',
			},
			'.layout' : {
				flexDirection: 'column',
				'@media (min-width: 855px)': {
					flexDirection: 'row',
				},
				'>.aside': {
					minWidth: '100%',
					// '@media (min-height: 375px) and (min-width: 667px)': {
					// '@media (min-width: 667px)': {
					// 	minWidth: '280px',
					// },
					'@media (min-width: 855px)': {
						minWidth: '380px',
						borderRight: '2px solid #5d8bdE',
					},
					'@media (min-width: 1280px)': {
						minWidth: '480px',
					},
				},
			},
			'@media (min-width: 855px)': {
				'.flex-bp-column' : {
					flexDirection: 'column !important',
				},
				'.flex-bp-row' : {
					flexDirection: 'row !important',
				},
			},
			".visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within), .sr-only, .sr-only-focusable:not(:focus):not(:focus-within)": {
				position: 'absolute !important',
				width: '1px !important',
				height: '1px !important',
				padding: '0 !important',
				margin: '-1px !important',
				overflow: 'hidden !important',
				clip: 'rect(0, 0, 0, 0) !important',
				whiteSpace: 'nowrap !important',
				border: '0 !important',
			},
			'.MuiCardHeader-action': {
				alignSelf: 'center !important',
			},
			'.scrollbar-container': {
				borderRight: '0 !important',
			},
			"*[dir='rtl'] .welcomebg:before": {
				backgroundPosition: 'top -24px left -9px !important',
			},
			".view": {
				position: 'fixed',
				display: "flex",
				flex: "1 1 auto",
				height: "100%",    
				width: "100%",
				overflow: "hidden",
				overflowY: "auto",
				backgroundColor: 'inherit',
				flexDirection: 'column',
				zIndex: '1',
				transition: 'opacity 0.4s ease, left 0.4s ease',
				top: '0',
				bottom: '0',
				"&:not(.show)": {
					left: '100%',
					opacity: '0',
				},
				"&.show": {
					opacity: 1,
				},
			},
			"@media (min-width: 667px)": { 
				".open-view .view:not(.show)": {
					opacity: 1,
				},
			},
			".rounded-0 >* >*": {
				borderRadius: '0!important;'
			}
		},
	},
	MuiTabs: {
		styleOverrides: {
			flexContainer: {
				flex: '1 1 auto',
				justifyContent: 'space-between'
			},
		},
	},
	MuiContainer: {
		styleOverrides: {
			root: {
				// height: '100%'
			},
		},
	},	
	MuiBottomNavigation: {
		styleOverrides: {
			root: {
				borderTopLeftRadius: '24px',
				borderTopRightRadius: '24px',
				boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
			},
		},
	},
	MuiBottomNavigationAction: {
		styleOverrides: {
			root: {
				fontWeight: '500',
				textTransform: 'uppercase',
				fontSize: '.9375rem',
				borderBottom: '2px solid transparent',
				padding: '0 16px',
				flex: '1 1 auto',
				// borderRadius: 'inherit',
				'&.Mui-selected':{
					borderColor: 'currentColor'
				}
			},
			label: {
				fontSize: 'inherit',
				'&.Mui-selected':{
					fontSize: 'inherit',
				}
			}
		},
	},
	MuiSelect: {
		styleOverrides: {
			root: {
				borderColor: 'transparent',
				borderRadius: '40px',
				'&:before, &:after': {
					borderRadius: 'inherit',
				},
			},
			select: {
				borderColor: 'transparent',
				borderRadius: 'inherit',
				'&:-webkit-autofill': {
					borderRadius: 'inherit !important',
				},
			},
		},
	},
	MuiTextField: {
		defaultProps: {
			fullWidth: true,
			variant: "filled"
		},
		styleOverrides: {
			root: {
				borderColor: 'transparent',
				borderRadius: '40px',
				'&:before': {
					borderColor: 'inherit !important',
				},
				'&:before, &:after': {
					left: '1.75rem',
					right: '1.75rem',
				},
				'&[readonly]': {
					opacity: '.66',
					pointerEvents: 'none',
					userSelect: 'none',
				},
			},
			input: {
				borderRadius: 'inherit',
				'&:-webkit-autofill': {
					borderRadius: 'inherit !important',
				},
			}
		}
	},
	MuiOutlinedInput: {
		styleOverrides: {
			root: {
				borderColor: 'transparent',
				borderRadius: '40px',
				'&:before': {
					borderColor: 'inherit !important',
				},
				'&:before, &:after': {
					left: '1.75rem',
					right: '1.75rem',
				},
			},
			input: {
				borderRadius: 'inherit',
				'&:-webkit-autofill': {
					borderRadius: 'inherit !important',
				},
			}
		},
	},
	MuiFilledInput: {
		styleOverrides: {
			root: {
				backgroundColor: 'rgba(40,49,66,0.06)',
				borderColor: 'transparent',
				borderRadius: '40px',
				'&:before': {
					borderColor: 'inherit !important',
				},
				'&:before, &:after': {
					left: '1.75rem',
					right: '1.75rem',
				},
				'&[readonly]': {
					backgroundColor: 'transparent',
				},
			},
			input: {
				borderColor: 'transparent',
				borderRadius: 'inherit',
				'&:-webkit-autofill': {
					borderRadius: 'inherit !important',
				},
			}
		},
	},
	MuiToggleButtonGroup: {
		styleOverrides: {
			root: {
				borderRadius: '40px',
				padding: '4px',
			},
			grouped: {
				border: 0,
				'&.Mui-disabled': {
					border: 0,
				},
			},
		},
	},
	MuiToggleButton: {
		styleOverrides: {
			root: {
				borderRadius: 'inherit',
			},
			sizeMedium: {
				padding: '8px 14px',
			},
		},
	},
	MuiButton: {
		styleOverrides: {
			root: {
				// boxShadow: 'none',
				// textAlign: 'center',
				borderRadius: '24px',
				'&:hover': {
					boxShadow: 'none',
				},
			},
			sizeLarge:{
				borderRadius: '24px',
			},
			textSizeInherit:{
				fontSize: "inherit",
				fontWeight:"inherit", 
				textTransform: "none", 
				justifyContent:"start"
			},
		},
		variants: [
			{
			  props: { variant: 'card' },
			  style: {
				padding: '16px',
				textTransform: 'none',
				borderRadius: '8px',
				textAlign: 'center',
				flexDirection:'column',
				gap: `12px`,
				backgroundColor: palette.primary.contrastText,
			  },
			},
			{
			  props: { variant: 'card', color: 'active' },
			  style: {
				boxShadow: shadows[2],
				backgroundColor: palette.primary.main,
				color: palette.primary.contrastText,
				'&:hover': {
					backgroundColor: palette.text.dark,
					boxShadow: shadows[3],
				},
			  },
			},
			{
			  props: { variant: 'card', color: 'primary' },
			  style: {
				boxShadow: shadows[2],
				backgroundColor: palette.primary.main,
				color: palette.primary.contrastText,
				'&:hover': {
					backgroundColor: palette.primary.dark,
					boxShadow: shadows[3],
				},
			  },
			},
			{
			  props: { variant: 'card', color: 'secondary' },
			  style: {
				boxShadow: shadows[2],
				backgroundColor: palette.secondary.main,
				color: palette.secondary.contrastText,
				'&:hover': {
					backgroundColor: palette.secondary.dark,
					boxShadow: shadows[3],
				},
			  },
			},
		],
	},
	MuiListItem: {
		styleOverrides: {
			root: {
				borderRadius: '9px',
			},
		},
	},
	MuiCard: {
		styleOverrides: {
			root: {
				borderRadius: '20px',
				boxShadow: '0px 8px 16px 0px rgba(40,49,66, 0.11)',
			},
		},
	},
	MuiListItemIcon: {
		styleOverrides: {
			root: {
				minWidth: '40px',
			},
		},
	},
	MuiGridItem: {
		styleOverrides: {
			root: {
				paddingTop: '30px',
				paddingLeft: '30px !important',
			},
		},
	},
	MuiLinearProgress: {
		styleOverrides: {
			root: {
				backgroundColor: '#ecf0f2',
				borderRadius: '6px',
			},
		},
	},
	MuiMenuItem: {
		styleOverrides: {
			root: {
				borderRadius: '0',
			},
		},
	},
	MuiChip: {
		styleOverrides: {
			root: {
				fontWeight: '500',
				fontSize: '0.75rem',
			},
		},
	},
});

export default components;
	