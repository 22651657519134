import { useDispatch, useSelector } from 'react-redux';
import { DeleteDocument } from '../actions/document-api';
import * as DocumentLoadings from '../constants/loadings/document';

export const useDeleteDocument = () => {
    const documentState = useSelector(state => state.document);
    const dispatch = useDispatch();

    const deleteDocumentProp = (docId) => async () => {
        const doc = documentState.documents?.find(d => d.id === docId);
        await dispatch(DeleteDocument(doc?.companyId, doc?.id));
    };

    return (
        {
            isLoading: documentState.isLoading.includes(DocumentLoadings.DELETING_DOCUMENT),
            isDisabled: documentState.isLoading.includes(DocumentLoadings.DELETING_DOCUMENT),
            dispatchDelete: deleteDocumentProp,
        }
    );
};
