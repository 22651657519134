import React from 'react';
import * as R from 'ramda';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from "@mui/material/TextField";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Paper from '@mui/material/Paper';

// Components: Common
import { initialCompanyForm } from '../../../../constants/utils/formInitialData';
import { onChangeValidation } from '../../../../constants/utils/validators';
import { PARTICULAR } from '../../../../constants/utils/magicVariables';
import FormProfilePhoto from '../_common/profilePhoto';
import FormGlobalComplete from '../_common/globalComplete';

// Components: Views

export const FormTenant = ({ theme, mt, flex, ...others }) => {

    const {
        category,
        setCategory,
        assets,
        rentals,
        formData,
        setFormData,
        setAssetsFormData,
        assetsFormData,
        rentalsFormData,
        setRentalsFormData,
        profilePhoto,
        setProfilePhoto,
        companyUseAutocomplete,
        currentPhoto
    } = others;

    const handleOnChange = (event) => { setFormData(onChangeValidation(event.target, formData)); };

    const changeTenantType = (event, action) => {
        let formDataTmp = R.clone(formData);
        formDataTmp.social.isRequired = action !== PARTICULAR;
        setCategory(action);
        setFormData(formDataTmp);
    };

    return (
        <React.Fragment>
            <Stack spacing={2} width="100%" theme={theme} mt={mt} flex={flex}>
                <Container maxWidth="100%" sx={{ display: "inherit", flexDirection: "inherit", gap: 2 }}>
                    <Stack direction="column" spacing={4}>
                        <Paper elevation={0} sx={{ marginX: "auto", width: "auto", borderRadius: 5 }}>
                            <ToggleButtonGroup exclusive aria-label="tenant type"
                                onChange={changeTenantType}
                                value={category}>
                                <ToggleButton value={1} aria-label="Particulier">
                                    <Typography variant="button">Particulier</Typography>
                                </ToggleButton>
                                <ToggleButton value={2} aria-label="entreprise">
                                    <Typography variant="button">Entreprise</Typography>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Paper>
                        {category === PARTICULAR ?
                            <Stack direction="column" spacing={2}>
                                <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                                    <FormProfilePhoto theme={theme} currentPhoto={currentPhoto} profilePhoto={profilePhoto} setProfilePhoto={setProfilePhoto} />
                                    <Stack direction="row" flex="1 1 auto" spacing={1}>
                                        <TextField
                                            label='Prénom *'
                                            id="Prenom"
                                            type="text"
                                            fullWidth
                                            variant="filled"
                                            name="firstName"
                                            error={formData.firstName.error && !formData.firstName.isValid}
                                            helperText={formData.firstName.error}
                                            value={formData.firstName.value}
                                            onChange={(event) => { handleOnChange(event); }}
                                        />
                                        <TextField
                                            label='Nom *'
                                            id="Nom"
                                            type="text"
                                            fullWidth
                                            variant="filled"
                                            name="lastName"
                                            error={formData.lastName.error && !formData.lastName.isValid}
                                            helperText={formData.lastName.error}
                                            value={formData.lastName.value}
                                            onChange={(event) => { handleOnChange(event); }}
                                        />
                                    </Stack>
                                </Stack>
                                <TextField
                                    label='Profession'
                                    id="Profession"
                                    type="text"
                                    // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    fullWidth
                                    variant="filled"
                                    name="profession"
                                    error={formData.profession.error && !formData.profession.isValid}
                                    helperText={formData.profession.error}
                                    value={formData.profession.value}
                                    onChange={(event) => { handleOnChange(event); }}
                                />
                                <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                                    <TextField
                                        label='Téléphone'
                                        id="Téléphone"
                                        type="text"
                                        fullWidth
                                        variant="filled"
                                        name="phone"
                                        error={formData.phone.error && !formData.phone.isValid}
                                        helperText={formData.phone.error}
                                        value={formData.phone.value}
                                        onChange={(event) => { handleOnChange(event); }}
                                    />
                                    <TextField
                                        label='Email'
                                        id="Email"
                                        type="text"
                                        fullWidth
                                        variant="filled"
                                        name="email"
                                        error={formData.email.error && !formData.email.isValid}
                                        helperText={formData.email.error}
                                        value={formData.email.value}
                                        onChange={(event) => { handleOnChange(event); }}
                                    />
                                </Stack>
                            </Stack>
                            :
                            <Stack direction="column" spacing={2}>
                                <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                                    <FormProfilePhoto theme={theme} profilePhoto={profilePhoto} setProfilePhoto={setProfilePhoto} />
                                    <TextField
                                        label='Raison sociale *'
                                        id="RaisonSocial"
                                        type="text"
                                        fullWidth
                                        variant="filled"
                                        name="social"
                                        error={formData.social.error && !formData.social.isValid}
                                        helperText={formData.social.error}
                                        value={formData.social.value}
                                        onChange={(event) => { handleOnChange(event); }}
                                    />
                                </Stack>
                                <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                                    <TextField
                                        label='Prénom'
                                        id="Prenom"
                                        type="text"
                                        fullWidth
                                        variant="filled"
                                        name="firstName"
                                        error={formData.firstName.error && !formData.firstName.isValid}
                                        helperText={formData.firstName.error}
                                        value={formData.firstName.value}
                                        onChange={(event) => { handleOnChange(event); }}
                                    />
                                    <TextField
                                        label='Nom'
                                        id="Nom"
                                        type="text"
                                        fullWidth
                                        variant="filled"
                                        name="lastName"
                                        error={formData.lastName.error && !formData.lastName.isValid}
                                        helperText={formData.lastName.error}
                                        value={formData.lastName.value}
                                        onChange={(event) => { handleOnChange(event); }}
                                    />
                                </Stack>
                                <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                                    <TextField
                                        label='Téléphone'
                                        id="Téléphone"
                                        type="text"
                                        fullWidth
                                        variant="filled"
                                        name="phone"
                                        error={formData.phone.error && !formData.phone.isValid}
                                        helperText={formData.phone.error}
                                        value={formData.phone.value}
                                        onChange={(event) => { handleOnChange(event); }}
                                    />
                                    <TextField
                                        label='Email'
                                        id="Email"
                                        type="text"
                                        fullWidth
                                        variant="filled"
                                        name="email"
                                        error={formData.email.error && !formData.email.isValid}
                                        helperText={formData.email.error}
                                        value={formData.email.value}
                                        onChange={(event) => { handleOnChange(event); }}
                                    />
                                </Stack>
                            </Stack>
                        }
                        {/* <Stack direction="column" flex="1 1 auto" spacing={1} width="100%">
                            <Typography variant="label" color="text.secondary">Contrat de location</Typography>
                            {assetsFormData.map((asset, index) => (
                                <Stack direction={{ default: "column", md: "row" }} spacing={1} my={1} key={index + 15}>
                                    <Autocomplete
                                        value={assetsFormData[index].label}
                                        disablePortal
                                        fullWidth
                                        sx={{ width: '100%' }}
                                        id="bien"
                                        onChange={(event, newValue) => {
                                            const assetsFormDataTmp = R.clone(assetsFormData);
                                            const rentalsFormDataTmp = R.clone(rentalsFormData);
                                            if (typeof newValue === 'string') {
                                                //timeout to avoid instant validation of the dialog's form.
                                                setTimeout(() => {
                                                    //handleSetCompanyOpen(newValue, index);
                                                });
                                            } else if (newValue && newValue.inputValue) {
                                                //handleSetCompanyOpen(newValue.inputValue, index);
                                            } else {
                                                assetsFormDataTmp[index] = {
                                                    ...assetsFormDataTmp[index],
                                                    error: null,
                                                    isValid: false,
                                                    label: !R.isNil(newValue) && !R.isNil(newValue.label) ? newValue.label : '',
                                                    value: !R.isNil(newValue) && !R.isNil(newValue.assetId) ? newValue.assetId : '',
                                                    companyId: !R.isNil(newValue) && !R.isNil(newValue.companyId) ? newValue.companyId : ''
                                                };
                                                setAssetsFormData(assetsFormDataTmp);
                                                rentalsFormDataTmp[index].isRequired = true;
                                                setRentalsFormData(rentalsFormDataTmp);
                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            if (params.inputValue !== '') {
                                                filtered.push({
                                                    inputValue: params.inputValue,
                                                    label: `Ajouter "${params.inputValue}"`,
                                                });
                                            }

                                            return filtered;
                                        }}
                                        options={assets}
                                        getOptionLabel={(option) => {
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            return option.label;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                        freeSolo
                                        renderInput={(params) =>
                                            <TextField
                                                label="Bien"
                                                type="text"
                                                fullWidth
                                                variant="filled"
                                                {...params}
                                                name="bien"
                                                error={assetsFormData[index].error && !assetsFormData[index].isValid}
                                                helperText={assetsFormData[index].error}
                                            />}
                                    />
                                    <TextField
                                        label="Société"
                                        readOnly={true}
                                        type="text"
                                        variant="outlined"
                                        value={!R.isNil(companyState.companies) && !R.isEmpty(companyState.companies) ? companyState.companies.find(c => c.id === asset.companyId)?.title : ''}
                                    // onChange={(event) => handleData('Société', event)}
                                    />
                                    <Autocomplete
                                        value={rentalsFormData[index].label}
                                        disablePortal
                                        fullWidth
                                        sx={{ width: '100%' }}
                                        id="Contrat"
                                        onChange={(event, newValue) => {
                                            const rentalsFormDataTmp = R.clone(rentalsFormData);
                                            if (typeof newValue === 'string') {
                                                // timeout to avoid instant validation of the dialog's form.
                                                setTimeout(() => {
                                                    //  handleSetCompanyOpen(newValue, index);
                                                });
                                            } else if (newValue && newValue.inputValue) {
                                                // handleSetCompanyOpen(newValue.inputValue, index);
                                            } else {
                                                rentalsFormDataTmp[index] = { ...rentalsFormDataTmp[index], error: null, isValid: false, label: !R.isNil(newValue) && !R.isNil(newValue.label) ? newValue.label : '', value: !R.isNil(newValue) && !R.isNil(newValue.rentalId) ? newValue.rentalId : '' };
                                                setRentalsFormData(rentalsFormDataTmp);
                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            if (params.inputValue !== '') {
                                                filtered.push({
                                                    inputValue: params.inputValue,
                                                    label: `Ajouter "${params.inputValue}"`,
                                                });
                                            }

                                            return filtered;
                                        }}
                                        options={rentals}
                                        getOptionLabel={(option) => {
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            return option.label;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                        freeSolo
                                        renderInput={(params) =>
                                            <TextField
                                                label="Contrat"
                                                type="text"
                                                fullWidth
                                                variant="filled"
                                                {...params}
                                                name="Contrat"
                                                error={rentalsFormData[index].error && !rentalsFormData[index].isValid}
                                                helperText={rentalsFormData[index].error}
                                            />}
                                    />
                                </Stack>
                            ))
                            }
                            <Button variant="text" onClick={() => {
                                setAssetsFormData([...assetsFormData, { ...initialAssetForm }]);
                                setRentalsFormData([...rentalsFormData, { ...initialRentalForm }]);
                            }} color="inherit" size="inherit" startIcon={<AddIcon color="primary" />}>
                                Ajouter nouveau
                            </Button>
                        </Stack> */}
                        <Stack direction="column" flex="1 1 auto" spacing={1} width="100%">
                            <Typography variant="label" color="text.secondary">Rattaché à</Typography>
                            <FormGlobalComplete
                                options={companyUseAutocomplete.options}
                                optionsFormData={companyUseAutocomplete.dropdownFormData}
                                setOptionsFormData={companyUseAutocomplete.setDropdownFormData}
                                handleSetOpen={companyUseAutocomplete.handleSetOpen}
                                handleSetOptionsFormData={companyUseAutocomplete.handleSetOptionsFormData}
                                multi={true}
                                name="company"
                                label="Société"
                                initialFormData={initialCompanyForm}
                            />
                        </Stack>
                    </Stack>
                </Container>
            </Stack>
        </React.Fragment >
    );
};

export default FormTenant;
