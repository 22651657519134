import React from 'react';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from "@mui/material/TextField";

// Components: Common
import { onChangeValidation } from '../../../../constants/utils/validators';
import FormGlobalComplete from '../_common/globalComplete';

// Components: Views

export const FormPassword = ({ theme, formData, setFormData, companyUseAutocomplete, tenantUseAutocomplete, companyId, ...others }) => {
    const handleOnChange = (event) => { setFormData(onChangeValidation(event.target, formData)); };

    return (
        <Stack spacing={2} flex="1 1 auto" width="100%" {...others}>
            <Container maxWidth="100%" sx={{ display: "inherit", flexDirection: "inherit", gap: 2 }}>
                <Typography className="sr-only">Password Form</Typography>
                <Stack direction="column" spacing={1}>
                    <TextField
                        label='Titre *'
                        id="Pre"
                        type="text"
                        variant="filled"
                        name="title"
                        error={formData.title.error && !formData.title.isValid}
                        helperText={formData.title.error}
                        value={formData.title.value}
                        onChange={(event) => { handleOnChange(event); }}
                    />
                    <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                        <TextField
                            label='URL *'
                            id="Nom"
                            type="text"
                            variant="filled"
                            name="url"
                            error={formData.url.error && !formData.url.isValid}
                            helperText={formData.url.error}
                            value={formData.url.value}
                            onChange={(event) => { handleOnChange(event); }}
                        />
                        <TextField
                            label='Identifiant *'
                            id="Identifiant"
                            type="text"
                            variant="filled"
                            name="userName"
                            error={formData.userName.error && !formData.userName.isValid}
                            helperText={formData.userName.error}
                            value={formData.userName.value}
                            onChange={(event) => { handleOnChange(event); }}
                        />
                        <TextField
                            label='Mot de passe *'
                            id="Nom"
                            type="password"
                            variant="filled"
                            name="password"
                            error={formData.password.error && !formData.password.isValid}
                            helperText={formData.password.error}
                            value={formData.password.value}
                            onChange={(event) => { handleOnChange(event); }}
                        />
                    </Stack>
                    <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                        <FormGlobalComplete
                            options={companyUseAutocomplete.options}
                            optionsFormData={companyUseAutocomplete.dropdownFormData}
                            setOptionsFormData={companyUseAutocomplete.setDropdownFormData}
                            handleSetOpen={companyUseAutocomplete.handleSetOpen}
                            handleSetOptionsFormData={companyUseAutocomplete.handleSetOptionsFormData}
                            multi={false}
                            name="company"
                            label="Société"
                            companyId={companyId}
                        />
                        <FormGlobalComplete
                            options={tenantUseAutocomplete.options}
                            optionsFormData={tenantUseAutocomplete.dropdownFormData}
                            setOptionsFormData={tenantUseAutocomplete.setDropdownFormData}
                            handleSetOpen={tenantUseAutocomplete.handleSetOpen}
                            handleSetOptionsFormData={tenantUseAutocomplete.handleSetOptionsFormData}
                            multi={false}
                            name="tenant"
                            label="Locataire"
                        />
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
};

export default FormPassword;
