import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as R from 'ramda';

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from '@mui/lab/LoadingButton';

import * as Loadings from '../../constants/loadings/user';
import GoogleButton from 'react-google-button';
import { GoogleAuthentication, GoogleRevoke } from "../../actions/user-api";
import Loading from '../../_common/loading';


const ModalIntegrations = ({ modalClose, userState, open, theme }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();


    const getService = async (service) => {
        try {
            if (service.type !== "GoogleDrive") return;
            const url = await dispatch(GoogleAuthentication());
            window.location.replace(url);
            // window.location.replace(encodeURI());
        } catch (error) {
            console.log(error);
            // toast.error("Échec de l'opération, réessayez !", toastConf);
        }
    };


    return (
        <Dialog fullWidth maxWidth="xs" onClose={modalClose} open={open} theme={theme} sx={{ textAlign: "center" }}>
            <DialogTitle variant="h4" color="primary" sx={{ pt: 3, pb: 1 }}>Intégrations</DialogTitle>
            {userState.isLoading.includes(Loadings.FETCHING_AVAILABLE_SERVICES) ?
                <Loading />
                :
                !R.isNil(userState.services) && !R.isEmpty(userState.services) &&
                userState.services.map(service => (
                    <React.Fragment key={service.type}>
                        <DialogContent sx={{ alignItems: "center", pb: 1 }}>
                            <DialogContentText variant="body" mb={2}>
                                {service.type === "GoogleDrive" && !service.isConnected ?
                                    "Pour exporter le contenu de vos documents vers Google Drive, veuillez vous connecter à votre compte:"
                                    :
                                    "La connexion à Google Drive a été correctement configurée."
                                }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions disableSpacing={true} sx={{ flexDirection: "column", p: 3, pt: 0 }}>
                            {service.type === "GoogleDrive" && !service.isConnected ?
                                <GoogleButton style={{ margin: "auto" }} onClick={(event) => getService(service)} /> :
                                service.isConnected &&
                                <LoadingButton
                                    onClick={() => dispatch(GoogleRevoke())}
                                    disabled={userState.isLoading.includes(Loadings.REVOKING_GOOGLE_AUTHENTICATION) || userState.isLoading.includes(Loadings.FETCHING_GOOGLE_AUTHENTICATION) || userState.isLoading.includes(Loadings.FETCHING_AVAILABLE_SERVICES)}
                                    loading={userState.isLoading.includes(Loadings.REVOKING_GOOGLE_AUTHENTICATION) || userState.isLoading.includes(Loadings.FETCHING_GOOGLE_AUTHENTICATION) || userState.isLoading.includes(Loadings.FETCHING_AVAILABLE_SERVICES)}
                                    size="large" fullWidth variant="contained" theme={theme} color='error' sx={{ px: 3 }}>Déconnecter Google</LoadingButton>
                            }
                            <Button theme={theme} color="neutral" fullWidth onClick={() => {
                                !R.isEmpty(location.search) && navigate('/app/menu');
                                modalClose();
                            }} sx={{ px: 3, mt: 1 }}>Annuler</Button>
                        </DialogActions>
                    </React.Fragment>
                ))}
            {/* <DialogContent sx={{ alignItems: "center", pb: 1 }}>
                <DialogContentText variant="body" mb={2}>
                    {!isConfigured ?
                        "Pour exporter le contenu de vos documents vers Google Drive, veuillez vous connecter à votre compte:"
                        : "La connexion a été correctement configurée."
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions disableSpacing={true} sx={{ flexDirection: "column", p: 3, pt: 0 }}>
                {!isConfigured ?
                    <GoogleButton style={{ margin: "auto" }} disabled /> :
                    <Button disabled={userState.isLoading.includes(Loadings.UPDATING_USER)} loading={userState.isLoading.includes(Loadings.UPDATING_USER).toString()} type="submit" size="large" fullWidth variant="contained" theme={theme} color='error' sx={{ px: 3 }}>Désactiver access</Button>
                }
                <Button theme={theme} color="neutral" fullWidth onClick={modalClose} sx={{ px: 3, mt: 1 }}>Annuler</Button>
            </DialogActions> */}
        </Dialog >
    );
};

export default ModalIntegrations;