import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as R from 'ramda';

// Components
// Components: Global
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

// Components: Common
import FormCollaborator from '../info/collaborator';
import { onSubmitMultipleValidation, onSubmitValidation, validateTitle } from '../../../../constants/utils/validators';
import { CreateCollaborator, UpdateCollaborator, GetDashboardCollaborators } from '../../../../actions/collaborator-api';
import * as Loadings from '../../../../constants/loadings/collaborator';
import * as LoadingsFile from '../../../../constants/loadings/file';
import { Alert, Dialog } from '@mui/material';
import { UploadProfilePhoto } from '../../../../actions/file-api';
import { useAutoComplete } from '../../../../hooks/useAutoComplete';
import { permissionsTypeMap } from '../../../../constants/utils/objectProperties';
import CompanyModalContent from '../modals/companyModalcontent';


// Components: Views

export const MainCollaborator = ({ theme, collaborators, photo, companies, collaboratorState, initialCollaboratorFormData, initialCompanyForm, resetCompanyForm, id } = {}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stateFile = useSelector(state => state.file);
    const [formData, setFormData] = useState(initialCollaboratorFormData);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [currentPhoto, setCurrentPhoto] = useState(!R.isNil(photo) ? photo : null);
    const companyUseAutocomplete = useAutoComplete(initialCompanyForm, companies, resetCompanyForm);
    const [hasCurrentTitle, setHasCurrentTitle] = useState(null);
    const payload = { items: collaborators, properties: ["firstName", "lastName"], value: formData.firstName.value.trim() + formData.lastName.value.trim(), id: id };


    const submitForm = async (event) => {
        event.preventDefault();
        let newCollaborator;
        const companiesFormValidation = onSubmitMultipleValidation(companyUseAutocomplete.dropdownFormData);
        const validatedForm = onSubmitValidation(formData);

        if (validateTitle(payload)) {
            setHasCurrentTitle(true);
            return setTimeout(() => { setHasCurrentTitle(null); }, 4000);
        }
        if (!validatedForm.isValid || !companiesFormValidation.isValid) {
            setFormData(validatedForm.form);
            companyUseAutocomplete.setDropdownFormData(companiesFormValidation.form);
            return;
        }
        try {
            const collaborator = {
                firstName: formData.firstName.value.trim(),
                lastName: formData.lastName.value.trim(),
                email: formData.email.value.trim(),
                companyAccesses: companyUseAutocomplete.dropdownFormData.map(c =>
                ({
                    companyId: c.value,
                    accessTypes: R.isNil(c.permissionsType) || R.isEmpty(c.permissionsType) ? ['Read'] : permissionsTypeMap[c.permissionsType]
                })),
                files: []
            };
            if (R.isNil(id)) {
                newCollaborator = await dispatch(CreateCollaborator(collaborator));
                collaborator.id = newCollaborator.id;
            } else {
                collaborator.id = id;
                await dispatch(UpdateCollaborator(collaborator));
            }
            if (!R.isNil(profilePhoto)) {
                await dispatch(UploadProfilePhoto({ file: profilePhoto, id: collaborator.id, profile: "Collaborator" }));
            }

            dispatch(GetDashboardCollaborators());
            navigate(`/app/collaborators/${collaborator.id}`);
        } catch (error) {
            console.log(error);
            // toast.error("Échec de l'opération, réessayez !", toastConf);
        }
    };



    return (
        <React.Fragment>
            {hasCurrentTitle &&
                <Container maxWidth="100%" sx={{ display: "inherit", justifyContent: "inherit", gap: 2 }}>
                    <Alert sx={{ width: '100%', borderRadius: 3, marginY: 2 }} severity="error">
                        Élément existant. Sélectionnez l'élément existant ou choisissez un autre élément.
                    </Alert>
                </Container>
            }
            <Stack spacing={2} component="form" onSubmit={(event) => submitForm(event)} flex="1 1 auto" width="100%" height="100%" overflow="hidden auto">
                <FormCollaborator
                    theme={theme}
                    formData={formData}
                    setFormData={setFormData}
                    companyUseAutocomplete={companyUseAutocomplete}
                    profilePhoto={profilePhoto}
                    currentPhoto={currentPhoto}
                    setCurrentPhoto={setCurrentPhoto}
                    setProfilePhoto={setProfilePhoto}
                    id={id}
                    mt={2} flex="1 1 auto"
                />
                <Stack direction={{ default: "column", md: "row" }} justifyContent="end" alignItems="center" pb={2} spacing={2}>
                    <Container maxWidth="sm" sx={{ display: "inherit", justifyContent: "inherit", flex: "1 1 auto", marginLeft: 0 }}>
                        <Typography variant="body2" color="text.secondary">Vous pourrez ajouter des documents relatifs à cet élément après l'avoir Enregistré, à l'aide du bouton + en bas de l'écran.</Typography>
                    </Container>
                    <Container sx={{ display: "inherit", justifyContent: "inherit", gap: 2, flex: "0" }}>
                        <Button color="text" onClick={() => navigate(-1)}>Annuler</Button>
                        <LoadingButton
                            loading={collaboratorState.isLoading.includes(Loadings.CREATING_COLLABORATOR) || collaboratorState.isLoading.includes(Loadings.UPDATING_COLLABORATOR) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_FILES)}
                            disabled={collaboratorState.isLoading.includes(Loadings.CREATING_COLLABORATOR) || collaboratorState.isLoading.includes(Loadings.UPDATING_COLLABORATOR) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_FILES)}
                            type="submit" size="large" variant="contained" color='primary'>Enregistrer</LoadingButton>
                    </Container>
                </Stack>
            </Stack>
            <Dialog fullWidth maxWidth="md" onClose={companyUseAutocomplete.setClose} open={companyUseAutocomplete.isOpen} theme={theme}>
                <CompanyModalContent modalClose={companyUseAutocomplete.setClose} open={companyUseAutocomplete.isOpen} theme={theme} newCompanyName={companyUseAutocomplete.newOptionName} handleAddNewCompany={companyUseAutocomplete.handleAddNew} />
            </Dialog>
        </React.Fragment>
    );
};

export default MainCollaborator;