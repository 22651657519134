import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';


// Components
// Components: Global
import Box from '@mui/material/Box';

// Components: Common

// Components: Views
import TenantsViewEmpty from './empty';
import TenantsViewDetail from './detail';

export const TenantsView = ({ theme, userState, tenantState, documentState, companyState, rentalState, paymentState, assetState, subcontractorState }) => {
    const { pathname } = useLocation();
    const showView = !pathname.endsWith('/tenants');

    return (
        <Box display={{ default: showView ? 'flex' : 'none', lg: "flex" }} flexDirection="column" width="100%" overflow="hidden auto" backgroundColor="background.default" sx={{ borderRadius: 2, position: { default: showView && 'fixed', lg: 'relative' }, left: { default: showView && 0, lg: 'unset' }, right: { default: showView && 0, lg: 'unset' }, top: { default: showView && '5rem', lg: 'unset' }, height: { default: showView && 'calc(100% - 5rem)', lg: '100%' }, bottom: { default: showView && 0, lg: 'unset' }, zIndex: { default: showView && 2, lg: 'unset' } }}>
            <Routes>
                <Route index end element={
                    <TenantsViewEmpty />
                } />
                <Route path=":id/*" element={
                    <TenantsViewDetail theme={theme} userState={userState} tenantState={tenantState} documentState={documentState} companyState={companyState} rentalState={rentalState} paymentState={paymentState} assetState={assetState} subcontractorState={subcontractorState} />
                } />
                <Route path="*" element={<Navigate to="/app/tenants" />} />
                <Route path="/" element={
                    <Navigate replace to="/app/tenants" />
                } />
            </Routes>
        </Box>
    );
};

export default TenantsView;
