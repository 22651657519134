import React from 'react';
import * as R from 'ramda';

import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import { createFilterOptions } from '@mui/base';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';



const filter = createFilterOptions();
export const FormGlobalComplete = ({ options, optionsFormData, setOptionsFormData, handleSetOpen, handleSetOptionsFormData, multi, name, label, initialFormData, hasPermission, companyId }) => {

    return (
        <Stack flex="1 1 auto" direction="column" spacing={2}>
            {
                optionsFormData.map((option, index) => (
                    <Stack flex="1 1 auto" key={name + index} direction={{ default: "column", md: "row" }} spacing={.5}>
                        <Stack flex="1 1 auto" key={name + index} direction={{ default: "column", md: "row" }} spacing={1} my={0}>
                            <Autocomplete
                                disabled={!R.isNil(companyId)}
                                key={index}
                                value={optionsFormData[index].label || null}
                                disablePortal
                                fullWidth
                                sx={{ width: '100%' }}
                                id={name}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        setTimeout(() => { handleSetOpen(newValue, index); });
                                    } else if (newValue && newValue.inputValue) {
                                        handleSetOpen(newValue.inputValue, index);
                                    } else {
                                        handleSetOptionsFormData(newValue, index);
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            inputValue: params.inputValue,
                                            label: `Ajouter "${params.inputValue}"`,
                                        });
                                    }
                                    return filtered;
                                }}
                                options={options}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.label;
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                freeSolo
                                renderInput={(params) =>
                                    <TextField
                                        label={`${label}${option.isRequired ? ' *' : ''}`}
                                        type="text"
                                        fullWidth
                                        variant="filled"
                                        {...params}
                                        name={name}
                                        error={optionsFormData[index].error && !optionsFormData[index].isValid}
                                        helperText={optionsFormData[index].error}
                                    />}
                            />
                            {
                                hasPermission &&
                                <TextField key={"select" + index}
                                    select
                                    disabled={option.permissionsType === 'Admin'}
                                    value={R.isNil(option.permissionsType) ? "Read" : option.permissionsType}
                                    label="Permissions"
                                    variant="outlined"
                                    onChange={(event) => {
                                        const companiesFormDataTmp = R.clone(optionsFormData);
                                        companiesFormDataTmp[index].permissionsType = event.target.value;
                                        setOptionsFormData(companiesFormDataTmp);
                                    }}
                                >
                                    <MenuItem value="Admin" disabled>Admin</MenuItem>
                                    <MenuItem value="Delete">Co-propriétaire (ajout, modification et suppression d'informations)</MenuItem>
                                    <MenuItem value="Update">Écriture (ajout et modification d'informations)</MenuItem>
                                    <MenuItem value="Read">Lecture</MenuItem>
                                    {/* <MenuItem value="Create">Créer</MenuItem> */}
                                    {/* <MenuItem value="Admin" disabled>Admin</MenuItem>
                                    <MenuItem value="Read Create Update Delete">Co-propriétaire (ajout, modification et suppression d'informations)</MenuItem>
                                    <MenuItem value="Read Create Update">Écriture (ajout et modification d'informations)</MenuItem>
                                    <MenuItem value="Create">Créer</MenuItem>
                                    <MenuItem value="Read">Lecture</MenuItem> */}
                                </TextField>
                            }
                        </Stack>
                        {multi &&
                            <IconButton disabled={(index === 0) || (hasPermission && option.permissionsType === 'Admin')} size="medium" color="danger" sx={{ width: 56 }}
                                onClick={() => setOptionsFormData(optionsFormData.filter((c, idx, options) => {
                                    if (options.length === 1) return true;
                                    return idx !== index;
                                }))}>
                                <CloseIcon />
                            </IconButton>
                        }

                    </Stack>
                ))
            }
            {multi &&
                <Button onClick={() => setOptionsFormData([...optionsFormData, { ...initialFormData }])} variant="text" color="inherit" size="inherit" startIcon={<AddIcon color="primary" />}>
                    Ajouter nouveau
                </Button>
            }

        </Stack>
    );
};

export default FormGlobalComplete;