import * as R from 'ramda';

export const downloadFile = async (file) => {

    if (!R.isNil(file) && !R.isEmpty(file)) {

        //get file from endpoint (file.url)
        let blob = await fetch(file.url).then((res) => {        
            return res.blob();
        })

        let url = URL.createObjectURL(blob);

        const link = window.document.createElement("a");
        link.download = file.fileName;
        link.href = url;

        window.document.body.appendChild(link);
        link.click();
        link.remove();
    }
};

export const downloadFileByUrl = async (url) => {

    if (url) {
        let blob = await fetch(url).then((res) => {        
            return res.blob();
        })

        let url = URL.createObjectURL(blob);

        const link = window.document.createElement("a");
        link.download = 'file.fileName';
        link.href = url;

        window.document.body.appendChild(link);
        link.click();
        link.remove();
    }
};