import { createSlice } from '@reduxjs/toolkit';
import * as Loadings from '../constants/loadings/search';



const initState = {
    isLoading: [],
    results: undefined,
};

const SearchReducer = createSlice({
    name: 'search',
    initialState: { ...initState },
    reducers: {
        getSearchRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_SEARCH)) {
                state.isLoading.push(Loadings.FETCHING_SEARCH);
            }
        },
        getSearchSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_SEARCH);
            state.results = payload;
        },
        getSearchFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_SEARCH);
        },

        resetSearchSuccess(state, { payload }) {
            state.results = undefined;
        },
    }
});

export const searchActions = SearchReducer.actions;
export default SearchReducer.reducer;