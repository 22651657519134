import { createSlice } from '@reduxjs/toolkit';
import * as Loadings from '../constants/loadings/tier';


const initState = {
    isLoading: [],
    tiers: undefined,
    tier: undefined
};

const TiersReducer = createSlice({
    name: 'tier',
    initialState: { ...initState },
    reducers: {
        //#region GET TIERS
        getTiersRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_TIERS)) {
                state.isLoading.push(Loadings.FETCHING_TIERS);
            }
        },
        getTiersSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_TIERS);
            state.tiers = payload.data;
        },
        getTiersFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_TIERS);
        },
        //#endregion

        //#region GET TIER
        getTierRequest(state, { payload }) {
        if (!state.isLoading.includes(Loadings.FETCHING_TIER)) {
            state.isLoading.push(Loadings.FETCHING_TIER);
        }
        },
        getTierSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_TIER);
            state.tier = payload.data;
        },
        getTierFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_TIER);
        },
        //#endregion

    }
});

export const tiersActions = TiersReducer.actions;
export default TiersReducer.reducer;