import React, { useEffect, useState } from "react";
import { Link as RouterLink, useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import * as R from "ramda";

// Components
// Components: Global
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Components: Common
import { DeleteAsset, GetAsset } from "../../../../actions/asset-api";
import { GetAssetGroups } from "../../../../actions/assetGroup-api";
import { GetTenants } from "../../../../actions/tenant-api";
import { GetRentals } from "../../../../actions/rental-api";
import { GetDocuments } from "../../../../actions/document-api";
import { GetPayments } from "../../../../actions/payment-api";
import * as AssetLoadings from "../../../../constants/loadings/asset";
import { dayMonthYear } from "../../../../constants/utils/date";
import { paymentTypes } from "../../../../constants/utils/objectProperties";
import Loading from "../../../../_common/loading";
import ItemCard from "../../../_common/itemCard";
import AssetsViewDetailEmpty from "../empty";

// Components: Views
import AssetsViewDetailHeader from "./_common/header";
import ModalDelete from "../../../_common/modalDelete";
import ModalCarousel from "../../../_common/modalCarousel";
import { imageTypes } from "../../../../constants/utils/filesTypes";
import DocumentRow from "../../../_common/documentRow";
import { useDeleteDocument } from "../../../../hooks/useDeleteDocument";
import { EQUAL_QUERY } from "../../../../constants/utils/filterQuery";
import { usePermissionModal } from "../../../../hooks/usePermissionModal";
import { GetSubcontractors } from "../../../../actions/subcontractor-api";
import { TransactionRow } from "../../../_common/transactionRow";
import { useDeletePayment } from "../../../../hooks/useDeletePayment";
import { useTheme } from '@mui/material/styles';
export const AssetsViewDetailBien = ({  companyState, tenantState, assetState, assetGroupState, subcontractorState, collaboratorState, documentState, rentalState, accountState, paymentState }) => {
	const { companyId, id } = useParams();
	const theme = useTheme()
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const showView = !pathname.endsWith("/assets");
	const [openDelete, setOpenDelete] = useState(false);
	const [openCarousel, setOpenCarousel] = useState(false);
	const [openDeleteDoc, setOpenDeleteDoc] = useState(false);
	const [openDeletePay, setOpenDeletePay] = useState(false);
	const [selectedPayment, setSelectedPayment] = useState(false);
	const [rentalsExpanded, setRentalsExpanded] = useState("panel-rentals", true);
	const [docsExpanded, setDocsExpanded] = useState("panel-docs", true);
	const [paymentsExpanded, setPaymentsExpanded] = useState("panel-payments", true);
	const [tenantsExpanded, setTenantsExpanded] = useState("panel-tenants", true);
	const deleteDocument = useDeleteDocument();
	const deletePayment = useDeletePayment();
	const userState = useSelector((state) => state.user);
	const { openPermissionModal } = usePermissionModal();

	const modalOpenDelete = () => {
		setOpenDelete(true);
	};
	const modalCloseDelete = () => {
		setOpenDelete(false);
	};

	const modalOpenCarousel = () => {
		setOpenCarousel(true);
	};
	const modalCloseCarousel = () => {
		setOpenCarousel(false);
	};

	useEffect(() => {
		if (R.isNil(companyState.companies)) {
			// dispatch(GetCompanies());
		}
		if (R.isNil(tenantState.tenants)) {
			dispatch(GetTenants());
		}
		if (R.isNil(assetGroupState.assetGroups)) {
			dispatch(GetAssetGroups());
		}
		if (R.isNil(subcontractorState.subcontractors)) {
			dispatch(GetSubcontractors());
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		dispatch(GetAsset(companyId, id));
		dispatch(GetRentals({ filter: { [EQUAL_QUERY.assetId]: id } }));
		dispatch(GetDocuments({ filter: { [EQUAL_QUERY.assetId]: id } }));
		dispatch(GetPayments({ filter: { [EQUAL_QUERY.assetId]: id } }));
		// dispatch(GetOccurrences({ occurrenceType: 'asset', companyId, occurrenceTypeId: id }));
		// eslint-disable-next-line
	}, [companyId, id]);

	const deleteAssetProp = async () => {
		await dispatch(DeleteAsset(assetState.asset.companyId, assetState.asset.id));
		navigate("/app/assets");
	};

	const permissions = userState.user.companyAccesses.find((c) => c.companyId === companyId)?.accessTypes || [];
	const handleAccordionChange = (panel) => (event, isExpanded) => {
		switch (panel) {
			default:
			case "panel-rentals": {
				setRentalsExpanded(isExpanded ? panel : false);
				break;
			}
			case "panel-docs": {
				setDocsExpanded(isExpanded ? panel : false);
				break;
			}
			case "panel-payments": {
				setPaymentsExpanded(isExpanded ? panel : false);
				break;
			}
			case "panel-tenants": {
				setTenantsExpanded(isExpanded ? panel : false);
				break;
			}
		}
	};

	return (
		<Box
			display={{ default: showView ? "flex" : "none", lg: "flex" }}
			flexDirection="column"
			height="100%"
			width="100%"
			overflow="hidden auto"
			backgroundColor={theme.palette.background.default}
			sx={{
				borderRadius: 2,
				position: { default: showView && "fixed", lg: "relative" },
				left: { default: showView && 0, lg: "unset" },
				right: { default: showView && 0, lg: "unset" },
				top: { default: showView && "5rem", lg: "unset" },
				height: { default: showView && "calc(100% - 5rem)", lg: "inherit" },
				bottom: { default: showView && 0, lg: "unset" },
				zIndex: { default: showView && 2, lg: "unset" },
			}}>
			{assetState.isLoading.includes(AssetLoadings.FETCHING_ASSET) ? (
				<Loading />
			) : (
				<React.Fragment>
					<AssetsViewDetailHeader
						openPermissionModal={openPermissionModal}
						permissions={permissions}
						theme={theme}
						modalOpenDelete={modalOpenDelete}
						openDelete={openDelete}
						modalOpenCarousel={modalOpenCarousel}
						openCarousel={openCarousel}
						userState={userState}
						companyId={companyId}
						companyState={companyState}
						assetState={assetState}
						assetGroupState={assetGroupState}
						tenantState={tenantState}
						subcontractorState={subcontractorState}
						collaboratorState={collaboratorState}
					/>
					{R.isNil(assetState.asset) || R.isEmpty(assetState.asset) ? (
						<AssetsViewDetailEmpty title="Ooops..." description="Quelque chose s'est mal passée. On dirait que ce bien n'existe pas !" />
					) : (
						<Container maxWidth="100%">
							<Stack maxWidth="100%" my={3} direction="column" spacing={4}>
								<Accordion sx={{ backgroundColor: "transparent", boxShadow: "none", border: "none", p: 0, ":before": { backgroundColor: "inherit" } }} disableGutters expanded={rentalsExpanded === "panel-rentals"} onChange={handleAccordionChange("panel-rentals")}>
									<AccordionSummary sx={{ minHeight: "auto", ".MuiAccordionSummary-content": { my: 1 }, p: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
										<Typography variant="h6" sx={{ lineHeight: 1 }}>
											Locations
										</Typography>
									</AccordionSummary>
									<AccordionDetails sx={{ p: 0 }}>
										<Stack maxWidth="100%" direction="column" spacing={1}>
											{!R.isNil(rentalState.rentals) && !R.isEmpty(rentalState.rentals) && (
												<Stack direction="column" spacing={2}>
													<Stack direction="column" spacing={1}>
														{/* <Typography variant="label" color="text.secondary">{rentalState.rentals?.filter(r => r.assetId === assetState.asset?.id)?.filter(r => (!R.isNil(r.startDate) && (R.isNil(r.endDate) || new Date(r.endDate) >= new Date()))).length} Actives</Typography> */}
														{rentalState.rentals
															.filter((r) => r.assetId === assetState.asset?.id)
															?.filter((r) => !R.isNil(r.startDate) && (R.isNil(r.endDate) || new Date(r.endDate) >= new Date()))
															.map((rental, index) => (
																<ItemCard
																	key={"r" + rental.title}
																	to={`/app/contrats/${rental.companyId}/${rental.id}`}
																	component={RouterLink}
																	elevation={R.isNil(rental.startDate) ? 0 : 2}
																	backgroundColor={R.isNil(rental.startDate) ? "transparent" : "background.paper"}
																	border={R.isNil(rental.startDate) && "1px dashed"}
																	borderColor={R.isNil(rental.startDate) && "grey.A300"}>
																	<Avatar variant="circular" children={rental.title.charAt(0)} sx={{ bgcolor: "grey.A200", my: 0.25, width: "3rem", height: "3rem", fontWeight: "bold" }} />
																	<Stack direction="column" spacing={{ default: 0.5, xl: 0 }} pr={{ default: 0, xl: 1 }} flex="1 1 auto" justifyContent={{ lg: "space-between" }}>
																		<Stack direction={{ default: "column", xl: "row" }} spacing={{ xl: 4 }} flex="1 1 auto" justifyContent={{ lg: "space-between" }}>
																			<Typography fontWeight="medium" color="text.dark">
																				{rental.title}
																			</Typography>
																			<Typography variant="body1">
																				<Box component="span" color="text.secondary">
																					Signature:{" "}
																				</Box>
																				{!R.isNil(rental.startDate) ? (
																					<Box component="span" ml={0.5}>
																						{format(new Date(rental.startDate), dayMonthYear)}
																					</Box>
																				) : (
																					<Box component="span" ml={0.5}>
																						Candidature
																					</Box>
																				)}
																			</Typography>
																		</Stack>
																		<Stack direction={{ default: "column", hd: "row" }} spacing={{ xl: 1 }} flex="1 1 auto" justifyContent={{ hd: "space-between" }} alignItems={{ hd: "center" }}>
																			<Box flex="1 1 auto">
																				<Typography component="span" color="text.secondary">
																					Locataire:{" "}
																				</Typography>
																				{!R.isNil(tenantState.tenants) && !R.isNil(rental.contactId) ? (
																					tenantState.tenants
																						.filter((contact) => rental.contactId.includes(contact.id))
																						.map((rental, index) => (
																							<Typography color="text.main" component={RouterLink} to={`/app/tenants/${rental.id}`} key={index} display="inline-flex" alignItems="center" aria-label="go to company" sx={{ textDecoration: "none", marginLeft: 0.25 }}>
																								{rental.firstName} {rental.lastName}
																							</Typography>
																						))
																				) : (
																					<Typography component="span" color="text.hint">
																						Le champ est vide
																					</Typography>
																				)}
																			</Box>
																			<Stack direction={{ default: "column", md: "row" }} flex="1 1 auto" spacing={{ default: 1, lg: 4 }} justifyContent={{ lg: "space-between" }}>
																				{rental.paymentType !== 0 && (
																					<Typography color="text.secondary" variant="body1">
																						Modalité de paiement:{" "}
																						<Box component="span" color="text.main">
																							{paymentTypes[rental.paymentType]}
																						</Box>
																					</Typography>
																				)}
																				<Typography color="text.secondary" variant="body1">
																					{/* Loyer sans Taxes: <Box component="span" color="text.main">{rental.rentPriceWithoutTax ? rental.rentPriceWithoutTax + '€' : 'N/A'} </Box> */}
																					Loyer:{" "}
																					<Box component="span" color="text.main">
																						{rental.rentPriceWithoutTax ? rental.rentPriceWithoutTax + "€" : "N/A"}{" "}
																					</Box>
																				</Typography>
																			</Stack>
																		</Stack>
																	</Stack>
																</ItemCard>
															))}
													</Stack>
													<Stack direction="column" spacing={1}>
														<Typography variant="label" color="text.secondary">
															{rentalState.rentals?.filter((r) => r.assetId === assetState.asset?.id)?.filter((r) => R.isNil(r.startDate)).length} Candidatures
														</Typography>
														{rentalState.rentals
															.filter((r) => r.assetId === assetState.asset?.id)
															?.filter((r) => R.isNil(r.startDate))
															.map((rental, index) => (
																<ItemCard key={"r" + rental.title} to={`/app/contrats/${rental.companyId}/${rental.id}`} component={RouterLink} elevation={0} backgroundColor="transparent" border={"1px dashed"} borderColor={"grey.A300"}>
																	<Avatar variant="circular" children={rental.title.charAt(0)} sx={{ bgcolor: "grey.A200", my: 0.25, width: "3rem", height: "3rem", fontWeight: "bold" }} />
																	<Stack direction="column" spacing={{ default: 0.5, xl: 0 }} pr={{ default: 0, xl: 1 }} flex="1 1 auto" justifyContent={{ lg: "space-between" }}>
																		<Stack direction={{ default: "column", xl: "row" }} spacing={{ xl: 4 }} flex="1 1 auto" justifyContent={{ lg: "space-between" }}>
																			<Typography fontWeight="medium" color="text.dark">
																				{rental.title}
																			</Typography>
																			<Typography variant="body1">
																				<Box component="span" color="text.secondary">
																					Signature:{" "}
																				</Box>
																				{!R.isNil(rental.startDate) ? (
																					<Box component="span" ml={0.5}>
																						{format(new Date(rental.startDate), dayMonthYear)}
																					</Box>
																				) : (
																					<Box component="span" ml={0.5}>
																						Candidature
																					</Box>
																				)}
																			</Typography>
																		</Stack>
																		<Stack direction={{ default: "column", hd: "row" }} spacing={{ xl: 1 }} flex="1 1 auto" justifyContent={{ hd: "space-between" }} alignItems={{ hd: "center" }}>
																			<Box flex="1 1 auto">
																				<Typography component="span" color="text.secondary">
																					Locataire:{" "}
																				</Typography>
																				{!R.isNil(tenantState.tenants) && !R.isNil(rental.contactId) ? (
																					tenantState.tenants
																						.filter((contact) => rental.contactId.includes(contact.id))
																						.map((rental, index) => (
																							<Typography color="text.main" component={RouterLink} to={`/app/tenants/${rental.id}`} key={index} display="inline-flex" alignItems="center" aria-label="go to company" sx={{ textDecoration: "none", marginLeft: 0.25 }}>
																								{rental.firstName} {rental.lastName}
																							</Typography>
																						))
																				) : (
																					<Typography component="span" color="text.hint">
																						Le champ est vide
																					</Typography>
																				)}
																			</Box>
																			<Stack direction={{ default: "column", lg: "row" }} flex="1 1 auto" spacing={{ default: 1, lg: 4 }} justifyContent={{ lg: "space-between" }}>
																				{rental.paymentType !== 0 && (
																					<Typography color="text.secondary" variant="body1">
																						Modalité de paiement:{" "}
																						<Box component="span" color="text.main">
																							{paymentTypes[rental.paymentType]}
																						</Box>
																					</Typography>
																				)}
																				<Typography color="text.secondary" variant="body1">
																					{/* Loyer sans Taxes: <Box component="span" color="text.main">{rental.rentPriceWithoutTax ? rental.rentPriceWithoutTax + '€' : 'N/A'} </Box> */}
																					Loyer:{" "}
																					<Box component="span" color="text.main">
																						{rental.rentPriceWithoutTax ? rental.rentPriceWithoutTax + "€" : "N/A"}{" "}
																					</Box>
																				</Typography>
																			</Stack>
																		</Stack>
																	</Stack>
																</ItemCard>
															))}
													</Stack>
													<Stack direction="column" spacing={1}>
														<Typography variant="label" color="text.secondary">
															{rentalState.rentals.filter((r) => r.assetId === assetState.asset?.id)?.filter((r) => !R.isNil(r.endDate) && new Date(r.endDate) < new Date()).length} Cloturées
														</Typography>
														{rentalState.rentals
															.filter((r) => r.assetId === assetState.asset?.id)
															?.filter((r) => !R.isNil(r.endDate) && new Date(r.endDate) < new Date())
															.map((rental, index) => (
																<ItemCard key={"r" + rental.title} to={`/app/contrats/${rental.companyId}/${rental.id}`} component={RouterLink} elevation={0} backgroundColor={"background.dim"}>
																	<Avatar variant="circular" children={rental.title.charAt(0)} sx={{ bgcolor: "grey.A200", my: 0.25, width: "3rem", height: "3rem", fontWeight: "bold" }} />
																	<Stack direction="column" spacing={{ default: 0.5, xl: 0 }} pr={{ default: 0, xl: 1 }} flex="1 1 auto" justifyContent={{ lg: "space-between" }}>
																		<Stack direction={{ default: "column", xl: "row" }} spacing={{ xl: 4 }} flex="1 1 auto" justifyContent={{ lg: "space-between" }}>
																			<Typography fontWeight="medium" color="text.dark">
																				{rental.title}
																			</Typography>
																			<Typography variant="body1">
																				<Box component="span" color="text.secondary">
																					Signature:{" "}
																				</Box>
																				{!R.isNil(rental.startDate) ? (
																					<Box component="span" ml={0.5}>
																						{format(new Date(rental.startDate), dayMonthYear)}
																					</Box>
																				) : (
																					<Box component="span" ml={0.5}>
																						Candidature
																					</Box>
																				)}
																			</Typography>
																		</Stack>
																		<Stack direction={{ default: "column", hd: "row" }} spacing={{ xl: 1 }} flex="1 1 auto" justifyContent={{ hd: "space-between" }} alignItems={{ hd: "center" }}>
																			<Box flex="1 1 auto">
																				<Typography component="span" color="text.secondary">
																					Locataire:{" "}
																				</Typography>
																				{!R.isNil(tenantState.tenants) && !R.isNil(rental.contactId) ? (
																					tenantState.tenants
																						.filter((contact) => rental.contactId.includes(contact.id))
																						.map((rental, index) => (
																							<RouterLink variant="body1" color="text.main" to={`/app/tenants/${rental.id}`} key={index} display="inline-flex" aria-label="go to company" sx={{ textDecoration: "none", marginLeft: 0.25 }}>
																								{rental.firstName} {rental.lastName}
																							</RouterLink>
																						))
																				) : (
																					<Typography component="span" color="text.hint">
																						Le champ est vide
																					</Typography>
																				)}
																			</Box>
																			<Stack direction={{ default: "column", lg: "row" }} flex="1 1 auto" spacing={{ default: 1, lg: 4 }} justifyContent={{ lg: "space-between" }}>
																				{rental.paymentType !== 0 && (
																					<Typography color="text.secondary" variant="body1">
																						Modalité de paiement:{" "}
																						<Box component="span" color="text.main">
																							{paymentTypes[rental.paymentType]}
																						</Box>
																					</Typography>
																				)}
																				<Typography color="text.secondary" variant="body1">
																					{/* Loyer sans Taxes: <Box component="span" color="text.main">{rental.rentPriceWithoutTax ? rental.rentPriceWithoutTax + '€' : 'N/A'} </Box> */}
																					Loyer:{" "}
																					<Box component="span" color="text.main">
																						{rental.rentPriceWithoutTax ? rental.rentPriceWithoutTax + "€" : "N/A"}{" "}
																					</Box>
																				</Typography>
																			</Stack>
																		</Stack>
																	</Stack>
																</ItemCard>
															))}
													</Stack>
												</Stack>
											)}
											{permissions.includes("Create") ? (
												<Button fullWidth component={RouterLink} to={`/app/new/contrat?assetId=${assetState.asset.id}&companyId=${assetState.asset.companyId}&origin=assetDetail`} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
													Ajouter un Contrat de location
												</Button>
											) : (
												<Button fullWidth onClick={openPermissionModal} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
													Ajouter un Contrat de location
												</Button>
											)}
										</Stack>
									</AccordionDetails>
								</Accordion>
								<Divider />
								<Accordion sx={{ backgroundColor: "transparent", boxShadow: "none", border: "none", p: 0, ":before": { backgroundColor: "inherit" } }} disableGutters expanded={docsExpanded === "panel-docs"} onChange={handleAccordionChange("panel-docs")}>
									<AccordionSummary sx={{ minHeight: "auto", ".MuiAccordionSummary-content": { my: 1 }, p: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
										<Typography variant="h6" sx={{ lineHeight: 1 }}>
											Documents
										</Typography>
									</AccordionSummary>
									<AccordionDetails sx={{ p: 0 }}>
										<Stack maxWidth="100%" direction="column" spacing={1}>
											{!R.isNil(documentState.documents) && (
												<Stack direction="column" spacing={1}>
													{documentState.documents
														.filter((r) => r.assetId === assetState.asset.id && r.documentType === "Document")
														.map((document) => (
															<DocumentRow permissions={permissions} openPermissionModal={openPermissionModal} key={document.id} setOpenDeleteDoc={setOpenDeleteDoc} document={document} theme={theme} />
														))}
												</Stack>
											)}
											{permissions.includes("Create") ? (
												<Button fullWidth component={RouterLink} to={`/app/new/document?assetId=${assetState.asset.id}&companyId=${assetState.asset.companyId}&origin=assetDetail`} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
													Ajouter un document
												</Button>
											) : (
												<Button fullWidth onClick={openPermissionModal} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
													Ajouter un document
												</Button>
											)}
										</Stack>
									</AccordionDetails>
								</Accordion>
								<Divider />
								<Accordion sx={{ backgroundColor: "transparent", boxShadow: "none", border: "none", p: 0, ":before": { backgroundColor: "inherit" } }} disableGutters expanded={paymentsExpanded === "panel-payments"} onChange={handleAccordionChange("panel-payments")}>
									<AccordionSummary sx={{ minHeight: "auto", ".MuiAccordionSummary-content": { my: 1 }, p: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
										<Typography variant="h6" sx={{ lineHeight: 1 }}>
											Transactions
										</Typography>
									</AccordionSummary>
									<AccordionDetails sx={{ p: 0 }}>
										<Stack maxWidth="100%" direction="column" spacing={1}>
											{!R.isNil(paymentState.payments) && (
												<Stack direction="column" spacing={1}>
													{paymentState.payments
														.filter((r) => r.assetId === assetState.asset.id)
														.map((payment) => (
															<TransactionRow
																key={payment.id}
																setOpenDeletePay={setOpenDeletePay}
																permissions={permissions}
																openPermissionModal={openPermissionModal}
																payment={payment}
																assetState={assetState}
																subcontractorState={subcontractorState}
																tenantState={tenantState}
																companyState={companyState}
																setSelectedPayment={setSelectedPayment}
															/>
														))}
												</Stack>
											)}
											{permissions.includes("Create") ? (
												<Button fullWidth component={RouterLink} to={`/app/new/payment?assetId=${assetState.asset.id}&companyId=${assetState.asset.companyId}&origin=assetDetail`} variant="contained" color="primary" sx={{ boxShadow: "none", mb: 3 }}>
													Ajouter un paiement
												</Button>
											) : (
												<Button fullWidth onClick={openPermissionModal} variant="contained" color="primary" sx={{ boxShadow: "none", mb: 3 }}>
													Ajouter un paiement
												</Button>
											)}
										</Stack>
									</AccordionDetails>
								</Accordion>
								<Divider />
								<Accordion sx={{ backgroundColor: "transparent", boxShadow: "none", border: "none", p: 0, ":before": { backgroundColor: "inherit" } }} disableGutters expanded={tenantsExpanded === "panel-tenants"} onChange={handleAccordionChange("panel-tenants")}>
									<AccordionSummary sx={{ minHeight: "auto", ".MuiAccordionSummary-content": { my: 1 }, p: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
										<Typography variant="h6" sx={{ lineHeight: 1 }}>
											Historique des locataires
										</Typography>
									</AccordionSummary>
									<AccordionDetails sx={{ p: 0 }}>
										<Stack maxWidth="100%" direction="column" spacing={1}>
											<Stack direction="column" spacing={1} justifyContent={{ lg: "space-between" }}>
												{R.isNil(rentalState.rentals) || R.isEmpty(rentalState.rentals) ? (
													<Typography component="span" color="text.hint">
														Le bien n'a pas été occupé par des locataires à ce jour.
													</Typography>
												) : (
													// rentalState.rentals.filter(r => r.assetId === assetState.asset?.id).map(rental => (
													//     rental.startDate && !R.isNil(tenantState.tenants) && !R.isNil(rental.contactId) && tenantState.tenants.filter(contact => rental.contactId.includes(contact.id)).map((tenant, index) => (
													//         <ItemCard key={"r" + tenant.id + index} to={`/app/tenants/${tenant.id}`} component={RouterLink}>
													//             <Avatar variant="circular" sx={{ backgroundColor: R.isNil(tenant.profileUrl?.url) ? "grey.A300" : "background.paper", width: '3rem', height: '3rem', fontWeight: 'bold', border: ".0625rem solid", borderColor: (R.isNil(tenant.profileUrl?.url) ? "transparent" : "grey.main") }} imgProps={{ sx: { borderRadius: 'inherit' } }} children={!R.isNil(tenant.title) ? tenant.title.charAt(0) : (tenant.firstName.charAt(0) + tenant.lastName.charAt(0))} srcSet={!R.isNil(tenant.profileUrl?.url) ? tenant.profileUrl?.url : null} />
													//             <Stack direction="column" flex="1 1 auto" width="100%">
													//                 <Typography variant="text" sx={{}}>{tenant.firstName} {tenant.lastName}</Typography>
													//             </Stack>
													//         </ItemCard>
													//     ))
													// ))
													// R.uniqBy(R.prop('id'), rentalState.rentals.filter(r => r.assetId === assetState.asset?.id).reduce((acc, rental) => {
													//     if (rental.startDate && !R.isNil(tenantState.tenants) && !R.isNil(rental.contactId))
													//         return [...acc, ...tenantState.tenants.reduce((a, c) => rental.contactId.includes(c.id) ? [...a, { ...c, startDate: rental.startDate, endDate: rental.endDate }] : a, [])];
													//     return acc;
													// }, []))
													rentalState.rentals
														.filter((r) => r.assetId === assetState.asset?.id)
														.reduce((acc, rental) => {
															if (rental.startDate && !R.isNil(tenantState.tenants) && !R.isNil(rental.contactId)) return [...acc, ...tenantState.tenants.reduce((a, c) => (rental.contactId.includes(c.id) ? [...a, { ...c, startDate: rental.startDate, endDate: rental.endDate }] : a), [])];
															return acc;
														}, [])
														.map((tenant, index) => (
															<ItemCard key={"r" + tenant.id + index} to={`/app/tenants/${tenant.id}`} component={RouterLink}>
																<Avatar
																	variant="circular"
																	sx={{ backgroundColor: R.isNil(tenant.profileUrl?.url) ? "grey.A300" : "background.paper", width: "3rem", height: "3rem", fontWeight: "bold", border: ".0625rem solid", borderColor: R.isNil(tenant.profileUrl?.url) ? "transparent" : "grey.main" }}
																	imgProps={{ sx: { borderRadius: "inherit" } }}
																	children={!R.isNil(tenant.title) ? tenant.title.charAt(0) : tenant.firstName.charAt(0) + tenant.lastName.charAt(0)}
																	srcSet={!R.isNil(tenant.profileUrl?.url) ? tenant.profileUrl?.url : null}
																/>
																<Stack direction={{ default: "column", xl: "row" }} sx={{ gap: 0.5 }} width="100%" justifyContent="space-between">
																	<Typography variant="text" flex="1 1 auto" sx={{}}>
																		{tenant.firstName} {tenant.lastName}
																	</Typography>
																	<Stack direction="row" sx={{ gap: 1 }} justifyContent={{ xl: "end" }} className="truncated">
																		<Typography color="text.hint" variant="body1">
																			{!R.isNil(tenant.firstName) && format(new Date(tenant.startDate), dayMonthYear)}
																		</Typography>
																		<Typography sx={{ lineHeight: 1, opacity: ".66" }} display={{ default: "none", sm: "inherit" }} variant="h6" component="span">
																			—
																		</Typography>
																		<Typography color="text.hint" variant="body1">
																			{!R.isNil(tenant.firstName) && format(new Date(tenant.endDate), dayMonthYear)}
																		</Typography>
																	</Stack>
																</Stack>
															</ItemCard>
														))
												)}
											</Stack>
											{/* <Button fullWidth component={RouterLink} to="/app/new/contrat" variant="contained" color="primary" sx={{boxShadow: 'none'}}>Ajouter un locataire</Button> */}
										</Stack>
									</AccordionDetails>
								</Accordion>
								{/*<Divider />
								 <Stack direction={{ default: "column", lg: "row" }} spacing={0} justifyContent={{ lg: "space-between" }}>
                                    <Typography variant="h6" sx={{ lineHeight: 1 }}>
                                        Historique des interventions
                                    </Typography>
                                </Stack> */}
							</Stack>
							{openCarousel && <ModalCarousel 
							    theme={theme}
								items={assetState.asset.files.filter((c) => imageTypes.includes(c.contentType))}
								modalClose={modalCloseCarousel} 
								open={openCarousel} 
							/>}
						</Container>
					)}
				</React.Fragment>
			)}
			<ModalDelete theme={theme} item={assetState.asset} type="asset" isLoading={assetState.isLoading.includes(AssetLoadings.DELETING_ASSET)} isDisabled={assetState.isLoading.includes(AssetLoadings.DELETING_ASSET)} dispatchDelete={deleteAssetProp} modalClose={modalCloseDelete} open={openDelete} />
			<ModalDelete
				theme={theme}
				item={documentState.documents?.find((d) => d.id === openDeleteDoc)}
				type="document"
				isLoading={deleteDocument.isLoading}
				isDisabled={deleteDocument.isDisabled}
				dispatchDelete={deleteDocument.dispatchDelete(openDeleteDoc)}
				modalClose={() => {
					setOpenDeleteDoc(null);
				}}
				open={!!openDeleteDoc}
			/>
			<ModalDelete
				theme={theme}
				item={selectedPayment}
				type="payment"
				isLoading={deletePayment.isLoading}
				isDisabled={deletePayment.isDisabled}
				dispatchDelete={deletePayment.dispatchDelete(selectedPayment)}
				modalClose={() => {
					setOpenDeletePay(null);
				}}
				open={openDeletePay}
			/>
		</Box>
	);
};

export default AssetsViewDetailBien;
