/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
// import SwipeableViews from 'react-swipeable-views';
// import { autoPlay } from 'react-swipeable-views-utils';
import * as R from "ramda";
// Components
// Components: Global
import Avatar from "@mui/material/Avatar";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DownloadIcon from "@mui/icons-material/Download";

import { Document, Page } from "react-pdf";

// Components: Common
import Loading from "../../_common/loading";
import { downloadFile } from "../../constants/utils/download";
import { Box } from "@mui/material";

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const ModalCarousel = ({ modalClose, assetState, isDisabled, isLoading, open, theme, items, isImmeuble, isVendu, type }) => {
	const [activeStep, setActiveStep] = useState(0);
	const maxSteps = items?.length;

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStepChange = (step) => {
		setActiveStep(step);
	};



	const download = async () => {
		
        if (!R.isNil(items[activeStep]) && !R.isEmpty(items[activeStep])) {
			
			downloadFile(items[activeStep]);
        }
    };



	return R.isNil(items) ? (
		<Dialog fullWidth maxWidth="lg" onClose={modalClose} open={open} theme={theme}>
			<Loading />
		</Dialog>
	) : (
		<Dialog fullWidth maxWidth="lg" onClose={modalClose} open={open} theme={theme}>
			{/* <AutoPlaySwipeableViews
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={activeStep}
					onChangeIndex={handleStepChange}
					enableMouseEvents 
					>
					{items.map((item, index) => (
						<ImageListItem elevation={0} sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }} key={index}>
							<Avatar src={item.url} alt={item.fileName.charAt(0)} loading="lazy" style={{ height: '100%', width: '100%', borderRadius: 'inherit'}}>
								{ item.fileName.split(".")[1] === 'pdf' ? 
									<PictureAsPdfIcon width="100%"/>
									:
									<AttachFileIcon width="100%"/>
								}
							</Avatar>
							<ImageListItemBar
								sx={{ 
									borderRadius: 'inherit',
									background:
									'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
									'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
								}}
								title={item.fileName}
								position="bottom"
							/>
						</ImageListItem>
					))}
				</AutoPlaySwipeableViews> */}
			<ImageListItem elevation={0} sx={{ position: "relative", display: "flex", flexDirection: "column", gap: 1, height: "100%" }} key={items[activeStep]}>
				{items[activeStep]?.fileName.split(".")[1] === "pdf" ? (
					<div style={{ overflow: "hidden", height: "500px", display: "flex", justifyContent: 'center' }}>
						{/* <Document file={items[activeStep]?.url}>
							<Page pageNumber={1} width={650} />
						</Document> */}
						{/* { items[activeStep]?.url && <iframe src={items[activeStep]?.url} />} */}
						{
							items[activeStep]?.url && ( <Document file={items[activeStep]?.url}>
							<Page pageNumber={1} />
							</Document>)
						}
					</div>
				) : (
					<Avatar src={items[activeStep]?.url} alt={items[activeStep]?.fileName.charAt(0)} loading="lazy" style={{ height: "100%", width: "100%", borderRadius: "inherit" }}>
						{items[activeStep]?.fileName.split(".")[1] === "pdf" ? <PictureAsPdfIcon width="100%" /> : <AttachFileIcon width="100%" />}
					</Avatar>
				)}
				<ImageListItemBar
					sx={{
						borderRadius: "inherit",
						background: "linear-gradient(to top, rgba(0,0,0,0.7) 0%, " + "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
					}}
					title={items[activeStep]?.fileName}
					position="bottom"
					actionIcon={
						<IconButton sx={{ color: "white", mx: 1 }} aria-label={`download`} onClick={download}>
							<DownloadIcon />
						</IconButton>
					}
					actionPosition="left"
				/>
			</ImageListItem>
			<MobileStepper
		        variant="text"
				steps={maxSteps}
				position="static"
				activeStep={activeStep}
				nextButton={
					<Button size="large" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
						<Box sx={{paddingX:"10px",display:"flex",alignItems:"center"}}>
						 <span>Suivant</span>
						{theme?.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
						</Box>
					</Button>
				}
				backButton={
					<Button size="large" onClick={handleBack} disabled={activeStep === 0}>
						{theme?.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
						Précedent
					</Button>
				}
			/>
		</Dialog>
	);
};

export default ModalCarousel;
