import React, { useEffect, useState } from "react";
import * as R from 'ramda';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useForm } from "react-hook-form";

import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import Logo from './../_common/logo';
import { LoginUser } from "../actions/auth-api";
import * as Loadings from '../constants/loadings/auth';
import { emailRegex, inputTypes } from "../constants/utils/validators";
import Auth from "../constants/utils/auth";
import { GetDashboardAssets } from "../actions/asset-api";
import { GetDashboardCompanies } from "../actions/company-api";
import { GetDashboardTenants } from "../actions/tenant-api";
import { GetDashboardSubcontractors } from "../actions/subcontractor-api";
import { GetDashboardCollaborators } from "../actions/collaborator-api";
import { GetDashboardAssetGroups } from "../actions/assetGroup-api";


export const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authState = useSelector(state => state.auth);
    const [loading,setLoading]= useState(false)

    useEffect(() => {
        document.title = '👋 Connexion Magestimmo';
    }, []);

    const submitLoginForm = (data) => {
         setLoading(true)
        dispatch(LoginUser({ userName: data.email.trim(), password: data.password.trim() }))
            .then(({ token, name }) => {
                Auth.setAccessToken(token);
               
                dispatch(GetDashboardAssets()); 
                dispatch(GetDashboardCompanies()); 
                dispatch(GetDashboardTenants()); 
                dispatch(GetDashboardSubcontractors()); 
                dispatch(GetDashboardCollaborators()); 
                dispatch(GetDashboardAssetGroups());
                setTimeout(() => {
                    //setLoading(false)
                    navigate('/app',{replace:true});
                }, 2000);
            }).catch(() => { })
            .finally(() => setLoading(false))
            ;
    };


    return (
        <Container maxWidth="sm" sx={{ m: "auto" }}>
            <Stack direction="column" flex="1 1 auto" justifyContent="center" alignItems="center" my={5} spacing={2}>
                <Logo />
                <Typography variant="h2" fontWeight="600" color='primary' lineHeight="1.2">Magestimmo</Typography>
            </Stack>
            <Stack direction="column" flex="1 1 auto" spacing={2} alignItems="center" sx={{ maxWidth: '100%', marginX: 'auto' }} mb={5}>
                <Box name="login" onSubmit={handleSubmit(submitLoginForm)} component="form" sx={{ width: '100%', maxWidth: '100%' }} alignItems='center' display="flex" flexDirection="column" noValidate autoComplete="off">
                    <Stack direction="column" flex="1 1 auto" mb={3} spacing={.5} alignItems="center" width="100%" sx={{ maxWidth: '100%', margin: 'auto' }}>
                        <TextField
                            label='Identifiant de connexion'
                            fullWidth={true}
                            required={true}
                            size="medium"
                            variant="filled"
                            name="email"
                            inputProps={register("email", { required: inputTypes.required.errorMessage, pattern: { value: emailRegex, message: inputTypes.email.errorMessage } })}
                            error={!!errors.email}
                            helperText={errors.email ? errors.email.message : ''}
                        />
                        <TextField
                            label='Mot de passe'
                            fullWidth={true}
                            validator={'password'}
                            required={true}
                            size="medium"
                            variant="filled"
                            name="password"
                            type="password"
                            inputProps={register("password", { required: inputTypes.required.errorMessage })}
                            error={!!errors.password}
                            helperText={errors.password ? errors.password.message : ''}
                        />
                    </Stack>
                    <Stack flex="1 1 auto" justifyContent="space-between" alignItems="center" width="100%" my={2} sx={{ maxWidth: '100%', marginX: 'auto', flexDirection: { xs: "column", sm: "row" }, gap: .5 }}>
                        {/* <FormControlLabel
                            control={<Checkbox
                                color="success"
                                size="medium"
                                display="none"
                            />}
                            label="Se souvenir de moi"
                        /> */}
                        <Link to='/auth/forget' component={RouterLink} underline="hover" color="text.muted" ml='auto' mt={-1} fontSize='.75rem'>Mot de passe <span style={{ fontWeight: "bold" }}> oublié</span> ?</Link>
                    </Stack>
                    <LoadingButton loading={authState.isLoading.includes(Loadings.FETCHING_LOGIN) || loading} type="submit" color='primary' fullWidth={true} variant="contained" size="large">
                        SE CONNECTER
                    </LoadingButton>
                </Box>
                {R.isNil(authState.isLoggedIn) || authState.isLoggedIn ? null : <Alert sx={{ width: '100%', borderRadius: 3 }} severity="error">Nom d'utilisateur ou mot de passe incorrect.</Alert>}
                <Link to='/auth/registry' component={RouterLink} underline="hover" color="secondary" mt={2}>Créer un nouveau compte <span style={{ fontWeight: "bold" }}> ici</span></Link>
                {/* <Link to='/auth/registry' underline="hover" color="secondary" mt={2}>Créer un nouveau compte <span style={{ fontWeight: "bold" }}> ici</span></Link> */}
            </Stack>
        </Container >
    );
};

export default Login;