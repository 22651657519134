import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";
import { useNavigate } from "react-router";

// Components
// Components: Global
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { LoadingButton } from "@mui/lab";

// Components: Common
import FormContrat from "../info/contrat";
import * as Loadings from "../../../../constants/loadings/rental";
import * as LoadingsFile from "../../../../constants/loadings/file";
import { useAutoComplete } from "../../../../hooks/useAutoComplete";
import { onSubmitMultipleValidation, onSubmitValidation } from "../../../../constants/utils/validators";
import { CreateRental, UpdateRental } from "../../../../actions/rental-api";
import { UploadFiles, DeleteFiles } from "../../../../actions/file-api";
import CompanyModalContent from "../modals/companyModalcontent";
import { Dialog } from "@mui/material";
import AssetModalContent from "../modals/assetModalContent";
import TenantModalContent from "../modals/tenantModalContent";
import { setRentalType } from "../../../../constants/utils/formHandlers"; 

export const MainContrat = ({ theme, documents, initialContractFormData, companies, assets, tenants, rentalState, initialCompanyForm, initialAssetForm, initialTenantForm, resetCompanyForm, resetAssetForm, resetTenantForm, companyId, id }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const stateFile = useSelector((state) => state.file);
	const [formData, setFormData] = useState(initialContractFormData);
	const [docFiles, setDocFiles] = useState([]);
	const [uploadedFiles, setUploadedFiles] = useState(R.isNil(documents) || R.isEmpty(documents) ? null : documents);


	const companyUseAutocomplete = useAutoComplete(initialCompanyForm, companies, resetCompanyForm);
	const assetUseAutocomplete = useAutoComplete(initialAssetForm, assets, resetAssetForm);
	const tenantUseAutocomplete = useAutoComplete(initialTenantForm, tenants, resetTenantForm);

	const selectCompanyByAsset = ({ id, index } = {}) => {
		if (R.isNil(id) || !id) return;
		const companyformDataTemp = R.clone(companyUseAutocomplete.dropdownFormData);
		let companyId = assets.find((a) => a.id === id)?.companyId;
		let foundCompany = companies.find((c) => c.id === companyId);
		companyformDataTemp[index] = { isRequired: true, error: null, isValid: false, label: foundCompany.title, value: foundCompany.id };
		companyUseAutocomplete.setDropdownFormData(companyformDataTemp);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const validatedForm = onSubmitValidation(formData);
		const validatedCompanyForm = onSubmitMultipleValidation(companyUseAutocomplete.dropdownFormData);
		const validatedAssetForm = onSubmitMultipleValidation(assetUseAutocomplete.dropdownFormData);
		if (!validatedForm.isValid || !validatedCompanyForm.isValid || !validatedAssetForm.isValid) {
			setFormData(validatedForm.form);
			companyUseAutocomplete.setDropdownFormData(validatedCompanyForm.form);
			assetUseAutocomplete.setDropdownFormData(validatedAssetForm.form);
			return;
		}
		try {
			const companyId = companyUseAutocomplete.dropdownFormData[0].value;
			const assetId = assetUseAutocomplete.dropdownFormData[0].value;
			const tenantId = tenantUseAutocomplete.dropdownFormData[0].value;
			const contract = {
				title: formData.title.value.trim(),
				documentAssociationType: 1,
				companyId: R.isEmpty(companyId) ? null : companyId,
				assetId: R.isEmpty(assetId) ? null : assetId,
				contactId: R.isEmpty(tenantId) ? null : tenantId,
				date: formData.date.value,
				description: R.isEmpty(formData.notes.value) ? null : formData.notes.value.trim(),
				firstNameCaution: R.isEmpty(formData.firstName.value) ? null : formData.firstName.value.trim(),
				lastNameCaution: R.isEmpty(formData.lastName.value) ? null : formData.lastName.value.trim(),
				//WARNING: when we start managing RentalPrice, this needs to be updated
				rentPrice: R.isEmpty(formData.rentPriceWithoutTax.value) ? null : formData.rentPriceWithoutTax.value,
				rentPriceWithoutTax: R.isEmpty(formData.rentPriceWithoutTax.value) ? null : formData.rentPriceWithoutTax.value,
				monthlyCharges: R.isEmpty(formData.monthlyCharges.value) ? null : formData.monthlyCharges.value,
				caution: R.isEmpty(formData.caution.value) ? null : formData.caution.value,
				garant: R.isEmpty(formData.warranty.value) ? null : formData.warranty.value,
				iban: R.isEmpty(formData.iban.value) ? null : formData.iban.value,
				paymentType: R.isEmpty(formData.paymentType.value) ? null : formData.paymentType.value,
				isSigned: false,
				tenantState: null,
				modifiedOn: new Date().toISOString(),
			};
			contract.tenantState = setRentalType({
				startDate: R.isEmpty(formData.startDate.value) ? null : formData.startDate.value,
				departureDate: R.isEmpty(formData.departureDate.value) ? null : formData.departureDate.value,
			});

			if (contract.tenantState >= 2) {
				contract.startDate = formData.startDate.value;
				contract.endDate = formData.endDate.value;
				contract.numberOfKeys = formData.numbersOfKeys.value;
				contract.paymentDay = formData.paymentDate.value;
			}
			if (contract.tenantState === 3) {
				contract.departureDate = formData.departureDate.value;
				contract.emergencyAddress = R.isEmpty(formData.address.value) ? null : formData.address.value;
				contract.emergencyPhone = R.isEmpty(formData.phone.value) ? null : formData.phone.value;
				contract.isSigned = true;
			}
			if (R.isNil(id)) {
				const newContract = await dispatch(CreateRental(contract));
				contract.id = newContract.id;
			} else {
				contract.id = id;
				await dispatch(UpdateRental(contract));
			}
			if (!R.isEmpty(uploadedFiles) && !R.isNil(uploadedFiles)) {
			    let toDelete = rentalState.rental && rentalState.rental.files ? rentalState.rental.files.filter((file) => !uploadedFiles.map((m) => m.id).includes(file.id)) : null;
				if (toDelete.length) {
				 		//await dispatch(UploadFiles(toDelete, contract.id, "Rental"));
						 await dispatch(DeleteFiles(toDelete, contract.id, 'Rental'));
				 	}
			}
			if(R.isEmpty(uploadedFiles) && rentalState.rental && rentalState.rental.files.length) {
			    await dispatch(DeleteFiles(rentalState.rental.files, contract.id, 'Rental'));
			}

			if (!R.isEmpty(docFiles)) {
				await dispatch(UploadFiles(docFiles, contract.id, "Rental"));
			}

			navigate(`/app/contrats/${contract.companyId}/${contract.id}`);
			setFormData(initialCompanyForm);
			companyUseAutocomplete.setDropdownFormData(initialCompanyForm);
			tenantUseAutocomplete.setDropdownFormData(initialAssetForm);
			assetUseAutocomplete.setDropdownFormData(initialTenantForm);
		} catch (error) {
			//console.log(error); 
		}
	};

	return (
		<React.Fragment>
			<Stack spacing={2} onSubmit={handleSubmit} component="form" flex="1 1 auto" width="100%" height="100%" overflow="hidden auto">
				<FormContrat
					companyId={companyId}
					formData={formData}
					setFormData={setFormData}
					selectCompanyByAsset={selectCompanyByAsset}
					companyUseAutocomplete={companyUseAutocomplete}
					assetUseAutocomplete={assetUseAutocomplete}
					tenantUseAutocomplete={tenantUseAutocomplete}
					docFiles={docFiles}
					setDocFiles={setDocFiles}
					uploadedFiles={uploadedFiles}
					setUploadedFiles={setUploadedFiles}
					theme={theme}
					mt={{ md: -2 }}
				/>
				<Stack direction={{ default: "column", md: "row" }} justifyContent="end" alignItems="center" pb={2} spacing={2}>
					<Container maxWidth="sm" sx={{ display: "inherit", justifyContent: "inherit", flex: "1 1 auto", marginLeft: 0 }}>
						<Typography variant="body2" color="text.secondary">
							Vous pourrez ajouter des documents relatifs à cet élément après l'avoir Enregistré, à l'aide du bouton + en bas de l'écran.
						</Typography>
					</Container>
					<Container sx={{ display: "inherit", justifyContent: "inherit", gap: 2, flex: "0" }}>
						<Button color="text" onClick={() => navigate(-1)}>
							Annuler
						</Button>
						<LoadingButton
							type="submit"
							loading={rentalState.isLoading.includes(Loadings.CREATING_RENTAL) || rentalState.isLoading.includes(Loadings.UPDATING_RENTAL) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_FILES)}
							disabled={rentalState.isLoading.includes(Loadings.CREATING_RENTAL) || rentalState.isLoading.includes(Loadings.UPDATING_RENTAL) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_FILES)}
							size="large"
							variant="contained"
							color="primary">
							Enregistrer
						</LoadingButton>
					</Container>
				</Stack>
			</Stack>
			<Dialog fullWidth maxWidth="md" onClose={companyUseAutocomplete.setClose} open={companyUseAutocomplete.isOpen} theme={theme}>
				<CompanyModalContent modalClose={companyUseAutocomplete.setClose} open={companyUseAutocomplete.isOpen} theme={theme} newCompanyName={companyUseAutocomplete.newOptionName} handleAddNewCompany={companyUseAutocomplete.handleAddNew} />
			</Dialog>
			<Dialog fullWidth maxWidth="md" onClose={assetUseAutocomplete.setClose} open={assetUseAutocomplete.isOpen} theme={theme}>
				<AssetModalContent modalClose={assetUseAutocomplete.setClose} open={assetUseAutocomplete.isOpen} theme={theme} newTitle={assetUseAutocomplete.newOptionName} handleAddNew={assetUseAutocomplete.handleAddNew} />
			</Dialog>
			<Dialog fullWidth maxWidth="md" onClose={tenantUseAutocomplete.setClose} open={tenantUseAutocomplete.isOpen} theme={theme}>
				<TenantModalContent modalClose={tenantUseAutocomplete.setClose} open={tenantUseAutocomplete.isOpen} theme={theme} newTitle={tenantUseAutocomplete.newOptionName} handleAddNew={tenantUseAutocomplete.handleAddNew} />
			</Dialog>
		</React.Fragment>
	);
};

export default MainContrat;
