export const RESET_FILTER = "RESET_FILTER";
export const PARTICULAR = 1;
// export const ENCAISSEMENT = "Encaissement";
// export const DECAISSEMENT = "Décaissement";
export const ENCAISSEMENT = 1;
export const DECAISSEMENT = 2;


export const ASSET_MAX_TIER_ERROR = "Vous avez atteint la limite de création de biens/appartements. Pour créer un nouveau bien, veuillez augmenter votre forfait.";
export const TENANT_MAX_TIER_ERROR = "Vous avez la limite de création de Locataires. Pour créer un nouveau locataire, veuillez augmenter votre forfait"

export const tiers = [
    {
        "id": "1b56afda-32aa-44d6-bc49-3653eea5f79e",
        "name": "Professional",
        "isFree": false
      },
      {
        "id": "cbc3f17a-76c5-4623-97c1-76ed1c04a81b",
        "name": "Starter",
        "isFree": false
      },
      {
        "id": "7344bbee-00d1-4878-b2aa-e130ac02135a",
        "name": "Free",
        "isFree": true
      }
]

