import axios from 'axios';
import * as R from 'ramda';
import { accountsActions } from "../reducers/account";
import * as urls from '../constants/webapi/account';
import { PAGE_SIZE } from '../constants/utils/pageSize';
import { urlBuilder } from '../constants/utils/urlBuilder';




export const GetAccounts = ({ pageSize, pageNumber, sortBy, sortDesc, filter } = {}) => {
    const size = !R.isNil(pageSize) && !R.isEmpty(pageSize) ? pageSize : PAGE_SIZE;
    const page = !R.isNil(pageNumber) && !R.isEmpty(pageNumber) ? pageNumber : 1;
    const sort = !R.isNil(sortBy) && !R.isEmpty(sortBy) ? sortBy : undefined;
    const desc = !R.isNil(sortDesc) && !R.isEmpty(sortDesc) ? sortDesc : false;
    const filters = !R.isNil(filter) && !R.isEmpty(filter) ? urlBuilder(filter) : undefined;

    return async dispatch => {
        dispatch(accountsActions.getAccountsRequest());
        return axios.get(urls.getAccountsUrl({ size, page, sort, desc, filters }))
            .then((response) => {
                dispatch(accountsActions.getAccountsSuccess({
                    data: response?.data,
                    pageSize: size,
                    pageNumber: page,
                    sortBy: sort,
                    sortDesc: desc
                }));
            })
            .catch((error) => {
                dispatch(accountsActions.getAccountsFailure(error.response.data));
            });
    };
};

export const CreateAccount = (account) => {
    return async dispatch => {
        dispatch(accountsActions.createAccountRequest());
        return axios.post(urls.getAccountUrl(account.companyId), account)
            .then((response) => {
                dispatch(accountsActions.createAccountSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(accountsActions.createAccountFailure(error.response.data));
                throw error;
            });
    };
};

export const GetAccount = (companyId, accountId) => {
    return async dispatch => {
        dispatch(accountsActions.getAccountRequest());
        return axios.get(urls.getAccountByIdUrl(companyId, accountId))
            .then((response) => {
                dispatch(accountsActions.getAccountSuccess(response.data));
            })
            .catch((error) => {
                dispatch(accountsActions.getAccountFailure(error.response.data));
            });
    };
};

export const UpdateAccount = (account) => {
    return async dispatch => {
        dispatch(accountsActions.updateAccountRequest());
        return axios.put(urls.getAccountByIdUrl(account.companyId, account.id), account)
            .then((response) => {
                dispatch(accountsActions.updateAccountSuccess(account));
            })
            .catch((error) => {
                dispatch(accountsActions.updateAccountFailure(error.response.data));
            });
    };
};

export const DeleteAccount = (companyId, accountId) => {
    return async dispatch => {
        dispatch(accountsActions.deleteAccountRequest());
        return axios.delete(urls.getAccountByIdUrl(companyId, accountId))
            .then((response) => {
                dispatch(accountsActions.deleteAccountSuccess({ id: accountId }));
            })
            .catch((error) => {
                dispatch(accountsActions.deleteAccountFailure(error.response.data));
                throw error;
            });
    };
};