import { createSlice } from '@reduxjs/toolkit';
import * as Loadings from '../constants/loadings/payment';
import * as R from 'ramda';

const initState = {
    isLoading: [],
    payments: undefined,
    payment: undefined
};

const PaymentsReducer = createSlice({
    name: 'payment',
    initialState: { ...initState },
    reducers: {
        //#region GET PAYMENTS
        getPaymentsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_PAYMENTS)) {
                state.isLoading.push(Loadings.FETCHING_PAYMENTS);
            }
        },
        getPaymentsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_PAYMENTS);
            state.payments = payload.data;
        },
        getPaymentsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_PAYMENTS);
        },
        //#endregion

        //#region CREATE PAYMENT
        createPaymentRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_PAYMENT)) {
                state.isLoading.push(Loadings.CREATING_PAYMENT);
            }
        },
        createPaymentSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_PAYMENT);
            if (R.isNil(state.payments)) {
                state.payments = [];
            }
            state.payments.push(payload);
        },
        createPaymentFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_PAYMENT);
        },
        //#endregion

        //#region GET PAYMENT
        getPaymentRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_PAYMENT)) {
                state.isLoading.push(Loadings.FETCHING_PAYMENT);
            }
        },
        getPaymentSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_PAYMENT);
            state.payment = payload;
        },
        getPaymentFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_PAYMENT);
        },
        //#endregion

        //#region UPDATE PAYMENT
        updatePaymentRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_PAYMENT)) {
                state.isLoading.push(Loadings.UPDATING_PAYMENT);
            }
        },
        updatePaymentSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_PAYMENT);
        },
        updatePaymentFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_PAYMENT);
        },
        //#endregion

        //#region DELETE PAYMENT
        deletePaymentRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_PAYMENT)) {
                state.isLoading.push(Loadings.DELETING_PAYMENT);
            }
        },
        deletePaymentSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_PAYMENT);
            state.payments = state.payments.filter(p => p.id !== payload.paymentId);
        },
        deletePaymentFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_PAYMENT);
        },
        //#endregion

        //#region CLEAR PAYMENT
        clearPayment(state) {
            state.payment = undefined;
        }
        //#endregion
    }
});

export const paymentsActions = PaymentsReducer.actions;
export default PaymentsReducer.reducer;