import { Avatar, Button } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import * as R from 'ramda';
import React from 'react';

export const ProfilePhoto = ({ theme, profilePhoto, setProfilePhoto, currentPhoto }) => {
    return (
        <Button component="label" sx={{ padding: "0", margin: "auto", width: "auto", bordeRadius: 6 }}>
           <Avatar src={profilePhoto ? URL.createObjectURL(profilePhoto) : currentPhoto && currentPhoto} theme={theme} sx={{ margin: "auto", bgcolor: (profilePhoto || currentPhoto ? "grey.main" : "secondary.main"), width: '3.5rem', height: '3.5rem', fontSize: '2rem', fontWeight: 'bold' }} >
                <CameraAltIcon fontSize="large" />
            </Avatar>
            <input hidden type="file" accept="image/*" onChange={(e) => {
                if (R.isNil(e.target.files[0])) return;
                const filename = e.target.files[0].name;
                if ((filename.endsWith('.jpg') || filename.endsWith('.webp') || filename.endsWith('.avif') || filename.endsWith('.jpeg') || filename.endsWith('.png'))) {
                    setProfilePhoto(e.target.files[0]);
                }
            }} />
        </Button>
    );
};

export default ProfilePhoto;