import React, { useEffect} from 'react';
import { useDispatch} from 'react-redux';
import * as R from 'ramda';


// Components: Common

import * as LoadingsCollaborator from '../../constants/loadings/collaborator';
import * as LoadingsCompany from '../../constants/loadings/company';
import { GetCompanies } from '../../actions/company-api';
import { GetCollaborators } from '../../actions/collaborator-api';
import Loading from '../../_common/loading';
import { initialCollaboratorFormData, initialCompanyForm } from '../../constants/utils/formInitialData';
import MainCollaborator from '../_common/forms/layout/collaborator';



// Components: Views

export const NewCollaborator = ({ theme, companyState, collaboratorState }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (R.isNil(collaboratorState.collaborators)) {
            dispatch(GetCollaborators());
        }
        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        // eslint-disable-next-line 
    }, [collaboratorState.collaborators]);

    if (collaboratorState.isLoading.includes(LoadingsCollaborator.FETCHING_COLLABORATORS) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)) {
        return <Loading theme={theme} />;
    }

    let collaborators = !R.isNil(collaboratorState.collaborators) ? collaboratorState.collaborators : [];
    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];

    return (
        <MainCollaborator
            theme={theme}
            companies={companies}
            collaborators={collaborators}
            collaboratorState={collaboratorState}
            initialCollaboratorFormData={initialCollaboratorFormData}
            initialCompanyForm={[initialCompanyForm]}
        />

    );
};

export default NewCollaborator;