import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

// Components: Common
import * as LoadingsCompany from '../../constants/loadings/company';
import * as LoadingsTenant from '../../constants/loadings/tenant';
import * as LoadingsRental from '../../constants/loadings/rental';
import * as LoadingsAsset from '../../constants/loadings/asset';
import { GetCompanies } from '../../actions/company-api';
import { GetAssets } from '../../actions/asset-api';
import { GetTenants } from '../../actions/tenant-api';
import { GetRentals } from '../../actions/rental-api';
import Loading from '../../_common/loading';
import { initialAssetForm, initialCompanyForm, initialContractFormData, initialTenantForm } from '../../constants/utils/formInitialData';
import { MainContrat } from '../_common/forms/layout/contract';



export const NewContrat = ({ theme, companyState, assetState, tenantState, rentalState }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const search = new URLSearchParams(location.search);
    const query = {
        companyId: search.get("companyId"),
        assetId: search.get("assetId"),
        tenantId: search.get("tenantId"),
    };

    useEffect(() => {
        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        if (R.isNil(assetState.assets)) {
            dispatch(GetAssets());
        }
        if (R.isNil(tenantState.tenants)) {
            dispatch(GetTenants());
        }
        if (R.isNil(rentalState.rentals)) {
            dispatch(GetRentals());
        }
        // eslint-disable-next-line 
    }, []);


    if (tenantState.isLoading.includes(LoadingsTenant.FETCHING_TENANTS) ||
        assetState.isLoading.includes(LoadingsAsset.FETCHING_ASSETS) ||
        rentalState.isLoading.includes(LoadingsRental.FETCHING_RENTALS) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)) {
        return <Loading />;
    }

    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let assets = !R.isNil(assetState.assets) ? assetState.assets : [];
    let tenants = !R.isNil(tenantState.tenants) ? tenantState.tenants.map(t => ({ ...t, title: t.firstName + " " + t.lastName })) : [];

    const foundCompany = companies.find(c => c.id === query.companyId);
    const foundAsset = assets.find(c => c.id === query.assetId);
    const foundTenant = tenants.find(c => c.id === query.tenantId);

    const tempInitialCompanyForm = R.clone(initialCompanyForm);
    const tempInitialAssetForm = R.clone(initialAssetForm);
    const tempInitialTenantForm = R.clone(initialTenantForm);

    if (!R.isNil(query.companyId) && !R.isNil(foundCompany)) {
        tempInitialCompanyForm.value = query.companyId;
        tempInitialCompanyForm.label = foundCompany.title;
    }
    if (!R.isNil(query.assetId) && !R.isNil(foundAsset)) {
        tempInitialAssetForm.value = query.assetId;
        tempInitialAssetForm.label = foundAsset.title;
    }
    if (!R.isNil(query.tenantId) && !R.isNil(foundTenant)) {
        tempInitialTenantForm.value = query.tenantId;
        tempInitialTenantForm.label = foundTenant.title;
    }


    return (
        <MainContrat
            theme={theme}
            initialContractFormData={initialContractFormData}
            companies={companies}
            assets={assets}
            tenants={tenants}
            rentalState={rentalState}
            // initialCompanyForm={[initialCompanyForm]}
            // initialAssetForm={[{ ...initialAssetForm, isRequired: true }]}
            // initialTenantForm={[initialTenantForm]}
            initialCompanyForm={[tempInitialCompanyForm]}
            initialAssetForm={[{ ...tempInitialAssetForm, isRequired: true }]}
            initialTenantForm={[tempInitialTenantForm]}
            resetCompanyForm={initialCompanyForm}
            resetAssetForm={{ ...initialAssetForm, isRequired: true }}
            resetTenantForm={initialTenantForm}
        />

    );
};

export default NewContrat;