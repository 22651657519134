import React from 'react';
import * as R from 'ramda';

// Components
// Components: Global
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import { paymentTypes } from '../../../../constants/utils/objectProperties';

// Components: Common

// Components: Views

export const FromLease = ({ theme, formData, setFormData, handleOnChange, ...others }) => {


    function handleData(name, event) {
        const newFormData = { ...formData };
        newFormData[name] = event.target.value;
        // setFormData(newFormData);
    }

    const handleStep = (step) => {
        const tempFormData = R.clone(formData);
        if (step === 2) {
            tempFormData.startDate = { ...tempFormData.startDate, isRequired: true, error: null, isValid: false };
            tempFormData.endDate = { ...tempFormData.endDate, isRequired: false, error: null, isValid: false };
            tempFormData.paymentDate = { ...tempFormData.paymentDate, isRequired: true, error: null, isValid: false };
            tempFormData.numbersOfKeys = { ...tempFormData.numbersOfKeys, isRequired: true, error: null, isValid: false };
            tempFormData.paymentType = { ...tempFormData.paymentType, isRequired: true, error: null, isValid: false };

            tempFormData.departureDate = { ...tempFormData.departureDate, isRequired: false, error: null, isValid: false };
            tempFormData.address = { ...tempFormData.address, isRequired: false, error: null, isValid: false };
            tempFormData.phone = { ...tempFormData.phone, isRequired: false, error: null, isValid: false };
        } else if (step === 3) {
            tempFormData.departureDate = { ...tempFormData.departureDate, isRequired: true, error: null, isValid: false };
            tempFormData.address = { ...tempFormData.address, isRequired: true, error: null, isValid: false };
            tempFormData.phone = { ...tempFormData.phone, isRequired: true, error: null, isValid: false };
        }
        setFormData(tempFormData);
    };

    return (
        <Stack spacing={2} flex="1 1 auto" width="100%" {...others}>
            <Stack direction="column" spacing={1}>
                <Typography variant="label" color="text.secondary">Identifiants de contrat de location</Typography>
                <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                    <TextField
                        label='Nom du garant'
                        id="Nom"
                        type="text"
                        fullWidth
                        variant="filled"
                        name="firstName"
                        error={formData.firstName.error && !formData.firstName.isValid}
                        helperText={formData.firstName.error}
                        value={formData.firstName.value}
                        onChange={(event) => { handleOnChange(event); }}
                    />
                    <TextField
                        label='Prénom du garant'
                        id="Prenom"
                        type="text"
                        fullWidth
                        variant="filled"
                        name="lastName"
                        error={formData.lastName.error && !formData.lastName.isValid}
                        helperText={formData.lastName.error}
                        value={formData.lastName.value}
                        onChange={(event) => { handleOnChange(event); }}
                    />
                </Stack>
                <Stack direction={{ default: "column", md: "row" }} spacing={1} my={1}>
                    {/* <TextField
                        label='Garant(s)'
                        type="text"
                        fullWidth
                        variant="filled"
                        name="warranty"
                        error={formData.warranty.error && !formData.warranty.isValid}
                        helperText={formData.warranty.error}
                        value={formData.warranty.value}
                        onChange={(event) => { handleOnChange(event); }}
                    /> */}
                     <TextField
                        label='Caution *'
                        type="number"
                        fullWidth
                        variant="filled"
                        name="caution"
                        error={formData.caution.error && !formData.caution.isValid}
                        helperText={formData.caution.error}
                        value={formData.caution.value}
                        onChange={(event) => { handleOnChange(event); }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><EuroSymbolIcon /></InputAdornment>,
                            inputProps: {min:0}
                            
                        }}
                    />
                    <TextField
                        label='RIB/IBAN'
                        type="text"
                        fullWidth
                        variant="filled"
                        name="iban"
                        error={formData.iban.error && !formData.iban.isValid}
                        helperText={formData.iban.error}
                        value={formData.iban.value}
                        onChange={(event) => { handleOnChange(event); }}
                    />
                </Stack>
                <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                   
                    <TextField
                        label='Loyer mensuel HC *'
                        type="number"
                        fullWidth
                        variant="filled"
                        name="rentPriceWithoutTax"
                        error={formData.rentPriceWithoutTax.error && !formData.rentPriceWithoutTax.isValid}
                        helperText={formData.rentPriceWithoutTax.error}
                        value={formData.rentPriceWithoutTax.value}
                        onChange={(event) => { handleOnChange(event); }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><EuroSymbolIcon /></InputAdornment>,
                            inputProps: {min:0}
                        }}
                    />
                    <TextField
                        label='Charges mensuelles'
                        type="number"
                        fullWidth
                        variant="filled"
                        name="monthlyCharges"
                        error={formData.monthlyCharges.error && !formData.monthlyCharges.isValid}
                        helperText={formData.monthlyCharges.error}
                        value={formData.monthlyCharges.value}
                        onChange={(event) => { handleOnChange(event); }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><EuroSymbolIcon /></InputAdornment>,
                            inputProps: {min:0}
                        }}
                    />
                </Stack>

            </Stack>
            <Stack direction="column" spacing={1}>
                <Typography variant="label" color="text.secondary">Début contrat de location</Typography>
                <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                    <DesktopDatePicker
                        label={`Date d'entrée ${formData.startDate.isRequired ? " *" : ""}`}
                        inputFormat="dd/MM/yyyy"
                        fullWidth
                        name="startDate"
                        helperText={formData.startDate.error}
                        value={R.isEmpty(formData.startDate.value) ? null : new Date(formData.startDate.value)}
                        onChange={(date) => {
                            if (isNaN(date)) {return; }
                            setFormData({
                                ...formData,
                                startDate: { ...formData.startDate, value: date?.toISOString(), isRequired: true, error: null, isValid: true },
                                endDate: { ...formData.endDate, isRequired: false },
                                numbersOfKeys: { ...formData.numbersOfKeys, isRequired: true },
                                paymentDate: { ...formData.paymentDate, isRequired: true },
                                paymentType: { ...formData.paymentType, isRequired: true },
                            });
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                error={formData.startDate.error && !formData.startDate.isValid}
                                helperText={formData.startDate.error}
                            />}
                        // inputProps={{ readOnly: true, }}
                    />
                    <TextField
                            label={`Jour de paiement (1-28) ${formData.paymentDate.isRequired ? " *" : ""}`}
                            id="paymentDate"
                            type="text"
                            inputProps={{ 
                                inputMode: 'numeric', pattern: '^[1-9]$|^[1-2][0-8]$',
                                inputProps: {min:0}
                             }}
                            fullWidth
                            variant="filled"
                            name="paymentDate"
                            error={formData.paymentDate.error && !formData.paymentDate.isValid}
                            helperText={formData.paymentDate.error}
                            value={formData.paymentDate.value}
                            onChange={(event) => { handleOnChange(event); }}
                        />
                </Stack>
                <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                    <TextField
                        // label='Nombre de clefs *'
                        label={`Nombre de clefs ${formData.numbersOfKeys.isRequired ? " *" : ""}`}
                        id="cles"
                        type="text"
                        fullWidth
                        variant="filled"
                        name="numbersOfKeys"
                        error={formData.numbersOfKeys.error && !formData.numbersOfKeys.isValid}
                        helperText={formData.numbersOfKeys.error}
                        value={formData.numbersOfKeys.value}
                        onChange={(event) => { handleOnChange(event); }}
                    />
                    <TextField fullWidth id="select"
                        // label="Type d'opération"
                        name="paymentType"
                        label={`Type d'opération ${formData.paymentType.isRequired ? " *" : ""}`}
                        error={formData.paymentType.error && !formData.paymentType.isValid}
                        helperText={formData.paymentType.error}
                        value={formData.paymentType.value}
                        onChange={(event) => { handleOnChange(event); }}
                        variant='filled'
                        select>
                            {Object.entries(paymentTypes).map(([key, value]) => {
                                return(<MenuItem value={key}>{value}</MenuItem>);
                            })}
                    </TextField>
                </Stack>
            </Stack>
            <Stack direction="column" spacing={1} >
                <Typography variant="label" color="text.secondary">Fin du contrat de location </Typography>
                <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                    <DesktopDatePicker
                        label={`Date de fin de contrat ${formData.startDate.isRequired ? " *" : ""}`}
                        inputFormat="dd/MM/yyyy"
                        fullWidth
                        name="endDate"
                        minDate={new Date(formData.startDate.value)}
                        helperText={formData.endDate.error}
                        value={R.isEmpty(formData.endDate.value) ? null : new Date(formData.endDate.value)}
                        onChange={(date) => { 
                            if (isNaN(date)) {return; }
                            setFormData({ ...formData, endDate: { ...formData.endDate, value: date?.toISOString(), error: null, isValid: true } }); 
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                error={formData.endDate.error && !formData.endDate.isValid}
                                helperText={formData.endDate.error}
                            />}
                        // inputProps={{ readOnly: true, }}
                    />

                    <DesktopDatePicker
                        // label="Date de départ *"
                        label="Date de départ"
                        inputFormat="dd/MM/yyyy"
                        fullWidth
                        name="departureDate"
                        minDate={new Date(formData.endDate.value)}
                        helperText={formData.departureDate.error}
                        value={R.isEmpty(formData.departureDate.value) ? null : new Date(formData.departureDate.value)}
                        onChange={(date) => {
                            if (isNaN(date)) {return; }
                            setFormData({ ...formData, departureDate: { ...formData.departureDate, value: date?.toISOString(), error: null, isValid: true } }); 
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                error={formData.departureDate.error && !formData.departureDate.isValid}
                                helperText={formData.departureDate.error}
                            />}
                    />
                </Stack>
                <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                    <TextField
                        label="Adresse à utiliser en cas de problème"
                        id="emergencyAddress"
                        type="text"
                        fullWidth
                        variant="filled"
                        name="address"
                        error={formData.address.error && !formData.address.isValid}
                        helperText={formData.address.error}
                        value={formData.address.value}
                        onChange={(event) => { handleOnChange(event); }}

                    />
                    <TextField
                        label="Téléphone à utiliser en cas de problème"
                        id="emergencyPhone"
                        type="text"
                        fullWidth
                        variant="filled"
                        name="phone"
                        error={formData.phone.error && !formData.phone.isValid}
                        helperText={formData.phone.error}
                        value={formData.phone.value}
                        onChange={(event) => { handleOnChange(event); }}

                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default FromLease;
