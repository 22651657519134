import axios from 'axios';
import * as R from 'ramda';
import { assetsActions } from "../reducers/asset";
import * as urls from '../constants/webapi/asset';
import { urlBuilder } from '../constants/utils/urlBuilder';
import { PAGE_SIZE, SMALL_PAGE_SIZE } from '../constants/utils/pageSize';


//#region ASSETS

export const GetAssets = ({ pageSize, pageNumber, sortBy, sortDesc, filter } = {}) => {
    const size = !R.isNil(pageSize) && !R.isEmpty(pageSize) ? pageSize : PAGE_SIZE;
    const page = !R.isNil(pageNumber) && !R.isEmpty(pageNumber) ? pageNumber : 1;
    const sort = !R.isNil(sortBy) && !R.isEmpty(sortBy) ? sortBy : undefined;
    const desc = !R.isNil(sortDesc) && !R.isEmpty(sortDesc) ? sortDesc : false;
    const filters = !R.isNil(filter) && !R.isEmpty(filter) ? urlBuilder(filter) : undefined;

    return async dispatch => {
        dispatch(assetsActions.getAssetsRequest());
        return axios.get(urls.getAssetsUrl({ size, page, sort, desc, filters }))
            .then((response) => {
                dispatch(assetsActions.getAssetsSuccess({
                    data: response?.data,
                    pageSize: size,
                    pageNumber: page,
                    sortBy: sort,
                    sortDesc: desc,
                    filters: filters
                }));
            })
            .catch((error) => {
                dispatch(assetsActions.getAssetsFailure(error.response.data));
            });
    };
};

export const GetDashboardAssets = ({ pageSize, pageNumber, sortBy, sortDesc, filter } = {}) => {
    const params = { size: SMALL_PAGE_SIZE, page: 1, sort: "Title", desc: false };

    return async dispatch => {
        dispatch(assetsActions.getDashboardAssetsRequest());
        return axios.get(urls.getAssetsUrl(params))
            .then((response) => {
                dispatch(assetsActions.getDashboardAssetsSuccess({
                    data: response?.data,
                    pageSize: params.size,
                    pageNumber: params.page,
                    sortBy: params.sort,
                    sortDesc: params.desc
                }));
            })
            .catch((error) => {
                dispatch(assetsActions.getDashboardAssetsFailure(error.response.data));
            });
    };
};

export const CreateAsset = (asset) => {
    return async dispatch => {
        dispatch(assetsActions.createAssetRequest());
        return axios.post(urls.getAssetUrl(asset.companyId), asset)
            .then((response) => {
                dispatch(assetsActions.createAssetSuccess(response.data));
                return response?.data;
            })
            .catch((error) => {
                dispatch(assetsActions.createAssetFailure());
                throw error;
            });
    };
};

export const GetAsset = (companyId, assetId) => {
    return async dispatch => {
        dispatch(assetsActions.getAssetRequest());
        return axios.get(urls.getAssetByIdUrl(companyId, assetId))
            .then((response) => {
                dispatch(assetsActions.getAssetSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(assetsActions.getAssetFailure(error.response.data));
            });
    };
};

export const UpdateAsset = (asset) => {
    return async dispatch => {
        dispatch(assetsActions.updateAssetRequest());
        return axios.put(urls.getAssetByIdUrl(asset.companyId, asset.id), asset)
            .then((response) => {
                dispatch(assetsActions.updateAssetSuccess(asset));
            })
            .catch((error) => {
                dispatch(assetsActions.updateAssetFailure(error.response.data));
                throw error;
            });
    };
};

export const DeleteAsset = (companyId, assetId) => {
    return async dispatch => {
        dispatch(assetsActions.deleteAssetRequest());
        return axios.delete(urls.getAssetByIdUrl(companyId, assetId))
            .then((response) => {
                dispatch(assetsActions.deleteAssetSuccess({ id: assetId }));
            })
            .catch((error) => {
                dispatch(assetsActions.deleteAssetFailure(error.response.data));
                throw error;
            });
    };
};

//#endregion

//#region SOLD ASSETS
export const GetSoldAssets = ({ pageSize, pageNumber, sortBy, sortDesc } = {}) => {
    const size = !R.isNil(pageSize) && !R.isEmpty(pageSize) ? pageSize : PAGE_SIZE;
    const page = !R.isNil(pageNumber) && !R.isEmpty(pageNumber) ? pageNumber : 1;
    const sort = !R.isNil(sortBy) && !R.isEmpty(sortBy) ? sortBy : undefined;
    const desc = !R.isNil(sortDesc) && !R.isEmpty(sortDesc) ? sortDesc : false;
    const filters = encodeURIComponent("assetState==3");

    return async dispatch => {
        dispatch(assetsActions.getSoldAssetsRequest());
        return axios.get(urls.getAssetsUrl({ size, page, sort, desc, filters }))
            .then((response) => {
                dispatch(assetsActions.getSoldAssetsSuccess({
                    data: response.data,
                    pageSize: size,
                    pageNumber: page,
                    sortBy: sort,
                    sortDesc: desc
                }));
            })
            .catch((error) => {
                dispatch(assetsActions.getSoldAssetsFailure(error.response.data));
            });
    };
};

export const CreateSoldAsset = (asset) => {
    return async dispatch => {
        dispatch(assetsActions.createSoldAssetRequest());
        return axios.post(urls.getAssetUrl(asset.companyId), asset)
            .then((response) => {
                dispatch(assetsActions.createSoldAssetSuccess(response.data));
            })
            .catch((error) => {
                dispatch(assetsActions.createSoldAssetFailure(error.response.data));
            });
    };
};

export const GetSoldAsset = (companyId, assetId) => {
    return async dispatch => {
        dispatch(assetsActions.getSoldAssetRequest());
        return axios.get(urls.getAssetByIdUrl(companyId, assetId))
            .then((response) => {
                dispatch(assetsActions.getSoldAssetSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(assetsActions.getSoldAssetFailure(error.response.data));
            });
    };
};

export const UpdateSoldAsset = (asset) => {
    return async dispatch => {
        dispatch(assetsActions.updateSoldAssetRequest());
        return axios.put(urls.getAssetByIdUrl(asset.companyId, asset.id), asset)
            .then((response) => {
                dispatch(assetsActions.updateSoldAssetSuccess(asset));
            })
            .catch((error) => {
                dispatch(assetsActions.updateSoldAssetFailure(error.response.data));
            });
    };
};

export const DeleteSoldAsset = (companyId, assetId) => {
    return async dispatch => {
        dispatch(assetsActions.deleteSoldAssetRequest());
        return axios.delete(urls.getAssetByIdUrl(companyId, assetId))
            .then((response) => {
                dispatch(assetsActions.deleteSoldAssetSuccess({ id: assetId }));
                return response.data;
            })
            .catch((error) => {
                dispatch(assetsActions.deleteSoldAssetFailure(error.response.data));
                throw error;
            });
    };
};
//#endregion
