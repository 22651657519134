import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { useNavigate } from "react-router";

// Components
// Components: Global
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Alert, LoadingButton } from '@mui/lab';

// Components: Common
import FormTenant from '../info/tenant';
import { onSubmitMultipleValidation, onSubmitValidation, validateTitle } from '../../../../constants/utils/validators';
import CompanyModalContent from '../modals/companyModalcontent';
import { CreateTenant, UpdateTenant, GetDashboardTenants } from '../../../../actions/tenant-api';
import * as Loadings from '../../../../constants/loadings/tenant';
import * as LoadingsFile from '../../../../constants/loadings/file';
import { UploadProfilePhoto } from '../../../../actions/file-api';
import { PARTICULAR } from '../../../../constants/utils/magicVariables';
import { useAutoComplete } from '../../../../hooks/useAutoComplete';
import { Dialog } from '@mui/material';

// Components: Views

export const MainTenant = ({ theme, tenantState, photo, companies, tenants, initialTenantFormData, initialCompanyForm, contactCategory, resetTenantFormData, resetCompanyForm, id }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stateFile = useSelector(state => state.file);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [currentPhoto] = useState(!R.isNil(photo) ? photo : null);
    const [formData, setFormData] = useState(initialTenantFormData);
    const [category, setCategory] = useState(R.isNil(contactCategory) ? PARTICULAR : contactCategory);
    const companyUseAutocomplete = useAutoComplete(initialCompanyForm, companies, resetCompanyForm);
    const [hasCurrentTitle, setHasCurrentTitle] = useState(null);
    const payload = { items: tenants, properties: ["firstName", "lastName"], value: formData.firstName.value.trim() + formData.lastName.value.trim(), id: id };


    const handleSubmit = async (event) => {
        event.preventDefault();
        const validatedForm = onSubmitValidation(formData);
        const companiesFormValidation = onSubmitMultipleValidation(companyUseAutocomplete.dropdownFormData);
        if (validateTitle(payload)) {
            setHasCurrentTitle(true);
            return setTimeout(() => { setHasCurrentTitle(null); }, 4000);
        }
        if (!validatedForm.isValid || !companiesFormValidation.isValid) {
            setFormData(validatedForm.form);
            companyUseAutocomplete.setDropdownFormData(companiesFormValidation.form);
            return;
        }
        try {
            const tenant = {
                contactCategory: category,
                firstName: R.isEmpty(formData.firstName.value) ? null : formData.firstName.value.trim(),
                lastName: R.isEmpty(formData.lastName.value) ? null : formData.lastName.value.trim(),
                companyName: R.isEmpty(formData.social.value) ? null : formData.social.value.trim(),
                files: [],
                occupation: R.isEmpty(formData.profession.value) ? null : formData.profession.value,
                companyIds: companyUseAutocomplete.dropdownFormData.map(c => c.value),
                phoneNumber: R.isEmpty(formData.phone.value) ? null : formData.phone.value,
                email: R.isEmpty(formData.email.value) ? null : formData.email.value
            };

            if (R.isNil(id)) {
                const newTenant = await dispatch(CreateTenant(tenant));
                tenant.id = newTenant.id;
            } else {
                tenant.id = id;
                await dispatch(UpdateTenant(tenant));
            }
            if (!R.isNil(profilePhoto)) {
                await dispatch(UploadProfilePhoto({ file: profilePhoto, id: tenant.id, profile: "Tenant" }));
            }
            dispatch(GetDashboardTenants());
            navigate(`/app/tenants/${tenant.id}`);

        } catch (error) {
            console.log(error);
            // if (error.response.status === 422) return;
            // toast.error("Échec de l'opération, réessayez !", toastConf);
        }
    };


    return (
        <React.Fragment>
            {hasCurrentTitle &&
                <Container maxWidth="100%" sx={{ display: "inherit", justifyContent: "inherit", gap: 2 }}>
                    <Alert sx={{ width: '100%', borderRadius: 3, marginY: 2 }} severity="error">
                        Un locataire existe déjà avec ce nom. Veuillez le sélectionner ou alors choisir un autre nom de locataire.
                    </Alert>
                </Container>
            }
            <Stack spacing={2} component="form" onSubmit={handleSubmit} flex="1 1 auto" width="100%" height="100%" overflow="hidden auto">
                <FormTenant
                    profilePhoto={profilePhoto}
                    setProfilePhoto={setProfilePhoto}
                    companies={companies}
                    category={category}
                    formData={formData}
                    setFormData={setFormData}
                    setCategory={setCategory}
                    currentPhoto={currentPhoto}
                    companyUseAutocomplete={companyUseAutocomplete}
                    theme={theme} mt={2} flex="1 1 auto"
                />
                <Stack direction={{ default: "column", md: "row" }} justifyContent="end" alignItems="center" pb={2} spacing={2}>
                    <Container maxWidth="sm" sx={{ display: "inherit", justifyContent: "inherit", flex: "1 1 auto", marginLeft: 0 }}>
                        <Typography variant="body2" color="text.secondary">Vous pourrez ajouter des documents relatifs à cet élément après l'avoir Enregistré, à l'aide du bouton + en bas de l'écran.</Typography>
                    </Container>
                    <Container sx={{ display: "inherit", justifyContent: "inherit", gap: 2, flex: "0" }}>
                        <Button color="text" onClick={() => navigate(-1)}>Annuler</Button>
                        <LoadingButton
                            loading={tenantState.isLoading.includes(Loadings.CREATING_TENANT) || tenantState.isLoading.includes(Loadings.UPDATING_TENANT) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
                            disabled={tenantState.isLoading.includes(Loadings.CREATING_TENANT) || tenantState.isLoading.includes(Loadings.UPDATING_TENANT) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)} size="large" type="submit" variant="contained" color='primary'>Enregistrer</LoadingButton>
                    </Container>
                </Stack>
            </Stack>
            <Dialog fullWidth maxWidth="md" onClose={companyUseAutocomplete.setClose} open={companyUseAutocomplete.isOpen} theme={theme}>
                <CompanyModalContent modalClose={companyUseAutocomplete.setClose} open={companyUseAutocomplete.isOpen} theme={theme} newCompanyName={companyUseAutocomplete.newOptionName} handleAddNewCompany={companyUseAutocomplete.handleAddNew} />
            </Dialog>
        </React.Fragment>
    );
};

export default MainTenant;