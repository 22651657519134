import { createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';
import * as Loadings from '../constants/loadings/rental';


const initState = {
    isLoading: [],
    rentals: undefined,
    rental: undefined
};

const RentalsReducer = createSlice({
    name: 'rental',
    initialState: { ...initState },
    reducers: {
        //#region GET RENTALS
        getRentalsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_RENTALS)) {
                state.isLoading.push(Loadings.FETCHING_RENTALS);
            }
        },
        getRentalsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_RENTALS);
            state.rentals = payload.data;
        },
        getRentalsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_RENTALS);
        },
        //#endregion

        //#region CREATE RENTAL
        createRentalRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_RENTAL)) {
                state.isLoading.push(Loadings.CREATING_RENTAL);
            }
        },
        createRentalSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_RENTAL);
            if (R.isNil(state.rentals)) {
                state.rentals = [];
            }
            state.rentals.push(payload);
        },
        createRentalFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_RENTAL);
        },
        //#endregion

        //#region GET RENTAL
        getRentalRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_RENTAL)) {
                state.isLoading.push(Loadings.FETCHING_RENTAL);
            }
        },
        getRentalSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_RENTAL);
            state.rental = payload;
        },
        getRentalFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_RENTAL);
        },
        //#endregion

        //#region  UPDATE RENTAL
        updateRentalRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_RENTAL)) {
                state.isLoading.push(Loadings.UPDATING_RENTAL);
            }
        },
        updateRentalSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_RENTAL);
        },
        updateRentalFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_RENTAL);
        },
        //#endregion

        //#region DELETE RENTAL
        deleteRentalRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_RENTAL)) {
                state.isLoading.push(Loadings.DELETING_RENTAL);
            }
        },
        deleteRentalSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_RENTAL);
            state.rental = undefined;
            state.rentals = state.rentals.filter(t => t.id !== payload.id);
        },
        deleteRentalFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_RENTAL);
        },
        //#endregion

        //#region CLEAR RENTAL
        clearRental(state) {
            state.rental = undefined;
        }
        //#endregion
    }
});

export const rentalsActions = RentalsReducer.actions;
export default RentalsReducer.reducer;