import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Components
// Components: Global

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

import * as theme from './theme';

export const NoMatch = ({ customTheme, messageTitle, messageDetail, error }) => {
	return (
		<Container maxWidth="md" sx={{ m: "auto" }}>
			<Box flex="1 1 auto" py={3} theme={customTheme ? customTheme : theme}>
				{ error === "500" ?
					<React.Fragment>
						<Typography variant="caption" component="p" lineHeight="1" color="text" gutterBottom>
							Error 500
						</Typography>
						<Typography variant="h3" fontWeight="medium" component="h1" lineHeight="1" color="primary" gutterBottom>
							Service indisponible
						</Typography>
					</React.Fragment>
					:
					messageTitle ? 
						<React.Fragment>{messageTitle}</React.Fragment>
						:
						<React.Fragment>
							<Typography variant="caption" component="p" lineHeight="1" color="text" gutterBottom>
								Error 404
							</Typography>
							<Typography variant="h3" fontWeight="medium" component="h1" lineHeight="1" color="primary" gutterBottom>
								Ceci n’est pas une page
							</Typography>
						</React.Fragment>
				}
				<Typography variant="body1" color={theme} mb={4}>
					{ error === "500" ?
						<React.Fragment><strong>Erreur dans le chargement des données, veuillez réessayer plus tard...</strong> Le serveur est actuellement incapable de traiter la demande en raison d'une surcharge temporaire ou d'une maintenance du serveur.</React.Fragment>
						:
						messageDetail ? 
							<React.Fragment>{messageDetail}</React.Fragment>
							:
							<React.Fragment><strong>La page que vous recherchez n'existe pas.</strong> Vous avez peut-être mal tapé l'adresse ou la page a peut-être été déplacée ou supprimée.</React.Fragment>
					}
				</Typography>
				{ error === "500" ? 
					<Button component={RouterLink} to="/" variant="contained" color="secondary">Refresh</Button>
					: 
					<Button component={RouterLink} to="/" variant="contained" color="secondary">Retourner</Button>
				}


			</Box>
		</Container>
	);
}

export default NoMatch;