import axios from 'axios';
import * as R from 'ramda';
import { documentsActions } from "../reducers/document";
import * as urls from '../constants/webapi/document';
import { urlBuilder } from '../constants/utils/urlBuilder';
import { PAGE_SIZE } from '../constants/utils/pageSize';



export const GetDocuments = ({ pageSize, pageNumber, sortBy, sortDesc, filter } = {}) => {
    const size = !R.isNil(pageSize) && !R.isEmpty(pageSize) ? pageSize : PAGE_SIZE;
    const page = !R.isNil(pageNumber) && !R.isEmpty(pageNumber) ? pageNumber : 1;
    const sort = !R.isNil(sortBy) && !R.isEmpty(sortBy) ? sortBy : undefined;
    const desc = !R.isNil(sortDesc) && !R.isEmpty(sortDesc) ? sortDesc : false;
    const filters = !R.isNil(filter) && !R.isEmpty(filter) ? urlBuilder(filter) : undefined;

    return async dispatch => {
        dispatch(documentsActions.getDocumentsRequest());
        return axios.get(urls.getDocumentsUrl({ size, page, sort, desc, filters }))
            .then((response) => {
                dispatch(documentsActions.getDocumentsSuccess({
                    data: response.data,
                    pageSize: size,
                    pageNumber: page,
                    sortBy: sort,
                    sortDesc: desc
                }));
            })
            .catch((error) => {
                dispatch(documentsActions.getDocumentsFailure(error.response.data));
            });
    };
};

export const CreateDocument = (document) => {
    return async dispatch => {
        dispatch(documentsActions.createDocumentRequest());
        return axios.post(urls.getDocumentUrl(document.companyId), document)
            .then((response) => {
                dispatch(documentsActions.createDocumentSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(documentsActions.createDocumentFailure(error.response.data));
                throw error;
            });
    };
};

export const GetDocument = (companyId, documentId) => {
    return async dispatch => {
        dispatch(documentsActions.getDocumentRequest());
        return axios.get(urls.getDocumentByIdUrl(companyId, documentId))
            .then((response) => {
                dispatch(documentsActions.getDocumentSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(documentsActions.getDocumentFailure(error.response.data));
            });
    };
};

export const UpdateDocument = (document) => {
    return async dispatch => {
        dispatch(documentsActions.updateDocumentRequest());
        return axios.put(urls.getDocumentByIdUrl(document.companyId, document.id), document)
            .then((response) => {
                dispatch(documentsActions.updateDocumentSuccess(document));
            })
            .catch((error) => {
                dispatch(documentsActions.updateDocumentFailure(error.response.data));
            });
    };
};

export const DeleteDocument = (companyId, documentId) => {
    return async dispatch => {
        dispatch(documentsActions.deleteDocumentRequest());
        return axios.delete(urls.getDocumentByIdUrl(companyId, documentId))
            .then((response) => {
                dispatch(documentsActions.deleteDocumentSuccess({ id: documentId }));
                return response.data;
            })
            .catch((error) => {
                dispatch(documentsActions.deleteDocumentFailure(error.response.data));
            });
    };
};