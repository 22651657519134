import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

// Components: Common
import * as LoadingsCompany from '../../constants/loadings/company';
import * as LoadingsTenant from '../../constants/loadings/tenant';
import { initialTenantFormData, initialCompanyForm } from '../../constants/utils/formInitialData';
import { GetCompanies } from '../../actions/company-api';
import { GetTenants } from '../../actions/tenant-api';
import Loading from '../../_common/loading';
import MainTenant from '../_common/forms/layout/tenant';


export const NewTenant = ({ theme, companyState, tenantState }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        if (R.isNil(tenantState.tenants)) {
            dispatch(GetTenants());
        }
        // eslint-disable-next-line 
    }, []);

    if (tenantState.isLoading.includes(LoadingsTenant.FETCHING_TENANTS) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)) {
        return <Loading theme={theme} />;
    }

    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let tenants = !R.isNil(tenantState.tenants) ? tenantState.tenants.map(t => ({ ...t, title: t.firstName + " " + t.lastName })) : [];

    return (
        <MainTenant
            theme={theme}
            tenantState={tenantState}
            companies={companies}
            tenants={tenants}
            initialTenantFormData={initialTenantFormData}
            initialCompanyForm={[initialCompanyForm]}
            resetTenantFormData={initialTenantFormData}
            resetCompanyForm={initialCompanyForm}
        />
    );
};

export default NewTenant;