import React from 'react';
import * as R from 'ramda';

// Components
// Components: Global
import Stack from '@mui/material/Stack';
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FormGlobalComplete from './globalComplete';

// Components: Common

// Components: Views

export const FormMain = ({ theme, formData, setFormData, handleOnChange, companyUseAutocomplete, tenantUseAutocomplete, assetUseAutocomplete, selectCompanyByAssetGroup, selectCompanyByAsset, assetGroupUseAutocomplete, subcontractorUseAutocomplete, resetOnSelectingCompany, resetOnSelectingAsset, companyId, ...others }) => {


    return (
        <Stack direction="column" flex="1 1 auto" spacing={2} width="100%" {...others}>
            <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                <DesktopDatePicker
                    label="Date *"
                    inputFormat="dd/MM/yyyy"
                    fullWidth
                    name="date"
                    helperText={formData.date.error}
                    value={R.isEmpty(formData.date.value) ? new Date() : new Date(formData.date.value)}
                    onChange={(date) => { 
                        if (isNaN(date)) {return; }
                        setFormData({ ...formData, date: { ...formData.date, value: date?.toISOString(), error: null, isValid: true } }); 
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            error={formData.date.error && !formData.date.isValid}
                            helperText={formData.date.error}
                        />}
                    // inputProps={{ readOnly: true, }}
                />
                <TextField
                    label='Titre *'
                    id="Titre"
                    type="text"
                    fullWidth
                    name="title"
                    error={formData.title.error && !formData.title.isValid}
                    helperText={formData.title.error}
                    value={formData.title.value}
                    onChange={(event) => { handleOnChange(event); }}
                />
            </Stack>
            <Stack direction={{ default: "column", md: "row" }} spacing={1}>
                <FormGlobalComplete
                    options={assetUseAutocomplete.options}
                    optionsFormData={assetUseAutocomplete.dropdownFormData}
                    setOptionsFormData={assetUseAutocomplete.setDropdownFormData}
                    handleSetOpen={assetUseAutocomplete.handleSetOpen}
                    handleSetOptionsFormData={resetOnSelectingAsset}
                    multi={false}
                    name="asset"
                    required={assetUseAutocomplete.dropdownFormData[0].isRequired}
                    label="Bien"
                />
                <FormGlobalComplete
                    options={tenantUseAutocomplete.options}
                    optionsFormData={tenantUseAutocomplete.dropdownFormData}
                    setOptionsFormData={tenantUseAutocomplete.setDropdownFormData}
                    handleSetOpen={tenantUseAutocomplete.handleSetOpen}
                    handleSetOptionsFormData={tenantUseAutocomplete.handleSetOptionsFormData}
                    multi={false}
                    name="tenant"
                    required={false}
                    label="Locataire"
                />
                <FormGlobalComplete
                    options={companyUseAutocomplete.options}
                    optionsFormData={companyUseAutocomplete.dropdownFormData}
                    setOptionsFormData={companyUseAutocomplete.setDropdownFormData}
                    handleSetOpen={companyUseAutocomplete.handleSetOpen}
                    handleSetOptionsFormData={resetOnSelectingCompany}
                    multi={false}
                    name="company"
                    required={true}
                    label="Société"
                    companyId={companyId}
                />
            </Stack>
        </Stack>
    );
};

export default FormMain;

//selectCompanyByAssetGroup, selectCompanyByAsset