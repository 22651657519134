import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';


// Components
// Components: Global
import Box from '@mui/material/Box';

// Components: Common
// Components: Views
import PasswordsViewEmpty from './empty';
import PasswordsViewDetail from './detail';

export const PasswordsView = ({ theme, userState, companyState, tenantState, assetState, assetGroupState, subcontractorState, collaboratorState, documentState, rentalState, accountState }) => {
    const { pathname } = useLocation();
    const showView = !pathname.endsWith('/passwords');

    return (
        <Box display={{ default: showView ? 'flex' : 'none', lg: "flex" }} flexDirection="column" width="100%" overflow="hidden auto" backgroundColor="background.default" sx={{ borderRadius: 2, position: { default: showView && 'fixed', lg: 'relative' }, left: { default: showView && 0, lg: 'unset' }, right: { default: showView && 0, lg: 'unset' }, top: { default: showView && '5rem', lg: 'unset' }, height: { default: showView && 'calc(100% - 5rem)', lg: '100%' }, bottom: { default: showView && 0, lg: 'unset' }, zIndex: { default: showView && 2, lg: 'unset' } }}>
			<Routes>
				<Route index end element={
					<PasswordsViewEmpty />
				} />
				<Route path=":companyId/:id/*" element={
					<PasswordsViewDetail theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} documentState={documentState} rentalState={rentalState} accountState={accountState}/>
				} />
				<Route path="*" element={<Navigate to="/app/passwords" />} />
				<Route path="/" element={
					<Navigate replace to="/app/passwords" />
				} />
			</Routes>
        </Box>
    );
};

export default PasswordsView;
