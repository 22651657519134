import React, { useState } from 'react';

// Components
// Components: Global
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// Components: Views
import AssetsMenuNav from './navbar';
import AssetsMenuView from './view';
import AssetsMenuFooter from './footer';


export const AssetsMenu = ({   companyState, tenantState, assetState, assetGroupState, subcontractorState, collaboratorState, documentState, rentalState, accountState, paymentState }) => {
    const [value, setValue] = useState(0);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    return (
        <Box component="aside" className="aside" display="flex" flex="1 1 auto" py={2} height="inherit" width="100%" sx={{ flexDirection: 'column', maxWidth: { lg: '350px', hd: 'sm' } }}>
            <Typography component="h3" className="sr-only">Menu</Typography>
            <AssetsMenuNav
             handleChange={handleChange} 
             value={value} 
             setValue={setValue} 
             />
            <AssetsMenuView
             height="inherit"
             overflow="hidden auto" 
             handleChangeIndex={handleChangeIndex} 
             value={value} 
             setValue={setValue}
             companyState={companyState} 
             tenantState={tenantState} 
             assetState={assetState} 
             assetGroupState={assetGroupState} 
             subcontractorState={subcontractorState} 
             collaboratorState={collaboratorState} 
             documentState={documentState} 
             rentalState={rentalState} 
             accountState={accountState} 
             paymentState={paymentState}
             />
            <AssetsMenuFooter />
        </Box>
    );
};

export default AssetsMenu;
