import  { useEffect, useState } from 'react';
import * as R from 'ramda';
import { useToggle } from './useModal';

export const useAutoComplete = (initialData, initialOptions, resetForm) => {
    const [dropdownFormData, setDropdownFormData] = useState(initialData);
    const [newOptionName, setNewOptionName] = useState('');
    const { isOpen, setClose, setOpen } = useToggle();

    useEffect(() => {
        // if (initialData) {
        //     setDropdownFormData([initialData]);
        // }
    }, [initialData]);


    const handleAddNew = (option) => {
        let formDataTemp = R.clone(dropdownFormData);
        const index = formDataTemp.findIndex(a => a.value === '');
        if (index === -1) return;
        formDataTemp[index].value = option.id;
        formDataTemp[index].label = R.isNil(option.title) ? option.firstName : option.title;
        setDropdownFormData(formDataTemp.filter(a => !R.isEmpty(a.value)));
        return option;
    };

    const handleSetOpen = (name, index) => {
        const formDataTemp = R.clone(dropdownFormData);
        formDataTemp[index].error = null;
        formDataTemp[index].isValid = false;
        formDataTemp[index].value = "";
        formDataTemp[index].label = "";
        setDropdownFormData(formDataTemp);
        setNewOptionName(name);
        setOpen();
    };

    const handleSetOptionsFormData = (newValue, index) => {
        const formDataTemp = R.clone(dropdownFormData);
        formDataTemp[index].error = null;
        formDataTemp[index].isValid = false;
        formDataTemp[index].label = !R.isNil(newValue) && !R.isNil(newValue.label) ? newValue.label : '';
        formDataTemp[index].value = !R.isNil(newValue) && !R.isNil(newValue.id) ? newValue.id : '';
        setDropdownFormData(formDataTemp);
        return { id: !R.isNil(newValue) && !R.isNil(newValue.id) && newValue.id, index };
    };

    const resetFormData = () => {
        setDropdownFormData([resetForm]);
    };
    let options = !R.isNil(initialOptions) ? initialOptions.map(a => ({ label: a.title, id: a.id })) : [];
    options = options.filter((a) => !dropdownFormData.find(f => a.id === f.value));


    return {
        handleSetOpen,
        handleAddNew,
        handleSetOptionsFormData,
        newOptionName,
        setNewOptionName,
        options,
        dropdownFormData,
        setDropdownFormData,
        resetFormData,
        isOpen,
        setClose,
        setOpen,
    };
};


