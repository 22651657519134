import React from 'react';
import * as R from 'ramda';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export const ModalPermission = ({ theme, userState, isOpen, setIsOpen }) => {

    return (
        <Dialog fullWidth maxWidth="xs" onClose={() => setIsOpen(false)} open={isOpen} theme={theme} sx={{ textAlign: "center" }}>
            <DialogTitle variant="h4" color="warning.main" sx={{ pt: 3, pb: 1, lineHeight: 1 }}>Pas de permission !</DialogTitle>
            <DialogContent sx={{ alignItems: "center", pb: 0 }}>
                <DialogContentText variant="body" mb={2}>
                    Demandez à votre administrateur la permission d'effectuer cette action.
                    {!R.isNil(userState.permissionMessage) && userState.permissionMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions disableSpacing={true} sx={{ flexDirection: "column", p: 3, pt: 0 }}>
                {/* {true ?
                    <Button type="submit" size="large" variant="contained" theme={theme} color='info' sx={{ px: 3 }}>Déverrouiller</Button> :
                    <Button disabled={userState.isLoading.includes(Loadings.UPDATING_USER)} loading={userState.isLoading.includes(Loadings.UPDATING_USER).toString()} type="submit" size="large" variant="contained" theme={theme} color='error' sx={{ px: 3 }}>Désactiver accès</Button>
                } */}
                <Button theme={theme} color="neutral" fullWidth onClick={() => setIsOpen(false)} sx={{ px: 3, mt: 1 }}>Annuler</Button>
            </DialogActions>
        </Dialog >
    );
};
