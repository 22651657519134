import * as R from 'ramda';

export const BASE_URL = process.env.REACT_APP_APIURL;


// export const getAccountListUrl = ({ page, size, sort, desc }) => `${BASE_URL}Account/list?Page=${page}&PageSize=${size}${R.isNil(sort) ? "" : "&Sorts=" + sort}${R.isNil(desc) ? "" : desc ? "&sortByDesc=true" : "&sortByDesc=false"}`;
export const getAccountsUrl = ({ page, size, sort, desc, filters }) => `${BASE_URL}Account?Page=${page}&PageSize=${size}${R.isNil(sort) ? "" : + desc ? "&Sorts=-" + sort : "&Sorts=" + sort}${filters ? "&filters=" + filters : ""}`;

export const getAccountUrl = (companyId) => `${BASE_URL}Account/${companyId}`;
export const getAccountByIdUrl = (companyId, accountId) => `${BASE_URL}Account/${companyId}/${accountId}`;


