import React, { useMemo } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';

// Components
// Components: Global
import Container from '@mui/material/Container';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export const AssetsMenuNav = ({ value, setValue, handleChange, ...others }) => {
    const theme = useTheme()
    const routerLocation = useLocation(); 
    const path = useMemo(() => {
        if (routerLocation.pathname.includes('immeubles')) {
            return 'immeubles';
        } else if (routerLocation.pathname.includes('vendu')) {
            return 'vendu';
        } else {
            return 'assets';
        }
        //return '';
    }, [routerLocation.pathname])
    const obj = {
        "assets": 0,
        "immeubles": 1,
        "vendu": 2,
    }

    return (
        <Container maxWidth="100%" sx={{ m: 0, position: 'sticky', bottom: 0, zIndex: 1 }} {...others}>
            <BottomNavigation sx={{ boxShadow: 'none', width: '100%', borderRadius: '5px', mt: { md: 2 } }} position="sticky" top="0" showLabels value={obj[path]} onChange={handleChange}>
                <BottomNavigationAction
                    component={RouterLink}
                    to="/app/assets"
                    sx={{
                        maxWidth: '100%',
                        paddingX: { default: 1, md: 2 },
                        borderTopLeftRadius: 'inherit',
                        borderBottomLeftRadius: 'inherit'
                    }}
                    color="inherit"
                    aria-label="home"
                    label={useMediaQuery(theme.breakpoints.up('sm')) ? "Appartements" : "Appart"}
                />
                <BottomNavigationAction
                    component={RouterLink}
                    to="/app/assets/immeubles"
                    sx={{ maxWidth: '100%', paddingX: { default: 1, md: 2 } }}
                    color="inherit"
                    aria-label="add"
                    label="Immeubles"
                />
                <BottomNavigationAction
                    component={RouterLink}
                    to="/app/assets/vendu"
                    sx={{ maxWidth: '100%', paddingX: { default: 1, md: 2 }, borderTopRightRadius: 'inherit', borderBottomRightRadius: 'inherit' }}
                    color="inherit"
                    aria-label="menu"
                    label="Vendus"
                />
            </BottomNavigation>
        </Container>
    );
}

export default AssetsMenuNav;