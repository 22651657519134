import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import * as R from 'ramda';

// Components
// Components: Global
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';

// Components: Common
import FormImmeuble from '../info/immeuble';
import CompanyModalContent from '../modals/companyModalcontent';
import { Alert, Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { onSubmitMultipleValidation, onSubmitValidation, validateTitle } from '../../../../constants/utils/validators';
import { CreateAssetGroup, UpdateAssetGroup, GetDashboardAssetGroups } from '../../../../actions/assetGroup-api';
import * as Loadings from '../../../../constants/loadings/assetGroup';
import * as LoadingsFile from '../../../../constants/loadings/file';
import { useAutoComplete } from '../../../../hooks/useAutoComplete';
import { UploadProfilePhoto } from '../../../../actions/file-api';

// Components: Views

export const MainAssetGroup = ({ theme, assetGroupState, photo, assetGroup, assetGroups, companies, initialAssetGroupFormData, initialCompanyForm, resetCompanyForm, resetAssetGroupFormData, companyId, id }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const stateFile = useSelector(state => state.file);
    const [formData, setFormData] = useState(initialAssetGroupFormData);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [currentPhoto, setCurrentPhoto] = useState(!R.isNil(photo) ? photo : null);
    const companyUseAutocomplete = useAutoComplete(initialCompanyForm, companies, resetCompanyForm);
    const [hasCurrentTitle, setHasCurrentTitle] = useState(null);
    const payload = { items: assetGroups, properties: ["title"], value: formData.title.value, id: id };


    const submitForm = async (event) => {
        event.preventDefault();
        let newAssetGroup;
        const companyFormValidation = onSubmitMultipleValidation(companyUseAutocomplete.dropdownFormData);
        const validatedForm = onSubmitValidation(formData);
        if (validateTitle(payload)) {
            setHasCurrentTitle(true);
            return setTimeout(() => { setHasCurrentTitle(null); }, 4000);
        }
        if (!validatedForm.isValid || !companyFormValidation.isValid) {
            companyUseAutocomplete.setDropdownFormData(companyFormValidation.form);
            setFormData(validatedForm.form);
            return;
        }
        try {
            const assetGroupModel = {
                title: formData.title.value.trim(),
                companyId: companyUseAutocomplete.dropdownFormData[0].value.trim(),
                address: {
                    addressLine1: formData.address.value.trim(),
                    addressLine2: formData.address.value.trim(),
                    postalCode: formData.postalCode.value.trim(),
                    locality: formData.city.value.trim(),
                    region: 'France',
                    country: 'France'
                },
            };
            if (R.isNil(id)) {
                newAssetGroup = await dispatch(CreateAssetGroup(assetGroupModel));
                assetGroupModel.id = newAssetGroup.id;
            } else {
                assetGroupModel.id = id;
                assetGroupModel.address.id = assetGroup.address.id;
                assetGroupModel.modifiedOn = new Date().toISOString();
                newAssetGroup = await dispatch(UpdateAssetGroup(assetGroupModel));
            }
            if (!R.isNil(profilePhoto)) {
                await dispatch(UploadProfilePhoto({ file: profilePhoto, id: assetGroupModel.id, profile: "AssetGroup" }));
            }

            dispatch(GetDashboardAssetGroups());
            navigate(`/app/assets/immeubles/${assetGroupModel.companyId}/${assetGroupModel.id}`, { state: "form" });
        } catch (error) { 
            // toast.error("Échec de l'opération, réessayez !", toastConf);
        }
    };


    return (
        <React.Fragment>
            {hasCurrentTitle &&
                <Container maxWidth="100%" sx={{ display: "inherit", justifyContent: "inherit", gap: 2 }}>
                    <Alert sx={{ width: '100%', borderRadius: 3, marginY: 2 }} severity="error">
                    Un immeuble existe déjà avec ce nom. Veuillez le sélectionner ou alors choisir un autre nom d'immeuble.
                    </Alert>
                </Container>
            }
            <Stack spacing={2} component="form" onSubmit={(e) => submitForm(e)} flex="1 1 auto" width="100%" height="100%" overflow="hidden auto">
                <FormImmeuble
                    theme={theme}
                    companies={companies}
                    formData={formData}
                    setFormData={setFormData}
                    companyUseAutocomplete={companyUseAutocomplete}
                    companyId={companyId}
                    profilePhoto={profilePhoto}
                    setProfilePhoto={setProfilePhoto}
                    currentPhoto={currentPhoto}
                    mt={2}
                />
                <Stack direction={{ default: "column", md: "row" }} justifyContent="end" alignItems="center" pb={2} spacing={2}>
                    <Container maxWidth="sm" sx={{ display: "inherit", justifyContent: "inherit", flex: "1 1 auto", marginLeft: 0 }}>
                        <Typography variant="body2" color="text.secondary">Vous pourrez ajouter des documents relatifs à cet élément après l'avoir Enregistré, à l'aide du bouton + en bas de l'écran.</Typography>
                    </Container>
                    <Container sx={{ display: "inherit", justifyContent: "inherit", gap: 2, flex: "0" }}>
                        <Button color="text" onClick={() => navigate(-1)}>Annuler</Button>
                        <LoadingButton
                            loading={assetGroupState.isLoading.includes(Loadings.CREATING_ASSETGROUP) || assetGroupState.isLoading.includes(Loadings.UPDATING_ASSETGROUP) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
                            disabled={assetGroupState.isLoading.includes(Loadings.CREATING_ASSETGROUP) || assetGroupState.isLoading.includes(Loadings.UPDATING_ASSETGROUP) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
                            type="submit" size="large" variant="contained" color='primary'>Enregistrer</LoadingButton>
                    </Container>
                </Stack>
            </Stack>
            <Dialog fullWidth maxWidth="md" onClose={companyUseAutocomplete.setClose} open={companyUseAutocomplete.isOpen} theme={theme}>
                <CompanyModalContent modalClose={companyUseAutocomplete.setClose} open={companyUseAutocomplete.isOpen} theme={theme} newCompanyName={companyUseAutocomplete.newOptionName} handleAddNewCompany={companyUseAutocomplete.handleAddNew} />
            </Dialog>
        </React.Fragment >
    );
};

export default MainAssetGroup;