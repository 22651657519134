import { createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';
import * as Loadings from '../constants/loadings/assetGroup';
import { SMALL_PAGE_SIZE } from '../constants/utils/pageSize';


const initState = {
    isLoading: [],
    assetGroups: undefined,
    dashboardAssetGroups: undefined,
    assetGroup: undefined
};

const AssetGroupsReducer = createSlice({
    name: 'assetGroup',
    initialState: { ...initState },
    reducers: {
        getAssetGroupsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_ASSETGROUPS)) {
                state.isLoading.push(Loadings.FETCHING_ASSETGROUPS);
            }
        },
        getAssetGroupsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ASSETGROUPS);
            if (typeof payload.data !== 'string') {
                state.assetGroups = payload.data.filter(a => !Boolean(a.isDefault));
            }
        },
        getAssetGroupsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ASSETGROUPS);
        },

        getDashboardAssetGroupsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_ASSETGROUPS)) {
                state.isLoading.push(Loadings.FETCHING_ASSETGROUPS);
            }
        },
        getDashboardAssetGroupsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ASSETGROUPS);
            state.dashboardAssetGroups = payload.data.filter(a => !Boolean(a.isDefault));

        },
        getDashboardAssetGroupsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ASSETGROUPS);
        },

        createAssetGroupRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_ASSETGROUP)) {
                state.isLoading.push(Loadings.CREATING_ASSETGROUP);
            }
        },
        createAssetGroupSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_ASSETGROUP);
            if (R.isNil(state.assetGroups)) {
                state.assetGroups = [];
            }
            if (!R.isNil(state.dashboardAssetGroups) && state.dashboardAssetGroups.length < SMALL_PAGE_SIZE) {
                state.dashboardAssetGroups.push(payload);
            }
            if (R.isNil(state.dashboardAssetGroups)) {
                state.dashboardAssetGroups = [];
                state.dashboardAssetGroups.push(payload);
            }
            state.assetGroups.push(payload);
        },
        createAssetGroupFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_ASSETGROUP);
        },

        getAssetGroupRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_ASSETGROUP)) {
                state.isLoading.push(Loadings.FETCHING_ASSETGROUP);
            }
        },
        getAssetGroupSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ASSETGROUP);
            state.assetGroup = payload;
        },
        getAssetGroupFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ASSETGROUP);
        },

        updateAssetGroupRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_ASSETGROUP)) {
                state.isLoading.push(Loadings.UPDATING_ASSETGROUP);
            }
        },
        updateAssetGroupSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_ASSETGROUP);
            state.assetGroups = state.assetGroups.map(assetGroup => {
                if (assetGroup.id === payload.id) {
                    return payload;
                }
                return assetGroup;
            });
        },
        updateAssetGroupFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_ASSETGROUP);
        },

        deleteAssetGroupRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_ASSETGROUP)) {
                state.isLoading.push(Loadings.DELETING_ASSETGROUP);
            }
        },
        deleteAssetGroupSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_ASSETGROUP);
            state.assetGroup = undefined;
            state.assetGroups = state.assetGroups.filter(a => a.id !== payload.id);
            if (!R.isNil(state.dashboardAssetGroups)) {
                state.dashboardAssetGroups = state.dashboardAssetGroups.filter(a => a.id !== payload.id);
            }
        },
        deleteAssetGroupFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_ASSETGROUP);
        },
    }
});

export const assetGroupsActions = AssetGroupsReducer.actions;
export default AssetGroupsReducer.reducer;