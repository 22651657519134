import React, { useState } from 'react';
import * as R from 'ramda';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';

import { Avatar, Box, Divider, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PaymentsIcon from '@mui/icons-material/Payments';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/Visibility';
import ItemCard from './itemCard';
import { dayMonthYear } from '../../constants/utils/date';
import ModalCarousel from "./modalCarousel";
import { paymentTypes } from '../../constants/utils/objectProperties';
import {  useSelector } from 'react-redux/es/hooks/useSelector';

export const TransactionRow = ({ permissions, openPermissionModal, payment, assetState, subcontractorState, tenantState, setSelectedPayment, setOpenDeletePay, theme }) => {
    const [optionsMenu, setOptionsMenu] = useState(null);
    const [openCarousel, setOpenCarousel] = useState(false);
    const [openTransaction, setOpenTransaction] = useState(false);
    const showMe = Boolean(optionsMenu);
    const optionsMenuClick = (e) => {
        e.preventDefault();
        setOptionsMenu(e.currentTarget);
        setSelectedPayment(payment);
    };

    const modalOpenCarousel = () => {
		setOpenCarousel(true);
	};
	const modalCloseCarousel = () => {
		setOpenCarousel(false);
	};

    const companyState = useSelector((state) => state.company);

    return (
        <ItemCard raw alignItems={{ default: "start", uhd: "center" }} key={payment.id} padding={1} borderRadius={2}>
            <Stack direction="row" alignItems={{ default: "start", uhd: "center" }} spacing={1}>
                <Avatar 
                   variant="rounded" 
                   children={payment.title}
                   sx={{ 
                    bgcolor: "background.paper",
                    fontWeight: 'bold',
                    color: (payment.amount === 0 ? "info.light" : (payment.amount > 0 ? "success.light" : "danger.main")) 
                    }}
                >
                    <PaymentsIcon />
                </Avatar>
                <Stack alignItems={{ default: "start", hd: "center" }} direction={{ default: "column", uhd: "row" }} spacing={{ uhd: 2 }} flex='1 1 auto'>
                    <Stack direction={{ default: "column", lg: "row" }} spacing={{ uhd: 2 }} width="100%" justifyContent="space-between">
                        <Typography className="truncate" fontWeight="medium" my="auto">
                            {payment.title}
                        </Typography>
						{payment.assetId && 
                        <Typography className="truncate" color="text.hint" variant="body2" textAlign={{default: 'right', uhd: 'left'}}>
                            {/* {payment.assetId} */}
                            {!R.isNil(assetState.assets) && !R.isEmpty(assetState.assets) && assetState.assets.filter(r => r.id === payment.assetId).map(c => c.title)}
                        </Typography>}
                    </Stack>
                    <Stack direction={{ default: "column", xl: "row" }} spacing={{ xl: 2 }} flex='1 1 auto' width="100%" justifyContent="space-between">
                        <Stack direction={{ default: "column", md: "row" }} spacing={{ md: 1 }} flex='1 1 auto' justifyContent="space-between" >
                            <Typography color="text.dark" variant="body1">
                                {payment.paymentCategory === 1 ? 'Loyer' : payment.paymentCategory === 2 ? 'Caution' :
                                    payment.paymentCategory === 3 ? 'Facture' : payment.paymentCategory === 4 ? (payment.amount > 0 ? 'Debit' : 'Crédit') : "Autre"}
                            </Typography>
                            <Typography color="text.secondary" variant="body1" textAlign="right">
                                {!R.isNil(payment.date) && format(new Date(payment.date), dayMonthYear)}
                            </Typography>
                        </Stack>
                        <Stack direction={{ default: "column", md: "row" }} flex='1 1 auto' spacing={{ md: 1 }} justifyContent="space-between">
							<Typography color="text.disabled" className="truncate" variant="body1" width="4em" maxWidth="100%">
                                {payment.transactionNumber ? payment.transactionNumber : 'Aucun Nº transaction'}
                            </Typography>
                            <Typography
                              variant="body1" 
                              textAlign="right"
                              sx={{
                                color: (payment.amount === 0 ? "info.light" : (payment.amount > 0 ? "success.light" : "danger.main")),
                                fontSize: { xs: '1rem', sm: '1rem' },
                                fontWeight: 'bold' 
                              }}
                            >
                              {payment?.amount > 0 ? '+':'-'} €{payment?.amount?.toString()?.replace('-','')} 
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Box position={{ default: "absolute", xs: "relative" }} top={{ default: -8, xs: "unset" }} right={{ default: 8, xs: "unset" }}>
                    <IconButton sx={{ justifyContent: "space-between", maxWidth: { xs: "fit-content" } }} variant="contained" color="inherit" id="optionButtom" aria-controls={showMe ? 'optionMenu' : undefined} aria-haspopup="true" aria-expanded={showMe ? 'true' : undefined} onClick={optionsMenuClick}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu id="optionMenu" anchorEl={optionsMenu} open={showMe} onClose={() => setOptionsMenu(null)} MenuListProps={{ 'aria-labelledby': 'optionButtom' }}>
                        <MenuItem variant="text" color="inherit" size="inherit" key="detail" sx={{ backgroundColor: "primary.dim", marginX: 1, paddingX: 1, borderRadius: 1, gap: 1 }} onClick={() => setOpenTransaction(transac => !transac)}>
                            <Box component="span" flex="1 1 auto" sx={{ color: 'text.dark' }}>Détails de paiement</Box>
                            {!openTransaction ?
                                <VisibilityIcon sx={{ color: "text.disabled" }} />
                                :
                                <VisibilityOffIcon sx={{ color: "text.secondary" }} />
                            }
                        </MenuItem>
                        <Divider />
                        {permissions.includes("Update") ?
                            <MenuItem component={RouterLink} to={"/app/edit/payment/" + payment.companyId + '/' + payment.id}>
                                Éditer information
                            </MenuItem>
                            :
                            <MenuItem onClick={openPermissionModal} >
                                Éditer information
                            </MenuItem>
                        }
                        <MenuItem disabled={R.isEmpty(payment.files)} onClick={modalOpenCarousel} variant="text" color="inherit" size="inherit" key="Voir fichiers">
                            Voir les fichiers
                        </MenuItem>
                        <MenuItem onClick={() => { permissions.includes("Delete") ? setOpenDeletePay(true) : openPermissionModal(); }} variant="text" color="inherit" size="inherit" key="effacer">
                            Effacer
                        </MenuItem>
                    </Menu>
                </Box>
                <ModalCarousel theme={theme} items={payment.files} modalClose={modalCloseCarousel} open={openCarousel} />
            </Stack>
            {openTransaction &&
                <Box key={payment.id} padding={4} mt={1} backgroundColor="background.dim" borderRadius={2} elevation={0} gap={4} display="flex" flexDirection="column">
                    <Stack direction="column" spacing={1}>
                        <Typography variant="label" color="text.secondary">Généralités</Typography>
                        <Stack direction="column" spacing={1}>
                            <Stack direction={{ xs: "column", lg: "row" }} flex='1 1 auto' spacing={1}>
                                <Typography variant="body1" flex="1">Date: {!R.isNil(payment.date) ? <Typography component="span" fontWeight="bold">{format(new Date(payment.date), dayMonthYear)}</Typography> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>

                                <Typography sx={{ lineHeight: 1 }} display={{ default: 'none', lg: 'inherit' }} variant="h6" component="p" px={3} color="text.disabled">•</Typography>

                                <Typography variant="body1" flex="1">Titre: {!R.isNil(payment.title) ? <Typography component="span" fontWeight="bold">{payment.title}</Typography> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>
                            </Stack>
                            <Stack direction={{ xs: "column", lg: "row" }} flex='1 1 auto' spacing={1}>
                                <Typography variant="body1" flex="1">Bien: {!R.isNil(payment.assetId) ? <Typography component="span" fontWeight="bold">{assetState.assets.filter(r => r.id === payment.assetId).map(c => c.title)}</Typography> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>

                                <Typography sx={{ lineHeight: 1 }} display={{ default: 'none', lg: 'inherit' }} variant="h6" component="p" px={3} color="text.disabled">•</Typography>

                                <Typography variant="body1" flex="1">Locataire: {!R.isNil(payment.contactId) && !R.isNil(tenantState.tenants) ? <Typography component="span" fontWeight="bold">{tenantState.tenants.filter(r => r.id === payment.contactId).map(c => c.firstName + ' ' + c?.lastName)}</Typography> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>

                                <Typography sx={{ lineHeight: 1 }} display={{ default: 'none', lg: 'inherit' }} variant="h6" component="p" px={3} color="text.disabled">•</Typography>

                                <Typography variant="body1" flex="1">Société: {!R.isNil(payment.companyId) ? <Typography component="span" fontWeight="bold">{companyState.companies?.filter(r => r.id === payment.companyId).map(c => c.title)}</Typography> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>
                            </Stack>

                            <Typography variant="body1" flex="1">
                                Sous-traitant: 
                                {!R.isNil(payment.subcontractorId) && !R.isNil(subcontractorState.subcontractors) ?
                                    <Typography component="span" fontWeight="bold">{subcontractorState.subcontractors.filter(r => r.id === payment.subcontractorId).map(c => c.firstName + ' ' + c?.lastName)}</Typography>
                                    :
                                    <Typography component="span" color="text.hint">Le champ est vide</Typography>
                                }
                            </Typography>
                            <Stack direction="column" spacing={1} pt={2}>
                                <Divider />
                                <Typography variant="body1" flex="1">Pièce jointe: {!R.isNil(payment.files) ? <Typography component="span" fontWeight="bold">{payment.files.length}</Typography> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>
                                <Divider />
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="label" color="text.secondary">{payment.amount > 0 ? 'Encaissement' : 'Décaissement'}</Typography>
                        <Stack direction="column" spacing={1}>
                            {payment.amount > 0 &&
                                <Stack direction="row" flex='1 1 auto' spacing={1}>
                                    <Typography variant="body1" flex="1">Date: {!R.isNil(payment.paymentDeadline) ? <Typography component="span" fontWeight="bold">{format(new Date(payment.paymentDeadline), dayMonthYear)}</Typography> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>

                                    <Typography sx={{ lineHeight: 1 }} display={{ default: 'none', lg: 'inherit' }} variant="h6" component="p" px={3} color="text.disabled">•</Typography>
                                    <Typography variant="body1" flex="1">Montant HT: {!R.isNil(payment.amountWithoutTax) ? <Typography component="span" fontWeight="bold">
                                        {payment.amountWithoutTax + " €"}</Typography> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>

                                    <Typography sx={{ lineHeight: 1 }} display={{ default: 'none', lg: 'inherit' }} variant="h6" component="p" px={3} color="text.hint">•</Typography>

                                    <Typography 
                                       variant="body1"
                                       flex="1">
                                        Montant TTC: {!R.isNil(payment.amount) ? 
                                        <Typography 
                                          component="span"
                                          fontWeight="bold"
                                          sx= {{
                                            color: (payment.amount === 0 ? "info.light" : (payment.amount > 0 ? "success.light" : "danger.main")),
                                          }}
                                          >
                                            {payment.amount + " €"}
                                        </Typography> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>
                                </Stack>
                            }
                            <Stack direction="row" flex='1 1 auto' spacing={1}>
                                {payment.amount < 0 &&
                                    <Typography 
                                       variant="body1"
                                       flex="1"
                        
                                    >
                                        Montant TTC *:{!R.isNil(payment.amount) ? 
                                        <Typography
                                          component="span" 
                                          fontWeight="bold"
                                          sx={{
                                            color: "danger.main",
                                          }}
                                        >
                                            {payment.amount + " €"}
                                         </Typography> : 
                                    <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>
                                }
                                <Typography variant="body1" flex="1">Catégorie de paiement: {!R.isNil(payment.paymentCategory) ? <Typography component="span" fontWeight="bold">
                                    {payment.paymentCategory === 1 ? 'Loyer' : payment.paymentCategory === 2 ? 'Caution' :
                                        payment.paymentCategory === 3 ? 'Facture' : payment.paymentCategory === 4 ? (payment.amount > 0 ? 'Debit' : 'Crédit') : "Autre"}</Typography> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>

                                <Typography sx={{ lineHeight: 1 }} display={{ default: 'none', lg: 'inherit' }} variant="h6" component="p" px={3} color="text.hint">•</Typography>

                                <Typography variant="body1" flex="1">Type d'opération: {!R.isNil(payment.paymentType) 
                                    ? <Typography component="span" fontWeight="bold">
                                        {paymentTypes[payment.paymentType]}
                                    </Typography> 
                                    : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>
                            </Stack>

                            <Typography variant="body1">Nº transaction: {payment.transactionNumber ? <Typography component="span" fontWeight="bold">{payment.transactionNumber}</Typography> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>
                        </Stack>
                    </Stack>
                    {/* <Stack direction="column" spacing={1}>
                <Typography variant="label" color="text.secondary">Pièces jointes</Typography>
            </Stack> */}
                </Box>
            }
        </ItemCard>
    );
};
