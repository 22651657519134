import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from 'ramda';


import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';
import Container from '@mui/material/Container';

import { onSubmitMultipleValidation, onSubmitValidation, validateTitle } from "../../../../constants/utils/validators";
import { initialAssetFormData, initialAssetGroupForm, initialCompanyForm } from "../../../../constants/utils/formInitialData";
import { CreateAsset } from "../../../../actions/asset-api";
import * as Loadings from '../../../../constants/loadings/company';
import * as LoadingsFile from '../../../../constants/loadings/file';
import { useAutoComplete } from "../../../../hooks/useAutoComplete";
import CompanyModalContent from "./companyModalcontent";

import FormAsset from "../info/asset";
// import FormImmeuble from "../info/immeuble";
// import FormCompany from "../info/company";
import AssetGroupModalContent from "./assetGroupModalContent";
import { UploadProfilePhoto, UploadFiles } from "../../../../actions/file-api";



const AssetModalContent = ({ modalClose, open, theme, newTitle, handleAddNew }) => {
    const dispatch = useDispatch();
    const assetGroupState = useSelector(state => state.assetGroup);
    const fileState = useSelector(state => state.file);
    const assetState = useSelector(state => state.asset);
    const companyState = useSelector(state => state.company);
    const [formData, setFormData] = useState(initialAssetFormData);
    const [photos, setPhotos] = useState([]);
    const [profilePhoto, setProfilePhoto] = useState(null);
    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let assetGroups = !R.isNil(assetGroupState.assetGroups) ? assetGroupState.assetGroups : [];
    let assets = !R.isNil(assetState.assets) ? assetState.assets : [];
    const companyUseAutocomplete = useAutoComplete([initialCompanyForm], companies, initialCompanyForm);
    const assetGroupUseAutocomplete = useAutoComplete([initialAssetGroupForm], assetGroups, initialAssetGroupForm);
    const [hasCurrentTitle, setHasCurrentTitle] = useState(null);
    const payload = { items: assets, properties: ["title"], value: formData.title.value, id: null };

    useEffect(() => {
        setFormData({ ...formData, title: { ...formData.title, value: newTitle } });
        // eslint-disable-next-line 
    }, [newTitle]);

    const resetAssetGroup = () => {
        assetGroupUseAutocomplete.setDropdownFormData([initialAssetGroupForm]);
    };

    const selectCompany = ({ id, index = 0 } = {}) => {
        if (R.isNil(id) || !id) return;
        const companyformDataTemp = R.clone(companyUseAutocomplete.dropdownFormData);
        let companyId = assetGroupState.assetGroups.find(a => a.id === id)?.companyId;
        let foundCompany = companyState.companies?.find(c => c.id === companyId);
        companyformDataTemp[index] = { isRequired: true, error: null, isValid: false, label: foundCompany.title, value: foundCompany.id };
        companyUseAutocomplete.setDropdownFormData(companyformDataTemp);
    };

    const selectCompanyById = ({ companyId, index = 0 } = {}) => {
        if (R.isNil(companyId)) return;
        const companyformDataTemp = R.clone(companyUseAutocomplete.dropdownFormData);
        let foundCompany = companyState.companies?.find(c => c.id === companyId);
        companyformDataTemp[index] = { isRequired: true, error: null, isValid: false, label: foundCompany.title, value: foundCompany.id };
        companyUseAutocomplete.setDropdownFormData(companyformDataTemp);
    };

    const submitForm = async (event) => {
        const validatedForm = onSubmitValidation(formData);
        const validatedCompanyForm = onSubmitMultipleValidation(companyUseAutocomplete.dropdownFormData);
        if (validateTitle(payload)) {
            setHasCurrentTitle(true);
            return setTimeout(() => { setHasCurrentTitle(null); }, 4000);
        }
        if (!validatedForm.isValid || !validatedCompanyForm.isValid) {
            setFormData(validatedForm.form);
            companyUseAutocomplete.setDropdownFormData(validatedCompanyForm.form);
            return;
        }
        try {
            const assetGroupId = assetGroupUseAutocomplete.dropdownFormData[0].value;
            const companyId = companyUseAutocomplete.dropdownFormData[0].value;
            const asset = {
                title: formData.title.value.trim(),
                companyId: companyId,
                assetGroupId: R.isEmpty(assetGroupId) ? null : assetGroupId,
                assetType: formData.locationType.value,
                assetState: formData.status.value,
                description: R.isEmpty(formData.notes.value) ? null : formData.notes.value.trim(),
                files: [],
                address: {
                    addressLine1: formData.address.value.trim(),
                    addressLine2: formData.address.value.trim(),
                    postalCode: formData.postalCode.value.trim(),
                    locality: formData.city.value.trim(),
                    region: "France",
                    country: "France"
                },
                assetFields: !R.isEmpty(formData.wifi.value) ? [{ name: "wifi", value: formData.wifi.value.trim() }] : [],
                // assetFields: !R.isEmpty(formData.assetFields?.find((a) => a.name.toLowerCase() === "wifi").value) ? [{ name: "wifi", value: formData.assetFields?.find((a) => a.name.toLowerCase() === "wifi").value.trim() }] : [],
                keywords: R.isEmpty(formData.tags.value) ? null : formData.tags.value.join(',')
            };
            const newAsset = await dispatch(CreateAsset(asset));
            if (!R.isNil(profilePhoto)) {
                await dispatch(UploadProfilePhoto({ file: profilePhoto, id: newAsset.id, profile: "Asset" }));
            }
            if (!R.isNil(photos)) {
                await dispatch(UploadFiles(photos, newAsset.id,"Asset"));
            }
            // handleAddNew();
            // modalClose();
            handleAddNew({
                companyId: newAsset.companyId,
                id: newAsset.id,
                title: formData.title.value.trim()
            });
            setFormData(initialAssetFormData);
            modalClose();
        } catch (error) {
            console.log(error);
            // toast.error("Échec de l'opération, réessayez !", toastConf);
        }

    };


    return (
			<React.Fragment>
				{hasCurrentTitle && (
					<Container maxWidth="100%" sx={{ display: "inherit", justifyContent: "inherit", gap: 2 }}>
						<Alert sx={{ width: "100%", borderRadius: 3, marginY: 2 }} severity="error">
							Élément existant. Sélectionnez l'élément existant ou choisissez un autre élément.
						</Alert>
					</Container>
				)}
				<FormAsset
					resetAssetGroup={resetAssetGroup}
					selectCompany={selectCompany}
					docFiles={photos}
					setDocFiles={setPhotos}
					uploadedFiles={photos}
					setUploadedFiles={setPhotos}
					profilePhoto={profilePhoto}
					setProfilePhoto={setProfilePhoto}
					formData={formData}
					setFormData={setFormData}
					assetGroupUseAutocomplete={assetGroupUseAutocomplete}
					companyUseAutocomplete={companyUseAutocomplete}
					theme={theme}
					mt={{ md: -2 }}>
					{assetGroupUseAutocomplete.isOpen && (
						<AssetGroupModalContent
							modalClose={assetGroupUseAutocomplete.setClose}
							open={assetGroupUseAutocomplete.isOpen}
							theme={theme}
							newTitle={assetGroupUseAutocomplete.newOptionName}
							newAddress={formData.address}
							newCity={formData.city}
							newPostalCode={formData.postalCode}
							handleAddNew={R.compose(selectCompanyById, assetGroupUseAutocomplete.handleAddNew)}
							sx={{ backgroundColor: "background.dim", borderRadius: 2 }}
						/>
					)}
					{companyUseAutocomplete.isOpen && (
						<CompanyModalContent modalClose={companyUseAutocomplete.setClose} open={companyUseAutocomplete.isOpen} theme={theme} newCompanyName={companyUseAutocomplete.newOptionName} handleAddNewCompany={companyUseAutocomplete.handleAddNew} sx={{ backgroundColor: "background.dim", borderRadius: 2 }} />
					)}
				</FormAsset>
				<DialogActions disableSpacing={true} sx={{ flexDirection: "column-reverse", p: theme.spacing(3) }}>
					<Button theme={theme} color="neutral" fullWidth onClick={modalClose} sx={{ px: theme.spacing(3) }}>
						Annuler
					</Button>
					<LoadingButton
						onClick={(event) => submitForm(event)}
						loading={companyState.isLoading.includes(Loadings.CREATING_COMPANY) || fileState.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
						disabled={companyState.isLoading.includes(Loadings.CREATING_COMPANY) || fileState.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
						type="submit"
						size="large"
						fullWidth
						variant="contained"
						theme={theme}
						color="primary"
						sx={{ px: theme.spacing(3), mb: theme.spacing(1) }}>
						Enregistrer
					</LoadingButton>
				</DialogActions>
			</React.Fragment>
		);
};

export default AssetModalContent;