import React, { useMemo } from 'react';
import * as R from 'ramda';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

// Components: Common
import FormUpload from '../_common/upload';
import FormMain from '../_common/main';
import FormClefs from '../_common/clefs';
import FormOptions from '../_common/option';
import FormNotes from '../_common/notes';
import FormGlobalComplete from '../_common/globalComplete';
import { initialAssetGroupForm, initialSubcontractorForm } from '../../../../constants/utils/formInitialData';
import { onChangeValidation } from '../../../../constants/utils/validators';
import FormPhotos from '../_common/photos';
import { categoryType } from '../../../../constants/utils/objectProperties';

// Components: Views

export const FormDocument = ({ theme, docFiles, setDocFiles, formData, setFormData, companyUseAutocomplete, assetUseAutocomplete, assetGroupUseAutocomplete, tenantUseAutocomplete, subcontractorUseAutocomplete, selectCompanyByAssetGroup, selectCompanyByAsset, companyId, uploadedFiles, setUploadedFiles, ...others }) => {
    const handleOnChange = (event) => { setFormData(onChangeValidation(event.target, formData)); };
    const handleClick = (event, item) => { event.target.value = ''; };
    const documentTypes = ['Juridique', 'Impôt', 'Personnel', "Comptable"];
	const _uploadedFiles = useMemo(() => uploadedFiles,[uploadedFiles])
	const _docFiles = useMemo(() => docFiles,[docFiles]) 

    return (
			<Stack spacing={2} flex="1 1 auto" width="100%" {...others}>
				<FormUpload theme={theme} photos={docFiles} setPhotos={setDocFiles} type="document" />
				<Container maxWidth="100%" sx={{ display: "inherit", flexDirection: "inherit", gap: 2 }}>
					<Stack direction="column" spacing={4}>
						<Stack direction="column" spacing={2}>
							<FormMain
								companyId={companyId}
								formData={formData}
								setFormData={setFormData}
								handleOnChange={handleOnChange}
								companyUseAutocomplete={companyUseAutocomplete}
								tenantUseAutocomplete={tenantUseAutocomplete}
								assetUseAutocomplete={assetUseAutocomplete}
								resetOnSelectingAsset={R.compose(assetGroupUseAutocomplete.resetFormData, selectCompanyByAsset, assetUseAutocomplete.handleSetOptionsFormData)}
								selectCompanyByAssetGroup={selectCompanyByAssetGroup}
								selectCompanyByAsset={selectCompanyByAsset}
								subcontractorUseAutocomplete={subcontractorUseAutocomplete}
								resetOnSelectingCompany={R.compose(tenantUseAutocomplete.resetFormData, subcontractorUseAutocomplete.resetFormData, assetUseAutocomplete.resetFormData, assetGroupUseAutocomplete.resetFormData, companyUseAutocomplete.handleSetOptionsFormData)}
							/>
							<Stack direction={{ default: "column", md: "row" }} spacing={1} my={1}>
								<FormGlobalComplete
									options={assetGroupUseAutocomplete.options}
									optionsFormData={assetGroupUseAutocomplete.dropdownFormData}
									setOptionsFormData={assetGroupUseAutocomplete.setDropdownFormData}
									handleSetOpen={assetGroupUseAutocomplete.handleSetOpen}
									handleSetOptionsFormData={R.compose(assetUseAutocomplete.resetFormData, selectCompanyByAssetGroup, assetGroupUseAutocomplete.handleSetOptionsFormData)}
									multi={false}
									name="assetGroup"
									label="Immeuble"
									initialFormData={initialAssetGroupForm}
								/>
								<FormGlobalComplete
									options={subcontractorUseAutocomplete.options}
									optionsFormData={subcontractorUseAutocomplete.dropdownFormData}
									setOptionsFormData={subcontractorUseAutocomplete.setDropdownFormData}
									handleSetOpen={subcontractorUseAutocomplete.handleSetOpen}
									handleSetOptionsFormData={subcontractorUseAutocomplete.handleSetOptionsFormData}
									multi={false}
									name="subcontractors"
									label="Sous-traitant"
									initialFormData={initialSubcontractorForm}
								/>
							</Stack>
						</Stack>
						<FormPhotos
						  fileType="document"
						  title="document"
						  photos={_docFiles} 
						  setPhotos={setDocFiles} 
						  uploadedFiles={_uploadedFiles} 
						  setUploadedFiles={setUploadedFiles} 
						  handleClick={handleClick} 
						  theme={theme}
						   />
						<FormOptions formLabel="Categorie" value={formData.type.value} fieldName="type" formData={formData} setFormData={setFormData} options={categoryType} />
						<FormNotes
						 value={formData?.notes?.value}
					    	//  handleOnChange={handleOnChange} 
							handleOnChange={(newValue) => setFormData({ ...formData, notes: { ...formData.notes, value: newValue?.target?.value} })}
						 />
					</Stack>
					<FormClefs 
					  theme={theme}
					  handleChange={(newValue) => setFormData({ ...formData, tags: { ...formData.tags, value: newValue } })}
					  value={formData.tags.value}
					/>
				</Container>
			</Stack>
		);
};

export default FormDocument;
