import React from 'react';

// Components
// Components: Global
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Components: Common
// Components: Views
import PasswordsMenu from './menu';
import PasswordsView from './view';

export const Passwords = ({ theme, userState, companyState, tenantState, assetState, assetGroupState, subcontractorState, collaboratorState, documentState, rentalState, accountState }) => {

	return (
		<Box component="section" className="layout" flex="1 1 auto" alignItems="center" width="100%" height="calc(100% - 6.5rem)" overflow="hidden auto" mt={{ md: -2 }}>
			<Typography component="h1" className="sr-only">Content</Typography>
			<PasswordsMenu theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} documentState={documentState} rentalState={rentalState} accountState={accountState} />
			<PasswordsView theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} documentState={documentState} rentalState={rentalState} accountState={accountState} />
		</Box>
    );
}

export default Passwords;