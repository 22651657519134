import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { initialAssetFormData, initialAssetGroupForm, initialCompanyForm } from '../../constants/utils/formInitialData';
import MainAsset from '../_common/forms/layout/asset';
import { GetCompanies } from '../../actions/company-api';
import { GetAssetGroups } from '../../actions/assetGroup-api';
import * as LoadingsAssetGroup from '../../constants/loadings/assetGroup';
import * as LoadingsCompany from '../../constants/loadings/company';
import * as LoadingAsset from '../../constants/loadings/asset';
import Loading from '../../_common/loading';
import { GetAssets } from '../../actions/asset-api';


export const NewAsset = ({ theme, companyState, assetGroupState, assetState }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (R.isNil(assetState.assets)) {
            dispatch(GetAssets());
        }
        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        if (R.isNil(assetGroupState.assetGroups)) {
            dispatch(GetAssetGroups());
        }
        // eslint-disable-next-line 
    }, []);

    if (assetGroupState.isLoading.includes(LoadingsAssetGroup.FETCHING_ASSETGROUPS) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES) ||
        assetState.isLoading.includes(LoadingAsset.FETCHING_ASSETS)) {
        return <Loading theme={theme} />;
    }

    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let assets = !R.isNil(assetState.assets) ? assetState.assets : [];
    let assetGroups = !R.isNil(assetGroupState.assetGroups) ? assetGroupState.assetGroups.filter(ag => !ag.isDefault) : [];


    return (
        <MainAsset
            theme={theme}
            assetState={assetState}
            assetGroups={assetGroups}
            companies={companies}
            assets={assets}
            initialAssetFormData={initialAssetFormData}
            initialCompanyForm={[initialCompanyForm]}
            initialAssetGroupForm={[initialAssetGroupForm]}
            resetCompanyForm={initialCompanyForm}
            resetAssetGroupForm={initialAssetGroupForm}
        />
    );
};
