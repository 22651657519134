import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

export const ModalDescription = ({ theme, description, open, modalClose }) => {

    return (
        <Dialog fullWidth maxWidth="xs" onClose={modalClose} open={open} theme={theme} sx={{ textAlign: "center" }}>
            <DialogContent sx={{ alignItems: "center", pb: 0 }}>
                <DialogContentText variant="body" mb={2}>
                  {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions disableSpacing={true} sx={{ flexDirection: "column", p: 3, pt: 0 }}>
                <Button theme={theme} color="neutral" fullWidth onClick={modalClose} sx={{ px: 3, mt: 1 }}>Annuler</Button>
            </DialogActions>
        </Dialog >
    );
};
