// breakpoints
export const xsBreakpoint = 375;
export const smBreakpoint = 480;
export const mdBreakpoint = 667;
export const lgBreakpoint = 855;
export const xlBreakpoint = 1024;
export const hdBreakpoint = 1280;
export const uhdBreakpoint = 1440;
export const DesktopBreakpoint = 1520;

// colors
export const primaryMain = '#5d8bdE';
export const primaryDark = '#41619b';
export const primaryLight = '#7da2e4';
export const primaryContrastText = '#ffffff';
 
export const secondaryMain = '#bd59b9';
export const secondaryDark = '#773e74';
export const secondaryLight = '#bb7ab8';
export const secondaryContrastText = '#ffffff';
 
export const accentMain = '#68EDCB';
 
export const textPrimary = '#424656';
export const textDark = '#283142';
export const textLight = '#686b78';
export const textMain = 'rgba(66,70,86,0.84)';
export const textSecondary = 'rgba(66,70,86,0.66)';
export const textMuted = 'rgba(66,70,86,0.54)';
export const textHint = 'rgba(66,70,86,0.46)';
export const textDisabled = 'rgba(66,70,86,0.28)';
export const textDim = 'rgba(66,70,86,0.028)';
export const textEmpty = 'rgba(66,70,86,0.08)';
 
export const backgroundMain = 'rgba(0,0,0,0.84)';
export const backgroundMaster = 'rgba(66,70,86,0.064)';
export const backgroundLight = 'rgba(255,255,255,0.18)';
export const backgroundDim = 'rgba(66,70,86,0.038)';
export const backgroundEmpty = '#e8e9ec';
export const backgroundAvatar = '#9499a7';
export const backgroundPlaceholder = '#bbbec7';
export const backgroundDefault = "#f6f7f9";
export const backgroundStage = "#f5f6f9";
export const backgroundPaper = "#ffffff";

export const successMain = "#00c292";
export const infoMain = "#0bb2fb";
export const warningMain = "#fec90f";
export const dangerMain = "#e53e3e";
export const dangerLight = "#e95d5d";
export const dangerDark = "#e22c2c";

export const errorMain = "#e46a76";
export const errorLight = "#fdf3f5";
export const errorDark = "#e45a68";

// border
export const borderWidth = 2;
export const borderColor = "rgba(66,70,86, 0.13)";