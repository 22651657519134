import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { initialCollaboratorFormData, initialCompanyForm } from '../../constants/utils/formInitialData';
import { GetCompanies } from '../../actions/company-api';
import * as LoadingsCompany from '../../constants/loadings/company';
import * as LoadingsCollaborator from '../../constants/loadings/collaborator';
import Loading from '../../_common/loading';
import { useParams } from 'react-router';
import { GetCollaborator, GetCollaborators } from '../../actions/collaborator-api';
import MainCollaborator from '../_common/forms/layout/collaborator';
import { getPermissionType } from '../../constants/utils/formHandlers';


export const EditCollaborator = ({ theme, companyState, collaboratorState, assetState }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        dispatch(GetCollaborator(id));

        if (R.isNil(collaboratorState.collaborators)) {
            dispatch(GetCollaborators());
        }
        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        // eslint-disable-next-line 
    }, []);


    if (collaboratorState.isLoading.includes(LoadingsCollaborator.FETCHING_COLLABORATORS) ||
        collaboratorState.isLoading.includes(LoadingsCollaborator.FETCHING_COLLABORATOR) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)) {
        return <Loading theme={theme} />;
    }

    if (R.isNil(collaboratorState.collaborator) || R.isEmpty(collaboratorState.collaborator) || R.isNil(companyState.companies)) {
        return '';
    }

    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let collaborators = !R.isNil(collaboratorState.collaborators) ? collaboratorState.collaborators : [];

    const tempInitialCollaboratorFormData = R.clone(initialCollaboratorFormData);
    tempInitialCollaboratorFormData.firstName.value = collaboratorState.collaborator.firstName;
    tempInitialCollaboratorFormData.lastName.value = collaboratorState.collaborator.lastName;
    tempInitialCollaboratorFormData.email.value = collaboratorState.collaborator.email;
    tempInitialCollaboratorFormData.profileUrl.value = collaboratorState.collaborator.profileUrl;

    let tempInitialCompanyForm = R.clone(initialCompanyForm);
    tempInitialCompanyForm = collaboratorState.collaborator.companyAccesses.map(u => ({ ...initialCompanyForm, value: u.companyId, permissionsType: getPermissionType(u.accessTypes), label: companies.find(c => c.id === u.companyId)?.title }));
    // const final = arr.companyAccesses.reduce((acc, access) => [...acc, ...access.accessTypes.reduce((a, type) => [...a, { companyId: access.companyId, accessTypes: type }], [])], []);



    return (
        <MainCollaborator
            theme={theme}
            companies={companies}
            collaborators={collaborators}
            collaboratorState={collaboratorState}
            photo={collaboratorState.collaborator.profileUrl?.url}
            initialCollaboratorFormData={tempInitialCollaboratorFormData}
            initialCompanyForm={tempInitialCompanyForm}
            resetCompanyForm={initialCompanyForm}
            id={id}
        />
    );
};
