import React, { useEffect, useState } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as R from "ramda";
import { format } from "date-fns";

// Components
// Components: Global
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Components: Common
import { DeleteTenant, GetTenant } from "../../../../actions/tenant-api";
import { GetPayments } from "../../../../actions/payment-api";
import { GetDocuments } from "../../../../actions/document-api";
import { GetRentals } from "../../../../actions/rental-api";
import { GetCompanies } from "../../../../actions/company-api";
import { GetAssets } from "../../../../actions/asset-api";

import * as TentantLoadings from "../../../../constants/loadings/tenant";

import { dayMonthYear } from "../../../../constants/utils/date";
import { paymentTypes } from "../../../../constants/utils/objectProperties";

import ItemCard from "../../../_common/itemCard";
import Loading from "../../../../_common/loading";
import TenantsViewEmpty from "./../empty";

import TenantsViewDetailHeader from "./_common/header";
import DocumentRow from "../../../_common/documentRow";
import { useDeleteDocument } from "../../../../hooks/useDeleteDocument";
import ModalDelete from "../../../_common/modalDelete";
import { usePermissionModal } from "../../../../hooks/usePermissionModal";
import { TransactionRow } from "../../../_common/transactionRow";
import { useDeletePayment } from "../../../../hooks/useDeletePayment";

// Components: View

export const TenantsViewDetail = ({
  theme,
  userState,
  tenantState,
  documentState,
  companyState,
  rentalState,
  paymentState,
  assetState,
  subcontractorState,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openDeleteDoc, setOpenDeleteDoc] = useState(false);
  const [openDeletePay, setOpenDeletePay] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(false);
  const [rentalsExpanded, setRentalsExpanded] = useState("panel-rentals", true);
  const [docsExpanded, setDocsExpanded] = useState("panel-docs", true);
  const [paymentsExpanded, setPaymentsExpanded] = useState(
    "panel-payments",
    true
  );
  const deleteDocument = useDeleteDocument();
  const deletePayment = useDeletePayment();
  const { openPermissionModal } = usePermissionModal();

  const modalOpenProfile = () => {
    setOpen(true);
  };
  const modalCloseProfile = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (R.isNil(companyState.companies) || R.isEmpty(companyState.companies)) {
      dispatch(GetCompanies());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(GetTenant(id));
    dispatch(GetRentals({ filter: { "ContactId=": id } }));
    dispatch(GetDocuments({ filter: { "ContactId=": id } }));
    dispatch(GetPayments({ filter: { "ContactId=": id } }));
    if (R.isNil(assetState.assets)) {
      dispatch(GetAssets());
    }
    // eslint-disable-next-line
  }, [id]);

  const deleteTenantProp = async () => {
    await dispatch(DeleteTenant(tenantState.tenant.id));
    navigate("/app/tenants");
  };

  if (tenantState.isLoading.includes(TentantLoadings.FETCHING_TENANT)) {
    return <Loading />;
  }
  if (R.isNil(tenantState.tenant) || R.isEmpty(tenantState.tenant)) {
    return <TenantsViewEmpty />;
  }
  const permissions = userState.user.companyAccesses.reduce((acc, c) => {
    if (tenantState.tenant.companyIds.includes(c.companyId))
      return [...acc, ...c.accessTypes];
    return acc;
  }, []);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    switch (panel) {
      default:
      case "panel-rentals": {
        setRentalsExpanded(isExpanded ? panel : false);
        break;
      }
      case "panel-docs": {
        setDocsExpanded(isExpanded ? panel : false);
        break;
      }
      case "panel-payments": {
        setPaymentsExpanded(isExpanded ? panel : false);
        break;
      }
    }
  };

  return (
    <React.Fragment>
      <TenantsViewDetailHeader
        openPermissionModal={openPermissionModal}
        permissions={permissions}
        theme={theme}
        modalOpenProfile={modalOpenProfile}
        companyState={companyState}
        tenantState={tenantState}
      />
      <Container maxWidth="100%" sx={{ height: "100%" }}>
        <Stack maxWidth="100%" my={3} direction="column" spacing={4}>
          <Accordion
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              border: "none",
              p: 0,
              ":before": { backgroundColor: "inherit" },
            }}
            disableGutters
            expanded={rentalsExpanded === "panel-rentals"}
            onChange={handleAccordionChange("panel-rentals")}
          >
            <AccordionSummary
              sx={{
                minHeight: "auto",
                ".MuiAccordionSummary-content": { my: 1 },
                p: 0,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <Typography variant="h6" sx={{ lineHeight: 1 }}>
                Locations
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Stack maxWidth="100%" direction="column" spacing={1}>
                {!R.isNil(rentalState.rentals) &&
                  !R.isEmpty(rentalState.rentals) && (
                    <Stack direction="column" spacing={2}>
                      <Stack direction="column" spacing={1}>
                        {/* <Typography variant="label" color="text.secondary">{rentalState.rentals?.filter(r => r.assetId === assetState.asset?.id)?.filter(r => (!R.isNil(r.startDate) && (R.isNil(r.endDate) || new Date(r.endDate) >= new Date()))).length} Actives</Typography> */}
                        {rentalState.rentals
                          .filter((r) => r.contactId == tenantState.tenant?.id)
                          .filter(
                            (r) =>
                              !R.isNil(r.startDate) &&
                              (R.isNil(r.endDate) ||
                                new Date(r.endDate) >= new Date())
                          )
                          .map((rental, index) => (
                            <ItemCard
                              key={"r" + rental.title}
                              to={`/app/contrats/${rental.companyId}/${rental.id}`}
                              component={RouterLink}
                              elevation={R.isNil(rental.startDate) ? 0 : 2}
                              backgroundColor={
                                R.isNil(rental.startDate)
                                  ? "transparent"
                                  : "background.paper"
                              }
                              border={R.isNil(rental.startDate) && "1px dashed"}
                              borderColor={
                                R.isNil(rental.startDate) && "grey.A300"
                              }
                            >
                              <Avatar
                                variant="circular"
                                children={rental.title.charAt(0)}
                                sx={{
                                  bgcolor: "grey.A200",
                                  my: 0.25,
                                  width: "3rem",
                                  height: "3rem",
                                  fontWeight: "bold",
                                }}
                              />
                              <Stack
                                direction="column"
                                spacing={{ default: 0.5, xl: 0 }}
                                pr={{ default: 0, xl: 1 }}
                                flex="1 1 auto"
                                justifyContent={{ lg: "space-between" }}
                              >
                                <Stack
                                  direction={{ default: "column", xl: "row" }}
                                  spacing={{ xl: 4 }}
                                  flex="1 1 auto"
                                  justifyContent={{ lg: "space-between" }}
                                >
                                  <Typography fontWeight="medium">
                                    {rental.title}
                                  </Typography>
                                  <Typography variant="body1">
                                    <Box
                                      component="span"
                                      color="text.secondary"
                                    >
                                      Signature:{" "}
                                    </Box>
                                    {!R.isNil(rental.startDate) ? (
                                      <Box component="span" ml={0.5}>
                                        {format(
                                          new Date(rental.startDate),
                                          dayMonthYear
                                        )}
                                      </Box>
                                    ) : (
                                      <Box component="span" ml={0.5}>
                                        Candidature
                                      </Box>
                                    )}
                                  </Typography>
                                </Stack>
                                <Stack
                                  direction={{ default: "column", hd: "row" }}
                                  spacing={{ xl: 1 }}
                                  flex="1 1 auto"
                                  justifyContent={{ hd: "space-between" }}
                                  alignItems={{ hd: "center" }}
                                >
                                  <Typography variant="body1" flex="1 1 auto">
                                    <Box
                                      component="span"
                                      color="text.secondary"
                                    >
                                      Bien:{" "}
                                    </Box>
                                    {!R.isNil(assetState.assets) &&
                                    !R.isNil(rental.assetId) ? (
                                      assetState.assets
                                        .filter((r) => r.id === rental.assetId)
                                        .map((item, index) => (
                                          <Typography
                                            color="text.dark"
                                            component={RouterLink}
                                            to={`/app/assets/${item.companyId}/${item.id}`}
                                            key={index}
                                            display="inline-flex"
                                            alignItems="center"
                                            aria-label="go to company"
                                            sx={{
                                              textDecoration: "none",
                                              marginLeft: 0.25,
                                            }}
                                          >
                                            {item.title}
                                          </Typography>
                                        ))
                                    ) : (
                                      <Typography
                                        component="span"
                                        color="text.hint"
                                      >
                                        Le champ est vide
                                      </Typography>
                                    )}
                                  </Typography>
                                  <Stack
                                    direction={{ default: "column", lg: "row" }}
                                    flex="1 1 auto"
                                    spacing={{ default: 1, lg: 4 }}
                                    justifyContent={{ lg: "space-between" }}
                                  >
                                    {rental.paymentType !== 0 && (
                                      <Typography
                                        color="text.secondary"
                                        variant="body1"
                                      >
                                        Modalité de paiement:{" "}
                                        <Box component="span" color="text.dark">
                                          {paymentTypes[rental.paymentType]}
                                        </Box>
                                      </Typography>
                                    )}
                                    <Typography
                                      color="text.secondary"
                                      variant="body1"
                                    >
                                      {/* Loyer sans Taxes: <Box component="span" color="text.dark">{rental.rentPriceWithoutTax ? rental.rentPriceWithoutTax + '€' : 'N/A'} </Box> */}
                                      Loyer:{" "}
                                      <Box component="span" color="text.dark">
                                        {rental.rentPriceWithoutTax
                                          ? rental.rentPriceWithoutTax + "€"
                                          : "N/A"}{" "}
                                      </Box>
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Stack>
                            </ItemCard>
                          ))}
                      </Stack>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="label" color="text.secondary">
                          {
                            rentalState.rentals
                              .filter(
                                (r) => r.contactId == tenantState.tenant?.id
                              )
                              ?.filter((r) => R.isNil(r.startDate)).length
                          }{" "}
                          Candidatures
                        </Typography>
                        {rentalState.rentals
                          .filter((r) => r.contactId == tenantState.tenant?.id)
                          .filter((r) => R.isNil(r.startDate))
                          .map((rental, index) => (
                            <ItemCard
                              key={"r" + rental.title}
                              to={`/app/contrats/${rental.companyId}/${rental.id}`}
                              component={RouterLink}
                              elevation={0}
                              backgroundColor="transparent"
                              border={"1px dashed"}
                              borderColor={"grey.A300"}
                            >
                              <Avatar
                                variant="circular"
                                children={rental.title.charAt(0)}
                                sx={{
                                  bgcolor: "grey.A200",
                                  my: 0.25,
                                  width: "3rem",
                                  height: "3rem",
                                  fontWeight: "bold",
                                }}
                              />
                              <Stack
                                direction="column"
                                spacing={{ default: 0.5, xl: 0 }}
                                pr={{ default: 0, xl: 1 }}
                                flex="1 1 auto"
                                justifyContent={{ lg: "space-between" }}
                              >
                                <Stack
                                  direction={{ default: "column", xl: "row" }}
                                  spacing={{ xl: 4 }}
                                  flex="1 1 auto"
                                  justifyContent={{ lg: "space-between" }}
                                >
                                  <Typography fontWeight="medium">
                                    {rental.title}
                                  </Typography>
                                  <Typography variant="body1">
                                    <Box
                                      component="span"
                                      color="text.secondary"
                                    >
                                      Signature:{" "}
                                    </Box>
                                    {!R.isNil(rental.startDate) ? (
                                      <Box component="span" ml={0.5}>
                                        {format(
                                          new Date(rental.startDate),
                                          dayMonthYear
                                        )}
                                      </Box>
                                    ) : (
                                      <Box component="span" ml={0.5}>
                                        Candidature
                                      </Box>
                                    )}
                                  </Typography>
                                </Stack>
                                <Stack
                                  direction={{ default: "column", hd: "row" }}
                                  spacing={{ xl: 1 }}
                                  flex="1 1 auto"
                                  justifyContent={{ hd: "space-between" }}
                                  alignItems={{ hd: "center" }}
                                >
                                  <Typography variant="body1" flex="1 1 auto">
                                    <Box
                                      component="span"
                                      color="text.secondary"
                                    >
                                      Bien:{" "}
                                    </Box>
                                    {!R.isNil(assetState.assets) &&
                                    !R.isNil(rental.assetId) ? (
                                      assetState.assets
                                        .filter((r) => r.id === rental.assetId)
                                        .map((item, index) => (
                                          <Typography
                                            color="text.dark"
                                            component={RouterLink}
                                            to={`/app/assets/${item.companyId}/${item.id}`}
                                            key={index}
                                            display="inline-flex"
                                            alignItems="center"
                                            aria-label="go to company"
                                            sx={{
                                              textDecoration: "none",
                                              marginLeft: 0.25,
                                            }}
                                          >
                                            {item.title}
                                          </Typography>
                                        ))
                                    ) : (
                                      <Typography
                                        component="span"
                                        color="text.hint"
                                      >
                                        Le champ est vide
                                      </Typography>
                                    )}
                                  </Typography>
                                  <Stack
                                    direction={{ default: "column", lg: "row" }}
                                    flex="1 1 auto"
                                    spacing={{ default: 1, lg: 4 }}
                                    justifyContent={{ lg: "space-between" }}
                                  >
                                    {rental.paymentType !== 0 && (
                                      <Typography
                                        color="text.secondary"
                                        variant="body1"
                                      >
                                        Modalité de paiement:{" "}
                                        <Box component="span" color="text.dark">
                                          {paymentTypes[rental.paymentType]}
                                        </Box>
                                      </Typography>
                                    )}
                                    <Typography
                                      color="text.secondary"
                                      variant="body1"
                                    >
                                      Loyer:{" "}
                                      <Box component="span" color="text.dark">
                                        {rental.rentPriceWithoutTax
                                          ? rental.rentPriceWithoutTax + "€"
                                          : "N/A"}{" "}
                                      </Box>
                                      {/* Loyer sans Taxes: <Box component="span" color="text.dark">{rental.rentPriceWithoutTax ? rental.rentPriceWithoutTax + '€' : 'N/A'} </Box> */}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Stack>
                            </ItemCard>
                          ))}
                      </Stack>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="label" color="text.secondary">
                          {
                            rentalState.rentals
                              .filter(
                                (r) => r.contactId == tenantState.tenant?.id
                              )
                              ?.filter(
                                (r) =>
                                  !R.isNil(r.endDate) &&
                                  new Date(r.endDate) < new Date()
                              ).length
                          }{" "}
                          Cloturées
                        </Typography>
                        {rentalState.rentals
                          ?.filter((r) => r.contactId == tenantState.tenant?.id)
                          ?.filter(
                            (r) =>
                              !R.isNil(r.endDate) &&
                              new Date(r.endDate) < new Date()
                          )
                          .map((rental, index) => (
                            <ItemCard
                              key={"r" + rental.title}
                              to={`/app/contrats/${rental.companyId}/${rental.id}`}
                              component={RouterLink}
                              elevation={0}
                              backgroundColor={"background.dim"}
                            >
                              <Avatar
                                variant="circular"
                                children={rental.title.charAt(0)}
                                sx={{
                                  bgcolor: "grey.A200",
                                  my: 0.25,
                                  width: "3rem",
                                  height: "3rem",
                                  fontWeight: "bold",
                                }}
                              />
                              <Stack
                                direction="column"
                                spacing={{ default: 0.5, xl: 0 }}
                                pr={{ default: 0, xl: 1 }}
                                flex="1 1 auto"
                                justifyContent={{ lg: "space-between" }}
                              >
                                <Stack
                                  direction={{ default: "column", xl: "row" }}
                                  spacing={{ xl: 4 }}
                                  flex="1 1 auto"
                                  justifyContent={{ lg: "space-between" }}
                                >
                                  <Typography fontWeight="medium">
                                    {rental.title}
                                  </Typography>
                                  <Typography variant="body1">
                                    <Box
                                      component="span"
                                      color="text.secondary"
                                    >
                                      Signature:{" "}
                                    </Box>
                                    {!R.isNil(rental.startDate) ? (
                                      <Box component="span" ml={0.5}>
                                        {format(
                                          new Date(rental.startDate),
                                          dayMonthYear
                                        )}
                                      </Box>
                                    ) : (
                                      <Box component="span" ml={0.5}>
                                        Candidature
                                      </Box>
                                    )}
                                  </Typography>
                                </Stack>
                                <Stack
                                  direction={{ default: "column", hd: "row" }}
                                  spacing={{ xl: 1 }}
                                  flex="1 1 auto"
                                  justifyContent={{ hd: "space-between" }}
                                  alignItems={{ hd: "center" }}
                                >
                                  <Typography variant="body1" flex="1 1 auto">
                                    <Box
                                      component="span"
                                      color="text.secondary"
                                    >
                                      Bien:{" "}
                                    </Box>
                                    {!R.isNil(assetState.assets) &&
                                    !R.isNil(rental.assetId) ? (
                                      assetState.assets
                                        .filter((r) => r.id === rental.assetId)
                                        .map((item, index) => (
                                          <Typography
                                            color="text.dark"
                                            component={RouterLink}
                                            to={`/app/assets/${item.companyId}/${item.id}`}
                                            key={index}
                                            display="inline-flex"
                                            alignItems="center"
                                            aria-label="go to company"
                                            sx={{
                                              textDecoration: "none",
                                              marginLeft: 0.25,
                                            }}
                                          >
                                            {item.title}
                                          </Typography>
                                        ))
                                    ) : (
                                      <Typography
                                        component="span"
                                        color="text.hint"
                                      >
                                        Le champ est vide
                                      </Typography>
                                    )}
                                  </Typography>
                                  <Stack
                                    direction={{ default: "column", lg: "row" }}
                                    flex="1 1 auto"
                                    spacing={{ default: 1, lg: 4 }}
                                    justifyContent={{ lg: "space-between" }}
                                  >
                                    {rental.paymentType !== 0 && (
                                      <Typography
                                        color="text.secondary"
                                        variant="body1"
                                      >
                                        Modalité de paiement:{" "}
                                        <Box component="span" color="text.dark">
                                          {paymentTypes[rental.paymentType]}
                                        </Box>
                                      </Typography>
                                    )}
                                    <Typography
                                      color="text.secondary"
                                      variant="body1"
                                    >
                                      Loyer:{" "}
                                      <Box component="span" color="text.dark">
                                        {rental.rentPriceWithoutTax
                                          ? rental.rentPriceWithoutTax + "€"
                                          : "N/A"}{" "}
                                      </Box>
                                      {/* Loyer sans Taxes: <Box component="span" color="text.dark">{rental.rentPriceWithoutTax ? rental.rentPriceWithoutTax + '€' : 'N/A'} </Box> */}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Stack>
                            </ItemCard>
                          ))}
                      </Stack>
                    </Stack>
                  )}
                {permissions.includes("Create") ? (
                  <Button
                    fullWidth
                    component={RouterLink}
                    to={`/app/new/contrat?tenantId=${tenantState.tenant.id}&companyId=${tenantState.tenant.companyIds[0]}&origin=tenantDetail`}
                    variant="contained"
                    color="primary"
                    sx={{ boxShadow: "none" }}
                  >
                    Ajouter un Contrat de location
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    onClick={openPermissionModal}
                    variant="contained"
                    color="primary"
                    sx={{ boxShadow: "none" }}
                  >
                    Ajouter un Contrat de location
                  </Button>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Divider />
          <Accordion
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              border: "none",
              p: 0,
              ":before": { backgroundColor: "inherit" },
            }}
            disableGutters
            expanded={docsExpanded === "panel-docs"}
            onChange={handleAccordionChange("panel-docs")}
          >
            <AccordionSummary
              sx={{
                minHeight: "auto",
                ".MuiAccordionSummary-content": { my: 1 },
                p: 0,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <Stack direction="row" spacing={0} justifyContent="space-between">
                <Typography variant="h6" sx={{ lineHeight: 1 }}>
                  Documents
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Stack maxWidth="100%" direction="column" spacing={1}>
                {!R.isNil(documentState.documents) && (
                  <Stack direction="column" spacing={1}>
                    {documentState.documents
                      .filter(
                        (r) =>
                          r.contactId === tenantState.tenant.id &&
                          r.documentType === "Document"
                      )
                      .map((document) => (
                        <DocumentRow
                          permissions={permissions}
                          openPermissionModal={openPermissionModal}
                          key={document.id}
                          setOpenDeleteDoc={setOpenDeleteDoc}
                          document={document}
                          theme={theme}
                        />
                      ))}
                  </Stack>
                )}
                {permissions.includes("Create") ? (
                  <Button
                    fullWidth
                    component={RouterLink}
                    to={`/app/new/document?tenantId=${tenantState.tenant.id}&companyId=${tenantState.tenant.companyIds[0]}&origin=tenantDetail`}
                    variant="contained"
                    color="primary"
                    sx={{ boxShadow: "none" }}
                  >
                    Ajouter un document
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    onClick={openPermissionModal}
                    variant="contained"
                    color="primary"
                    sx={{ boxShadow: "none" }}
                  >
                    Ajouter un document
                  </Button>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Divider />
          <Accordion
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              border: "none",
              p: 0,
              ":before": { backgroundColor: "inherit" },
            }}
            disableGutters
            expanded={paymentsExpanded === "panel-payments"}
            onChange={handleAccordionChange("panel-payments")}
          >
            <AccordionSummary
              sx={{
                minHeight: "auto",
                ".MuiAccordionSummary-content": { my: 1 },
                p: 0,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <Stack
                direction={{ default: "column", hd: "row" }}
                spacing={0}
                justifyContent="space-between"
              >
                <Typography variant="h6" sx={{ lineHeight: 1 }}>
                  Transactions
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Stack maxWidth="100%" direction="column" spacing={1}>
                {!R.isNil(paymentState.payments) && (
                  <Stack direction="column" spacing={1}>
                    {paymentState.payments
                      .filter((r) => r.contactId === tenantState.tenant.id)
                      .map((payment) => (
                        <TransactionRow
                          key={payment.id}
                          setOpenDeletePay={setOpenDeletePay}
                          permissions={permissions}
                          openPermissionModal={openPermissionModal}
                          payment={payment}
                          assetState={assetState}
                          subcontractorState={subcontractorState}
                          tenantState={tenantState}
                          companyState={companyState}
                          setSelectedPayment={setSelectedPayment}
                        />
                      ))}
                  </Stack>
                )}
                {permissions.includes("Create") ? (
                  <Button
                    fullWidth
                    component={RouterLink}
                    to={`/app/new/payment?tenantId=${tenantState.tenant.id}&companyId=${tenantState.tenant.companyIds[0]}&origin=tenantDetail`}
                    variant="contained"
                    color="primary"
                    sx={{ boxShadow: "none" }}
                  >
                    Ajouter un paiement
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    onClick={openPermissionModal}
                    variant="contained"
                    color="primary"
                    sx={{ boxShadow: "none" }}
                  >
                    Ajouter un paiement
                  </Button>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Container>
      <ModalDelete
        theme={theme}
        isLoading={tenantState.isLoading.includes(
          TentantLoadings.DELETING_TENANT
        )}
        isDisabled={tenantState.isLoading.includes(
          TentantLoadings.DELETING_TENANT
        )}
        item={tenantState.tenant}
        title={
          tenantState.tenant?.firstName + " " + tenantState.tenant?.lastName
        }
        dispatchDelete={deleteTenantProp}
        modalClose={() => {
          setOpen(false);
        }}
        open={open}
      />
      <ModalDelete
        theme={theme}
        item={documentState.documents?.find((d) => d.id === openDeleteDoc)}
        type="document"
        isLoading={deleteDocument.isLoading}
        isDisabled={deleteDocument.isDisabled}
        dispatchDelete={deleteDocument.dispatchDelete(openDeleteDoc)}
        modalClose={() => {
          setOpenDeleteDoc(null);
        }}
        open={!!openDeleteDoc}
      />
      <ModalDelete
        theme={theme}
        item={selectedPayment}
        type="payment"
        isLoading={deletePayment.isLoading}
        isDisabled={deletePayment.isDisabled}
        dispatchDelete={deletePayment.dispatchDelete(selectedPayment)}
        modalClose={() => {
          setOpenDeletePay(null);
        }}
        open={openDeletePay}
      />
    </React.Fragment>
  );
};

export default TenantsViewDetail;
