import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { initialAssetFormData, initialAssetGroupForm, initialCompanyForm } from '../../constants/utils/formInitialData';
import MainAsset from '../_common/forms/layout/asset';
import { GetCompanies } from '../../actions/company-api';
import { GetAssetGroups } from '../../actions/assetGroup-api';
import * as LoadingsAssetGroup from '../../constants/loadings/assetGroup';
import * as LoadingsCompany from '../../constants/loadings/company';
import * as LoadingsAsset from '../../constants/loadings/asset';
import Loading from '../../_common/loading';
import { useParams } from 'react-router';
import { GetAsset, GetAssets } from '../../actions/asset-api';


export const EditAsset = ({ theme, companyState, assetGroupState, assetState, documentState }) => {
    const dispatch = useDispatch();
    const { companyId, id } = useParams();

    useEffect(() => {
        dispatch(GetAsset(companyId, id));
        // dispatch(GetDocuments({ filter: { 'AssetId': id } }));

        if (R.isNil(assetState.assets)) {
            dispatch(GetAssets());
        }
        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        if (R.isNil(assetGroupState.assetGroups)) {
            dispatch(GetAssetGroups());
        }
        // eslint-disable-next-line 
    }, [companyId, id]);


    if (assetGroupState.isLoading.includes(LoadingsAssetGroup.FETCHING_ASSETGROUPS) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES) ||
        assetState.isLoading.includes(LoadingsAsset.FETCHING_ASSET)
    ) {
        return <Loading theme={theme} />;
    }

    if (R.isNil(assetState.asset) ||
        R.isEmpty(assetState.asset) ||
        R.isNil(companyState.companies) ||
        R.isNil(assetGroupState.assetGroups)) {
        return '';
    }

    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let assets = !R.isNil(assetState.assets) ? assetState.assets : [];
    let assetGroups = !R.isNil(assetGroupState.assetGroups) ? assetGroupState.assetGroups.filter(ag => !ag.isDefault) : [];

    const tempInitialAssetFormData = R.clone(initialAssetFormData);
    tempInitialAssetFormData.title.value = !R.isNil(assetState.asset.title) && !R.isEmpty(assetState.asset.title) ? assetState.asset.title : "";
    tempInitialAssetFormData.wifi.value = !R.isNil(assetState.asset.assetFields) && !R.isEmpty(assetState.asset.assetFields) ? assetState.asset.assetFields.find(f => f.name === "wifi")?.value : "";
    // tempInitialAssetFormData.wifi.value = !R.isNil(assetState.asset.assetFields) && !R.isEmpty(assetState.asset.assetFields) ? assetState.asset.assetFields?.find(f => f.name.toLowerCase() === "wifi")?.value : "";
    tempInitialAssetFormData.city.value = !R.isNil(assetState.asset.address) && !R.isEmpty(assetState.asset.address) ? assetState.asset.address.locality : "";
    tempInitialAssetFormData.address.value = !R.isNil(assetState.asset.address) && !R.isEmpty(assetState.asset.address) ? assetState.asset.address.addressLine1 : "";
    tempInitialAssetFormData.postalCode.value = !R.isNil(assetState.asset.address) && !R.isEmpty(assetState.asset.address) ? assetState.asset.address.postalCode : "";
    tempInitialAssetFormData.locationType.value = !R.isNil(assetState.asset.assetType) && !R.isEmpty(assetState.asset.assetType) ? assetState.asset.assetType : "";
    tempInitialAssetFormData.status.value = !R.isNil(assetState.asset.assetState) && !R.isEmpty(assetState.asset.assetState) ? assetState.asset.assetState : "";
    tempInitialAssetFormData.notes.value = !R.isNil(assetState.asset.description) && !R.isEmpty(assetState.asset.description) ? assetState.asset.description : "";
    tempInitialAssetFormData.tags.value = !R.isNil(assetState.asset.keywords) && !R.isEmpty(assetState.asset.keywords) ? assetState.asset.keywords.split(",") : [];

    const tempInitialCompanyForm = [R.clone(initialCompanyForm)];
    const tempInitialAssetGroupForm = [R.clone(initialAssetGroupForm)];
    const companyTitle = companies.find(c => c.id === assetState.asset.companyId)?.title;
    const assetGroupTitle = assetGroups.find(c => c.id === assetState.asset.assetGroupId)?.title;
    tempInitialCompanyForm[0].value = assetState.asset.companyId;
    tempInitialCompanyForm[0].label = !R.isNil(companyTitle) ? companyTitle : "";
    tempInitialAssetGroupForm[0].value = assetState.asset.assetGroupId;
    tempInitialAssetGroupForm[0].label = !R.isNil(assetGroupTitle) ? assetGroupTitle : "";


    return (
        <MainAsset
            theme={theme}
            assetState={assetState}
            assetGroups={assetGroups}
            companies={companies}
            assets={assets}
            photo={assetState.asset.profileUrl?.url}
            initialAssetFormData={tempInitialAssetFormData}
            initialCompanyForm={tempInitialCompanyForm}
            initialAssetGroupForm={tempInitialAssetGroupForm}
            resetCompanyForm={initialCompanyForm}
            resetAssetGroupForm={initialAssetGroupForm}
            companyId={companyId}
            documents={assetState.asset.files}
            id={id}
            asset={assetState.asset}
        />
    );
};
