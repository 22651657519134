import React, { useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import * as R from 'ramda';

// Components
// Components: Global
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ArticleIcon from '@mui/icons-material/Article';
import { dayMonthYear } from '../../constants/utils/date';
import ItemCard from './itemCard';
import ModalCarousel from "./modalCarousel";

const DocumentRow = ({ document, permissions, openPermissionModal, setOpenDeleteDoc, theme }) => {
    const [optionsMenu, setOptionsMenu] = useState(null);
    const [openCarousel, setOpenCarousel] = useState(false);
    const optionsMenuClick = (e) => { e.preventDefault(); setOptionsMenu(e.currentTarget); };
    const showMe = Boolean(optionsMenu);


    const modalOpenCarousel = () => {
		setOpenCarousel(true);
	};
	const modalCloseCarousel = () => {
		setOpenCarousel(false);
	};

    return (
        <ItemCard key={document.id} padding={1} borderRadius={2} alignItems={{ default: "start", md: "center" }}>
            <Avatar variant="rounded" children={document.title[0]} sx={{ bgcolor: "background.default", fontWeight: 'bold' }}>
                {document.documentType === 'Document' ?
                    <ArticleIcon sx={{ color: document.contractCategory === 0 ? "info.main" : "primary.main" }} />
                    :
                    <AttachFileIcon sx={{ color: "primary.main" }} />
                }
            </Avatar>
            <Stack alignItems={{ xl: "center" }} direction={{ default: "column", xl: "row" }} spacing={{ xl: 2 }} flex='1 1 auto' justifyContent="space-between">
                <Stack direction="row" spacing={2} flex="1 1 auto" maxWidth={{ default: "100%", xl: "30em" }}>
                    <Typography fontWeight="medium" color="text.dark">
                        {document.title || 'N/A'}
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={{ default: 1, xl: 4 }} className="truncated" justifyContent="space-between">
                    <Typography color="text.secondary" variant="body1">
                        {document.documentType === 'Rental' ? 'Contrat' : document.documentType === 'Payment' ? 'Paiement' :
                            document.contractCategory === 1 ? 'Juridique' : document.contractCategory === 2 ? 'Impôts' : document.contractCategory === 3 ? 'Personnel' : "Comptable"}
                    </Typography>
                    <Typography color="text.hint" variant="body1">
                        {!R.isNil(document.date) && format(new Date(document.date), dayMonthYear)}
                    </Typography>
                </Stack>
            </Stack>
            <Box position={{ default: "absolute", xs: "relative" }} top={{ default: -8, xs: "unset" }} right={{ default: 8, xs: "unset" }}>
                <IconButton sx={{ justifyContent: "space-between", maxWidth: { xs: "fit-content" } }} variant="contained" color="inherit" id="optionButtom" aria-controls={showMe ? 'optionMenu' : undefined} aria-haspopup="true" aria-expanded={showMe ? 'true' : undefined} onClick={optionsMenuClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu id="optionMenu" anchorEl={optionsMenu} open={showMe} onClose={() => setOptionsMenu(null)} MenuListProps={{ 'aria-labelledby': 'optionButtom' }}>
                    {permissions?.includes("Update") ?
                        <MenuItem component={RouterLink} to={"/app/edit/document/" + document.companyId + '/' + document.id}>
                            Éditer information
                        </MenuItem>
                        :
                        <MenuItem onClick={openPermissionModal}>
                            Éditer information
                        </MenuItem>
                    }
                    <MenuItem component={RouterLink} to={"/app/documents/" + document.companyId + '/' + document.id}>
                        Voir le document
                    </MenuItem>
                    <MenuItem disabled={R.isEmpty(document.files)} variant="text" color="inherit" size="inherit" key="Voir fichiers" onClick={modalOpenCarousel} >
                        Ouvrir des fichiers
                    </MenuItem>
                    <MenuItem onClick={() => { permissions.includes("Delete") ? setOpenDeleteDoc(document.id) : openPermissionModal(); }} variant="text" color="inherit" size="inherit" key="effacer">
                        Effacer
                    </MenuItem>
                </Menu>
            </Box>
            <ModalCarousel theme={theme} items={document.files} modalClose={modalCloseCarousel} open={openCarousel} />
        </ItemCard>
    );
};

export default DocumentRow;