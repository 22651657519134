import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, Link as RouterLink } from 'react-router-dom';

import * as R from 'ramda';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// Components: Common
import { GetDocuments } from '../../../actions/document-api';
import { GetCompanies } from '../../../actions/company-api';
import { alphabet } from '../../../constants/utils/alphabet';
import * as RentalLoadings from '../../../constants/loadings/rental';

import { useInfinitScroll } from '../../../hooks/useInfinitScroll';
import { useSearchParams } from '../../../hooks/useSearchParams';

import Loading from '../../../_common/loading';
import ItemCard from '../../_common/itemCard';

// Components: Views
import DocumentsMenuList from './list';
import DocumentsMenuFooter from './footer';
import { START_QUERY } from '../../../constants/utils/filterQuery';



export const DocumentsMenu = ({ theme, showView, setShowView, userState, companyState, tenantState, assetState, assetGroupState, subcontractorState, collaboratorState, documentState, rentalState }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { page, start } = useSearchParams();
    const { lastCardRef, cardWrapperRef } = useInfinitScroll(page, documentState.hasMorePage, location);


    useEffect(() => {
        let filter;
        filter = R.isNil(start) ? filter : { [START_QUERY.title]: start };
        dispatch(GetDocuments({ pageNumber: page, filter }));
        // eslint-disable-next-line 
    }, [location.search]);

    useEffect(() => {
        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        // eslint-disable-next-line 
    }, [companyState.companies]);


    return (
        <Box component="aside" className="aside" display="flex" flex="1 1 auto" py={2} gap={2} pt={{ md: 4 }} height="100%" maxWidth="100%" sx={{ flexDirection: 'column' }}>
            {/* borderRight: '2px solid transparent',  */}
            <Container disableGutters maxWidth="100%" sx={{ maxWidth: "100%", height: "100%", display: "inherit", flexDirection: "inherit", overflow: "hidden auto" }}>
                <Typography component="h3" className="sr-only">Menu</Typography>
                <Stack direction="row" flex="1 1 auto" height="100%" maxWidth="100%" spacing={0}>
                    <Stack direction="column" flex="1 1 auto" height="100%" >
                        <Container ref={cardWrapperRef} maxWidth="100%" sx={{ paddingRight: { sm: '12px' }, maxWidth: "100%", height: "100%", overflow: "hidden auto", display: "inherit", flexDirection: "inherit", gap: 1 }}>
                            {
                                documentState.isLoading.includes(RentalLoadings.FETCHING_RENTALS) ?
                                    <Loading />
                                    :
                                    R.isNil(documentState.documents) || R.isEmpty(documentState.documents) ?
                                        <ItemCard /> :
                                        <DocumentsMenuList setShowView={setShowView} lastCardRef={lastCardRef} showView={showView} theme={theme} items={documentState.documents} sx={{ marginTop: 2, maxWidth: "100%", height: "inherit" }} companies={companyState.companies} />
                            }
                        </Container>
                    </Stack>
                    <Stack direction="column" flex="0" height="100%">
                        <Container maxWidth="100%" sx={{ paddingX: { sm: '12px' }, maxWidth: "100%", height: "100%", overflow: "hidden auto", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            {alphabet.map((letter) =>
                                <Box fontSize="small" color="text.disabled" to={letter === "#" ? `${location.pathname}` : `${location.pathname}?start=${letter}`} component={RouterLink} fontWeight="bold" role="button" key={letter} >
                                    {letter}
                                </Box>
                            )}
                        </Container>
                    </Stack>
                </Stack>
            </Container>
            <DocumentsMenuFooter theme={theme} />
        </Box >
    );
};

export default DocumentsMenu;
