import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';


export const PasswordsMenuFooter = ({theme}) => {
    return (
        <Container maxWidth="100%" sx={{mt: "auto"}}>
            <Button component={RouterLink} to="/app/new/password" variant="contained" color="primary" sx={{width: "100%"}}>Ajouter un Mot de passe</Button>
        </Container>
    );
}

export default PasswordsMenuFooter;
