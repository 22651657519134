import axios from 'axios';
import * as R from 'ramda';
import { companiesActions } from "../reducers/company";
import * as urls from '../constants/webapi/company';
import { LARGE_PAGE_SIZE, PAGE_SIZE } from '../constants/utils/pageSize';
import { urlBuilder } from '../constants/utils/urlBuilder';




export const GetCompanies = ({ pageSize, pageNumber, sortBy, sortDesc, filter } = {}) => {
    const size = !R.isNil(pageSize) && !R.isEmpty(pageSize) ? pageSize : PAGE_SIZE;
    const page = !R.isNil(pageNumber) && !R.isEmpty(pageNumber) ? pageNumber : 1;
    const sort = !R.isNil(sortBy) && !R.isEmpty(sortBy) ? sortBy : undefined;
    const desc = !R.isNil(sortDesc) && !R.isEmpty(sortDesc) ? sortDesc : false;
    const filters = !R.isNil(filter) && !R.isEmpty(filter) ? urlBuilder(filter) : undefined;

    return async dispatch => {
        dispatch(companiesActions.getCompaniesRequest());
        return axios.get(urls.getCompaniesUrl({ size, page, sort, desc, filters }))
            .then((response) => {
                dispatch(companiesActions.getCompaniesSuccess({
                    data: response.data,
                    pageSize: size,
                    pageNumber: page,
                    sortBy: sort,
                    sortDesc: desc
                }));
            })
            .catch((error) => {
                dispatch(companiesActions.getCompaniesFailure(error.response.data));
            });
    };
};

export const GetDashboardCompanies = ({ pageSize, pageNumber, sortBy, sortDesc } = {}) => {
    const params = { size: LARGE_PAGE_SIZE, page: 1, sort: "Title", desc: false };

    return async dispatch => {
        dispatch(companiesActions.getDashboardCompaniesRequest());
        return axios.get(urls.getCompaniesUrl(params))
            .then((response) => {
                dispatch(companiesActions.getDashboardCompaniesSuccess({
                    data: response.data,
                    pageSize: params.size,
                    pageNumber: params.page,
                    sortBy: params.sort,
                    sortDesc: params.desc
                }));
            })
            .catch((error) => {
                dispatch(companiesActions.getDashboardCompaniesFailure(error.response.data));
            });
    };
};

export const CreateCompany = (company) => {
    return async dispatch => {
        dispatch(companiesActions.createCompanyRequest());
        return axios.post(urls.getCompanyUrl(), company)
            .then((response) => {
                dispatch(companiesActions.createCompanySuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(companiesActions.createCompanyFailure(error.response.data));
                throw error;
            });
    };
};

export const GetCompany = (companyId) => {
    return async dispatch => {
        dispatch(companiesActions.getCompanyRequest());
        return axios.get(urls.getCompanyByIdUrl(companyId))
            .then((response) => {
                dispatch(companiesActions.getCompanySuccess(response.data));
            })
            .catch((error) => {
                dispatch(companiesActions.getCompanyFailure(error.response.data));
            });
    };
};

export const UpdateCompany = (company) => {
    return async dispatch => {
        dispatch(companiesActions.updateCompanyRequest());
        return axios.put(urls.getCompanyByIdUrl(company.id), company)
            .then((response) => {
                dispatch(companiesActions.updateCompanySuccess(company));
                return response.data;
            })
            .catch((error) => {
                dispatch(companiesActions.updateCompanyFailure(error.response.data));
                throw error;
            });
    };
};

export const DeleteCompany = (companyId) => {
    return async dispatch => {
        dispatch(companiesActions.deleteCompanyRequest());
        return axios.delete(urls.getCompanyByIdUrl(companyId))
            .then((response) => {
                dispatch(companiesActions.deleteCompanySuccess({ id: companyId }));
                return response.data;
            })
            .catch((error) => {
                dispatch(companiesActions.deleteCompanyFailure(error.response.data));
                throw error;
            });
    };
};

export const ExportCompanyToGoogleDrive = () => {
    return async dispatch => {
        dispatch(companiesActions.exportCompanyRequest());
        return axios.post(urls.getCompanyExportFilesUrl())
            .then((response) => {
                dispatch(companiesActions.exportCompanySuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(companiesActions.exportCompanyFailure(error.response.data));
                throw error;
            });
    };
};