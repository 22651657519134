import * as consts from './constants';

const typography = {
    fontFamily: '"Rubik", system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
		body2: {
			fontSize: ".9375rem",
		},
		caption: {
			fontWeight: '500',
			fontSize: ".6875rem",
			letterSpacing: '.015625rem'
		},
		label: {
			fontWeight: '600',
			fontSize: ".8125rem",
			letterSpacing: '.025625rem',
      textTransform: 'uppercase'
		},
    h1: {
      fontWeight: 500,
      // fontSize: '1.875rem',
      lineHeight: '1.5',
    },
    h2: {
      fontWeight: 500,
      // fontSize: '1.5rem',
      lineHeight: '1.5',
    },
    h3: {
      fontWeight: 400,
      fontSize: '3.3125rem',
      lineHeight: '1.5',
    },
    h4: {
      fontWeight: 500,
      // fontSize: '1.125rem',
      lineHeight: '1.5',
    },
    h5: {
      fontWeight: 500,
      lineHeight: '1.5',
    },
    h6: {
      fontWeight: 500,
			fontSize: "1.375rem",
      lineHeight: '1.5',
    },
    button: {
      fontSize: '.9375rem',
      // textTransform: 'none',
      fontWeight: 500,
    },
    subtitle1: {
			fontSize: ".9375rem",
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '0.875rem',
			fontWeight: 500,
    },
    hint: {
      color: consts.textHint,
    },
  };
  
  export default typography;
  