import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as R from 'ramda';

// Components
// Components: Global

// Components
// Components: Global
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import MenuIcon from '@mui/icons-material/Menu';

export const NavbarPage = ({ theme, page, location, mt, user, routerLocation }) => {
    const navigate = useNavigate();
    const [anchorOption, setAnchorOption] = useState(null);
    const [value, setValue] = useState(0);
    const obj = {
        "assets": 0,
        "immeubles": 1,
        "vendu": 2,
    }
    const pathGlobal = [
        { path: 'edit', title: 'Modifier' },
        { path: 'assets', title: 'Biens' },
        { path: 'tenants', title: 'Locataires' },
        { path: 'companies', title: 'Sociétés' },
        { path: 'immeubles', title: 'Immeubles' },
        { path: 'rentals', title: 'Contrats de locations' },
        { path: 'subcontractors', title: 'sous-traitants' },
        { path: 'collaborators', title: 'collaborateurs' },
        { path: 'share', title: "Partages d'accès" },
        { path: 'passwords', title: 'Mots de passe' },
        { path: 'contrats', title: 'Contrat de location' },
        { path: 'banks', title: 'Mouvements bancaires' },
    ];
    const pathNew = [
        { path: 'document', title: 'Document' },
        { path: 'asset', title: 'Bien' },
        { path: 'tenant', title: 'Locataire' },
        { path: 'company', title: 'Société' },
        { path: 'immeuble', title: 'Immeuble' },
        { path: 'payment', title: 'Paiement' },
        { path: 'collaborator', title: 'Collaborateur' },
        { path: 'subcontractor', title: 'Sous-traitant' },
        { path: 'contrat', title: 'Contrat de location' },
        { path: 'password', title: 'Mot de passe' },
    ];

    const matchTitle = [
        pathGlobal.filter(item => (item.path) === (routerLocation.pathname.split("/")[2]))[0],
        pathNew.filter(item => (item.path + 's') === (routerLocation.pathname.split("/")[2]))[0],
        pathNew.filter(item => (item.path) === (routerLocation.pathname.split("/")[3]))[0],
    ];
    const showMe = Boolean(anchorOption);

    let path = '';
    if (routerLocation.pathname.includes('immeubles')) {
        path = 'immeubles';
    } else if (routerLocation.pathname.includes('vendu')) {
        path = 'vendu';
    } else {
        path = 'assets';
    }

    const handleClick = (e) => {
        e.preventDefault();
        setAnchorOption(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorOption(null);
    };


    const switchTab = (event, newValue) => {
        setValue(newValue);
    };



    return (
        <AppBar elevation={1} direction="column" spacing={0} square position="sticky" sx={{ top: 0, width: '100%', zIndex: '2', marginTop: (mt ? mt : 0), backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`, backgroundColor: theme.palette.primary.main, borderBottomLeftRadius: '1.25rem', borderBottomRightRadius: '1.25rem' }}>
            <Container maxWidth="100%">
                {page || routerLocation.pathname === '/app/menu' ?
                    <Stack component="section" direction="row" spacing={.5} alignItems="center" flex="1 1 auto" my={.5}>
                        <Typography variant="h5" component="h2" fontWeight="normal" textTransform="capitalize" mb={.5}>{page ? page : location ? location : routerLocation.pathname.split("/")[2].split("/")}</Typography>
                    </Stack>
                    : routerLocation.pathname !== '/app' ?
                        routerLocation.pathname.includes('search') ?
                            <Stack component="section" direction="row" spacing={1} flex='1 1 auto' alignItems='center' sx={{ maxWidth: '100%' }}>
                                <Typography className="sr-only" component="h2">Navigantion</Typography>
                                <IconButton component={RouterLink} to="/" size="medium" color="inherit" title="back">
                                    <ArrowBackIcon />
                                </IconButton>
                                <Tabs textColor="inherit" indicatorColor="secondary" value={value} onChange={switchTab} variant="scrollable" scrollButtons={false} sx={{ flex: "1 1 auto" }}>
                                    <Tab sx={{ flex: "1 1 auto", minHeight: 60 }} icon={<Icon color="inherit" value={0}>subject</Icon>} aria-label="subject" />
                                    <Tab sx={{ flex: "1 1 auto", minHeight: 60 }} icon={<Icon color="inherit" value={1}>description</Icon>} aria-label="description" />
                                    <Tab sx={{ flex: "1 1 auto", minHeight: 60 }} icon={<Icon color="inherit" value={2}>apartment</Icon>} aria-label="apartment" />
                                </Tabs>
                            </Stack>
                            :
                            <Stack component="section" direction={{ sm: "row" }} spacing={1} flex='1 1 auto' justifyContent="space-between" alignItems='center' sx={{ maxWidth: '100%' }} pt={.5} pb={1}>
                                <Stack direction="row" spacing={1} width="100%" flex='1 1 auto' alignItems='center' sx={{ maxWidth: '100%' }} pt={.5} pb={1}>
                                    {
                                        routerLocation.state === 'menu' || routerLocation.pathname.includes('edit') ?
                                            <IconButton onClick={() => navigate(-1)} size="medium" color="inherit" title="back">
                                                <ArrowBackIcon />
                                            </IconButton>
                                            :
                                            <IconButton 
                                              component={RouterLink} 
                                              onClick={() => navigate(-1)}
                                              //to="/app" 
                                              size="medium" 
                                              color="inherit" 
                                              title="back">
                                                <ArrowBackIcon />
                                            </IconButton>
                                    }
                                    <Typography flex="1 1 auto" variant="h6" component="h2" fontWeight="normal" textTransform="capitalize">
                                        {location ?
                                            location
                                            :
                                            <React.Fragment>
                                                {!R.isNil(matchTitle[0]) && !R.isEmpty(matchTitle[0]) ? (routerLocation.pathname.includes('edit') ? ('Modifier ' + pathNew.filter(item => item.path === routerLocation.pathname.split("/")[3])[0]?.title) : matchTitle[0]?.title) : routerLocation.pathname.split("/")[2]} {(routerLocation.pathname.includes('new') && routerLocation.pathname.split("/")[3]) && routerLocation.pathname.split("/")[3].split("/")}
                                            </React.Fragment>
                                        }
                                    </Typography>
                                </Stack>
                                {routerLocation.pathname.includes('new') &&
                                    <React.Fragment>
                                        <Button sx={{ justifyContent: "space-between", maxWidth: { sm: "fit-content" }, backgroundColor: { sm: theme.palette.secondary.main } }} fullWidth variant="contained" color="light" id="optionButtom" aria-controls={showMe ? 'optionMenu' : undefined} aria-haspopup="true" aria-expanded={showMe ? 'true' : undefined} onClick={handleClick} endIcon={<ArrowDropDownIcon />}>
                                            {routerLocation.pathname.split("/")[3] ? pathNew.filter(item => item.path === routerLocation.pathname.split("/")[3])[0]?.title : "Type"}
                                        </Button>
                                        <Menu id="optionMenu" anchorEl={anchorOption} open={showMe} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'optionButtom' }}>
                                            {pathNew.map(({ path, title }) => (
                                                <MenuItem component={RouterLink} to={"/app/new/" + path} key={title} onClick={handleClose}>
                                                    {title}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </React.Fragment>
                                }
                            </Stack>
                        :
                        <Stack component="section" direction="row" spacing={.5} alignItems="center" flex="1 1 auto" my={.5}>
                            <Typography variant="h4" component="h2" fontWeight="normal" textTransform="capitalize">Bonjour <strong>{user.firstName}</strong></Typography>
                        </Stack>
                }
            </Container>
        </AppBar>
    );
};

export default NavbarPage;
