import axios from 'axios';
import * as R from 'ramda';
import { paymentsActions } from "../reducers/payment";
import * as urls from '../constants/webapi/payment';
import { urlBuilder } from '../constants/utils/urlBuilder';
import { PAGE_SIZE } from '../constants/utils/pageSize';




export const GetPayments = ({ pageSize, pageNumber, sortBy, sortDesc, filter } = {}) => {
    const size = !R.isNil(pageSize) && !R.isEmpty(pageSize) ? pageSize : PAGE_SIZE;
    const page = !R.isNil(pageNumber) && !R.isEmpty(pageNumber) ? pageNumber : 1;
    const sort = !R.isNil(sortBy) && !R.isEmpty(sortBy) ? sortBy : undefined;
    const desc = !R.isNil(sortDesc) && !R.isEmpty(sortDesc) ? sortDesc : false;
    const filters = !R.isNil(filter) && !R.isEmpty(filter) ? urlBuilder(filter) : undefined;

    return async dispatch => {
        dispatch(paymentsActions.getPaymentsRequest());
        return axios.get(urls.getPaymentsUrl({ size, page, sort, desc, filters }))
            .then((response) => {
                dispatch(paymentsActions.getPaymentsSuccess({
                    data: response.data,
                    pageSize: size,
                    pageNumber: page,
                    sortBy: sort,
                    sortDesc: desc
                }));
            })
            .catch((error) => {
                dispatch(paymentsActions.getPaymentsFailure(error.response.data));
            });
    };
};

export const CreatePayment = (payment) => {
    return async dispatch => {
        dispatch(paymentsActions.createPaymentRequest());
        return axios.post(urls.getPaymentUrl(payment.companyId), payment)
            .then((response) => {
                dispatch(paymentsActions.createPaymentSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(paymentsActions.createPaymentFailure(error.response.data));
                throw error;
            });
    };
};

export const GetPayment = (companyId, paymentId) => {
    return async dispatch => {
        dispatch(paymentsActions.getPaymentRequest());
        return axios.get(urls.getPaymentByIdUrl(companyId, paymentId))
            .then((response) => {
                dispatch(paymentsActions.getPaymentSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(paymentsActions.getPaymentFailure(error.response.data));
            });
    };
};

export const UpdatePayment = (payment) => {
    return async dispatch => {
        dispatch(paymentsActions.updatePaymentRequest());
        return axios.put(urls.getPaymentByIdUrl(payment.companyId, payment.id), payment)
            .then((response) => {
                dispatch(paymentsActions.updatePaymentSuccess(payment));
            })
            .catch((error) => {
                dispatch(paymentsActions.updatePaymentFailure(error.response.data));
                throw error;
            });
    };
};

export const DeletePayment = (companyId, paymentId) => {
    return async dispatch => {
        dispatch(paymentsActions.deletePaymentRequest());
        return axios.delete(urls.getPaymentByIdUrl(companyId, paymentId))
            .then((response) => {
                dispatch(paymentsActions.deletePaymentSuccess({ companyId, paymentId }));
                return response.data;
            })
            .catch((error) => {
                dispatch(paymentsActions.deletePaymentFailure(error.response.data));
                throw error;
            });
    };
};