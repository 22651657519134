import { createSlice } from '@reduxjs/toolkit';
import * as Loadings from '../constants/loadings/file';


const initState = {
    isLoading: [],
    files: undefined,
    file: undefined
};

const FilesReducer = createSlice({
    name: 'file',
    initialState: { ...initState },
    reducers: {
        //#region UPDATING FILE
        uploadFilesRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPLOADING_FILES)) {
                state.isLoading.push(Loadings.UPLOADING_FILES);
            }
        },
        uploadFilesSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPLOADING_FILES);
            state.files = payload;
        },
        uploadFilesFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPLOADING_FILES);
        },
        //#endregion

        //#region DELETE FILES
        deleteFilesRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_FILES)) {
                state.isLoading.push(Loadings.DELETING_FILES);
            }
        },
        deleteFilesSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_FILES);
        },
        deleteFilesFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_FILES);
        },
        //#endregion

        //#region UPLOAD PROFILE PHOTO
        uploadProfilePhotoRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPLOADING_PROFILE_PHOTO)) {
                state.isLoading.push(Loadings.UPLOADING_PROFILE_PHOTO);
            }
        },
        uploadProfilePhotoSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPLOADING_PROFILE_PHOTO);
            state.file = payload;
        },
        uploadProfilePhotoFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPLOADING_PROFILE_PHOTO);
        },
        //#endregion

        //#region DELETE PROFILE PHOTO
        deleteProfilePhotoRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_PROFILE_PHOTO)) {
                state.isLoading.push(Loadings.DELETING_PROFILE_PHOTO);
            }
        },
        deleteProfilePhotoSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_PROFILE_PHOTO);
        },
        deleteProfilePhotoFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_PROFILE_PHOTO);
        },
        //#endregion
    }
});

export const filesActions = FilesReducer.actions;
export default FilesReducer.reducer;