import React from 'react';

// Components
// Components: Global


// Components: Common
// Components: View
import ResultsList from './results/list';
import SharedAccessList from './share/collaboratorsList';

export const SearchViewDetail = ({ theme, results, text, collaborators, companies, }) => {

    return (
		text === "Partages d'accès" ?
			<SharedAccessList theme={theme} collaborators={collaborators} companies={companies} />
			:
			<ResultsList theme={theme} results={results} />
		
    );
};

export default SearchViewDetail;
