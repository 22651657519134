import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

// Components: Common
import * as LoadingsCompany from '../../constants/loadings/company';
import * as LoadingsSubcontractor from '../../constants/loadings/subcontractor';
import { initialSubcontractorFormData, initialCompanyForm } from '../../constants/utils/formInitialData';
import { GetCompanies } from '../../actions/company-api';
import { GetSubcontractors } from '../../actions/subcontractor-api';
import Loading from '../../_common/loading';
import MainSubcontractor from '../_common/forms/layout/subcontractor';



export const NewSubcontractor = ({ theme, subcontractorState, companyState }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        if (R.isNil(subcontractorState.subcontractors)) {
            dispatch(GetSubcontractors());
        }
        // eslint-disable-next-line 
    }, []);

    if (
        subcontractorState.isLoading.includes(LoadingsSubcontractor.FETCHING_SUBCONTRACTORS) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)) {
        return <Loading theme={theme} />;
    }
    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let subcontractors = !R.isNil(subcontractorState.subcontractors) ? subcontractorState.subcontractors.map(s => ({ ...s, title: s.firstName + " " + s.lastName })) : [];


    return (
        <MainSubcontractor
            theme={theme}
            subcontractorState={subcontractorState}
            subcontractors={subcontractors}
            companies={companies}
            initialSubcontractorFormData={initialSubcontractorFormData}
            initialCompanyForm={[initialCompanyForm]}
            resetSubcontractorFormData={initialSubcontractorFormData}
            resetCompanyForm={initialCompanyForm}
        />
    );
};

export default NewSubcontractor;