import axios from 'axios';
import * as R from 'ramda';
import { subcontractorsActions } from "../reducers/subcontractor";
import * as urls from '../constants/webapi/subcontractor';
import { PAGE_SIZE, SMALL_PAGE_SIZE } from '../constants/utils/pageSize';
import { urlBuilder } from '../constants/utils/urlBuilder';



export const GetSubcontractors = ({ pageSize, pageNumber, sortBy, sortDesc, filter } = {}) => {
    const size = !R.isNil(pageSize) && !R.isEmpty(pageSize) ? pageSize : PAGE_SIZE;
    const page = !R.isNil(pageNumber) && !R.isEmpty(pageNumber) ? pageNumber : 1;
    const sort = !R.isNil(sortBy) && !R.isEmpty(sortBy) ? sortBy : undefined;
    const desc = !R.isNil(sortDesc) && !R.isEmpty(sortDesc) ? sortDesc : false;
    const filters = !R.isNil(filter) && !R.isEmpty(filter) ? urlBuilder(filter) : undefined;

    return async dispatch => {
        dispatch(subcontractorsActions.getSubcontractorsRequest());
        return axios.get(urls.getSubcontractorsUrl({ size, page, sort, desc, filters }))
            .then((response) => {
                dispatch(subcontractorsActions.getSubcontractorsSuccess({
                    data: response.data,
                    pageSize: size,
                    pageNumber: page,
                    sortBy: sort,
                    sortDesc: desc
                }));
            })
            .catch((error) => {
                dispatch(subcontractorsActions.getSubcontractorsFailure(error.response.data));
            });
    };
};

export const GetDashboardSubcontractors = ({ pageSize, pageNumber, sortBy, sortDesc } = {}) => {
    const params = { size: SMALL_PAGE_SIZE, page: 1, sort: "firstName", desc: false };

    return async dispatch => {
        dispatch(subcontractorsActions.getDashboardSubcontractorsRequest());
        return axios.get(urls.getSubcontractorsUrl(params))
            .then((response) => {
                dispatch(subcontractorsActions.getDashboardSubcontractorsSuccess({
                    data: response.data,
                    pageSize: params.size,
                    pageNumber: params.page,
                    sortBy: params.sort,
                    sortDesc: params.desc
                }));
            })
            .catch((error) => {
                dispatch(subcontractorsActions.getDashboardSubcontractorsFailure(error.response.data));
            });
    };
};

export const CreateSubcontractor = (subcontractor) => {
    return async dispatch => {
        dispatch(subcontractorsActions.createSubcontractorRequest());
        return axios.post(urls.getSubcontractorUrl(subcontractor.companyId), subcontractor)
            .then((response) => {
                dispatch(subcontractorsActions.createSubcontractorSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(subcontractorsActions.createSubcontractorFailure(error.response.data));
                throw error;
            });
    };
};

export const GetSubcontractor = (subcontractorId) => {
    return async dispatch => {
        dispatch(subcontractorsActions.getSubcontractorRequest());
        return axios.get(urls.getSubcontractorByIdUrl(subcontractorId))
            .then((response) => {
                dispatch(subcontractorsActions.getSubcontractorSuccess(response.data));
            })
            .catch((error) => {
                dispatch(subcontractorsActions.getSubcontractorFailure(error.response.data));
            });
    };
};

export const UpdateSubcontractor = (subcontractor) => {
    return async dispatch => {
        dispatch(subcontractorsActions.updateSubcontractorRequest());
        return axios.put(urls.getSubcontractorByIdUrl(subcontractor.id), subcontractor)
            .then((response) => {
                dispatch(subcontractorsActions.updateSubcontractorSuccess(subcontractor));
            })
            .catch((error) => {
                dispatch(subcontractorsActions.updateSubcontractorFailure(error.response.data));
                throw error;
            });
    };
};

export const DeleteSubcontractor = (subcontractorId) => {
    return async dispatch => {
        dispatch(subcontractorsActions.deleteSubcontractorRequest());
        return axios.delete(urls.getSubcontractorByIdUrl(subcontractorId))
            .then((response) => {
                dispatch(subcontractorsActions.deleteSubcontractorSuccess({ id: subcontractorId }));
                return response.data;
            })
            .catch((error) => {
                dispatch(subcontractorsActions.deleteSubcontractorFailure(error.response.data));
                throw error;
            });
    };
};