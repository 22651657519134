import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';


// Components
// Components: Global
import Box from '@mui/material/Box';

// Components: Common

// Components: Views
import CollaboratorsViewEmpty from './empty';
import CollaboratorsViewDetail from './detail';

export const CollaboratorsView = ({ theme, userState, companyState, tenantState, assetState, subcontractorState, collaboratorState, documentState, rentalState }) => {
    const { pathname } = useLocation();
    const showView = !pathname.endsWith('/collaborators');

    return (
        <Box display={{ default: showView ? 'flex' : 'none', lg: "flex" }} flexDirection="column" width="100%" overflow="hidden auto" backgroundColor="background.default" sx={{ borderRadius: 2, position: { default: showView && 'fixed', lg: 'relative' }, left: { default: showView && 0, lg: 'unset' }, right: { default: showView && 0, lg: 'unset' }, top: { default: showView && '5rem', lg: 'unset' }, height: { default: showView && 'calc(100% - 5rem)', lg: '100%' }, bottom: { default: showView && 0, lg: 'unset' }, zIndex: { default: showView && 2, lg: 'unset' } }}>
            <Routes>
                <Route index end element={
                    <CollaboratorsViewEmpty />
                } />
                <Route path=":id/*" element={
                    <CollaboratorsViewDetail theme={theme} userState={userState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} documentState={documentState} rentalState={rentalState}/>
                } />
                <Route path="*" element={<Navigate to="/app/collaborators" />} />
                <Route path="/" element={
                    <Navigate replace to="/app/collaborators" />
                } />
            </Routes>
        </Box>
    );
};

export default CollaboratorsView;
