import React from 'react';
import * as R from 'ramda';

// Components
// Components: Global
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import theme from '../../_common/theme';

function ItemCard(props, ...others) {
    return (
        <Card to={props.to} ref={props.element} component={props.component} sx={{ position: "relative", overflowWrap: 'anywhere', border: props.border ? props.border : '0', borderColor: props.borderColor ? props.borderColor : 'transparent', textDecoration: props.textDecoration ? props.textDecoration : 'none', textTransform: props.textTransform, borderRadius: props.borderRadius, margin: props.margin, padding: props.padding && props.padding, backgroundColor: (R.isNil(props.children) || props.empty) ? theme.palette.background.dim : (props.backgroundColor === false ? 'transparent' : props.backgroundColor), boxShadow: (R.isNil(props.children) || props.empty || props.elevation === 0 ? 'none' : props.elevation) }}>
            {props.loading ?
                <React.Fragment>
                    <Stack direction={{ default: "column", xs: "row" }} alignItems={{ default: "start", xs: "center" }} spacing={1} mb={props.loading === 'multiple' ? 1.5 : 0}>
                        <Skeleton variant="circular" animation="wave" width={48} height={48} />
                        <Stack direction="column" flex="1 1 auto">
                            <Skeleton variant="text" width="100%" sx={{ maxWidth: "100%", fontSize: '1rem' }} />
                            <Skeleton variant="text" width="90%" sx={{ maxWidth: "100%", fontSize: '.75rem' }} />
                        </Stack>
                    </Stack>
                    {props.loading === 'multiple' && <Stack direction={{ default: "column", xs: "row" }} alignItems={{ default: "start", xs: "center" }} spacing={1}>
                        <Skeleton variant="circular" animation="wave" width={48} height={48} />
                        <Stack direction="column" flex="1 1 auto">
                            <Skeleton variant="text" width="100%" sx={{ maxWidth: "100%", fontSize: '1rem' }} />
                            <Skeleton variant="text" width="90%" sx={{ maxWidth: "100%", fontSize: '.75rem' }} />
                        </Stack>
                    </Stack>}
                </React.Fragment>
                :
                R.isNil(props.children) || props.empty ?
                    <Stack direction={{ default: "column", xs: "row" }} alignItems={{ default: "start", xs: "center" }} spacing={props.spacing ? props.spacing : 2}>
                        <Avatar variant="circular" children="/" sx={{ bgcolor: (R.isNil(props.children) || props.empty) && "grey.A300", width: '3rem', height: '3rem', fontWeight: 'bold' }} />
                        <Stack direction="column" flex="1 1 auto">
                            <Typography variant="text" fontWeight="bold" sx={{}}>Aucun resultat</Typography>
                            <Typography variant="text" sx={{}}>On dirait que c'est vide</Typography>
                        </Stack>
                    </Stack>
                    :
                    !props.raw ?
                        <Stack direction={props.direction ? props.direction : { default: "column", xs: "row" }} alignItems={props.alignItems ? props.alignItems : { default: "start", xs: "center" }} spacing={props.spacing ? props.spacing : 2} flex="1 1 auto">
                            {props.children}
                        </Stack>
                        :
                        props.children
            }
        </Card>
    );
}

ItemCard.defaultProps = {
    loading: false,
    empty: false,
    margin: 0,
    padding: 1.75,
    borderRadius: 4,
};

export default ItemCard;