import axios from 'axios';
import { filesActions } from "../reducers/file";
import * as urls from '../constants/webapi/file';


export const UploadFiles = (files, typeId, fileType, toReplace) => {
    return async dispatch => {
        dispatch(filesActions.uploadFilesRequest());
        const filesPromisses = [];
        for (const file of files) {
            const formData = new FormData();
            formData.append('files', file);
            formData.append('typeId', typeId);
            formData.append('type', fileType);
            if(toReplace) {
                formData.append('id', toReplace);
            }
            filesPromisses.push(axios.post(urls.getFileUrl(), formData));
        }
        return axios.all(filesPromisses)
            .then((response) => {
                dispatch(filesActions.uploadFilesSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(filesActions.uploadFilesFailure(error.response.data));
            });
    };
};

export const DeleteFiles = (files, typeId, fileType) => {
    return async dispatch => {
        dispatch(filesActions.deleteFilesRequest());
        const filesPromisses = [];
        for (const file of files) {
            const data = { id: file.id, type: fileType, typeId: typeId };
            filesPromisses.push(axios.delete(urls.getFileUrl(), { data }));
        }
        return axios.all(filesPromisses)
            .then((response) => {
                dispatch(filesActions.deleteFilesSuccess(response.data));
            })
            .catch((error) => {
                dispatch(filesActions.deleteFilesFailure(error.response.data));
            });
    };
};


export const UploadProfilePhoto = ({ file, id, profile }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', id);
    formData.append('profile', profile);
    return async dispatch => {
        dispatch(filesActions.uploadProfilePhotoRequest());
        return axios.post(urls.getProfileUrl(file.id), formData)
            .then((response) => {
                dispatch(filesActions.uploadProfilePhotoSuccess());
                return response.data;
            })
            .catch((error) => {
                dispatch(filesActions.uploadProfilePhotoFailure(error.response.data));
                throw error;
            });
    };
};

export const DeleteProfilePhoto = (fileId) => {
    return async dispatch => {
        dispatch(filesActions.deleteProfilePhotoRequest());
        return axios.delete(urls.getProfileUrl(fileId))
            .then((response) => {
                dispatch(filesActions.deleteProfilePhotoSuccess());
            })
            .catch((error) => {
                dispatch(filesActions.deleteProfilePhotoFailure(error.response.data));
            });
    };
};