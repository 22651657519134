import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from 'ramda';


// Components
// Components: Global
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';
import Container from '@mui/material/Container';

// Components: Common
import { CreateAssetGroup } from "../../../../actions/assetGroup-api";

import * as Loadings from '../../../../constants/loadings/assetGroup';
import * as LoadingsFile from '../../../../constants/loadings/file';
import { initialAssetGroupFormData, initialCompanyForm } from "../../../../constants/utils/formInitialData";
import { onSubmitMultipleValidation, onSubmitValidation, validateTitle } from "../../../../constants/utils/validators";
import { useAutoComplete } from "../../../../hooks/useAutoComplete";
import CompanyModalContent from "./companyModalcontent";
import FormImmeuble from "../info/immeuble";
import { UploadProfilePhoto } from "../../../../actions/file-api";



const AssetGroupModalContent = ({ modalClose, open, theme, newTitle, handleAddNew, newAddress, newCity, newPostalCode, ...others }) => {
    const dispatch = useDispatch();
    const assetGroupState = useSelector(state => state.assetGroup);
    const companyState = useSelector(state => state.company);
    const fileState = useSelector(state => state.file);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [formData, setFormData] = useState(initialAssetGroupFormData);
    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let assetGroups = !R.isNil(assetGroupState.assetGroups) ? assetGroupState.assetGroups : [];
    const companyUseAutocomplete = useAutoComplete([initialCompanyForm], companies, initialCompanyForm);
    const [hasCurrentTitle, setHasCurrentTitle] = useState(null);
    const payload = { items: assetGroups, properties: ["title"], value: formData.title.value, id: null };

    useEffect(() => {
			setFormData({
				...formData,
				title: { ...formData.title, value: newTitle },
				address: { ...formData.address, value: newAddress?.value || newAddress },
				city: { ...formData.city, value: newCity?.value || newCity },
				postalCode: { ...formData.postalCode, value: newPostalCode?.value || newPostalCode },
			});
			// eslint-disable-next-line
		}, [newTitle, newAddress, newCity, newPostalCode]);


    const submitForm = async (event) => {
        const companyFormValidation = onSubmitMultipleValidation(companyUseAutocomplete.dropdownFormData);
        const validatedForm = onSubmitValidation(formData);
        if (validateTitle(payload)) {
            setHasCurrentTitle(true);
            return setTimeout(() => { setHasCurrentTitle(null); }, 4000);
        }
        if (!validatedForm.isValid || !companyFormValidation.isValid) {
            companyUseAutocomplete.setDropdownFormData(companyFormValidation.form);
            setFormData(validatedForm.form);
            return;
        }
        try {
            const assetGroup = {
                title: formData.title.value.trim(),
                companyId: companyUseAutocomplete.dropdownFormData[0].value.trim(),
                address: {
                    addressLine1: formData.address.value.trim(),
                    addressLine2: formData.address.value.trim(),
                    postalCode: formData.postalCode.value.trim(),
                    locality: formData.city.value.trim(),
                    region: 'France',
                    country: 'France'
                },
            };
            const newAssetGroup = await dispatch(CreateAssetGroup(assetGroup));
            if (!R.isNil(profilePhoto)) {
                await dispatch(UploadProfilePhoto({ file: profilePhoto, id: newAssetGroup.id, profile: "AssetGroup" }));
            }
            modalClose();
            handleAddNew({
                companyId: newAssetGroup.companyId,
                id: newAssetGroup.id,
                title: formData.title.value.trim()
            });
            setFormData(initialAssetGroupFormData);
        } catch (error) {
            console.log(error);
            // toast.error("Échec de l'opération, réessayez !", toastConf);
        }
    };


    return (
        <Box {...others}>
            {hasCurrentTitle &&
                <Container maxWidth="100%" sx={{ display: "inherit", justifyContent: "inherit", gap: 2 }}>
                    <Alert sx={{ width: '100%', borderRadius: 3, marginY: 2 }} severity="error">
                        Un immeuble existe déjà avec ce nom. Veuillez le sélectionner ou alors choisir un autre nom d'immeuble.

                    </Alert>
                </Container>
            }
            <DialogTitle variant="h4" color="primary" sx={{ pt: theme.spacing(3), pb: theme.spacing(1) }}>Ajouter Immeuble</DialogTitle>
            <Stack direction="column" spacing={2}>
                <FormImmeuble
                    theme={theme}
                    companies={companies}
                    formData={formData}
                    setFormData={setFormData}
                    profilePhoto={profilePhoto}
                    setProfilePhoto={setProfilePhoto}
                    companyUseAutocomplete={companyUseAutocomplete}
                />
                {companyUseAutocomplete.isOpen &&
                    <Container>
                        <CompanyModalContent
                            modalClose={companyUseAutocomplete.setClose}
                            open={companyUseAutocomplete.isOpen}
                            theme={theme}
                            newCompanyName={companyUseAutocomplete.newOptionName}
                            handleAddNewCompany={companyUseAutocomplete.handleAddNew}
                            sx={{ backgroundColor: "background.dim", borderRadius: 2 }}
                        />
                    </Container>
                }
            </Stack>
            <DialogActions disableSpacing={true} sx={{ flexDirection: "column-reverse", p: theme.spacing(3) }}>
                <Button theme={theme} color="neutral" fullWidth onClick={modalClose} sx={{ px: theme.spacing(3) }}>Annuler</Button>
                <LoadingButton
                    onClick={(event) => submitForm(event)}
                    loading={assetGroupState.isLoading.includes(Loadings.CREATING_ASSETGROUP) || fileState.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
                    disabled={assetGroupState.isLoading.includes(Loadings.CREATING_ASSETGROUP) || fileState.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
                    type="submit" size="large" fullWidth variant="contained" theme={theme} color='primary' sx={{ px: theme.spacing(3), mb: theme.spacing(1) }}>Enregistrer</LoadingButton>
            </DialogActions>
        </Box>
    );
};

export default AssetGroupModalContent;