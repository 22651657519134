import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";
import { useNavigate } from "react-router";

// Components
// Components: Global
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { LoadingButton } from "@mui/lab";

// Components: Common
import FormPayment from "../info/payment";
import { useAutoComplete } from "../../../../hooks/useAutoComplete";
import * as Loadings from "../../../../constants/loadings/payment";
import * as LoadingsFile from "../../../../constants/loadings/file";
import { onSubmitMultipleValidation, onSubmitValidation } from "../../../../constants/utils/validators";
import { CreatePayment, UpdatePayment, GetPayments, GetPayment } from "../../../../actions/payment-api";
import CompanyModalContent from "../modals/companyModalcontent";
import { Dialog } from "@mui/material";
import AssetModalContent from "../modals/assetModalContent";
import TenantModalContent from "../modals/tenantModalContent";
import SubcontractorModalContent from "../modals/subcontractorModalContent";
import { UploadFiles, DeleteFiles } from "../../../../actions/file-api";
import { usePermissionModal } from "../../../../hooks/usePermissionModal";

// Components: Views
export const MainPayment = ({
	theme,
	documents,
	paymentState,
	companies,
	assets,
	tenants,
	subcontractors,
	initialPaymentFormData,
	initialCompanyForm,
	initialTenantForm,
	initialSubcontractorForm,
	initialAssetForm,
	paymentTypeStatus,
	resetPaymentFormData,
	resetCompanyForm,
	resetTenantForm,
	resetSubcontractorForm,
	resetAssetForm,
	companyId,
	id,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const stateFile = useSelector((state) => state.file);
	const [formData, setFormData] = useState(initialPaymentFormData);
	const [paymentType, setPaymentType] = useState(R.isNil(paymentTypeStatus) ? null : paymentTypeStatus ? 1 : 2);
	const [docFiles, setDocFiles] = useState([]);
	const [uploadedFiles, setUploadedFiles] = useState(R.isNil(documents) || R.isEmpty(documents) ? null : documents);
	const { openPermissionModal } = usePermissionModal();

	const companyUseAutocomplete = useAutoComplete(initialCompanyForm, companies, resetCompanyForm);
	const assetUseAutocomplete = useAutoComplete(initialAssetForm, assets, resetAssetForm);
	const tenantUseAutocomplete = useAutoComplete(initialTenantForm, tenants, resetTenantForm);
	const subcontractorUseAutocomplete = useAutoComplete(initialSubcontractorForm, subcontractors, resetSubcontractorForm);

	const selectCompanyByAsset = ({ id, index } = {}) => {
		if (R.isNil(id) || !id) return;
		const companyformDataTemp = R.clone(companyUseAutocomplete.dropdownFormData);
		let companyId = assets.find((a) => a.id === id)?.companyId;
		let foundCompany = companies?.find((c) => c.id === companyId);
		companyformDataTemp[index] = { isRequired: true, error: null, isValid: false, label: foundCompany.title, value: foundCompany.id };
		companyUseAutocomplete.setDropdownFormData(companyformDataTemp);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		let newPayment;
		const validatedForm = onSubmitValidation(formData);
		const companiesFormValidation = onSubmitMultipleValidation(companyUseAutocomplete.dropdownFormData);
		// if (R.isNil(paymentType)) return;
		if (!validatedForm.isValid || !companiesFormValidation.isValid) {
			setFormData(validatedForm.form);
			companyUseAutocomplete.setDropdownFormData(companiesFormValidation.form);
			return;
		}
		try {
			const companyId = companyUseAutocomplete.dropdownFormData[0].value;
			const assetId = assetUseAutocomplete.dropdownFormData[0].value;
			const tenantId = tenantUseAutocomplete.dropdownFormData[0].value;
			const subcontractorId = subcontractorUseAutocomplete.dropdownFormData[0].value;
			const payment = {
				title: formData.title.value.trim(),
				description: R.isEmpty(formData.notes.value) ? null : formData.notes.value.trim(),
				documentAssociationType: 1,
				companyId: companyId,
				assetId: R.isEmpty(assetId) ? null : assetId,
				subcontractorId: R.isEmpty(subcontractorId) ? null : subcontractorId,
				contactId: R.isEmpty(tenantId) ? null : tenantId,
				date: formData.date.value,
				paymentCategory: formData.paymentCategory.value,
				paymentType: formData.paymentType.value,
				transactionNumber: R.isEmpty(formData.transactionNumber.value) ? null : formData.transactionNumber.value.trim(),
				amount: paymentType === 1 ? parseInt(formData.totalTTC.value) : parseInt("-" + formData.totalHT.value),
				amountWithoutTax: R.isEmpty(formData.totalHT.value) ? null : parseInt(formData.totalHT.value),
				isConfirmed: false,
			};
			if (payment.amount >= 0) {
				payment.paymentDeadline = formData.dueDate.value;
			}
			if (R.isNil(id)) {
				newPayment = await dispatch(CreatePayment(payment));
				payment.id = newPayment.id;
			} else {
				payment.id = id;
				newPayment = await dispatch(UpdatePayment(payment));
			}
			if (!R.isEmpty(uploadedFiles) && !R.isNil(uploadedFiles)) {
				let toDelete = paymentState.payment && paymentState.payment.files ? paymentState.payment.files.filter((file) => !uploadedFiles.map((m) => m.id).includes(file.id)) : null;
				if (toDelete.length) {
					await dispatch(DeleteFiles(toDelete, payment.id, "Payment"));
				}
			}
			if (R.isEmpty(uploadedFiles) && paymentState.payment && paymentState.payment.files.length) {
				await dispatch(DeleteFiles(paymentState.payment.files, payment.id, "Payment"));
			}

			if (!R.isEmpty(docFiles)) {
				await dispatch(UploadFiles(docFiles, payment.id, "Payment"));
			}

			//To update paymentState
			dispatch(GetPayments());
			dispatch(GetPayment(payment.companyId, payment.id));

			navigate(`/app/banks/${payment.companyId}`);
		} catch (error) {
			console.log(error);
			// if (error.response.status === 403) return openPermissionModal();
			// toast.error("Échec de l'opération, réessayez !", toastConf);
		}
	};

	return (
		<React.Fragment>
			<Stack spacing={2} onSubmit={handleSubmit} component="form" flex="1 1 auto" width="100%" height="100%" overflow="hidden auto">
				<FormPayment
					formData={formData}
					setFormData={setFormData}
					docFiles={docFiles}
					setDocFiles={setDocFiles}
					uploadedFiles={uploadedFiles}
					setUploadedFiles={setUploadedFiles}
					selectCompanyByAsset={selectCompanyByAsset}
					companyUseAutocomplete={companyUseAutocomplete}
					assetUseAutocomplete={assetUseAutocomplete}
					tenantUseAutocomplete={tenantUseAutocomplete}
					paymentType={paymentType}
					setPaymentType={setPaymentType}
					theme={theme}
					subcontractorUseAutocomplete={subcontractorUseAutocomplete}
					mt={{ md: -2 }}
					companyId={companyId}
				/>
				<Stack direction={{ default: "column", md: "row" }} justifyContent="end" alignItems="center" pb={2} spacing={2}>
					<Container maxWidth="sm" sx={{ display: "inherit", justifyContent: "inherit", flex: "1 1 auto", marginLeft: 0 }}>
						<Typography variant="body2" color="text.secondary">
							Vous pourrez ajouter des documents relatifs à cet élément après l'avoir Enregistré, à l'aide du bouton + en bas de l'écran.
						</Typography>
					</Container>
					<Container sx={{ display: "inherit", justifyContent: "inherit", gap: 2, flex: "0" }}>
						<Button color="text" onClick={() => navigate(-1)}>
							Annuler
						</Button>
						<LoadingButton
							type="submit"
							loading={paymentState.isLoading.includes(Loadings.CREATING_PAYMENT) || paymentState.isLoading.includes(Loadings.UPDATING_PAYMENT) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_FILES)}
							disabled={paymentState.isLoading.includes(Loadings.CREATING_PAYMENT) || paymentState.isLoading.includes(Loadings.UPDATING_PAYMENT) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_FILES)}
							size="large"
							variant="contained"
							color="primary">
							Enregistrer
						</LoadingButton>
					</Container>
				</Stack>
			</Stack>
			<Dialog fullWidth maxWidth="md" onClose={companyUseAutocomplete.setClose} open={companyUseAutocomplete.isOpen} theme={theme}>
				<CompanyModalContent modalClose={companyUseAutocomplete.setClose} open={companyUseAutocomplete.isOpen} theme={theme} newCompanyName={companyUseAutocomplete.newOptionName} handleAddNewCompany={companyUseAutocomplete.handleAddNew} />
			</Dialog>
			<Dialog fullWidth maxWidth="md" onClose={assetUseAutocomplete.setClose} open={assetUseAutocomplete.isOpen} theme={theme}>
				<AssetModalContent modalClose={assetUseAutocomplete.setClose} open={assetUseAutocomplete.isOpen} theme={theme} newTitle={assetUseAutocomplete.newOptionName} handleAddNew={assetUseAutocomplete.handleAddNew} />
			</Dialog>
			<Dialog fullWidth maxWidth="md" onClose={tenantUseAutocomplete.setClose} open={tenantUseAutocomplete.isOpen} theme={theme}>
				<TenantModalContent modalClose={tenantUseAutocomplete.setClose} open={tenantUseAutocomplete.isOpen} theme={theme} newTitle={tenantUseAutocomplete.newOptionName} handleAddNew={tenantUseAutocomplete.handleAddNew} />
			</Dialog>
			<Dialog fullWidth maxWidth="md" onClose={subcontractorUseAutocomplete.setClose} open={subcontractorUseAutocomplete.isOpen} theme={theme}>
				<SubcontractorModalContent modalClose={subcontractorUseAutocomplete.setClose} open={subcontractorUseAutocomplete.isOpen} theme={theme} newTitle={subcontractorUseAutocomplete.newOptionName} handleAddNew={subcontractorUseAutocomplete.handleAddNew} />
			</Dialog>
		</React.Fragment>
	);
};

export default MainPayment;
