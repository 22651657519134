import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import * as R from 'ramda';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";

import { inputTypes } from "../../../../../constants/utils/validators";

import Loading from '../../../../../_common/loading';
import { useNavigate } from "react-router";
import { DeleteAccount } from "../../../../../actions/account-api";
import { LoadingButton } from "@mui/lab";



const ModalDelete = ({ modalCloseProfile, isLoading, isDisabled, item, open, theme }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const submitForm = async (data) => {
        try {
            await dispatch(DeleteAccount(item.companyId, item.id));
            navigate('/app/passwords');
        } catch (error) {
            console.log(error);
            // toast.error("Échec de l'opération, réessayez !", toastConf);
        }

    };
    return (
        R.isNil(item) ?
            <Dialog fullWidth maxWidth="xs" onClose={modalCloseProfile} open={open} theme={theme}>
                <Loading />
            </Dialog>
            :
            <Dialog component="form" onSubmit={handleSubmit(submitForm)} fullWidth maxWidth="xs" onClose={modalCloseProfile} open={open} theme={theme}>
                <DialogTitle variant="h4" color="danger.main" sx={{ pt: theme.spacing(3), pb: theme.spacing(1), lineHeight: 1.2, fontWeight: "normal", letterSpacing: -.5 }}>Effacer <br /><strong color="text.dark">{item.title}</strong> ?</DialogTitle>
                <DialogContent>
                    <DialogContentText variant="body" color="text.dark">
                        Veuillez confirmer cette action. <strong>Cette action NE PEUT PAS être annulée</strong>.
                    </DialogContentText>
                    <Box maxWidth={true} width="100%" style={{ gap: theme.spacing(1) }} mt={2} sx={{ '.MuiInputBase-root': { borderRadius: theme.spacing(4) } }}>
                        {/* <Typography>Veuillez saisir le mot "Supprimer" pour continuer :</Typography> */}
                        <Stack direction="row" spacing={1}>
                            <TextField
                                label='Veuillez saisir le mot "Supprimer" pour continuer.'
                                id="delete"
                                type="text"
                                fullWidth
                                variant="filled"
                                name="name"
                                inputProps={register("name", { required: inputTypes.required.errorMessage, validate: { validate: value => value.toLowerCase() === 'supprimer' || "Cela ne correspond pas" } })}
                                error={!!errors.name}
                                helperText={errors.name ? errors.name.message : ''}
                            />
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions disableSpacing={true} sx={{ flexDirection: "column-reverse", p: theme.spacing(3), pt: 0 }}>
                    <Button theme={theme} color="neutral" fullWidth onClick={modalCloseProfile} sx={{ px: theme.spacing(3) }}>Annuler</Button>
                    <LoadingButton disabled={isDisabled} loading={isLoading} type="submit" size="large" fullWidth variant="contained" theme={theme} color="danger" sx={{ px: theme.spacing(3), mb: theme.spacing(1) }}>Oui! Je veux supprimer</LoadingButton>
                </DialogActions>
            </Dialog>
    );
};

export default ModalDelete;