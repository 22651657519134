import React from 'react';

// Components
// Components: Global
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// Components: Views
import CollaboratorsMenu from './menu';
import CollaboratorsView from './view';

export const Collaborators = ({ theme, userState, companyState, tenantState, assetState, subcontractorState, collaboratorState, documentState, rentalState }) => {
    return (
		<Stack component="section" className="layout" direction={false} mt={{md: -2}} flex="1 1 auto" alignItems="center" width="100%" height="inherit" overflow="hidden auto">
			<Typography component="h1" className="sr-only">Content</Typography>
            <CollaboratorsMenu theme={theme} userState={userState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} documentState={documentState} rentalState={rentalState}/>
            <CollaboratorsView theme={theme} userState={userState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} documentState={documentState} rentalState={rentalState}/>
		</Stack>
    );
}

export default Collaborators;
