import React, { useState } from 'react';
import { useNavigate } from "react-router";
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

// Components
// Components: Global
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Alert, LoadingButton } from '@mui/lab';

// Components: Common
import FormPassword from '../info/password';
import { useAutoComplete } from '../../../../hooks/useAutoComplete';
import { onSubmitMultipleValidation, onSubmitValidation, validateTitle } from '../../../../constants/utils/validators';
import { CreateAccount, UpdateAccount } from '../../../../actions/account-api';
import * as Loadings from '../../../../constants/loadings/account';
import { usePermissionModal } from '../../../../hooks/usePermissionModal';

// Components: Views

export const MainPassword = ({ theme, accountState, accounts, tenants, companies, initialCompanyForm, initialPasswordFormData, initialTenantForm, resetCompanyForm, resetPasswordFormData, resetTenantForm, companyId, id }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState(initialPasswordFormData);
    const [hasCurrentTitle, setHasCurrentTitle] = useState(null);
    const { openPermissionModal } = usePermissionModal();
    const payload = { items: accounts, properties: ["title"], value: formData.title.value, id: id };
    const companyUseAutocomplete = useAutoComplete(initialCompanyForm, companies, resetCompanyForm);
    const tenantUseAutocomplete = useAutoComplete(initialTenantForm, tenants, resetTenantForm);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const validatedForm = onSubmitValidation(formData);
        const validatedCompanyForm = onSubmitMultipleValidation(companyUseAutocomplete.dropdownFormData);
        const validatedTenantForm = onSubmitMultipleValidation(tenantUseAutocomplete.dropdownFormData);
        if (validateTitle(payload)) {
            setHasCurrentTitle(true);
            return setTimeout(() => { setHasCurrentTitle(null); }, 4000);
        }
        if (!validatedForm.isValid || !validatedCompanyForm.isValid || !validatedTenantForm.isValid) {
            setFormData(validatedForm.form);
            companyUseAutocomplete.setDropdownFormData(validatedCompanyForm.form);
            tenantUseAutocomplete.setDropdownFormData(validatedTenantForm.form);
            return;
        }
        try {
            const companyId = companyUseAutocomplete.dropdownFormData[0].value;
            const tenantId = tenantUseAutocomplete.dropdownFormData[0].value;
            const account = {
                contactId: tenantId,
                companyId: companyId,
                title: formData.title.value.trim(),
                url: formData.url.value.trim(),
                userName: formData.userName.value.trim(),
                password: formData.password.value.trim(),
            };
            let newAccount;
            if (R.isNil(id)) {
                newAccount = await dispatch(CreateAccount(account));
                account.id = newAccount.id;
            } else {
                account.id = id;
                newAccount = await dispatch(UpdateAccount(account));
            }
            navigate(`/app/passwords/${account.companyId}/${account.id}`);
        } catch (error) {
            console.log(error);
            // if (error.response.status === 403) return openPermissionModal();
            // toast.error("Échec de l'opération, réessayez !", toastConf);
        }
    };


    return (
        <React.Fragment>
            {hasCurrentTitle &&
                <Container maxWidth="100%" sx={{ display: "inherit", justifyContent: "inherit", gap: 2 }}>
                    <Alert sx={{ width: '100%', borderRadius: 3, marginY: 2 }} severity="error">
                        Un mot de passe existe déjà avec ce nom. Veuillez le sélectionner ou alors choisir un autre nom de mot de passe.
                    </Alert>
                </Container>
            }
            <Stack spacing={2} onSubmit={handleSubmit} component="form" flex="1 1 auto" width="100%" height="100%" overflow="hidden auto">
                <FormPassword
                    companyId={companyId}
                    formData={formData}
                    setFormData={setFormData}
                    companyUseAutocomplete={companyUseAutocomplete}
                    tenantUseAutocomplete={tenantUseAutocomplete}
                    theme={theme}
                    mt={2}
                />
                <Stack direction={{ default: "column", md: "row" }} justifyContent="end" alignItems="center" pb={2} spacing={2}>
                    <Container maxWidth="sm" sx={{ display: "inherit", justifyContent: "inherit", flex: "1 1 auto", marginLeft: 0 }}>
                        <Typography variant="body2" color="text.secondary">Vous pourrez ajouter des documents relatifs à cet élément après l'avoir Enregistré, à l'aide du bouton + en bas de l'écran.</Typography>
                    </Container>
                    <Container sx={{ display: "inherit", justifyContent: "inherit", gap: 2, flex: "0" }}>
                        <Button color="text" onClick={() => navigate(-1)}>Annuler</Button>
                        <LoadingButton
                            loading={accountState.isLoading.includes(Loadings.CREATING_ACCOUNT) || accountState.isLoading.includes(Loadings.UPDATING_ACCOUNT)}
                            disabled={accountState.isLoading.includes(Loadings.CREATING_ACCOUNT) || accountState.isLoading.includes(Loadings.UPDATING_ACCOUNT)}
                            type="submit" size="large" variant="contained" color='primary'>Enregistrer</LoadingButton>
                    </Container>
                </Stack>
            </Stack>
        </React.Fragment>
    );
};

export default MainPassword;