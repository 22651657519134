import React, { useEffect } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import * as R from 'ramda';


// Components
// Components: Global
import Container from '@mui/material/Container';

// Components: Common
import * as AssetGroupLoadings from '../../../../../constants/loadings/assetGroup';
import Loading from '../../../../../_common/loading';
import { useDispatch, useSelector } from 'react-redux';
import { GetAssetGroups } from '../../../../../actions/assetGroup-api';
import ItemCard from '../../../../_common/itemCard';
import AssetsMenuViewList from './list';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import { useInfinitScroll } from '../../../../../hooks/useInfinitScroll';
import { Box, Stack } from '@mui/material';
import { alphabet } from '../../../../../constants/utils/alphabet';
import { RESET_FILTER } from '../../../../../constants/utils/magicVariables';
import { GetCompanies } from '../../../../../actions/company-api';
import { GetAssets } from '../../../../../actions/asset-api';
import { START_QUERY } from '../../../../../constants/utils/filterQuery';



export const AssetsMenuViewBien = ({ theme, value, index }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { page, start } = useSearchParams();
    //const { lastCardRef, cardWrapperRef } = useInfinitScroll(page, assetGroupState.hasMorePage, location);
    const companyState = useSelector((state) => state.company);
    const assetState = useSelector((state) => state.asset);
    const assetGroupState = useSelector((state) => state.assetGroup);
    const { lastCardRef, cardWrapperRef } = useInfinitScroll(page, assetGroupState.hasMorePage, location);


    useEffect(() => {
        if (R.isNil(assetGroupState.assetGroups) || R.isEmpty(assetGroupState.assetGroups) ||
            !R.isEmpty(location.search) || location.state === RESET_FILTER || location.state === "form"
        ) {
            let filter = !R.isNil(start) && { [START_QUERY.title]: start };
            dispatch(GetAssetGroups({ pageNumber: page, filter }));
        }
        // eslint-disable-next-line 
    }, [location.search]);

    useEffect(() => {
        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        // eslint-disable-next-line 
    }, [assetGroupState.assetGroups]);

    useEffect(() => {
        if (R.isNil(assetState.assets)) {
            dispatch(GetAssets());
        }
        // eslint-disable-next-line 
    }, [assetState.assets]);


    return (
        // <TabPanel value={value} index={index} dir={theme.direction} height="inherit">
        <Box display="flex" flex="1 1 auto" py={2} gap={2} pt={{ md: 0 }} height="100%" maxWidth="100%" sx={{ flexDirection: 'column' }}>
            <Container disableGutters maxWidth="100%" sx={{ maxWidth: "100%", height: "100%", display: "inherit", flexDirection: "inherit", overflow: "hidden auto" }}>
                <Stack direction="row" flex="1 1 auto" height="100%" maxWidth="100%" spacing={0}>
                    <Stack direction="column" flex="1 1 auto" height="100%" >
                        <Container ref={cardWrapperRef} maxWidth="100%" sx={{ paddingRight: { sm: '12px' }, maxWidth: "100%", height: "100%", overflow: "hidden auto", display: "inherit", flexDirection: "inherit", gap: 1 }}>
                            {
                               (assetGroupState.isLoading.includes(AssetGroupLoadings.FETCHING_ASSETGROUPS) && (R.isNil(assetGroupState.assetGroups) || R.isNil(assetState.assets))) ?
                                    <Loading />
                                    :
                                    R.isNil(assetGroupState.assetGroups) || R.isNil(assetState.assets) || (!R.isNil(assetGroupState.assetGroups) && R.isEmpty(assetGroupState.assetGroups.filter(asset => asset.isDefault !== true))) ?
                                        <ItemCard /> :
                                        <AssetsMenuViewList 
                                           theme={theme}
                                           assetGroups={assetGroupState.assetGroups}
                                           assets={assetState.assets} 
                                           lastCardRef={lastCardRef} 
                                           companies={companyState.companies} />
                            }
                        </Container>
                    </Stack>
                    <Stack direction="column" flex="0" height="100%">
                        <Container maxWidth="100%" sx={{ paddingX: { sm: '12px' }, maxWidth: "100%", height: "100%", overflow: "hidden auto", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            {alphabet.map((letter) =>
                                <Box fontSize="small" color="text.disabled" state={letter === "#" ? RESET_FILTER : ""} to={letter === "#" ? `${location.pathname}` : `${location.pathname}?start=${letter}`} component={RouterLink} fontWeight="bold" role="button" key={letter} >
                                    {letter}
                                </Box>
                            )}
                        </Container>
                    </Stack>
                </Stack>
            </Container>
        </Box >
        // </TabPanel>
    );
};

export default AssetsMenuViewBien;
