import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';


// Components
// Components: Global
// Components: Common

// Components: Views
// import NewAsset from './asset';
import NewDocument from './document';
import NewTenant from './tenant';
import NewCompany from './company';
import NewAssetGroup from './assetGroup';
import NewCollaborator from './collaborator';
import NewPassword from './password';
import NewContrat from './contrat';
import NewPayment from './payment';
import NewSubcontractor from './subcontractor';
import { useDispatch } from 'react-redux';
import { NewAsset } from './asset';


export const New = ({ theme, userState, companyState, assetGroupState, tenantState, assetState, subcontractorState, collaboratorState, documentState, rentalState, paymentState, accountState }) => {
    const dispatch = useDispatch();

    return (
        <Routes>
            <Route path='/' element={
                // <Navigate to="/app/new/document" />
                <NewDocument
                 theme={theme}
                 //userState={userState}
                assetGroupState={assetGroupState} 
                companyState={companyState}
                tenantState={tenantState}
                assetState={assetState} 
                subcontractorState={subcontractorState} 
                collaboratorState={collaboratorState} 
                documentState={documentState} 
                mt={{ md: -2 }} />
            } />
            <Route path="document" element={
                <NewDocument
                 theme={theme}
                 //userState={userState}
                assetGroupState={assetGroupState} 
                companyState={companyState}
                tenantState={tenantState}
                assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} documentState={documentState} mt={{ md: -2 }} />
            } />
            <Route path="asset" element={
                // <NewAsset theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} />
                <NewAsset 
                theme={theme}
                userState={userState}
                assetGroupState={assetGroupState} 
                companyState={companyState} 
                tenantState={tenantState} 
                assetState={assetState} 
                subcontractorState={subcontractorState} 
                collaboratorState={collaboratorState} />
            } />
            <Route path="tenant" element={
                <NewTenant theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} mt={{ md: -2 }} />
            } />
            <Route path="company" element={
                <NewCompany theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} mt={{ md: -2 }} />
            } />
            <Route path="immeuble" element={
                <NewAssetGroup theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} mt={{ md: -2 }} />
            } />
            <Route path="collaborator" element={
                <NewCollaborator theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} />
            } />
            <Route path="contrat" element={
                <NewContrat theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} rentalState={rentalState} />
            } />
            <Route path="payment" element={
                <NewPayment theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} paymentState={paymentState} />
            } />
            <Route path="password" element={
                <NewPassword theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} accountState={accountState} />
            } />
            <Route path="share" element={
                <Navigate to="/app/new/collaborator" />
            } />
            <Route path="subcontractor" element={
                <NewSubcontractor theme={theme} userState={userState} assetGroupState={assetGroupState} companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} />
            } />
            <Route path="*" element={<Navigate to="/404" />} />
            {/* <Route path="/" element={
                <Navigate replace to="/app/new/document" />
            } /> */}
        </Routes>
    );
};

export default New;