import React from 'react';
import { MuiChipsInput } from "mui-chips-input";

// Components
// Components: Global
import Stack from '@mui/material/Stack';

// Components: Common

// Components: Views

export const FormClefs = ({ theme, value, handleChange, ...others }) => {

    return (
        <Stack direction="column" flex="1 1 auto" spacing={1} width="100%" {...others}>
            <MuiChipsInput
                helperText={""}
                clearInputOnBlur
                value={value}
                onChange={handleChange}
                label="Mots clefs"
                placeholder="Tapez le mot clef et appuyez sur Entrée"

            />
        </Stack>
    );
};

export default FormClefs;
