import React from 'react';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from "@mui/material/TextField";

// Components: Common
import { onChangeValidation } from '../../../../constants/utils/validators';


// Components: Views

export const FormCompany = ({ theme, formData, setFormData, ...others }) => {

    const handleOnChange = (event) => { setFormData(onChangeValidation(event.target, formData)); };


    return (
        <Stack spacing={2} flex="1 1 auto" width="100%" {...others}>
            <Container maxWidth="100%" sx={{ display: "inherit", flexDirection: "inherit", gap: 2 }}>
                <Typography className="sr-only">Company Form</Typography>
                <Stack direction="column" spacing={2}>
                    <TextField
                        label='Société / Nom (si en nom propre) *'
                        id="Société"
                        type="text"
                        fullWidth
                        variant="filled"
                        name="title"
                        error={formData.title.error && !formData.title.isValid}
                        helperText={formData.title.error}
                        value={formData.title.value}
                        onChange={(event) => { handleOnChange(event); }}
                    />
                    <Stack direction={{ default: "column", md: "row" }} spacing={2}>
                        <TextField
                            label='Statut juridique'
                            id="status"
                            type="text"
                            fullWidth
                            variant="filled"
                            name="legalStatus"
                            error={formData.legalStatus.error && !formData.legalStatus.isValid}
                            helperText={formData.legalStatus.error}
                            value={formData.legalStatus.value}
                            onChange={(event) => { handleOnChange(event); }}
                        />
                        <TextField
                            label='Siret'
                            id="siret"
                            type="text"
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            fullWidth
                            variant="filled"
                            name="vatNumber"
                            error={formData.vatNumber.error && !formData.vatNumber.isValid}
                            helperText={formData.vatNumber.error}
                            value={formData.vatNumber.value}
                            onChange={(event) => { handleOnChange(event); }}
                        />
                    </Stack>
                    <Stack direction={{ default: "column", md: "row" }} spacing={2} my={1}>
                        <TextField
                            label='Site Internet'
                            id="url"
                            type="text"
                            fullWidth
                            variant="filled"
                            name="url"
                            error={formData.url.error && !formData.url.isValid}
                            helperText={formData.url.error}
                            value={formData.url.value}
                            onChange={(event) => { handleOnChange(event); }}
                        />
                        <TextField
                            id="bank"
                            label="Banque"
                            type="text"
                            fullWidth
                            variant="filled"
                            // sx={{ '.MuiInputBase-root': { borderRadius: theme.spacing(6) } }}
                            name="bank"
                            error={formData.bank.error && !formData.bank.isValid}
                            helperText={formData.bank.error}
                            value={formData.bank.value}
                            onChange={(event) => { handleOnChange(event); }}
                        />
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
};

export default FormCompany;
