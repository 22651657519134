import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


export const SearchViewEmpty = ({ theme, title, description, color, maxWidth, fontWeight, noBtn, onClick, ...others }) => {
    return (
        <Container maxWidth="sm" sx={{ margin: "auto" }} {...others}>
            <Typography variant="h3" fontWeight={fontWeight ? fontWeight : "medium"} letterSpacing={-1.25} component="h2" color={color ? (color === "primary" || color === "inherit" ? color : color + ".main") : "secondary"} lineHeight="1" mb={1} >{title ? title : 'Rechercher pour commencer.'}</Typography>
            <Typography variant="body1" maxWidth={maxWidth ?  maxWidth : "xs"} mb={4}>{description ? description : "Pour voir les détails d'un résultat et pour réduire la consommation de données, assurez-vous d'en sélectionner un dans la liste."}</Typography>
            {!noBtn && <Button component={RouterLink} to="/app/search" onClick={onClick} variant="contained" color={color ? color : "secondary"}>Commencer</Button>}
        </Container>
    );
};

export default SearchViewEmpty;
