import { useDispatch, useSelector } from 'react-redux';
import { DeletePayment } from '../actions/payment-api';
import * as PaymentLoadings from '../constants/loadings/payment';

export const useDeletePayment = () => {
    const paymentState = useSelector(state => state.payment);
    const dispatch = useDispatch();

    const deletePaymentProp = (payment) => async () => {
        await dispatch(DeletePayment(payment.companyId, payment.id));
    };

    return (
        {
            isLoading: paymentState.isLoading.includes(PaymentLoadings.DELETING_PAYMENT),
            isDisabled: paymentState.isLoading.includes(PaymentLoadings.DELETING_PAYMENT),
            dispatchDelete: deletePaymentProp,
        }
    );
};
