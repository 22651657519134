import React, { useEffect}  from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';


// Components
// Components: Global
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// Components: Common
import { GetSearch, ResetSearch } from '../../actions/search-api';
import * as LoadingsSearch from '../../constants/loadings/search';
import * as LoadingsCollaborator from '../../constants/loadings/collaborator';
import * as LoadingsCompany from '../../constants/loadings/company';
import Loading from '../../_common/loading';

// Components: Views
import SearchViewEmpty from './empty';
import SearchViewDetail from './detail';
import { GetCollaborators } from '../../actions/collaborator-api';
import { GetCompanies } from '../../actions/company-api';

export const Search = ({ theme, searchState, companyState, collaboratorState }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const search = new URLSearchParams(location.search);
    const text = search.get('text');
    const sortBy = search.get('sortBy');
    // const [searchText, setSearchText] = useState(R.isEmpty(text) || R.isNil(text) ? '' : text);


    useEffect(() => {
        const filters = {};
        filters.text = R.isEmpty(text) || R.isNil(text) ? '' : text;
        filters.sortBy = R.isNil(sortBy) || R.isEmpty(sortBy) ? 'Ascending' : sortBy;
        if (text === "Partages d'accès") return;

        if (!R.isNil(text) && !R.isEmpty(text)) {
            dispatch(GetSearch(filters));
        } else {
            dispatch(ResetSearch());
        }
        // eslint-disable-next-line 
    }, [location.search]);

    useEffect(() => {
        if (text !== "Partages d'accès") return;

        if (R.isNil(collaboratorState.collaborators)) {
            dispatch(GetCollaborators());
        }
        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        // eslint-disable-next-line 
    }, [text]);



    return (
        <Box component="section" className="layout" flex="1 1 auto" alignItems="center" width="100%" overflow="hidden auto" height="100%">
            <Typography component="h3" className="sr-only">Search</Typography>
            <Stack direction="row" flex="1 1 auto" overflow="hidden auto" height="100%" maxWidth="100%" width="100%" spacing={0}>
                {searchState.isLoading.includes(LoadingsSearch.FETCHING_SEARCH) || collaboratorState.isLoading.includes(LoadingsCollaborator.FETCHING_COLLABORATORS) || companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES) ?
                    <Loading theme={theme} />
                    :
					text === "Partages d'accès" ?
						<SearchViewDetail theme={theme} results={searchState.results} text={text} /> 
						:
						(R.isNil(searchState.results) || R.isEmpty(searchState.results)) ?
							<SearchViewEmpty theme={theme} noBtn />
							:
							(Object.values(searchState.results).every(r => R.isEmpty(r))) ?
								<SearchViewEmpty theme={theme} onClick={ResetSearch} title={`"` + text + `" ne correspond à aucun résultat!`} description="Veuillez vérifier que le mot est correctement écrit, effacez votre recherche et réessayez..." />
								:
								<SearchViewDetail theme={theme} results={searchState.results} text={text} collaborators={companyState.collaborators} companies={collaboratorState.companies} />
                }
            </Stack>
        </Box>
    );
};

export default Search;