import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from 'ramda';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';
import Container from '@mui/material/Container';

import FormCompany from "../info/company";
import { onSubmitValidation, validateTitle } from "../../../../constants/utils/validators";
import { initialCompanyFormData } from "../../../../constants/utils/formInitialData";
import { CreateCompany } from "../../../../actions/company-api";
import { UpdateUserPermission } from '../../../../actions/user-api';

import * as Loadings from '../../../../constants/loadings/company';
import * as LoadingsFile from '../../../../constants/loadings/file';
import { permissionsTypeMap } from '../../../../constants/utils/objectProperties';





const CompanyModalContent = ({ modalClose, open, theme, newCompanyName, handleAddNewCompany, ...others }) => {
    const dispatch = useDispatch();
    const companyState = useSelector(state => state.company);
    const fileState = useSelector(state => state.file);
    const [formData, setFormData] = useState(initialCompanyFormData);
    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    const [hasCurrentTitle, setHasCurrentTitle] = useState(null);
    const payload = { items: companies, properties: ["title"], value: formData.title.value, id: null };

    useEffect(() => {
        setFormData({ ...formData, title: { ...formData.title, value: newCompanyName } });
        // eslint-disable-next-line 
    }, [newCompanyName]);


    const submitForm = async (event) => {
        const validatedForm = onSubmitValidation(formData);
        if (validateTitle(payload)) {
            setHasCurrentTitle(true);
            return setTimeout(() => { setHasCurrentTitle(null); }, 4000);
        }
        if (!validatedForm.isValid) {
            return setFormData(validatedForm.form);
        }
        try {
            const company = {
                title: formData.title.value.trim(),
                vatNumber: R.isNil(formData.vatNumber.value) || R.isEmpty(formData.vatNumber.value) ? null : formData.vatNumber.value.trim(),
                bankName: R.isNil(formData.bank.value) || R.isEmpty(formData.bank.value) ? null : formData.bank.value.trim(),
                url: R.isNil(formData.url.value) || R.isEmpty(formData.url.value) ? null : formData.url.value.trim(),
                legalStatus: R.isNil(formData.legalStatus.value) || R.isEmpty(formData.legalStatus.value) ? null : formData.legalStatus.value.trim()
            };

            const companyData = await dispatch(CreateCompany(company));
            handleAddNewCompany({ id: companyData.id, title: companyData.title });
            await dispatch(UpdateUserPermission({ companyId: companyData.id, accessTypes: permissionsTypeMap.Admin }));
            modalClose();
            setFormData(initialCompanyFormData);
        } catch (error) {
            console.log(error);
            // toast.error("Échec de l'opération, réessayez !", toastConf);
        }
    };


    return (
        <Box {...others}>
            {/* <Dialog component="form" onSubmit={(event) => submitForm(event)} fullWidth maxWidth="md" onClose={modalClose} open={open} theme={theme}> */}
            <Stack >
                {
                    hasCurrentTitle &&
                    <Container maxWidth="100%" sx={{ display: "inherit", justifyContent: "inherit", gap: 2 }}>
                        <Alert sx={{ width: '100%', borderRadius: 3, marginY: 2 }} severity="error">
                            Une société existe déjà avec ce nom. Veuillez la sélectionner ou alors choisir un autre nom de société.
                        </Alert>
                    </Container>
                }
                <DialogTitle variant="h4" color="primary" sx={{ pt: theme.spacing(3), pb: theme.spacing(1) }}>Ajouter Société</DialogTitle>
                <FormCompany theme={theme} formData={formData} setFormData={setFormData} />
                <DialogActions disableSpacing={true} sx={{ flexDirection: "column-reverse", p: theme.spacing(3) }}>
                    <Button theme={theme} color="neutral" fullWidth onClick={modalClose} sx={{ px: theme.spacing(3) }}>Annuler</Button>
                    <LoadingButton onClick={(event) => submitForm(event)}
                        loading={companyState.isLoading.includes(Loadings.CREATING_COMPANY) || fileState.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
                        disabled={companyState.isLoading.includes(Loadings.CREATING_COMPANY) || fileState.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
                        type="submit" size="large" fullWidth variant="contained" theme={theme} color='primary' sx={{ px: theme.spacing(3), mb: theme.spacing(1) }}>Enregistrer</LoadingButton>
                </DialogActions>
                {/* </Dialog > */}
            </Stack>
        </Box>
    );
};

export default CompanyModalContent;