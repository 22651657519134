import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider, CssBaseline } from '@mui/material';

import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';
import { persistor, store } from './reducers';

// Components
// Components: Common
import theme from './_common/theme';
import Loading from './_common/loading';
import { PrivateRoutes } from './routes';
// import { PersistGate } from 'redux-persist/integration/react';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react'
// import NoMatch from './_common/noMatch';

// // Components: Views
// import { App } from './app';
// import { Auth } from './auth';
// import Authentication from './constants/utils/auth';

const container = document.getElementById('root');
const root = createRoot(container);

root.render( 
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<Loading theme={theme} />}>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <CssBaseline />

                    <h1 className="sr-only">Magestimmo App</h1>
                    <PrivateRoutes />
                </ThemeProvider>
            </BrowserRouter>
        </Suspense>
      </PersistGate>
    </Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
