import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import { useLocation } from 'react-router';


// Components: Common
import * as LoadingsDocument from '../../constants/loadings/document';
import * as LoadingsCompany from '../../constants/loadings/company';
import * as LoadingsTenant from '../../constants/loadings/tenant';
import * as LoadingsAssetGroup from '../../constants/loadings/assetGroup';
import * as LoadingsSubcontractor from '../../constants/loadings/subcontractor';
import * as LoadingsAsset from '../../constants/loadings/asset';
import { initialAssetForm, initialAssetGroupForm, initialCompanyForm, initialDocumentFormData, initialSubcontractorForm, initialTenantForm } from '../../constants/utils/formInitialData';
import { GetCompanies } from '../../actions/company-api';
import { GetAssets } from '../../actions/asset-api';
import { GetTenants } from '../../actions/tenant-api';
import { GetAssetGroups } from '../../actions/assetGroup-api';
import { GetSubcontractors } from '../../actions/subcontractor-api';
import Loading from '../../_common/loading';
import MainDocument from '../_common/forms/layout/document';
import { GetDocuments } from '../../actions/document-api';
import { documentsActions } from "../../reducers/document";

// Components: Views

export const NewDocument = ({ theme, companyState, assetState, assetGroupState, tenantState, subcontractorState, documentState }) => {
    
    const dispatch = useDispatch();
    const location = useLocation();
    const search = new URLSearchParams(location.search);
    const query = {
        companyId: search.get("companyId"),
        assetId: search.get("assetId"),
        tenantId: search.get("tenantId"),
        assetGroupId: search.get("assetGroupId"),
        subcontractorId: search.get("subcontractorId"),
    };

    useEffect(() => {
        dispatch(documentsActions.clearDocument());

        if (R.isNil(documentState.documents)) {
            dispatch(GetDocuments());
        }
        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        if (R.isNil(assetState.assets)) {
            dispatch(GetAssets());
        }
        if (R.isNil(assetGroupState.assetGroups)) {
            dispatch(GetAssetGroups());
        }
        if (R.isNil(tenantState.tenants)) {
            dispatch(GetTenants());
        }
        if (R.isNil(subcontractorState.subcontractors)) {
            dispatch(GetSubcontractors());
        }
        // eslint-disable-next-line 
    }, []);

    if (documentState.isLoading.includes(LoadingsDocument.FETCHING_DOCUMENTS) ||
        tenantState.isLoading.includes(LoadingsTenant.FETCHING_TENANTS) ||
        assetState.isLoading.includes(LoadingsAsset.FETCHING_ASSETS) ||
        subcontractorState.isLoading.includes(LoadingsSubcontractor.FETCHING_SUBCONTRACTORS) ||
        assetGroupState.isLoading.includes(LoadingsAssetGroup.FETCHING_ASSETGROUPS) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)) {
        return <Loading theme={theme} />;
    }

    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let documents = !R.isNil(documentState.documents) ? documentState.documents : [];
    let assets = !R.isNil(assetState.assets) ? assetState.assets : [];
    let assetGroups = !R.isNil(assetGroupState.assetGroups) ? assetGroupState.assetGroups.filter(ag => !ag.isDefault) : [];
    let tenants = !R.isNil(tenantState.tenants) ? tenantState.tenants.map(t => ({ ...t, title: t.firstName + " " + t.lastName })) : [];
    let subcontractors = !R.isNil(subcontractorState.subcontractors) ? subcontractorState.subcontractors.map(s => ({ ...s, title: s.firstName + " " + s.lastName })) : [];

    const foundCompany = companies.find(c => c.id === query.companyId);
    const foundAsset = assets.find(c => c.id === query.assetId);
    const foundAssetGroup = assetGroups.find(c => c.id === query.assetGroupId);
    const foundTenant = tenants.find(c => c.id === query.tenantId);
    const foundSubcontractor = subcontractors.find(c => c.id === query.subcontractorId);

    const tempInitialCompanyForm = R.clone(initialCompanyForm);
    const tempInitialAssetForm = R.clone(initialAssetForm);
    const tempInitialAssetGroupForm = R.clone(initialAssetGroupForm);
    const tempInitialTenantForm = R.clone(initialTenantForm);
    const tempInitialSubcontractorForm = R.clone(initialSubcontractorForm);

    if (!R.isNil(query.companyId) && !R.isNil(foundCompany)) {
        tempInitialCompanyForm.value = query.companyId;
        tempInitialCompanyForm.label = foundCompany.title;
    }
    if (!R.isNil(query.assetId) && !R.isNil(foundAsset)) {
        tempInitialAssetForm.value = query.assetId;
        tempInitialAssetForm.label = foundAsset.title;
    }
    if (!R.isNil(query.assetGroupId) && !R.isNil(foundAssetGroup)) {
        tempInitialAssetGroupForm.value = query.assetGroupId;
        tempInitialAssetGroupForm.label = foundAssetGroup.title;
    }
    if (!R.isNil(query.tenantId) && !R.isNil(foundTenant)) {
        tempInitialTenantForm.value = query.tenantId;
        tempInitialTenantForm.label = foundTenant.title;
    }
    if (!R.isNil(query.subcontractorId) && !R.isNil(foundSubcontractor)) {
        tempInitialSubcontractorForm.value = query.subcontractorId;
        tempInitialSubcontractorForm.label = foundSubcontractor.title;
    }


    return (
        <MainDocument
            theme={theme}
            documentState={documentState}
            documents={documents}
            companies={companies}
            assets={assets}
            assetGroups={assetGroups}
            tenants={tenants}
            subcontractors={subcontractors}
            initialDocumentFormData={initialDocumentFormData}
            initialCompanyForm={[tempInitialCompanyForm]}
            initialAssetForm={[tempInitialAssetForm]}
            initialAssetGroupForm={[tempInitialAssetGroupForm]}
            initialTenantForm={[tempInitialTenantForm]}
            initialSubcontractorForm={[tempInitialSubcontractorForm]}
            // initialCompanyForm={[initialCompanyForm]}
            // initialAssetForm={[initialAssetForm]}
            // initialAssetGroupForm={[initialAssetGroupForm]}
            // initialTenantForm={[initialTenantForm]}
            // initialSubcontractorForm={[initialSubcontractorForm]}
            resetDocumentFormData={initialDocumentFormData}
            resetCompanyForm={initialCompanyForm}
            resetAssetForm={initialAssetForm}
            resetAssetGroupForm={initialAssetGroupForm}
            resetTenantForm={initialTenantForm}
            resetSubcontractorForm={initialSubcontractorForm}
        />
    );
};

export default NewDocument;