export const setRentalType = (rental) => {
    if (!rental.startDate) {
        return 1;
    }
    else if (!!rental.departureDate) {
        return 3;
    }
    else {
        return 2;
    }
};

export const getPermissionType = (accesses = []) => {
    if (accesses.includes('Admin')) {
        return 'Admin';
    } else if (accesses.includes('Delete')) {
        return 'Delete';
    } else if (accesses.includes('Update')) {
        return 'Update';
    } else if (accesses.includes('Create')) {
        return 'Create';
    }
    return 'Read';
};