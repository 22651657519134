import axios from 'axios';
import { searchActions } from "../reducers/search";
import * as urls from '../constants/webapi/search';


export const GetSearch = (filters) => {
    return async dispatch => {
        dispatch(searchActions.getSearchRequest());
        return axios.post(urls.getSearchUrl(), filters)
            .then((response) => {
                dispatch(searchActions.getSearchSuccess(response.data));
            })
            .catch((error) => {
                dispatch(searchActions.getSearchFailure(error.response.data));
            });
    };
};
export const ResetSearch = () => {
    return async dispatch => {
        return dispatch(searchActions.resetSearchSuccess());
    };
};
