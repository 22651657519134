const shadows = [
    'none',
    '0px 2px 3px rgba(40,49,66, 0.10)',
    '0px 7px 30px 0px rgba(40,49,66, 0.11)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 2px 2px -2px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 3px 4px -2px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 3px 4px -2px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 4px 6px -2px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 4px 6px -2px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 4px 8px -2px rgba(40,49,66, 0.25)',
    '1px 2px 10px rgba(40,49,66, 0.08)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 6px 12px -4px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 7px 12px -4px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 6px 16px -4px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 7px 16px -4px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 8px 18px -8px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 9px 18px -8px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 10px 20px -8px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 11px 20px -8px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 12px 22px -8px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 13px 22px -8px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 14px 24px -8px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 16px 28px -8px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 18px 30px -8px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 20px 32px -8px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 22px 34px -8px rgba(40,49,66, 0.25)',
    '0 0 1px 0 rgba(40,49,66, 0.31), 0 24px 36px -8px rgba(40,49,66, 0.25)',
  ];
  
  export default shadows;
  