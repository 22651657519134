export const initialCompanyFormData = {
    title: { name: 'title', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    legalStatus: { name: 'legalStatus', isRequired: false, value: '', type: 'text', isValid: false, error: null },
    vatNumber: { name: 'vatNumber', isRequired: false, value: '', type: 'text', isValid: false, error: null },
    url: { name: 'url', isRequired: false, value: '', type: 'text', isValid: false, error: null },
    bank: { name: 'bank', isRequired: false, value: '', type: 'text', isValid: false, error: null },
};
export const initialCollaboratorFormData = {
    firstName: { name: 'firstName', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    lastName: { name: 'lastName', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    email: { name: 'email', isRequired: true, value: '', type: 'email', isValid: false, error: null },
    profileUrl: { name: 'profileUrl', isRequired: false, value: '', type: 'email', isValid: false, error: null },
};
export const initialAssetGroupFormData = {
    title: { name: 'title', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    address: { name: 'lastName', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    city: { name: 'city', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    postalCode: { name: 'postalCode', isRequired: true, value: '', type: 'number', isValid: false, error: null },
};


export const initialTenantFormData = {
    firstName: { name: 'firstName', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    lastName: { name: 'lastName', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    profession: { name: 'profession', isRequired: false, value: '', type: 'text', isValid: false, error: null },
    social: { name: 'social', isRequired: false, value: '', type: 'text', isValid: false, error: null },
    email: { name: 'email', isRequired: false, value: '', type: 'email', isValid: false, error: null },
    phone: { name: 'phone', isRequired: false, value: '', type: 'number', isValid: false, error: null },
};

export const initialSubcontractorFormData = {
    firstName: { name: 'firstName', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    lastName: { name: 'lastName', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    profession: { name: 'profession', isRequired: false, value: '', type: 'text', isValid: false, error: null },
    social: { name: 'social', isRequired: false, value: '', type: 'text', isValid: false, error: null },
    email: { name: 'email', isRequired: false, value: '', type: 'email', isValid: false, error: null },
    phone: { name: 'phone', isRequired: false, value: '', type: 'number', isValid: false, error: null },
};


export const initialAssetFormData = {
    title: { name: 'title', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    wifi: { name: 'wifi', isRequired: false, value: '', type: 'text', isValid: false, error: null },
    address: { name: 'address', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    postalCode: { name: 'postalCode', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    city: { name: 'city', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    locationType: { name: 'locationType', isRequired: true, value: 1, type: 'text', isValid: false, error: null },
    status: { name: 'status', isRequired: true, value: 1, type: 'text', isValid: false, error: null },
    notes: { name: 'notes', isRequired: false, value: '', type: 'text', isValid: false, error: null },
    tags: { name: 'tags', isRequired: false, value: [], type: 'text', isValid: false, error: null },
};

export const initialPasswordFormData = {
    title: { name: 'title', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    userName: { name: 'userName', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    password: { name: 'password', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    url: { name: 'url', isRequired: true, value: '', type: 'text', isValid: false, error: null },
};

export const initialDocumentFormData = {
    date: { name: 'date', isRequired: true, value: new Date().toISOString(), type: 'text', isValid: false, error: null },
    title: { name: 'title', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    type: { name: 'type', isRequired: true, value: 1, type: 'number', isValid: false, error: null },
    notes: { name: 'notes', isRequired: false, value: '', type: 'text', isValid: false, error: null },
    tags: { name: 'tags', isRequired: false, value: [], type: 'text', isValid: false, error: null },
};

export const initialPaymentFormData = {
    date: { name: 'date', isRequired: true, value: new Date().toISOString(), type: 'text', isValid: false, error: null },
    title: { name: 'title', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    dueDate: { name: 'date', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    paymentCategory: { name: 'paymentCategory', isRequired: true, value: 1, type: 'text', isValid: false, error: null },
    paymentType: { name: 'paymentType', isRequired: true, value: 1, type: 'text', isValid: false, error: null },
    transactionNumber: { name: 'transactionNumber', isRequired: false, value: '', type: 'number', isValid: false, error: null },
    totalHT: { name: 'totalHT', isRequired: true, value: "", type: 'number', isValid: false, error: null },
    totalTTC: { name: 'totalTTC', isRequired: true, value: "", type: 'number', isValid: false, error: null },
    // total: { name: 'total', isRequired: true, value: "", type: 'number', isValid: false, error: null },
    notes: { name: 'notes', isRequired: false, value: '', type: 'text', isValid: false, error: null },
};

export const initialContractFormData = {
    date: { name: 'date', isRequired: true, value: new Date().toISOString(), type: 'text', isValid: false, error: null },
    title: { name: 'title', isRequired: true, value: '', type: 'text', isValid: false, error: null },
    firstName: { name: 'firstName', isRequired: false, value: '', type: 'text', isValid: false, error: null },
    lastName: { name: 'lastName', isRequired: false, value: '', type: 'text', isValid: false, error: null },
    caution: { name: 'caution', isRequired: true, value: '', type: 'number', isValid: false, error: null },
    rentPriceWithoutTax: { name: 'rentPriceWithoutTax', isRequired: true, value: '', type: 'number', isValid: false, error: null },
    monthlyCharges: { name: 'monthlyCharges', isRequired: false, value: '', type: 'number', isValid: false, error: null },
    warranty: { name: 'warranty', isRequired: false, value: "", type: 'text', isValid: false, error: null },
    iban: { name: 'iban', isRequired: false, value: "", type: 'text', isValid: false, error: null },
    startDate: { name: 'startDate', isRequired: false, value: "", type: 'text', isValid: false, error: null },
    endDate: { name: 'endDate', isRequired: false, value: "", type: 'text', isValid: false, error: null },
    paymentDate: { name: 'paymentDate', isRequired: false, value: '', type: 'text', isValid: false, error: null },
    paymentType: { name: 'paymentType', isRequired: false, value: 1, type: 'text', isValid: false, error: null },
    numbersOfKeys: { name: 'numbersOfKeys', isRequired: false, value: '', type: 'number', isValid: false, error: null },
    departureDate: { name: 'departureDate', isRequired: false, value: '', type: 'text', isValid: false, error: null },
    address: { name: 'address', isRequired: false, value: '', type: 'text', isValid: false, error: null },
    phone: { name: 'phone', isRequired: false, value: '', type: 'text', isValid: false, error: null },
    notes: { name: 'notes', isRequired: false, value: '', type: 'text', isValid: false, error: null },
    tags: { name: 'tags', isRequired: false, value: [], type: 'text', isValid: false, error: null },
};


export const initialCompanyForm = { name: 'company', isRequired: true, value: '', label: '', type: 'text', isValid: false, error: null };
export const initialAssetGroupForm = { name: 'assetGroup', isRequired: false, value: '', label: '', type: 'text', isValid: false, error: null };
export const initialAssetForm = { name: 'asset', isRequired: false, value: '', label: '', type: 'text', isValid: false, error: null };
export const initialRentalForm = { name: 'rental', isRequired: false, value: '', label: '', type: 'text', isValid: false, error: null };
export const initialTenantForm = { name: 'tenant', isRequired: false, value: '', label: '', type: 'text', isValid: false, error: null };
export const initialSubcontractorForm = { name: 'subcontractor', isRequired: false, value: '', label: '', type: 'text', isValid: false, error: null };



