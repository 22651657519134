import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";

// Components
// Components: Global
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Alert, LoadingButton } from "@mui/lab";

// Components: Common
import FormAsset from "../info//asset";
import { useAutoComplete } from "../../../../hooks/useAutoComplete";
import * as Loadings from "../../../../constants/loadings/asset";
import * as LoadingsFile from "../../../../constants/loadings/file";
import { onSubmitMultipleValidation, onSubmitValidation, validateTitle } from "../../../../constants/utils/validators";
import { CreateAsset, UpdateAsset, GetDashboardAssets } from "../../../../actions/asset-api";
import { Dialog } from "@mui/material";
import CompanyModalContent from "../modals/companyModalcontent";
import AssetGroupModelContent from "../modals/assetGroupModalContent";
import { UploadFiles, UploadProfilePhoto, DeleteFiles } from "../../../../actions/file-api";
import { usePermissionModal } from "../../../../hooks/usePermissionModal";

// Components: Views

export const MainAsset = ({ theme, assetState, photo, assetGroups, assets, companies, initialAssetFormData, initialCompanyForm, initialAssetGroupForm, resetCompanyForm, resetAssetGroupForm, asset, documents, companyId, id }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const stateFile = useSelector((state) => state.file);
	const [docFiles, setDocFiles] = useState([]);
	const [uploadedFiles, setUploadedFiles] = useState(R.isNil(documents) || R.isEmpty(documents) ? null : documents);
	const [currentPhoto, setCurrentPhoto] = useState(!R.isNil(photo) ? photo : null);
	const [profilePhoto, setProfilePhoto] = useState(null);
	const [formData, setFormData] = useState(initialAssetFormData);
	const companyUseAutocomplete = useAutoComplete(initialCompanyForm, companies, resetCompanyForm);
	const assetGroupUseAutocomplete = useAutoComplete(initialAssetGroupForm, assetGroups, resetAssetGroupForm);
	const { openPermissionModal } = usePermissionModal();
	const [hasCurrentTitle, setHasCurrentTitle] = useState(null);
	const payload = { items: assets, properties: ["title"], value: formData.title.value, id: id };

	const selectCompany = ({ id, index = 0 } = {}) => {
		if (R.isNil(id) || !id) return;
		const companyformDataTemp = R.clone(companyUseAutocomplete.dropdownFormData);
		let companyId = assetGroups.find((a) => a.id === id)?.companyId;
		let foundCompany = companies?.find((c) => c.id === companyId);
		companyformDataTemp[index] = { isRequired: true, error: null, isValid: false, label: foundCompany.title, value: foundCompany.id };
		companyUseAutocomplete.setDropdownFormData(companyformDataTemp);
	};

	const selectCompanyById = ({ companyId, index = 0 } = {}) => {
		if (R.isNil(companyId) || !companyId) return;
		const companyformDataTemp = R.clone(companyUseAutocomplete.dropdownFormData);
		let foundCompany = companies?.find((c) => c.id === companyId);
		companyformDataTemp[index] = { isRequired: true, error: null, isValid: false, label: foundCompany.title, value: foundCompany.id };
		companyUseAutocomplete.setDropdownFormData(companyformDataTemp);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		let newAsset;
		const validatedForm = onSubmitValidation(formData);
		const validatedCompanyForm = onSubmitMultipleValidation(companyUseAutocomplete.dropdownFormData);
		if (validateTitle(payload)) {
			setHasCurrentTitle(true);
			return setTimeout(() => {
				setHasCurrentTitle(null);
			}, 4000);
		}
		if (!validatedForm.isValid || !validatedCompanyForm.isValid) {
			setFormData(validatedForm.form);
			companyUseAutocomplete.setDropdownFormData(validatedCompanyForm.form);
			return;
		}
		try {
			const assetGroupId = assetGroupUseAutocomplete.dropdownFormData[0].value;
			const companyId = companyUseAutocomplete.dropdownFormData[0].value;
			const assetModel = {
				title: formData.title.value.trim(),
				companyId: companyId,
				assetGroupId: R.isEmpty(assetGroupId) ? null : assetGroupId,
				assetType: formData.locationType.value,
				assetState: formData.status.value,
				description: R.isEmpty(formData.notes.value) ? null : formData.notes.value.trim(),
				files: [],
				address: {
					addressLine1: formData.address.value.trim(),
					addressLine2: formData.address.value.trim(),
					postalCode: formData.postalCode.value.trim(),
					locality: formData.city.value.trim(),
					region: "France",
					country: "France",
				},
				assetFields: !R.isEmpty(formData.wifi.value) ? [{ name: "wifi", value: formData.wifi.value.trim() }] : [],
				keywords: R.isEmpty(formData.tags.value) ? null : formData.tags.value.join(","),
			};
			if (R.isNil(id)) {
				newAsset = await dispatch(CreateAsset(assetModel));
				assetModel.id = newAsset.id;
			} else {
				assetModel.id = id;
				assetModel.addressId = asset.addressId;
				assetModel.address.id = asset.addressId;
				assetModel.createdOn = asset.createdOn;
				assetModel.modifiedOn = new Date().toISOString();
				newAsset = await dispatch(UpdateAsset(assetModel));
			}
			if (!R.isNil(profilePhoto)) {
				await dispatch(UploadProfilePhoto({ file: profilePhoto, id: assetModel.id, profile: "Asset" }));
			}
			if (!R.isEmpty(uploadedFiles) && !R.isNil(uploadedFiles)) {
				let toDelete = asset && asset.files ? asset.files.filter((file) => !uploadedFiles.map((m) => m.id).includes(file.id)) : null;
				if (toDelete) {
					await dispatch(DeleteFiles(toDelete, assetModel.id, "Asset"));
				}
			}
			if (R.isEmpty(uploadedFiles) && asset.files.length) {
				await dispatch(DeleteFiles(asset.files, assetModel.id, "Asset"));
			}
			if (!R.isEmpty(docFiles)) {
				await dispatch(UploadFiles(docFiles, assetModel.id, "Asset"));
			}

			dispatch(GetDashboardAssets());
			navigate(`/app/assets/${assetModel.companyId}/${assetModel.id}`, { state: "form" });
		} catch (error) { 
			// if (error.response.status === 403) return openPermissionModal();
			// if (error.response.status === 422) return;
			// toast.error("Échec de l'opération, réessayez !", toastConf);
		}
	};

	return (
		<React.Fragment>
			{hasCurrentTitle && (
				<Container maxWidth="100%" sx={{ display: "inherit", justifyContent: "inherit", gap: 2 }}>
					<Alert sx={{ width: "100%", borderRadius: 3, marginY: 2 }} severity="error">
						Un bien existe déjà avec ce nom. Veuillez le sélectionner ou alors choisir un autre nom de bien.
					</Alert>
				</Container>
			)}
			<Stack spacing={2} onSubmit={handleSubmit} component="form" flex="1 1 auto" width="100%" height="100%" overflow="hidden auto">
				<Stack spacing={2} flex="1 1 auto" width="100%">
					<FormAsset
						companyId={companyId}
						selectCompany={selectCompany}
						docFiles={docFiles}
						setDocFiles={setDocFiles}
						profilePhoto={profilePhoto}
						uploadedFiles={uploadedFiles}
						setUploadedFiles={setUploadedFiles}
						setProfilePhoto={setProfilePhoto}
						formdata={formData}
						setFormData={setFormData}
						currentPhoto={currentPhoto}
						assetGroupUseAutocomplete={assetGroupUseAutocomplete}
						companyUseAutocomplete={companyUseAutocomplete}
						theme={theme}
						mt={{ md: -2 }}
					/>
				</Stack>
				<Stack direction={{ default: "column", md: "row" }} justifyContent="end" alignItems="center" pb={2} spacing={2}>
					<Container maxWidth="sm" sx={{ display: "inherit", justifyContent: "inherit", flex: "1 1 auto", marginLeft: 0 }}>
						<Typography variant="body2" color="text.secondary">
							Vous pourrez ajouter des documents relatifs à cet élément après l'avoir Enregistré, à l'aide du bouton + en bas de l'écran.
						</Typography>
					</Container>
					<Container sx={{ display: "inherit", justifyContent: "inherit", gap: 2, flex: "0" }}>
						<Button color="text" onClick={() => navigate(-1)}>
							Annuler
						</Button>
						<LoadingButton
							type="submit"
							loading={assetState.isLoading.includes(Loadings.CREATING_ASSET) || assetState.isLoading.includes(Loadings.UPDATING_ASSET) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_FILES) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
							disabled={assetState.isLoading.includes(Loadings.CREATING_ASSET) || assetState.isLoading.includes(Loadings.UPDATING_ASSET) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_FILES) || stateFile.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
							size="large"
							variant="contained"
							color="primary">
							Enregistrer
						</LoadingButton>
					</Container>
				</Stack>
			</Stack>
			<Dialog fullWidth maxWidth="md" onClose={companyUseAutocomplete.setClose} open={companyUseAutocomplete.isOpen} theme={theme}>
				<CompanyModalContent modalClose={companyUseAutocomplete.setClose} open={companyUseAutocomplete.isOpen} theme={theme} newCompanyName={companyUseAutocomplete.newOptionName} handleAddNewCompany={companyUseAutocomplete.handleAddNew} />
			</Dialog>
			<Dialog fullWidth maxWidth="md" onClose={assetGroupUseAutocomplete.setClose} open={assetGroupUseAutocomplete.isOpen} theme={theme}>
				<AssetGroupModelContent
					modalClose={assetGroupUseAutocomplete.setClose}
					open={assetGroupUseAutocomplete.isOpen}
					theme={theme}
					newTitle={assetGroupUseAutocomplete.newOptionName}
					newAddress={formData.address}
					newCity={formData.city}
					newPostalCode={formData.postalCode}
					handleAddNew={R.compose(selectCompanyById, assetGroupUseAutocomplete.handleAddNew)}
				/>
			</Dialog>
		</React.Fragment>
	);
};

export default MainAsset;
