import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

// Components
// Components: Common
import NoMatch from '../_common/noMatch';

// Components: Views
import { Validation } from './validation';
import Login from './login';
import Registry from './registry';
import Oublie from './oublie';
import Reset from './reset';


export const Auth = () => {

    return (
        <Routes>
            <Route path="/:token" element={
                <Reset/>
            } />
            <Route index path="/validate/:token" element={
                <Validation/>
            } />
            <Route index path="/login" element={
                <Login/>
            } />
            <Route path="/registry" element={
                <Registry />
            } />
            <Route path="/forget" element={
                <Oublie />
            } />
            <Route path="404" element={<NoMatch />} />
            <Route path="*" element={<Navigate to="/404" />} />
            <Route path="/" element={
                <Navigate replace to="/auth/login" />
            } />
        </Routes>
    );
};

export default Auth;
