import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import typography from './typography';
import breakpoints from './breakpoints';
import components from './components';
import shadows from './shadows';
import palette from './palette';

const theme = createTheme({
	direction: 'ltr',
	shape: {
		borderRadius: 5,
	},
	status: {
		danger: '#e53e3e',
	},
	spacing: 8,
	components,
	breakpoints,
	typography,
	palette,
	shadows,
});

export default responsiveFontSizes(theme);