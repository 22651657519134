import * as consts from './constants';

const palette = {
    primary: {
        main: consts.primaryMain,
        light: consts.primaryLight,
        dark: consts.primaryDark,
        contrastText: consts.primaryContrastText,
        dim: 'rgba(93,139,222,0.075)',
    },
    secondary: {
        main: consts.secondaryMain,
        light: consts.secondaryLight,
        dark: consts.secondaryDark,
        contrastText: consts.secondaryContrastText,
        dim: 'rgba(189,89,185,0.075)',
    },
    accent: {
        main: consts.accentMain,
        // light: consts.accentLight,
        // dark: consts.accentDark,
        // contrastText: consts.accentContrastText,
    },
    success: {
        main: consts.successMain,
        contrastText: consts.primaryContrastText,
    },
    info: {
        main: consts.infoMain,
        contrastText: consts.primaryContrastText,
        dim: 'rgba(11, 178, 251,0.095)',
    },
    warning: {
        main: consts.warningMain,
        contrastText: consts.primaryContrastText,
    },
    danger: {
        main: '#e46a76',
        contrastText: consts.primaryContrastText,
    },
    background: {
        light:  consts.backgroundLight,
        dim: consts.backgroundDim,
        stage: consts.backgroundStage,
        default: consts.backgroundDefault,
        paper: consts.backgroundPaper,
        empty: consts.backgroundEmpty,
        placeholder: consts.backgroundPlaceholder,
        avatar: consts.backgroundAvatar,
        master: consts.backgroundMaster,
        main: consts.backgroundMain,
    },
    text: {
        primary: consts.textPrimary,
        dark: consts.textDark,
        light: consts.textLight,
        contrastText: consts.primaryContrastText,
		// others
        main: consts.textMain,
        secondary: consts.textSecondary,
        muted: consts.textMuted,
        hint: consts.textHint,
        disabled: consts.textDisabled,
        dim: consts.textDim,
        empty: consts.textEmpty,
    },
    grey: {
        main: 'rgba(73,82,88,0.1)',
        A100: '#e8e9ec',
        A200: '#ccced5',
        A300: '#bbbec7',
        A400: '#9499a7',
        A500: '#767e89',
        A700: '#5c616f',
        A800: '#0d0e10',
    },
    light: {
        main: 'rgba(255,255,255,0.08)',
        hoverOpacity: 0.02,
        hover: 'rgba(255, 255, 255, 0.03)',
    },
    action: {
        contrastText: consts.primaryContrastText,
        main: 'rgba(73,82,88,0.08)',
        disabledBackground: 'rgba(73,82,88,0.12)',
        hoverOpacity: 0.02,
        hover: 'rgba(73,82,88, 0.03)',
    },
    active: {
        main: consts.primaryMain,
        light: consts.primaryLight,
        dark: consts.textDark,
        contrastText: consts.primaryContrastText,
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
};
  
export default palette;