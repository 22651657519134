
export const FETCHING_ASSETS = 'FETCHING_ASSETS';
export const FETCHING_ASSET = 'FETCHING_ASSET';
export const CREATING_ASSET = 'CREATING_ASSET';
export const UPDATING_ASSET = 'UPDATING_ASSET';
export const DELETING_ASSET = 'DELETING_ASSET';

export const FETCHING_SOLD_ASSETS = 'FETCHING_SOLD_ASSETS';
export const FETCHING_SOLD_ASSET = 'FETCHING_SOLD_ASSET';
export const CREATING_SOLD_ASSET = 'CREATING_SOLD_ASSET';
export const UPDATING_SOLD_ASSET = 'UPDATING_SOLD_ASSET';
export const DELETING_SOLD_ASSET = 'DELETING_SOLD_ASSET';