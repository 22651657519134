import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

// Components: Common
import * as LoadingsCompany from '../../constants/loadings/company';
import * as LoadingsTenant from '../../constants/loadings/tenant';
import * as LoadingsRental from '../../constants/loadings/rental';
import * as LoadingsAsset from '../../constants/loadings/asset';
import { GetCompanies } from '../../actions/company-api';
import { GetAssets } from '../../actions/asset-api';
import { GetTenants } from '../../actions/tenant-api';
import { GetRental, GetRentals } from '../../actions/rental-api';
import Loading from '../../_common/loading';
import { initialAssetForm, initialCompanyForm, initialContractFormData, initialTenantForm } from '../../constants/utils/formInitialData';
import { MainContrat } from '../_common/forms/layout/contract';
import { useParams } from 'react-router';



export const NewContrat = ({ theme, companyState, assetState, tenantState, rentalState }) => {
    const dispatch = useDispatch();
    const { companyId, id } = useParams();

    useEffect(() => {
        dispatch(GetRental(companyId, id));

        if (R.isNil(companyState.companies)) {
            dispatch(GetCompanies());
        }
        if (R.isNil(assetState.assets)) {
            dispatch(GetAssets());
        }
        if (R.isNil(tenantState.tenants)) {
            dispatch(GetTenants());
        }
        if (R.isNil(rentalState.rentals)) {
            dispatch(GetRentals());
        }
        // eslint-disable-next-line 
    }, []);

    if (tenantState.isLoading.includes(LoadingsTenant.FETCHING_TENANTS) ||
        assetState.isLoading.includes(LoadingsAsset.FETCHING_ASSETS) ||
        rentalState.isLoading.includes(LoadingsRental.FETCHING_RENTALS) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)) {
        return <Loading theme={theme} />;
    }

    if (R.isNil(rentalState.rental) || R.isEmpty(rentalState.rental) ||
        R.isNil(companyState.companies) ||
        R.isNil(tenantState.tenants) ||
        R.isNil(assetState.assets)) {
        return '';
    }

    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    let assets = !R.isNil(assetState.assets) ? assetState.assets : [];
    let tenants = !R.isNil(tenantState.tenants) ? tenantState.tenants.map(t => ({ ...t, title: t.firstName + " " + t.lastName })) : [];

    const tempInitialContractFormData = R.clone(initialContractFormData);
    tempInitialContractFormData.date.value = !R.isNil(rentalState.rental.date) && !R.isEmpty(rentalState.rental.date) ? rentalState.rental.date : "";
    tempInitialContractFormData.title.value = !R.isNil(rentalState.rental.title) && !R.isEmpty(rentalState.rental.title) ? rentalState.rental.title : "";
    tempInitialContractFormData.firstName.value = !R.isNil(rentalState.rental.firstNameCaution) && !R.isEmpty(rentalState.rental.firstNameCaution) ? rentalState.rental.firstNameCaution : "";
    tempInitialContractFormData.lastName.value = !R.isNil(rentalState.rental.lastNameCaution) && !R.isEmpty(rentalState.rental.lastNameCaution) ? rentalState.rental.lastNameCaution : "";
    tempInitialContractFormData.caution.value = !R.isNil(rentalState.rental.caution) && !R.isEmpty(rentalState.rental.caution) ? rentalState.rental.caution : "";
    tempInitialContractFormData.rentPriceWithoutTax.value = !R.isNil(rentalState.rental.rentPriceWithoutTax) && !R.isEmpty(rentalState.rental.rentPriceWithoutTax) ? rentalState.rental.rentPriceWithoutTax : "";
    tempInitialContractFormData.monthlyCharges.value = !R.isNil(rentalState.rental.monthlyCharges) && !R.isEmpty(rentalState.rental.monthlyCharges) ? rentalState.rental.monthlyCharges : "";
    tempInitialContractFormData.warranty.value = !R.isNil(rentalState.rental.garant) && !R.isEmpty(rentalState.rental.garant) ? rentalState.rental.garant : "";
    tempInitialContractFormData.iban.value = !R.isNil(rentalState.rental.iban) && !R.isEmpty(rentalState.rental.iban) ? rentalState.rental.iban : "";
    tempInitialContractFormData.startDate.value = !R.isNil(rentalState.rental.startDate) && !R.isEmpty(rentalState.rental.startDate) ? rentalState.rental.startDate : "";
    tempInitialContractFormData.endDate.value = !R.isNil(rentalState.rental.endDate) && !R.isEmpty(rentalState.rental.endDate) ? rentalState.rental.endDate : "";
    tempInitialContractFormData.paymentDate.value = !R.isNil(rentalState.rental.paymentDay) && !R.isEmpty(rentalState.rental.paymentDay) ? rentalState.rental.paymentDay : "";
    tempInitialContractFormData.paymentType.value = !R.isNil(rentalState.rental.paymentType) && !R.isEmpty(rentalState.rental.paymentType) ? rentalState.rental.paymentType : "";
    tempInitialContractFormData.numbersOfKeys.value = !R.isNil(rentalState.rental.numberOfKeys) && !R.isEmpty(rentalState.rental.numberOfKeys) ? rentalState.rental.numberOfKeys : "";
    tempInitialContractFormData.departureDate.value = !R.isNil(rentalState.rental.departureDate) && !R.isEmpty(rentalState.rental.departureDate) ? rentalState.rental.departureDate : "";
    tempInitialContractFormData.address.value = !R.isNil(rentalState.rental.emergencyAddress) && !R.isEmpty(rentalState.rental.emergencyAddress) ? rentalState.rental.emergencyAddress : "";
    tempInitialContractFormData.phone.value = !R.isNil(rentalState.rental.emergencyPhone) && !R.isEmpty(rentalState.rental.emergencyPhone) ? rentalState.rental.emergencyPhone : "";
    tempInitialContractFormData.notes.value = !R.isNil(rentalState.rental.description) && !R.isEmpty(rentalState.rental.description) ? rentalState.rental.description : "";
    tempInitialContractFormData.tags.value = !R.isNil(rentalState.rental.keywords) && !R.isEmpty(rentalState.rental.keywords) ? rentalState.rental.keywords.split(",") : [];

    const tempInitialCompanyForm = [R.clone(initialCompanyForm)];
    const tempInitialAssetForm = [R.clone({ ...initialAssetForm, isRequired: true })];
    const tempInitialTenantForm = [R.clone(initialTenantForm)];
    const companyTitle = companies.find(c => c.id === rentalState.rental.companyId)?.title;
    const assetTitle = assets.find(c => c.id === rentalState.rental.assetId)?.title;
    const tenantName = tenants.find(c => c.id === rentalState.rental.contactId);
    tempInitialCompanyForm[0].value = rentalState.rental.companyId;
    tempInitialCompanyForm[0].label = !R.isNil(companyTitle) ? companyTitle : "";
    tempInitialAssetForm[0].value = !R.isNil(rentalState.rental.assetId) ? rentalState.rental.assetId : "";
    tempInitialAssetForm[0].label = !R.isNil(assetTitle) ? assetTitle : "";
    tempInitialTenantForm[0].value = !R.isNil(rentalState.rental.contactId) ? rentalState.rental.contactId : "";
    tempInitialTenantForm[0].label = !R.isNil(tenantName) ? `${tenantName.firstName} ${tenantName.lastName}` : "";


    return (
        <MainContrat
            theme={theme}
            companies={companies}
            assets={assets}
            tenants={tenants}
            rentalState={rentalState}
            documents={rentalState.rental.files}
            initialContractFormData={tempInitialContractFormData}
            initialCompanyForm={tempInitialCompanyForm}
            initialAssetForm={tempInitialAssetForm}
            initialTenantForm={tempInitialTenantForm}
            resetCompanyForm={initialCompanyForm}
            resetAssetForm={{ ...initialAssetForm, isRequired: true }}
            resetTenantForm={initialTenantForm}
            companyId={companyId}
            id={id}
        />
    );
};

export default NewContrat;