import React, { useEffect, useState } from "react";
import { Link as RouterLink, useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import * as R from "ramda";

// Components
// Components: Global
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import HouseIcon from "@mui/icons-material/House";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Components: Common
import { GetAssets } from "../../../../actions/asset-api";
import { GetRentals } from "../../../../actions/rental-api";
import { GetDocuments } from "../../../../actions/document-api";
import { DeleteAssetGroup, GetAssetGroup } from "../../../../actions/assetGroup-api";
import { GetCompanies } from "../../../../actions/company-api";
import * as AssetGroupLoadings from "../../../../constants/loadings/assetGroup";
import { dayMonthYear } from "../../../../constants/utils/date";
import Loading from "../../../../_common/loading";
import ItemCard from "../../../_common/itemCard";
import AssetsViewDetailEmpty from "../empty";

// Components: Views
import AssetsViewDetailHeader from "./_common/header";
import ModalDelete from "../../../_common/modalDelete";
import DocumentRow from "../../../_common/documentRow";
import { useDeleteDocument } from "../../../../hooks/useDeleteDocument";
import { EQUAL_QUERY } from "../../../../constants/utils/filterQuery";
import { usePermissionModal } from "../../../../hooks/usePermissionModal";
import { useTheme } from '@mui/material/styles';

export const AssetsViewDetailImmeubles = ({ companyState, tenantState, assetState, subcontractorState, collaboratorState, assetGroupState, documentState, rentalState }) => {
	const theme = useTheme()
	const { companyId, id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const showView = !pathname.endsWith("/immeubles");
	const [open, setOpen] = useState(false);
	const [openDeleteDoc, setOpenDeleteDoc] = useState(false);
	const [assetsExpanded, setAssetsExpanded] = useState("panel-assets", true);
	const [docsExpanded, setDocsExpanded] = useState("panel-docs", true);
	const { dispatchDelete, isLoading, isDisabled } = useDeleteDocument();
	const { openPermissionModal } = usePermissionModal();
	const userState = useSelector((state) => state.user);

	useEffect(() => {
		if (R.isNil(companyState.companies)) {
			dispatch(GetCompanies());
		}
		if (R.isNil(rentalState.rentals)) {
			dispatch(GetRentals());
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		dispatch(GetAssetGroup(companyId, id));
		dispatch(GetAssets({ filter: { [EQUAL_QUERY.assetGroupId]: id } }));
		dispatch(GetDocuments({ filter: { [EQUAL_QUERY.assetGroupId]: id } }));
		// dispatch(GetOccurrences({ occurrenceType: 'assetGroup', companyId, occurrenceTypeId: id }));
		// eslint-disable-next-line
	}, [companyId, id]);

	const deleteAssetProp = async () => {
		await dispatch(DeleteAssetGroup(assetGroupState.assetGroup.companyId, assetGroupState.assetGroup.id));
		navigate("/app/assets/immeubles");
	};

	const permissions = userState.user.companyAccesses.find((c) => c.companyId === companyId)?.accessTypes || [];
	const handleAccordionChange = (panel) => (event, isExpanded) => {
		switch (panel) {
			default:
			case "panel-assets": {
				setAssetsExpanded(isExpanded ? panel : false);
				break;
			}
			case "panel-docs": {
				setDocsExpanded(isExpanded ? panel : false);
				break;
			}
		}
	};

	return (
		<Box
			display={{ default: showView ? "flex" : "none", lg: "flex" }}
			flexDirection="column"
			height="100%"
			width="100%"
			overflow="hidden auto"
			backgroundColor={theme.palette.background.default}
			sx={{
				borderRadius: 2,
				position: { default: showView && "fixed", lg: "relative" },
				left: { default: showView && 0, lg: "unset" },
				right: { default: showView && 0, lg: "unset" },
				top: { default: showView && "5rem", lg: "unset" },
				height: { default: showView && "calc(100% - 5rem)", lg: "inherit" },
				bottom: { default: showView && 0, lg: "unset" },
				zIndex: { default: showView && 2, lg: "unset" },
			}}>
			{assetGroupState.isLoading.includes(AssetGroupLoadings.FETCHING_ASSETGROUP) ? (
				<Loading />
			) : (
				<React.Fragment>
					<AssetsViewDetailHeader
						permissions={permissions}
						openPermissionModal={openPermissionModal}
						isImmeuble
						title="Immeuble"
						theme={theme}
						modalOpenDelete={() => {
							setOpen(true);
						}}
						open={open}
						userState={userState}
						companyId={companyId}
						companyState={companyState}
						assetState={assetState}
						assetGroupState={assetGroupState}
						tenantState={tenantState}
						subcontractorState={subcontractorState}
						collaboratorState={collaboratorState}
					/>
					{R.isNil(assetGroupState.assetGroup) || R.isEmpty(assetGroupState.assetGroup) ? (
						<AssetsViewDetailEmpty title="Ooops..." description="Quelque chose s'est mal passée. On dirait que cet immeuble n'existe pas !" />
					) : (
						<Container maxWidth="100%" sx={{ height: "100%" }}>
							<Stack maxWidth="100%" my={3} direction="column" spacing={4}>
								<Accordion sx={{ backgroundColor: "transparent", boxShadow: "none", border: "none", p: 0, ":before": { backgroundColor: "inherit" } }} disableGutters expanded={assetsExpanded === "panel-assets"} onChange={handleAccordionChange("panel-assets")}>
									<AccordionSummary sx={{ minHeight: "auto", ".MuiAccordionSummary-content": { my: 1 }, p: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
										<Typography variant="h6" sx={{ lineHeight: 1 }}>
											Biens
										</Typography>
									</AccordionSummary>
									<AccordionDetails sx={{ p: 0 }}>
										<Stack maxWidth="100%" direction="column" spacing={1}>
											{!R.isNil(assetState.assets) && !R.isEmpty(assetState.assets) && !R.isNil(assetState.assets.assetsFilter) && (
												<Stack direction="column" spacing={1}>
													{assetState.assetsFilter
														.filter((i) => i.assetGroupId === assetGroupState.assetGroup.id)
														.map((asset) => (
															<ItemCard alignItems="top" to={`/app/assets/${asset.companyId}/${asset.id}`} component={RouterLink} key={asset.id}>
																<Avatar variant="circular" sx={{ bgcolor: "grey.A200", my: 0.25, width: "3rem", height: "3rem", fontWeight: "bold" }} children={asset.title[0]} src={!R.isNil(asset.profileUrl) ? asset.profileUrl?.url : null} />
																<Stack direction="column" flex="1 1 auto" width="100%" gap={0.5}>
																	<Stack direction="row" flex="1 1 auto" gap={2} width="100%">
																		<Typography flex="1 1 auto" variant="text" fontWeight="bold" sx={{ wordBreak: "break-all" }} color="text.dark">
																			{asset.title}
																		</Typography>
																		{!R.isNil(asset.assetState) && asset.assetState === 3 && (
																			<Typography fontWeight="bold" color="info.main" variant="caption" textTransform="uppercase">
																				Vendu
																			</Typography>
																		)}
																	</Stack>
																	<Stack direction={{ lg: "row", lg: "column", xl: "row" }} gap={0.5} flex="1 1 auto" width="100%">
																		<Stack direction="row" gap={0.5} flex="1 1 auto" width="100%">
																			<HouseIcon flex="1 0 auto" fontSize="small" sx={{ lineHeight: "1" }} />
																			<Typography flex="1 1 auto" color="text.main" variant="body2" sx={{ wordBreak: "break-all", letterSpacing: -0.3 }}>
																				{!R.isNil(companyState.companies) && !R.isEmpty(companyState.companies) ? companyState.companies.find((c) => c.id === asset.companyId).title : "N/D"}
																			</Typography>
																		</Stack>
																		<Typography flex="1 0 auto" textAlign={{ lg: "right", lg: "left", xl: "right" }} color="text.secondary" variant="body2" fontStyle="italic" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" maxWidth="sm">
																			{!R.isNil(asset.modifiedOn) ? format(new Date(asset.modifiedOn), dayMonthYear) : "DD/MM/YYYY"}
																		</Typography>
																	</Stack>
																</Stack>
															</ItemCard>
														))}
												</Stack>
											)}
											{permissions.includes("Create") ? (
												<Button fullWidth component={RouterLink} to={`/app/new/contrat?assetGroupId=${assetGroupState.assetGroup.id}&companyId=${assetGroupState.assetGroup.companyId}&origin=assetGroupDetail`} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
													Ajouter un Contrat de location
												</Button>
											) : (
												<Button fullWidth onClick={openPermissionModal} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
													Ajouter un Contrat de location
												</Button>
											)}
										</Stack>
									</AccordionDetails>
								</Accordion>
								<Divider />
								<Accordion sx={{ backgroundColor: "transparent", boxShadow: "none", border: "none", p: 0, ":before": { backgroundColor: "inherit" } }} disableGutters expanded={docsExpanded === "panel-docs"} onChange={handleAccordionChange("panel-docs")}>
									<AccordionSummary sx={{ minHeight: "auto", ".MuiAccordionSummary-content": { my: 1 }, p: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
										<Stack direction="row" spacing={0} justifyContent="space-between">
											<Typography variant="h6" sx={{ lineHeight: 1 }}>
												Documents
											</Typography>
										</Stack>
									</AccordionSummary>
									<AccordionDetails sx={{ p: 0 }}>
										<Stack maxWidth="100%" direction="column" spacing={1}>
											{!R.isNil(documentState.documents) && (
												<Stack direction="column" spacing={1}>
													{documentState.documents
														.filter((r) => r.assetGroupId === assetGroupState.assetGroup.id && r.documentType === "Document")
														.map((document) => (
															<DocumentRow permissions={permissions} openPermissionModal={openPermissionModal} key={document.id} setOpenDeleteDoc={setOpenDeleteDoc} document={document} theme={theme} />
														))}
												</Stack>
											)}
											{permissions.includes("Create") ? (
												<Button fullWidth component={RouterLink} to={`/app/new/document?assetGroupId=${assetGroupState.assetGroup.id}&companyId=${assetGroupState.assetGroup.companyId}&origin=assetGroupDetail`} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
													Ajouter un document
												</Button>
											) : (
												<Button fullWidth onClick={openPermissionModal} variant="contained" color="primary" sx={{ boxShadow: "none" }}>
													Ajouter un document
												</Button>
											)}
										</Stack>
									</AccordionDetails>
								</Accordion>
							</Stack>
						</Container>
					)}
					<ModalDelete
						theme={theme}
						item={assetGroupState.assetGroup}
						isLoading={assetGroupState.isLoading.includes(AssetGroupLoadings.DELETING_ASSETGROUP)}
						isDisabled={assetGroupState.isLoading.includes(AssetGroupLoadings.DELETING_ASSETGROUP)}
						isImmeuble
						type="assetGroup"
						dispatchDelete={deleteAssetProp}
						assetGroupState={assetGroupState}
						modalClose={() => {
							setOpen(false);
						}}
						open={open}
					/>
					<ModalDelete
						theme={theme}
						item={documentState.documents?.find((d) => d.id === openDeleteDoc)}
						type="document"
						isLoading={isLoading}
						isDisabled={isDisabled}
						dispatchDelete={dispatchDelete(openDeleteDoc)}
						modalClose={() => {
							setOpenDeleteDoc(null);
						}}
						open={!!openDeleteDoc}
					/>
				</React.Fragment>
			)}
		</Box>
	);
};

export default AssetsViewDetailImmeubles;
