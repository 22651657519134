

export const BASE_URL = process.env.REACT_APP_APIURL;

;
export const getUserUrl = () => `${BASE_URL}User`;
export const getUserByIdUrl = (userId) => `${BASE_URL}User/${userId}`;
export const getUserGoogleAuthUrl = () => `${BASE_URL}User/google/auth`;
export const getUserGoogleResponseUrl = () => `${BASE_URL}User/google/auth/response`;
export const getUserGoogleProvidersUrl = () => `${BASE_URL}User/external/providers`;
export const getUserGoogleRevokesUrl = () => `${BASE_URL}User/external/providers/google/revoke`;
