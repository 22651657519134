import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as R from "ramda";
import { toast } from "react-toastify";

// Components
// Components: Global
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";

import StarIcon from "@mui/icons-material/Star";
import GroupsIcon from "@mui/icons-material/Groups";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LinkIcon from "@mui/icons-material/Link";
import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import KeyIcon from "@mui/icons-material/Key";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DescriptionIcon from "@mui/icons-material/Description";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import SubjectIcon from '@mui/icons-material/Subject';

import EditIcon from "@mui/icons-material/Edit";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PolicyIcon from '@mui/icons-material/Policy';

// Components: Common
import Auth from "../../constants/utils/auth";
import { toastConf } from "../../constants/utils/toastConfig";
import { GetTenants } from "../../actions/tenant-api";
import { GetAssets } from "../../actions/asset-api";
import { GetAssetGroups } from "../../actions/assetGroup-api";
import { GetCompanies } from "../../actions/company-api";

// Components: Views
import ModalEditProfile from "./modalEditProfile";
import ModalIntegrations from "./modalIntegrations";
import ModalAbonnement from "./modalAbonnement";
import ModalExportConfirmation from "./modalExportConfirmation";

import { GetAvailableServices } from '../../actions/user-api';

export const Menu = ({ theme, userState, assetState, tenantState, assetGroupState, companyState, fileState, subscriptionState }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const [openProfile, setOpenProfile] = useState(false);
	const [openIntegrations, setOpenIntegrations] = useState(false);
	const [openAbonnement, setOpenAbonnement] = useState(false);
	const [openExportConfirmation, setOpenExportConfirmation] = useState(false);
	const user = userState.user;

	const modalOpenProfile = () => {
		setOpenProfile(true);
	};
	const modalCloseProfile = () => {
		setOpenProfile(false);
	};

	const modalOpenAbonnement = () => {
		setOpenAbonnement(true);
	};
	const modalCloseAbonnement = () => {
		setOpenAbonnement(false);
		navigate("/app/menu");
	};

	const modalOpenIntegrations = () => {
		setOpenIntegrations(true);
	};
	const modalCloseIntegrations = () => {
		setOpenIntegrations(false);
	};

	const modalOpenExportConfirmation = () => {
		setOpenExportConfirmation(true);
	};
	const modalCloseExportConfirmation = () => {
		setOpenExportConfirmation(false);
	};

	const handleLogout = () => {
		Auth.clearAccessToken();
		window.location.replace("/auth/login");
	};

	useEffect(() => {
		const queries = new URLSearchParams(location.search);
		if (queries.get("integration") === "google") {
			if (queries.get("status")?.toLowerCase() === "failed") {
				toast.error("Échec de l'opération, réessayez plus tard !", toastConf);
				navigate("/app/menu");
				return;
			}
			setOpenIntegrations(true);
		}
		if (queries.get("abonnement")) {
			setOpenAbonnement(true);
		}
		// eslint-disable-next-line
	}, [location.search]);

	useEffect(() => {
		if (R.isNil(userState.services)) {
			dispatch(GetAvailableServices());
		}
		if (R.isNil(tenantState.tenants)) {
			dispatch(GetTenants());
		}
		if (R.isNil(assetState.assets)) {
			dispatch(GetAssets());
		}
		if (R.isNil(companyState.companies)) {
			dispatch(GetCompanies());
		}
		if (R.isNil(assetGroupState.assetGroups)) {
			dispatch(GetAssetGroups());
		}
		// eslint-disable-next-line
	}, []);

	return (
		<React.Fragment>
			<Box component="section" flex="1 1 auto" alignItems="center" width="100%" mt={{ md: -2 }} mb={2}>
				<Card sx={{ overflow: "unset", padding: 3, borderTopLeftRadius: 0, borderTopRightRadius: 0, width: "100%", boxShadow: theme.shadows[1] }}>
					<Container maxWidth="100%" spacing={0} sx={{ alignItems: "center", justifyContent: "space-evenly", flexDirection: "column" }}>
						<Avatar
							sx={{
								color: "secondary.contrastText",
								backgroundColor: R.isNil(user.profileUrl?.url) ? "secondary.main" : "background.paper",
								width: "7rem",
								height: "7rem",
								fontSize: "2rem",
								fontWeight: "bold",
								margin: "1rem auto 0",
								border: user.profileUrl?.url && ".125rem solid" + theme.palette.secondary.main,
								padding: !R.isNil(user.profileUrl?.url) && ".25rem",
							}}
							imgProps={{ sx: { borderRadius: "inherit" } }}
							children={!R.isNil(user.title) ? user.title.charAt(0) : user.firstName.charAt(0) + user.lastName.charAt(0)}
							srcSet={!R.isNil(user.profileUrl?.url) ? user.profileUrl?.url : null}
						/>
						<Stack direction="column" alignItems="center" textAlign="center" pt={3}>
							<Typography variant="h5">
								{user.firstName} {user.lastName}
							</Typography>
							<Typography variant="body1" fontStyle="italic" color={theme.palette.text.secondary}>
								Profil proprietaire
							</Typography>
							<Stack direction={{ default: "column", xs: "row" }} spacing={1} alignItems="center" mt={2}>
								<Typography variant="body1">
									Biens: <strong>{!R.isNil(assetState.assets) && !R.isNil(assetGroupState.assetGroups) ? assetState.assets.length + assetGroupState.assetGroups.length : "--"}</strong>
								</Typography>
								<Typography variant="h6" component="p" px={2} color={theme.palette.text.hint}>
									•
								</Typography>
								<Typography variant="body1">
									Locataires: <strong>{!R.isNil(tenantState.tenants) ? tenantState.tenants.length : "--"}</strong>
								</Typography>
								<Typography variant="h6" component="p" px={2} color={theme.palette.text.hint}>
									•
								</Typography>
								<Typography variant="body1">
									Sociétés: <strong>{!R.isNil(companyState.companies) ? companyState.companies.length : "--"}</strong>
								</Typography>
							</Stack>
							<Stack direction={{ default: "column", xs: "row" }} spacing={1} alignItems="center">
								<Typography variant="body1">
									Rôle: <strong>Admin</strong>
								</Typography>
								<Typography variant="h6" component="p" px={2} color={theme.palette.text.hint}>
									•
								</Typography>
								<Typography variant="body1">
									Abonnement: <strong>{!R.isNil(subscriptionState.subscription?.tierName) ? subscriptionState.subscription?.tierName : ""}</strong>
								</Typography>
							</Stack>
						</Stack>
					</Container>
				</Card>
				<Container maxWidth="100%">
					<Stack direction={{ xs: "column", lg: "row" }} spacing={0} style={{ gap: theme.spacing(1) }} my={5} alignItems="center" justifyContent="center" flexWrap="wrap">
						<Stack direction="row" spacing={1} alignItems="center" justifyContent="center" flexWrap="wrap">
							<Button variant="card" color="active" component={RouterLink} to="/app/assets" state="menu" sx={{ width: "8.5rem", height: "8.5rem" }}>
								<ApartmentIcon fontSize="large" />
								<Typography variant="body1" lineHeight={1.2}>
									Biens
								</Typography>
							</Button>
							<Button variant="card" color="active" component={RouterLink} to="/app/tenants" state="menu" sx={{ width: "8.5rem", height: "8.5rem" }}>
								<GroupsIcon fontSize="large" />
								<Typography variant="body1" lineHeight={1.2}>
									Locataires
								</Typography>
							</Button>
							<Button variant="card" color="active" component={RouterLink} to="/app/companies" state="menu" sx={{ width: "8.5rem", height: "8.5rem" }}>
								<AccountBalanceWalletIcon fontSize="large" />
								<Typography variant="body1" lineHeight={1.2}>
									Comptabilités / Sociétés
								</Typography>
							</Button>
						</Stack>
						<Stack direction="row" spacing={1} alignItems="center" justifyContent="center" flexWrap="wrap">
							<Button variant="card" color="active" component={RouterLink} to="/app/contrats" state="menu" sx={{ width: "8.5rem", height: "8.5rem" }}>
								<DescriptionIcon fontSize="large" />
								<Typography variant="body1" lineHeight={1.2}>
									Contrats de location
								</Typography>
							</Button>
							<Button variant="card" color="primary" component={RouterLink} to="/app/banks" state="menu" sx={{ width: "8.5rem", height: "8.5rem" }}>
								<AccountBalanceIcon fontSize="large" />
								<Typography variant="body1" lineHeight={1.2}>
									Mouvements bancaires
								</Typography>
							</Button>
							<Button variant="card" color="primary" component={RouterLink} to="/app/documents" state="menu" sx={{ width: "8.5rem", height: "8.5rem" }}>
								<SubjectIcon fontSize="large" />
								<Typography variant="body1" lineHeight={1.2}>
									Documents
								</Typography>
							</Button>
						</Stack>
						<Stack direction="row" spacing={1} alignItems="center" justifyContent="center" flexWrap="wrap">
							<Button variant="card" color="active" component={RouterLink} to="/app/collaborators" sx={{ width: "8.5rem", height: "8.5rem" }}>
								<AssignmentIndIcon fontSize="large" />
								<Typography variant="body1" lineHeight={1.2}>
									Collaborateurs
								</Typography>
							</Button>
							<Button variant="card" color="active" component={RouterLink} to="/app/subcontractors" state="menu" sx={{ width: "8.5rem", height: "8.5rem" }}>
								<ListAltIcon fontSize="large" />
								<Typography variant="body1" lineHeight={1.2}>
									Sous-traitants
								</Typography>
							</Button>
							<Tooltip disableFocusListener title="Liste de mots de passes">
								<Button variant="card" color="active" component={RouterLink} to="/app/passwords" state="menu" sx={{ width: "8.5rem", height: "8.5rem" }}>
									<KeyIcon fontSize="large" />
									<Typography variant="body1" lineHeight={1.2}>
										Mots de passes
									</Typography>
								</Button>
							</Tooltip>

							{/* <Button variant="card" color="active" component={RouterLink} to="/app/search?text=Partages%20d%27accès" state="menu" sx={{ width: '8.5rem', height: '8.5rem' }}>
                                <CastIcon fontSize="large" />
                                <Typography variant="body1" lineHeight={1.2}>Partages d'accès</Typography>
                            </Button> */}
							{/* <Button variant="card" color="" component={RouterLink} to="/app/integrations" state="menu" sx={{ width: '8.5rem', height: '8.5rem' }}>
								<LinkIcon fontSize="large" />
								<Typography variant="body1" lineHeight={1.2}>Intégrations</Typography>
							</Button> */}
						</Stack>
						{/* <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" flexWrap="wrap">
							<Button variant="contained" color="contained" component={RouterLink} to="/app/share" state="menu" sx={{ width: '8.5rem', height: '8.5rem' }}>
								<CastIcon fontSize="large" />
								<Typography variant="body1" lineHeight={1.2}>Partages d'accès</Typography>
							</Button>
						</Stack> */}
					</Stack>
					<Box component="article" width="100%" mt="auto" mb={2}>
						<Box component="header" position="relative" width="100%" mt="auto" mb={1}>
							<Typography variant="body1" fontWeight="bold" component="h3" color="primary" lineHeight="1.2" backgroundColor={theme.palette.background.default} sx={{ position: "relative", display: "inline", zIndex: "1", paddingRight: 1 }}>
								Paramètre application
							</Typography>
							<Divider sx={{ position: "absolute", left: 0, right: 0, top: "50%" }} />
						</Box>
						<Button onClick={modalOpenAbonnement} variant="text" color="info" sx={{ marginLeft: 0, paddingX: 2, bgcolor: "info.dim" }} startIcon={<StarIcon color="info" />}>
							Abonnement Magestimmo {!R.isNil(subscriptionState.subscription?.tierName) ? subscriptionState.subscription?.tierName : ""}
						</Button>
						<br />
						<Button onClick={modalOpenProfile} variant="text" color="inherit" size="inherit" startIcon={<EditIcon color="primary" />}>
							Modifier mon profil
						</Button>
						<br />
						<Button onClick={modalOpenIntegrations} variant="text" color="inherit" size="inherit" startIcon={<LinkIcon color="primary" />}>
							Intégrations
						</Button>
						{!R.isNil(userState.services) && !R.isEmpty(userState.services) && userState.services.find((s) => s.type === "GoogleDrive")?.isConnected && (
							<Button onClick={modalOpenExportConfirmation} variant="text" color="inherit" size="inherit" startIcon={<AddToDriveIcon color="primary" />}>
								Exportation
							</Button>
						)}
						<br />
						<Button component="a" target="_blank" href="https://www.magestimmo.com/index.php/conditions-generales-dutilisation" variant="text" color="inherit" size="inherit" startIcon={<PolicyIcon color="primary" />}>
							Conditions generales d'utilisation
						</Button>
					</Box>
					<Button
						variant="text"
						color="inherit"
						onClick={() => {
							handleLogout();
						}}
						endIcon={<ExitToAppIcon color="secondary" />}>
						Se Déconnecter
					</Button>
				</Container>
			</Box>
			<ModalAbonnement theme={theme} userState={userState} modalClose={modalCloseAbonnement} open={openAbonnement} />
			<ModalIntegrations theme={theme} userState={userState} modalClose={modalCloseIntegrations} open={openIntegrations} />
			<ModalEditProfile fileState={fileState} theme={theme} userState={userState} modalClose={modalCloseProfile} open={openProfile} />
			<ModalExportConfirmation theme={theme} companyState={companyState} modalClose={modalCloseExportConfirmation} open={openExportConfirmation} />
		</React.Fragment>
	);
};

export default Menu;
