export const filterQuery = {
    companyId: "CompanyId",
    assetId: "AssetId",
    assetGroupId: "AssetGroupId",
    tenantId: "ContactId",
    firstName: "FirstName",
    lastName: "LastName",
    title: "Title",
    firstOrLastName: "(FirstName|LastName)"
};
export const EQUAL_QUERY = {
    companyId: filterQuery.companyId + "=",
    assetId: filterQuery.assetId + "=",
    tenantId: filterQuery.tenantId + "=",
    assetGroupId: filterQuery.assetGroupId + "=",
    firstOrLastName: filterQuery.firstOrLastName + "="
};
export const START_QUERY = {
    firstName: filterQuery.firstName + "_",
    lastName: filterQuery.lastName + "_",
    title: filterQuery.title + "_",
    assetGroupId: filterQuery.assetGroupId + "_",
    firstOrLastName: filterQuery.firstOrLastName + "_"
};