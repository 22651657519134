import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from 'ramda';


import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';
import Container from '@mui/material/Container';

import { onSubmitMultipleValidation, onSubmitValidation, validateTitle } from "../../../../constants/utils/validators";
import { initialCompanyForm, initialTenantFormData } from "../../../../constants/utils/formInitialData";
import * as Loadings from '../../../../constants/loadings/tenant';
import * as LoadingsFile from '../../../../constants/loadings/file';
import { useAutoComplete } from "../../../../hooks/useAutoComplete";
import FormTenant from "../info/tenant";
import CompanyModalContent from "./companyModalcontent";
import { PARTICULAR, } from "../../../../constants/utils/magicVariables";
import { CreateTenant } from "../../../../actions/tenant-api";
import { UploadProfilePhoto } from "../../../../actions/file-api";



const TenantModalContent = ({ modalClose, open, theme, newTitle, handleAddNew, ...others }) => {
    const dispatch = useDispatch();
    const tenantState = useSelector(state => state.tenant);
    const companyState = useSelector(state => state.company);
    const fileState = useSelector(state => state.file);
    const [category, setCategory] = useState(PARTICULAR);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [formData, setFormData] = useState(initialTenantFormData);
    const tenants = !R.isNil(tenantState.tenants) ? tenantState.tenants : [];
    const [hasCurrentTitle, setHasCurrentTitle] = useState(null);
    const payload = { items: tenants, properties: ["firstName", "lastName"], value: formData.firstName.value.trim() + formData.lastName.value.trim(), id: null };
    let companies = !R.isNil(companyState.companies) ? companyState.companies : [];
    const companyUseAutocomplete = useAutoComplete([initialCompanyForm], companies);

    useEffect(() => {
        setFormData({ ...formData, firstName: { ...formData.firstName, value: newTitle } });
        // eslint-disable-next-line 
    }, [newTitle]);


    const submitForm = async (event) => {
        const companyFormValidation = onSubmitMultipleValidation(companyUseAutocomplete.dropdownFormData);
        const validatedForm = onSubmitValidation(formData);
        if (validateTitle(payload)) {
            setHasCurrentTitle(true);
            return setTimeout(() => { setHasCurrentTitle(null); }, 4000);
        }
        if (!validatedForm.isValid || !companyFormValidation.isValid) {
            companyUseAutocomplete.setDropdownFormData(companyFormValidation.form);
            setFormData(validatedForm.form);
            return;
        }
        try {
            const tenant = {
                contactCategory: category,
                firstName: R.isEmpty(formData.firstName.value) ? null : formData.firstName.value.trim(),
                lastName: R.isEmpty(formData.lastName.value) ? null : formData.lastName.value.trim(),
                companyName: R.isEmpty(formData.social.value) ? null : formData.social.value.trim(),
                files: [],
                occupation: R.isEmpty(formData.profession.value) ? null : formData.profession.value,
                companyIds: companyUseAutocomplete.dropdownFormData.map(c => c.value),
                phoneNumber: R.isEmpty(formData.phone.value) ? null : formData.phone.value,
                email: R.isEmpty(formData.email.value) ? null : formData.email.value
            };
            const newTenant = await dispatch(CreateTenant(tenant));
            if (!R.isNil(profilePhoto)) {
                await dispatch(UploadProfilePhoto({ file: profilePhoto, id: newTenant.id, profile: "Tenant" }));
            }
            modalClose();
            handleAddNew({
                companyId: newTenant.companyId,
                id: newTenant.id,
                firstName: formData.firstName.value.trim() + " " + formData.lastName.value.trim()
            });
            setFormData(initialTenantFormData);
        } catch (error) {
            console.log(error);
            // if (error.response.status === 422) return;
            // toast.error("Échec de l'opération, réessayez !", toastConf);
        }
    };


    return (
        <Box {...others}>
            {hasCurrentTitle &&
                <Container maxWidth="100%" sx={{ display: "inherit", justifyContent: "inherit", gap: 2 }}>
                    <Alert sx={{ width: '100%', borderRadius: 3, marginY: 2 }} severity="error">
                        Un locataire existe déjà avec ce nom. Veuillez le sélectionner ou alors choisir un autre nom de locataire.
                    </Alert>
                </Container>
            }
            <DialogTitle variant="h4" color="primary" sx={{ pt: theme.spacing(3), pb: theme.spacing(1) }}>Ajouter Locataire</DialogTitle>
            <Stack direction="column" spacing={2}>
                <FormTenant
                    profilePhoto={profilePhoto}
                    setProfilePhoto={setProfilePhoto}
                    companies={companies}
                    category={category}
                    formData={formData}
                    setFormData={setFormData}
                    setCategory={setCategory}
                    companyUseAutocomplete={companyUseAutocomplete}
                    theme={theme}
                    mt={2} flex="1 1 auto"
                />
                {companyUseAutocomplete.isOpen &&
                    <Container>
                        <CompanyModalContent
                            modalClose={companyUseAutocomplete.setClose}
                            open={companyUseAutocomplete.isOpen}
                            theme={theme}
                            newCompanyName={companyUseAutocomplete.newOptionName}
                            handleAddNewCompany={companyUseAutocomplete.handleAddNew}
                        />
                    </Container>
                }
            </Stack>
            <DialogActions disableSpacing={true} sx={{ flexDirection: "column-reverse", p: theme.spacing(3) }}>
                <Button theme={theme} color="neutral" fullWidth onClick={modalClose} sx={{ px: theme.spacing(3) }}>Annuler</Button>
                <LoadingButton
                    onClick={(event) => submitForm(event)}
                    loading={tenantState.isLoading.includes(Loadings.CREATING_TENANT) || fileState.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
                    disabled={tenantState.isLoading.includes(Loadings.CREATING_TENANT) || fileState.isLoading.includes(LoadingsFile.UPLOADING_PROFILE_PHOTO)}
                    type="submit" size="large" fullWidth variant="contained" theme={theme} color='primary' sx={{ px: theme.spacing(3), mb: theme.spacing(1) }}>Enregistrer</LoadingButton>
            </DialogActions>
        </Box>
    );
};

export default TenantModalContent;