import axios from 'axios';
import { tiersActions } from "../reducers/tier";
import * as urls from '../constants/webapi/tier';

export const GetTier = (id) => {
    return async dispatch => {
        dispatch(tiersActions.getTierRequest());
        return axios.get(urls.getTierUrl(id))
            .then((response) => {
                dispatch(tiersActions.getTierSuccess({
                    data: response?.data
                }));
            })
            .catch((error) => {
                dispatch(tiersActions.getTiersFailure(error.response.data));
            });
    };
};

export const GetTiers = () => {
    return async dispatch => {
        dispatch(tiersActions.getTiersRequest());
        return axios.get(urls.getTiersUrl())
            .then((response) => {
                dispatch(tiersActions.getTiersSuccess({
                    data: response.data
                }));
                return response.data;
            })
            .catch((error) => {
                dispatch(tiersActions.getTiersFailure(error.response.data));
            });
    };
};