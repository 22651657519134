import React from 'react';
import { Route, Routes } from 'react-router-dom';

// Components
// Components: Global
import Box from '@mui/material/Box';

// Components: Common
import AssetsMenuViewBien from './bien';
import AssetsMenuViewImmeubles from './immeubles';
import AssetsMenuViewVendu from './vendu';
import { useTheme } from '@mui/material/styles';

export const AssetsMenuView = ({  value, setValue, handleChangeIndex, userState, companyState, tenantState, assetState, subcontractorState, collaboratorState, assetGroupState }) => {
    const theme = useTheme()
    return (
        <Box my={2} sx={{ flex: "1 1 auto", height: 'inherit', overflow: "hidden auto", WebkitOverflowScrolling: 'touch' }} axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChange={handleChangeIndex}>
            <Routes>
                <Route>
                    <Route index element={
                        <AssetsMenuViewBien
                         index={1}
                         value={value} 
                         setValue={setValue}
                         companyState={companyState} 
                         tenantState={tenantState} 
                         assetState={assetState} 
                         subcontractorState={subcontractorState} 
                         collaboratorState={collaboratorState} 
                         />
                    } />
                    <Route index path=":companyId/:id/*" element={
                        <AssetsMenuViewBien index={1} value={value} setValue={setValue}  companyState={companyState} tenantState={tenantState} assetState={assetState} subcontractorState={subcontractorState} collaboratorState={collaboratorState} />
                    } />
                </Route>
                <Route path="immeubles/*"element={
                    <AssetsMenuViewImmeubles 
                      index={0} 
                      theme={theme} 
                      value={value} 
                      setValue={setValue}
                      assetGroupState={assetGroupState} 
                      assetState={assetState}  
                    />
                } />
                <Route path="vendu/*"element={
                    <AssetsMenuViewVendu
                     index={1}
                     theme={theme}
                     value={value} 
                     setValue={setValue} 
                     assetGroupState={assetGroupState}  
                     companyState={companyState} 
                     tenantState={tenantState} 
                     assetState={assetState} 
                     subcontractorState={subcontractorState} 
                     collaboratorState={collaboratorState} 
                     />
                } />
            </Routes>
            {/* <AssetsMenuViewBien index={0} theme={theme} value={value} /> */}
            {/* <AssetsMenuViewImmeubles index={1} theme={theme} value={value} /> */}
            {/* <AssetsMenuViewVendu index={2} theme={theme} value={value} /> */}
        </Box>
    );
};

export default AssetsMenuView;
