import { createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';
import * as Loadings from '../constants/loadings/collaborator';
import { SMALL_PAGE_SIZE } from '../constants/utils/pageSize';


const initState = {
    isLoading: [],
    collaborators: undefined,
    dashBoardCollaborators: undefined,
    collaborator: undefined
};

const CollaboratorsReducer = createSlice({
    name: 'collaborator',
    initialState: { ...initState },
    reducers: {
        //#region GET COLLABORATORS
        getCollaboratorsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_COLLABORATORS)) {
                state.isLoading.push(Loadings.FETCHING_COLLABORATORS);
            }
        },
        getCollaboratorsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COLLABORATORS);
            state.collaborators = payload.data;
        },
        getCollaboratorsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COLLABORATORS);
        },

        getDashboardCollaboratorsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_COLLABORATORS)) {
                state.isLoading.push(Loadings.FETCHING_COLLABORATORS);
            }
        },
        getDashboardCollaboratorsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COLLABORATORS);
            state.dashBoardCollaborators = R.clone(payload.data).splice(0, 4);
        },
        getDashboardCollaboratorsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COLLABORATORS);
        },
        //#endregion

        //#region CREATE COLLABORATOR
        createCollaboratorRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_COLLABORATOR)) {
                state.isLoading.push(Loadings.CREATING_COLLABORATOR);
            }
        },
        createCollaboratorSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_COLLABORATOR);
            if (R.isNil(state.collaborators)) {
                state.collaborators = [];
            }
            if (!R.isNil(state.dashBoardCollaborators) && state.dashBoardCollaborators.length < SMALL_PAGE_SIZE) {
                state.dashBoardCollaborators.push(payload);
            }
            if (R.isNil(state.dashBoardCollaborators)) {
                state.dashBoardCollaborators = [];
                state.dashBoardCollaborators.push(payload);
            }
            state.collaborators.push(payload);
        },
        createCollaboratorFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_COLLABORATOR);
        },
        //#endregion

        //#region GET COLLABORATOR
        getCollaboratorRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_COLLABORATOR)) {
                state.isLoading.push(Loadings.FETCHING_COLLABORATOR);
            }
        },
        getCollaboratorSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COLLABORATOR);
            state.collaborator = payload;
        },
        getCollaboratorFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COLLABORATOR);
        },
        //#endregion

        //#region UPDATE COLLABORATOR
        updateCollaboratorRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_COLLABORATOR)) {
                state.isLoading.push(Loadings.UPDATING_COLLABORATOR);
            }
        },
        updateCollaboratorSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_COLLABORATOR);
            state.collaborators = state.collaborators.filter(collaborator => {
                if (collaborator.id === payload.id) {
                    return payload;
                }
                return payload;
            });
        },
        updateCollaboratorFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_COLLABORATOR);
        },
        //#endregion

        //#region DELETE COLLABORATOR
        deleteCollaboratorRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_COLLABORATOR)) {
                state.isLoading.push(Loadings.DELETING_COLLABORATOR);
            }
        },
        deleteCollaboratorSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_COLLABORATOR);
            state.collaborator = undefined;
            state.collaborators = state.collaborators.filter(c => c.id !== payload.id);
            if (!R.isNil(state.dashBoardCollaborators)) {
                state.dashBoardCollaborators = state.dashBoardCollaborators.filter(c => c.id !== payload.id);
            }
        },
        deleteCollaboratorFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_COLLABORATOR);
        },
        //#endregion
    }
});

export const collaboratorsActions = CollaboratorsReducer.actions;
export default CollaboratorsReducer.reducer;