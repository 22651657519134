import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import * as R from 'ramda';

// Components
// Components: Global
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';


import HomeWorkIcon from '@mui/icons-material/HomeWork';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhotoIcon from '@mui/icons-material/Photo';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

// Components: Common
import * as AssetGroupLoadings from '../../../../../constants/loadings/assetGroup';
import * as AssetLoadings from '../../../../../constants/loadings/asset';
import { dayMonthYear } from '../../../../../constants/utils/date';
import ItemCard from '../../../../_common/itemCard';
import { ModalDescription } from './modalDescription';

import { imageTypes } from '../../../../../constants/utils/filesTypes';


// Components: View

export const AssetsViewDetailHeader = ({ theme, path, isImmeuble, isVendu, title, description, assetGroupState, assetState, companyState, modalOpenDelete, openDelete, modalOpenCarousel, openCarousel, permissions, openPermissionModal }) => {
    // const item = (assetGroupState ? assetGroupState.assetGroup : assetState && assetState.asset);
    // let item = isImmeuble && assetGroupState ? assetGroupState.assetGroup :  assetState ? assetState.asset : assetState.soldAsset;
    let item = isImmeuble ? assetGroupState && assetGroupState.assetGroup : isVendu ? assetState && assetState.soldAsset : assetState && assetState.asset;
    const [anchorOption, setAnchorOption] = useState(null);
    const [openDescription, setOpenDescription] = useState(false);
    const optionsMenu = [
        // { path: null, name: 'Importer photo' },
        { path: '/app/edit/', name: 'Éditer information' },
        // { path: null, name: 'Télécharger tous les documents' },
    ];
    const showMe = Boolean(anchorOption);
    const [toggle, setToggle] = React.useState('left');

    const handleToggle = (event, newToggle) => {
        setToggle(newToggle);
    };
    const handleOpenDescription = () => {
        setOpenDescription(true);
    }

    const handleClick = (e) => {
        e.preventDefault();
        setAnchorOption(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorOption(null);
    };


    return (
        assetState.isLoading.includes(isImmeuble ? AssetGroupLoadings.FETCHING_ASSETGROUP : isVendu ? AssetLoadings.FETCHING_SOLD_ASSET : AssetLoadings.FETCHING_ASSET) ?
            <Box component="header" py={2} pt={{ md: 4 }}>
                <Container maxWidth="100%" sx={{ alignItems: 'center', justifyContent: "space-evenly" }}>
                    <ItemCard loading />
                </Container>
            </Box>
            :
            !R.isNil(item) &&
            <Box component="header"
             sx={{ 
                zIndex: 0,
                borderBottomLeftRadius: '1.25rem',
                borderBottomRightRadius: '1.25rem',
                backgroundSize: 'cover', 
                backgroundAttachment: 'fixed', 
                backgroundPosition: 'center bottom', 
                //backgroundImage: "url( " + (!R.isNil(item.profileUrl?.url) && item.profileUrl?.url) + ")" 
                }} 
                py={2} 
                pt={{ md: !R.isNil(item.profileUrl?.url) ? 8 : 4 }}
                position="relative"
                top="0"
                maxWidth="100%" 
                backgroundColor="background.paper"
                color={!R.isNil(item.profileUrl?.url) ? "background.paper" : "text.dark"}
                >
                <Container maxWidth="100%" sx={{ alignItems: 'center', justifyContent: "space-evenly" }} >
                    <Stack direction="column" flex="1 1 auto" justifyContent="space-between" spacing={1}>
                        <Stack direction={{ default: "column", xs: "row" }} sx={{ gap: 2 }} justifyContent="space-between">
                            <Avatar
                                children={!R.isNil(item.title) ? item.title.charAt(0) : !R.isNil(title) ? title.charAt(0) : 'A'}
                                sx={{
                                     color: "secondary.contrastText", 
                                     backgroundColor: (isImmeuble ? R.isNil(item.profileUrl) : R.isNil(item.profileUrl?.url)) ? "secondary.main" : "transparent", 
                                     width: !R.isNil(item.profileUrl?.url) ? '6rem' : '4.5rem', 
                                     height:  !R.isNil(item.profileUrl?.url) ? '6rem' : '4.5rem', 
                                     fontWeight: 'bold', 
                                    //  border: (isImmeuble ? !R.isNil(item.profileUrl) : !R.isNil(item.profileUrl?.url)) && ('.125rem solid' + theme.palette.secondary.main), 
                                     padding: isImmeuble ? !R.isNil(item.profileUrl) : !R.isNil(item.profileUrl?.url) && '.25rem' }} 
                                imgProps={{ sx: { borderRadius: 'inherit' } }}
                                variant="circular"
                                srcSet={!R.isNil(item.profileUrl?.url) ? item.profileUrl?.url : null} />
                            <Stack direction="column" spacing={.25} flex="1 1 auto" my="auto">
                                <Typography variant="h5" sx={{ lineHeight: 1, color: "text.main" }}>{!R.isNil(item.title) ? item.title : !R.isNil(title) ? title : 'Asset'}</Typography>
                                <Typography
                                 variant="body1" 
                                 color="inherit" 
                                 sx={{ opacity: ".54", textDecoration: "none", color: "text.main" }} 
                                 component={RouterLink} 
                                 to={"/app/companies/" + companyState.companies?.filter(company => item.companyId.includes(company.id)).map(c => c.id)}>
                                    {!R.isNil(companyState.companies) && !R.isEmpty(companyState.companies) && companyState.companies.filter(company => item.companyId.includes(company.id)).map(c => c.title).join(', ')}
                                </Typography>
                            </Stack>
                            <Box position="relative" mt={.75}>
                                <IconButton sx={{ justifyContent: "space-between", maxWidth: { sm: "fit-content", } }} variant="contained" color='text.main' id="optionButtom" aria-controls={showMe ? 'optionMenu' : undefined} aria-haspopup="true" aria-expanded={showMe ? 'true' : undefined} onClick={handleClick}>
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu id="optionMenu" anchorEl={anchorOption} open={showMe} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'optionButtom' }}>
                                    {optionsMenu.map(({ path, name }, index) => (
                                        permissions.includes("Update") ?
                                            <MenuItem component={RouterLink} to={path ? (path.split("/").includes('edit') ? path + (!isImmeuble ? 'asset/' : 'immeuble/') + item.companyId + '/' + item.id : path) : ''} key={(isImmeuble ? 'immeuble' : isVendu ? 'sold' : 'asset') + index + path} onClick={handleClose}>
                                                {name}
                                            </MenuItem>
                                            :
                                            <MenuItem key={(isImmeuble ? 'immeuble' : isVendu ? 'sold' : 'asset') + index + path} onClick={() => { openPermissionModal(); handleClose(); }}>
                                                {name}
                                            </MenuItem>
                                    ))}
                                    {
                                        permissions.includes("Delete") ?
                                            <MenuItem onClick={modalOpenDelete} variant="text" color="inherit" size="inherit" key="effacer">
                                                Effacer
                                            </MenuItem>
                                            :
                                            <MenuItem onClick={() => { openPermissionModal(); handleClose(); }} variant="text" color="inherit" size="inherit" key="effacer">
                                                Effacer
                                            </MenuItem>
                                    }
                                    <Divider />
                                    <MenuItem component={RouterLink} to={"/app/assets" + (isImmeuble ? "/immeubles" : "")} key="close">
                                        <CloseIcon fontSize='medium' /> <Box component="span" sx={{ color: 'text.secondary', mx: 1 }}>Fermer</Box>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Stack>
                        <Stack direction="column" py={1}>
                            <Stack direction={{ default: "column", hd: "row" }} spacing={.5} mb={{ default: 2, md: 0.5 }}>
                                <Typography variant="body1" color='text.main'>
                                    Adresse de l'immeuble: {!R.isNil(item.address.addressLine2) ? <strong>{item.address.addressLine2}</strong> : 
                                    <Typography component="span" color="text.main">Le champ est vide</Typography>}
                                </Typography>
                                <Typography 
                                 sx={{ lineHeight: 1, opacity: ".66", color: "text.main" }} 
                                 display={{ default: 'none', hd: 'inherit' }} 
                                 color='text.main'
                                 variant="h6" component="p" px={2}>
                                    •
                                </Typography>
                                <Stack direction={{ default: "column", lg: "row" }} spacing={.5}>
                                    <Typography color='text.main' variant="body1">Code Postal: {!R.isNil(item.address.postalCode) ? <strong>{item.address.postalCode}</strong> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>

                                    <Typography color='text.main' sx={{ lineHeight: 1, opacity: ".66" }} display={{ default: 'none', lg: 'inherit' }} variant="h6" component="p" px={2}>•</Typography>

                                    <Typography color='text.main' variant="body1">Ville: {!R.isNil(item.address.locality) ? <strong>{item.address.locality}</strong> : <Typography component="span" color="text.hint">Le champ est vide</Typography>}</Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={{ default: "column", md: "row", lg: "column", xl: "row" }} spacing={.5} justifyContent={{ md: "space-between" }} alignItems={{ md: "center" }}>
                                <Stack spacing={.25} justifyContent={{ md: "space-between" }}>
                                    <Stack direction={{ default: "column", md: "row" }} spacing={.5} alignItems={{ md: "center" }}>
                                        <Typography color='text.main' variant="body1"><Box component="span">Date: </Box>  <strong>{!R.isNil(item.modifiedOn) ? format(new Date(item.modifiedOn), dayMonthYear) : 'DD/MM/YYYY'}</strong></Typography>
                                        {isImmeuble ?
                                            <React.Fragment>
                                                <Typography color='text.main' sx={{ lineHeight: 1, opacity: ".66" }} display={{ default: 'none', sm: 'inherit' }} variant="h6" component="p" px={2}>•</Typography>
                                                <Typography color='text.main' variant="body1">Total Biens: <strong> {!R.isEmpty(assetState.assets) && assetState.assets?.filter(a => a.assetGroupId === assetGroupState.assetGroup.id)?.length || 0} </strong></Typography>
                                            </React.Fragment>
                                            :
                                            !R.isEmpty(item.assetGroupId) && assetGroupState.assetGroups?.find(ag => item.assetGroupId === ag.id) &&
                                            assetGroupState.assetGroups?.filter(ag => item.assetGroupId === ag.id).map((item,index) => (
                                                <React.Fragment key={index}>
                                                    <Typography color='text.main' sx={{ lineHeight: 1, opacity: ".66" }} display={{ default: 'none', lg: 'inherit' }} variant="h6" component="p" px={2}>•</Typography>
                                                    <Typography color='text.main' display="flex" alignItems="center" gap={1} aria-label="go to immeuble" sx={{ textDecoration: "none" }} >
                                                        Associé à:
                                                        <Box component={RouterLink} to={`/app/assets/immeubles/${item.companyId}/${item.id}`} fontWeight="bold" color='text.main' mx={1} display="flex" alignItems="center" gap={1} key={item.id}>
                                                            <HomeWorkIcon fontSize="small" />
                                                            {item.title}
                                                        </Box>
                                                    </Typography>


                                                </React.Fragment>
                                            ))
                                        }
                                        <Typography color='text.main' sx={{ lineHeight: 1, opacity: ".66" }} display={{ default: 'none', lg: 'inherit' }} variant="h6" component="p" px={2}>•</Typography>


                                        <Typography color='text.main' variant="body1">Wifi: {!R.isNil(item.assetFields) && item.assetFields.find((a) => a.name.toLowerCase() === "wifi") ? <strong>{item.assetFields.find((a) => a.name.toLowerCase() === "wifi").value}</strong> : <Typography component="span" color="inherit" sx={{ opacity: ".66" }}>Le champ est vide</Typography>}</Typography>

                                    </Stack>
                                    {!isImmeuble &&
                                        <Stack direction={{ default: "column", md: "row" }} spacing={.5} alignItems={{ md: "center" }}>
                                            <Typography color="secondary.main" variant="body1" noWrap maxWidth={{ default: '30em', lg: '40em' }}>Description: {!R.isNil(item.description) ? <strong>{item.description}</strong> : <Typography component="span" color="inherit" sx={{ opacity: ".66" }}>Le champ est vide</Typography>}</Typography>
                                            {item.description?.length > 46 &&
                                                <IconButton sx={{ justifyContent: "space-between", maxWidth: { sm: "fit-content" } }} variant="contained" color="inherit" id="descriptionButtom" onClick={handleOpenDescription}>
                                                    <UnfoldMoreIcon />
                                                </IconButton>}
                                        </Stack>
                                    }
                                </Stack>
                                {!isImmeuble &&
                                    <Box sx={{ width: "fit-content", borderRadius: 5, bgcolor: "background.default", ml: 'auto' }}>
                                        <ToggleButtonGroup exclusive aria-label="Type de fichier" value={toggle} onChange={handleToggle}>
                                            <ToggleButton value="gallery" sx={{ paddingY: .5, color: "text.main", fontSize: ".75rem", alignItems: "center", gap: .5, pointerEvents: "auto" }} onClick={modalOpenCarousel} aria-label="gallery">
                                                <PhotoIcon />
                                                <Box component="span" sx={{ display: "inherit", alignItems: "inherit", gap: .5 }}>
                                                    <Box component="span" lineHeight="1">
                                                        {item.files.filter(c => imageTypes.includes(c.contentType)).length}
                                                    </Box>
                                                    <Box component="span" lineHeight="1">
                                                        Photos
                                                    </Box>
                                                </Box>
                                            </ToggleButton>
                                            {/* <ToggleButton value="plan" sx={{ paddingY: .5, color: "text.main", fontSize: ".75rem", verticalAlign: "center", gap: .5, pointerEvents: "none" }} aria-label="floor plan">
                                                <SquareFootIcon />
                                                <Box component="span" sx={{ display: "inherit", alignItems: "inherit", gap: .5 }}>
                                                    <Box component="span" lineHeight="1">
                                                        {item.files.filter(c => !imageTypes.includes(c.contentType)).length}
                                                    </Box>
                                                    <Box component="span" lineHeight="1">
                                                        Autres
                                                    </Box>
                                                </Box>
                                            </ToggleButton> */}
                                        </ToggleButtonGroup>
                                    </Box>
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                    <ModalDescription
                        theme={theme}
                        description={item.description}
                        modalClose={() => {
                            setOpenDescription(null);
                        }}
                        open={openDescription}
                    />
                </Container>
                {/* {
                (isImmeuble ? !R.isNil(item.profileUrl) : !R.isNil(item.profileUrl?.url)) && 
                    <Box 
                       position="absolute" 
                       sx={{
                       borderRadius: 'inherit', 
                       backdropFilter: 'blur(.25rem)', 
                       backgroundImage: ('linear-gradient(180deg,' + theme.palette.background.dim + '30%, ' + theme.palette.background.main + ' 100%)'), zIndex: -1 }}
                        display="block" left="0" bottom="0" top="0" right="0"></Box>} */}
            </Box>
    );
};

export default AssetsViewDetailHeader;
