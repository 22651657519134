import { useDispatch, useSelector } from 'react-redux';
import { DeleteFiles } from '../actions/file-api';
import {GetRental} from '../actions/rental-api';
import * as FileLoadings from '../constants/loadings/file';

export const useDeleteFile = () => {
    const fileState = useSelector(state => state.file);
    const dispatch = useDispatch();

    const deleteFileProp = (selectedFile, fileType, companyId) => async () => {
        await dispatch(DeleteFiles([selectedFile], selectedFile.objectId, fileType));
        switch (fileType) {
            case 'Rental': {
                dispatch(GetRental(companyId, selectedFile.objectId))
                break;
            }
        }
    };

    return (
        {
            isLoading: fileState.isLoading.includes(FileLoadings.DELETING_FILES),
            isDisabled: fileState.isLoading.includes(FileLoadings.DELETING_FILES),
            dispatchDelete: deleteFileProp,
        }
    );
};
